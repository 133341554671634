import React from 'react';

const SubIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14">
		<g fill="#FFF" fillRule="evenodd">
			<path d="M16.54 7.43l-5.902-.428a.408.408 0 0 0-.38.189.438.438 0 0 0-.028.43l.702 1.427c-2.509 2.168-8.077 1.304-10.31.548a.416.416 0 0 0-.475.155.436.436 0 0 0 .005.509c2.525 3.426 9.156 4.444 12.825 2.09l.627 1.275a.421.421 0 0 0 .377.237h.007a.42.42 0 0 0 .376-.249l2.53-5.576a.437.437 0 0 0-.02-.398.421.421 0 0 0-.333-.209zM.46 6.57l5.902.428.03.002a.42.42 0 0 0 .359-.204.437.437 0 0 0 .019-.417l-.702-1.427c2.51-2.167 8.078-1.303 10.31-.548a.416.416 0 0 0 .475-.155.436.436 0 0 0-.005-.509C14.323.314 7.692-.704 4.023 1.65L3.396.374a.429.429 0 0 0-.384-.237.42.42 0 0 0-.376.249L.106 5.963a.437.437 0 0 0 .02.398c.07.12.195.2.333.209z"/>
		</g>
	</svg>
);

export default SubIcon;
