import React from 'react';
import classNames from 'classnames';
import { Constants } from '../../helpers/Constants';
import { TeamSVGMap } from '../../helpers/TeamSVGMap';
import getGameTimeInfo from '../../helpers/getGameTimeInfo';
import './NFLScore.scss';

const nonLivePhases = ['PREGAME', 'FINAL', 'FINAL_OVERTIME', 'PRE'];

const _renderPhase = (scoreData) => {
	const timeData = scoreData.score;
	if(!timeData || timeData.phase === 'PREGAME' || timeData.phase === 'FINAL' || timeData.phase === 'FINAL_OVERTIME') {
		return null;
	} else if (timeData.phase === 'PRE') {
		const { time } = getGameTimeInfo({ gameTime: scoreData.isoTime });

		return time;
	}

	return `${timeData.phase}: ${timeData.time}`;
};

const _renderSchedule = (gameData) => {
	const { time } = getGameTimeInfo(gameData);
	return (
		<div className='nfl-score__time-container'>
			<div className='nfl-score__time'>{time}</div>
		</div>
	);
};

const _renderScore = (showScore, scoreData) => (
	<div className='nfl-score__score-container'>
		<div className='nfl-score__score nfl-score__score--away'>{showScore ? scoreData.score.away : '-'}</div>
		<div className='nfl-score__score-separator' />
		<div className='nfl-score__score nfl-score__score--home'>{showScore ? scoreData.score.home : '-'}</div>
	</div>
);

const NFLScore = ({gameData, scoreData = {}, type}) => {
	let AwayIcon, HomeIcon, awayName, homeName, showScore;

	if(Object.keys(scoreData).length > 0) {
		AwayIcon = TeamSVGMap.teamMap[scoreData.awayTeam];
		HomeIcon = TeamSVGMap.teamMap[scoreData.homeTeam];
		awayName = scoreData.awayDisplayName;
		homeName = scoreData.homeDisplayName;
		showScore = scoreData.score.phase !== 'PRE';
	} else if(gameData) {
		AwayIcon = TeamSVGMap.teamMap[gameData.away];
		HomeIcon = TeamSVGMap.teamMap[gameData.home];
		awayName = Constants.teamNicknameMap[gameData.away];
		homeName = Constants.teamNicknameMap[gameData.home];
	} else {
		return null;
	}

	const gameTime = type === 'schedule' ? gameData.gameTime : scoreData.isoTime;
	const clsName = classNames('nfl-score', {
		'nfl-score--complete': scoreData.score && (scoreData.score.phase === 'FINAL' || scoreData.score.phase === 'FINAL_OVERTIME'),
		'nfl-score--live': scoreData.score && !nonLivePhases.includes(scoreData.score.phase)
	})
	return (
		<div className={clsName}>
			<div className='nfl-score__team-data'>
				<div className='nfl-score__team-nickname nfl-score__team-nickname--away'>{awayName}</div>
				<div className='nfl-score__team nfl-score__team--away'>
					<AwayIcon />
				</div>
				{type === 'schedule' || !showScore  ? _renderSchedule(gameTime) : _renderScore(showScore, scoreData)}
				<div className='nfl-score__team nfl-score__team--home'>
					<HomeIcon />
				</div>
				<div className='nfl-score__team-nickname nfl-score__team-nickname--home'>{homeName}</div>
			</div>
			<div className='nfl-score__clock-data'>
				{_renderPhase(scoreData)}
			</div>
		</div>
	)
};

export default NFLScore;
