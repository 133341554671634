import React from 'react';
import PointsStrategy from '../../../../helpers/PointsStrategy';
import Helmet from '../../../svgs/Helmet';
import './PlayerStat.scss';

const PlayerStat = ({stat, statType}) => {
	let statInfo = PointsStrategy[statType].find(ptsStat => ptsStat.name === stat.name);

	if(stat.name === 'ptsConceded' && stat.hasOwnProperty('value')) {
		statInfo = PointsStrategy.defensive.find(s => s.name === 'ptsConceded0');
	}

	if(!statInfo || (statInfo.text && stat.value === 0 && stat.name !== 'ptsConceded')) {
		return null;
	}

	const value = Array.isArray(stat.value) ? stat.value.reduce((prev, curr) => prev + curr, 0) : stat.value;

	const StatIcon = statInfo.icon || Helmet;
	return (
		<div className='player-stat'>
			<div className='player-stat__icon'><StatIcon /></div>
			<div className='player-stat__description-wrapper'>
				<div className='player-stat__text'>{statInfo.text || 'Points conceded'}</div>
				<div className='player-stat__value'>{value}</div>
			</div>

		</div>
	)
};

export default PlayerStat;
