import React, {Component} from 'react';
import { connect } from 'react-redux';
import Countdown from '../../components/Common/Countdown';
import Header from '../../components/Common/Header';
import Button from '../../components/Common/Button';
import DraftRound from '../../components/DraftRoom/DraftRound';
import DraftClock from '../../components/DraftRoom/DraftClock';
import hasDraftStarted from '../../helpers/hasDraftStarted';
import isDraftLive from '../../helpers/isDraftLive';
import './DraftHeader.scss';

class DraftHeader extends Component {
	static defaultProps = {
		gameWeekTeams: []
	};

	calculateCountdownTime(inPreDraftState, draftClock) {
		if(inPreDraftState && this.props.savedDraft.draft) {
			return new Date(this.props.savedDraft.draft.draftStartDate);
		}

		return new Date(draftClock.endTime);
	}

	getUserName(user) {
		if(!user) {
			return null;
		}
		if(user.userName) {
			return user.userName;
		}

		const foundUser = this.props.savedDraft.users.find(u => u.userId === user.userId ||  u.userId === user);
		return foundUser ? foundUser.userName : null;
	}

	renderCallToActions(canNotRequestTime, shouldRenderPreDraftHeader) {
		const pauseText = this.props.isPaused ? 'Unpause' : 'Pause Draft'

		return (
			<>
				{shouldRenderPreDraftHeader && this.props.isCommissioner ?
					<Button type={'secondary'} text={'Start Draft'} onClick={this.props.onDraftStart} disabled={!this.props.savedDraft ||!this.props.savedDraft.draft.draftId}/> : null}
				{!shouldRenderPreDraftHeader && !this.props.isPaused && this.props.draftClock.user && this.props.draftClock.user.userId === this.props.userId ?
					<Button type={'secondary'} text={'Request more time'} disabled={canNotRequestTime} onClick={this.props.onMoreTimeRequested} /> : null }
				{!shouldRenderPreDraftHeader && this.props.isCommissioner && <Button type={'tertiary'} text={pauseText} dataAttributes={{'data-pause': `${!!this.props.draftClock.isPaused}`}} onClick={this.props.togglePause} />}
			</>
		)
	}

	renderCountdown (canNotRequestTime, countdownTime, shouldRenderPreDraftHeader) {
		if(this.props.isDraftComplete && !this.props.isCommissioner) {
			return null;
		} else if (this.props.isDraftComplete && !this.props.savedDraft.league.season) {
			return <Button type={'tertiary'} text={'Start Season'} onClick={this.props.onSeasonStart} />
		} else if(this.props.isDraftComplete) {
			return null;
		}

		return (
			<>
				{!this.props.isPaused && <Countdown timeToCountdownTo={countdownTime} draftMode={!shouldRenderPreDraftHeader} preDraftMode={shouldRenderPreDraftHeader} />}
				{this.renderCallToActions(canNotRequestTime, shouldRenderPreDraftHeader)}
			</>
		);
	}

	renderHeaderContext(round, currentPick, draftClock, shouldRenderPreDraftHeader) {
		if(shouldRenderPreDraftHeader || this.props.isDraftComplete) {
			return null;
		}

		if(this.props.isCommissioner && this.props.editDraftPick.pick) {
			const { round, pick, playerName, userName } = this.props.editDraftPick;
			return (
				<div className='draft-header__edit-mode-wrapper'>
					<Header headerLevel={'h2'} text={'You are in edit mode'} secondaryText={`Currently editing pick ${pick} from round ${round}. ${playerName.display} currently belongs to ${userName}. Please go to player pool and press draft on who you want to replace ${playerName.display} for. To exit edit mode, press the edit icon again.`} />
				</div>
			);
		}

		return (
			<>
				<DraftRound round={round} pick={currentPick}/>
				<DraftClock isPaused={this.props.isPaused} onTheClock={this.getUserName(draftClock.user)} upNext={draftClock.next ? this.getUserName(draftClock.next) : ''} />
			</>
		)
	}

	render () {
		const draftClock = this.props.draftClock;
		const canNotRequestTime = draftClock.hasRequestedMoreTime;
		const currentPick = this.props.picksMade + 1;
		const round = Math.ceil(currentPick / this.props.numOfUsers);

		const shouldRenderPreDraftHeader = (!this.props.savedDraft.draft || !hasDraftStarted(this.props.savedDraft.draft.draftStartDate)) && !isDraftLive(this.props.draftClock);
		const countdownTime = this.calculateCountdownTime(shouldRenderPreDraftHeader, draftClock);
		const leagueName = this.props.savedDraft.league ? this.props.savedDraft.league.leagueName : '';

		return (
			<div className='draft-header'>
				<div className='draft-header__core'>
					<div className='draft-header__detail-wrapper'>
						<Header headerLevel={'h1'} text={leagueName} />
						{this.renderHeaderContext(round, currentPick, draftClock, shouldRenderPreDraftHeader)}
					</div>
					<div className='draft-header__time-wrapper'>
						{this.renderCountdown(canNotRequestTime, countdownTime, shouldRenderPreDraftHeader)}
					</div>
				</div>
				<div className='draft-header__mobile-actions'>
					{this.renderCallToActions(canNotRequestTime, shouldRenderPreDraftHeader)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ( state ) => {
	return {
		draftClock: state.draftClock,
		editDraftPick: state.editDraftPick,
		savedDraft: state.draftData,
	};
};


export default connect(mapStateToProps)(DraftHeader);
