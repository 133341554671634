import React, {Component} from 'react';
import LeagueStandings from '../LeagueStandings/LeagueStandings';
import LiveScores from '../../components/LiveScores';
import VisualSeparator from '../../components/Common/VisualSeparator';
import UserGWTally from '../../components/UserGWTally/UserGWTally';
import calculatePlayersPlayed from '../../helpers/calculatePlayersPlayed';
import PerfectTeam from '../../components/PerfectTeam';
import H2HFixtures from '../H2HFixtures/H2HFixtures';
import {Constants} from '../../helpers/Constants';

class LiveGameDay extends Component {

	calculateCurrentPlayersPlayed (games, gameWeek) {
		const userTeam = this.findUserTeam();
		if(!userTeam || !games || this.props.seasonUserTeams.length === 0 || this.props.gameWeekTeams.length === 0) {
			return 0;
		}

		const seasonUserTeam = this.props.seasonUserTeams.find(t => t.hashedUser === userTeam.managerId);
		return calculatePlayersPlayed(games, gameWeek, this.props.gameWeekTeams, this.props.players, seasonUserTeam, this.props.selectedLeague);
	}

	findUserTeam() {
		return this.props.standings.find(t => t.managerId === this.props.shakitzUser.userId);
	}
	render () {
		const gameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
		const userTeam = this.findUserTeam() || {};
		const scheduleData = this.props.schedule[this.props.selectedLeague.type][gameWeek];
		const games = scheduleData.games;

		const gwPoints = userTeam.gwPoints || 0;
		let averageTally = this.props.standings.reduce((prev, curr) => prev + curr.gwPoints, 0) / this.props.standings.length;
		if(isNaN(averageTally)) {
			averageTally = 0;
		} else {
			averageTally = Math.round(averageTally * 100) / 100;
		}

		const validFixtures = this.props.allFixtures.filter(fixture => fixture.gameWeek === this.props.h2hGameWeekToggle);

		return (
			<>
				<UserGWTally averageTally={averageTally} gameWeek={gameWeek} gwOver={false} userPlayersPlayed={this.calculateCurrentPlayersPlayed(games, gameWeek)} userTally={gwPoints} />
				<div className='game-day__section'>
					<LeagueStandings currentGameWeek={gameWeek} games={games} gameWeekTeams={this.props.leagueGameWeekTeams[this.props.selectedLeague.leagueId]} historicStandings={this.props.historicStandings} gwState={this.props.gwState} players={this.props.players} onUserClick={this.props.onUserClick} seasonUserTeams={this.props.seasonUserTeams} selectedLeague={this.props.selectedLeague} standings={this.props.standings} />
					<VisualSeparator />
					{validFixtures.length > 0 && (
						<>
							<H2HFixtures currentGameWeek={gameWeek} fixtures={this.props.allFixtures} gameWeekToShow={this.props.h2hGameWeekToggle} maxGameWeeks={Constants.numOfGameWeeks[this.props.selectedLeague.type]} liveMode={true} />
							<VisualSeparator />
						</>
					)}
				</div>
				<div className='game-day__section'>
					<LiveScores />
					<VisualSeparator />
					<PerfectTeam />
				</div>
				<VisualSeparator />
			</>
		);
	}
}

export default LiveGameDay;
