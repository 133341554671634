import axios from 'axios';
import { Constants } from '../helpers/Constants';
import Keys from 'shakitz-keys';
export const FETCH_WAIVERS_REQUEST = 'FETCH_WAIVERS_REQUEST';
export const FETCH_WAIVERS_SUCCESS = 'FETCH_WAIVERS_SUCCESS';
export const FETCH_WAIVERS_FAILURE = 'FETCH_WAIVERS_FAILURE';

export function fetchWaiversRequest ( isFetching ) {
	return {
		type: FETCH_WAIVERS_REQUEST,
		isFetching
	};
}

export function fetchWaiversFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_WAIVERS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchWaiversSuccess( data, leagueId ) {
	return {
		type: FETCH_WAIVERS_SUCCESS,
		data,
		leagueId
	};
}

// Handle getting it in usecase where we do not have id or have it (like in case with delete waiver flow)
export function fetchWaivers (leagueId, season, seasonUserTeamId, gameWeek, id = null) {
	return ( dispatch ) => {
		dispatch(fetchWaiversRequest(true));
		const waiversId = id || Keys.generateWaiver({seasonUserTeamId, gameWeek});
		const url = Constants.urls.GRAPHQL;
		const query = `query {
			leagueUsers(leagueId: "${leagueId}") {
			    userId
			    name
			  }
			  seasonUserTeams(leagueId: "${leagueId}", season: "${season}") {
				seasonUserTeamId
				hashedUser
			    teamName
			    shortTeamName
			  }
			  waivers(leagueId:"${leagueId}", season: "${season}", waiverId: "${waiversId}") {
			    active{
			      leagueId
			      season
			      waiversList {
			        priority
			        playerIn {
			          gameWeek
			          playerId
			          gameWeekPlayerId
			        }
			        playerOut {
			          gameWeek
			          playerId
			          gameWeekPlayerId
			        }
			      }
			    }
			    history{
			      gameWeek2 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
	              gameWeek3 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek4 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek5 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek6 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek7 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek8 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek9 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek10 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek11 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek12 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek13 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek14 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek15 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek16 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			      gameWeek17 {
			        seasonUserTeamId
			        playerIn {
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			        playerOut{
			          playerId
			          gameWeekPlayerId
			          gameWeek
			        }
			      }
			    }
			  }
			}
			`;
		axios.post(url, {
			query: query.replace(/\t+/g, "")
		})
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchWaiversRequest(false));

			return response.data.data;
		}).then(data => dispatch(fetchWaiversSuccess(data, leagueId))).catch(err => dispatch(fetchWaiversFailure(err)));
	};
}
