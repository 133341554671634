import React, {Component} from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import auth0 from 'auth0-js';
import { Constants } from '../../helpers/Constants'

class Login extends Component {

	constructor(props) {
		super(props);

		const opts = {
			domain: Constants.auth0[process.env.NODE_ENV].domain,
			clientID: Constants.auth0[process.env.NODE_ENV].clientId,
			redirectUri: Constants.auth0[process.env.NODE_ENV].uri,
			responseType: 'token id_token',
			scope: 'openid'
		};

		this.auth0 = new auth0.WebAuth(opts);

		this.accessToken = '';
		this.idToken = '';
		this.expiresAt = -1;

		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.handleAuthentication = this.handleAuthentication.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.getAccessToken = this.getAccessToken.bind(this);
		this.getIdToken = this.getIdToken.bind(this);
		this.renewSession = this.renewSession.bind(this);
	}

	componentDidMount() {
		// If we have a hash we have authenticated so lets handle it.
		if(this.props.location.hash) {
			this.handleAuthentication();
		} else if (this.props.location.search.includes('login=true')) {

			// Determine whether to renew the users session or log them in
			if (localStorage.getItem('isLoggedIn') === 'true') {
				this.renewSession();
			} else {
				this.login();
			}
		} else {
			this.logout();
		}
	}

	handleAuthentication() {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
			} else if (err) {
				console.log(err);
				this.props.history.push('/');
				//alert(`Error: ${err.error}. Check the console for further details.`);
			}
		});
	}

	getAccessToken() {
		return this.accessToken;
	}

	getIdToken() {
		return this.idToken;
	}

	setSession(authResult) {
		const userObj = authResult.idTokenPayload['https://shakitz.com'];

		this.props.setUserContext({ isAuthenticated: true, userObj: userObj.gmail});

		// Clear out hasAnimated flags
		for(let i = 0; i < 144; i++) {
			localStorage.removeItem('draftPick-' + i);
		}

		// Set isLoggedIn flag in localStorage for refresh
		localStorage.setItem('szaud', authResult.idToken);
		// Update axios to use new token
		axios.defaults.headers.common['Authorization'] = `Bearer ${authResult.idToken}`;
		localStorage.setItem('isLoggedIn', 'true');
		let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
		userObj.expiresAt = expiresAt;
		localStorage.setItem('tokenPayload', JSON.stringify(userObj));
		// Set the time that the access token will expire at
		this.accessToken = authResult.accessToken;
		this.idToken = authResult.idToken;
		this.expiresAt = expiresAt;

		// navigate to the home route
		this.props.history.push('/');
	}

	renewSession() {
		this.auth0.checkSession({}, (err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.setSession(authResult);
			} else if (err) {
				this.logout();
				console.log(err);
				//alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
			}
		});
	}

	login() {
		this.auth0.authorize();
	}

	logout() {
		/** TODO: May need to move this to local storage / store in case component gets torn down **/
		// Remove tokens and expiry time
		this.accessToken = null;
		this.idToken = null;
		this.expiresAt = 0;

		localStorage.removeItem('szaud');
		localStorage.removeItem('isLoggedIn');
		localStorage.removeItem('tokenPayload');
		this.props.setUserContext({
			isAuthenticated: false,
			userObj: {}
		});

		this.auth0.logout();
		// navigate to the home route
		this.props.history.push('/');
	}

	isAuthenticated() {
		// Check whether the current time is past the
		// access token's expiry time
		let expiresAt = this.expiresAt;
		return new Date().getTime() < expiresAt;
	}

	render () {
		return <></>;
	}
}

export default withRouter(Login);
