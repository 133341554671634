import React from 'react';
import './RoundRecord.scss';

const RoundRecord = ({type, value}) => (
	<div className='round-record'>
		<p className='round-record__description'>Round {type}:</p>
		<span className='round-record__value'>{value}</span>
	</div>
);

export default RoundRecord;
