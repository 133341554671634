import React, {Component} from 'react';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { Constants } from '../../helpers/Constants';
import { GA } from '../../helpers/ga';
import getPlayerShortName from '../../helpers/getPlayerShortName';
import DraftPlayerCard from '../../components/DraftRoom/DraftPlayerCard';
import DraftPlayerModal from '../../components/DraftRoom/DraftPlayerModal';
import withDraftPlayer from '../../HOC/withDraftPlayer';
import withPlayerPool from '../../HOC/withPlayerPool';
import Modal from '../Modal/Modal';
import PaginationToggle from '../../components/Common/Toggle/PaginationToggle';
import './DraftPlayerPool.scss';

class DraftPlayerPool extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};

		this.draftPlayer = this.draftPlayer.bind(this);
		this.onModalClose = this.onModalClose.bind(this);
		this.onPlayerClick = this.onPlayerClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.draftPickIsPosting && !this.props.draftPickIsPosting) {
			this.setState({
				activePlayer: null,
				showModal: false
			});
		}
	}

	componentWillUnmount () {
		if(this.timeout) {
			this.timeout = clearTimeout(this.timeout);
		}
	}

	draftPlayer(activePlayer) {
		const draftPickObj = this.props.generateDraftPick(activePlayer, this.props.userId);
		this.props.postDraftPick(draftPickObj);
	}

	onModalClose() {
		document.body.classList.add('animate-modal-out');
		this.timeout = setTimeout(() => {
			this.setState({showModal: false});
			document.body.classList.remove('animate-modal-out');
		}, Constants.modalAnimationTimeout);
	}

	onPlayerClick(evt) {
		evt.preventDefault();
		let target = evt.target;
		if(target.nodeName === 'path') {
			target = target.parentNode.parentNode;
		} else if (target.nodeName === 'svg') {
			target = target.parentNode;
		}

		const type = target.getAttribute('data-action-type');
		const playerId = target.getAttribute('data-player-id');

		if(!type) {
			return;
		}

		const activePlayer = this.props.playersToShow.find(player => `${player.playerId}` === playerId);
		if (type === 'info') {
			this.setState({showModal: true, activePlayer });
		} else if (type === 'draft') {
			this.draftPlayer(activePlayer);
			ReactGA.event({
				category: GA.CATEGORY.DRAFT,
				action: GA.ACTION.DRAFT_VIA_CARD
			});
		}
	}

	render () {
		const clsName = classNames('draft-player-pool', { 'player-pool--focus': this.props.searchInFocus});
		const modalName = `Draft Player Modal - ${this.state.activePlayer ? getPlayerShortName(this.state.activePlayer) : ''}`;

		return (
			<div className={clsName}>
				{this.props.children}
				<div className='draft-player-pool__list' onClick={this.onPlayerClick}>
					{this.props.playersToShow.slice(this.props.playersFrom, this.props.playersFrom + this.props.pageSize)
						.map((player) => {
							return <DraftPlayerCard key={player.playerId || player.profileId} player={player}
		                        canDraft={() => this.props.canDraft(this.props.userId)} type={'pool'} />;
					})}
				</div>
				<div className='draft-player-pool_paginate'>
					<PaginationToggle activeOption={this.props.activePage + 1} toggleOptions={this.props.paginationToggleOptions} onPageClick={this.props.onPageToggleClick} />
				</div>
				{this.state.showModal ?	<Modal name={modalName} onClose={this.onModalClose}><DraftPlayerModal canDraft={this.props.canDraft(this.props.userId)} player={this.state.activePlayer} onClose={this.onModalClose} onDraft={this.draftPlayer} userId={this.props.userId} /></Modal> : null}
			</div>
		);
	}
}

export default withDraftPlayer(withPlayerPool(DraftPlayerPool));
