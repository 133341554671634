import React, {Component} from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { GA } from '../../helpers/ga';
import Header from '../../components/Common/Header';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import GameWeekToggle from '../../components/Common/GameWeekToggle';
import Transfer from '../../components/Transfer';
import ErrorIcon from '../../components/svgs/ErrorIcon';
import './WaiverHistoryPane.scss';
import EmptyState from '../../components/Common/EmptyState';

class WaiverHistoryPane extends Component {

	constructor(props) {
		super(props);
		const { type } = this.props.selectedLeague;
		this.gameWeek = type && this.props.currentGameWeek ? this.props.currentGameWeek[type] : null;
		this.state = {
			waiversToShow: this.props.historicWaivers[`_${this.gameWeek}`] || [],
			selectedGameWeek: this.gameWeek
		};
		this.onToggleClick = this.onToggleClick.bind(this);
	}

	onToggleClick(gameWeek) {
		this.setState({
			waiversToShow: this.props.historicWaivers[`gameWeek${gameWeek}`] || [],
			selectedGameWeek: gameWeek
		});
		ReactGA.event({
			category: GA.CATEGORY.WAIVER,
			action: GA.ACTION.WAIVER_HISTORY_GW_TOGGLE
		});
	}

	transformData(waiver) {
		const playerOut = this.props.players.find(p => p.playerId === waiver.playerOut.playerId || p.statId === waiver.playerOut.playerId);
		const playerIn = this.props.players.find(p => p.playerId === waiver.playerIn.playerId || p.statId === waiver.playerIn.playerId);
		const waiverData = this.props.waivers[this.props.selectedLeague.leagueId];
		const managerId = waiverData.seasonUserTeams.find(entry => entry.seasonUserTeamId === waiver.seasonUserTeamId).hashedUser;
		const managerName = waiverData.leagueUsers.find(u => u.userId === managerId).name;

		return {
			leagueId: this.props.selectedLeague.leagueId,
			priority: waiver.round,
			teamA: {
				playerId: waiver.playerOut.playerId,
				player: playerOut,
				userName: managerName,
			}, teamB: {
				playerId: waiver.playerIn.playerId,
				player: playerIn
			}
		};
	}

	render () {
		const disablePrev = this.state.selectedGameWeek - 1 <= 0;
		const disableNext = this.state.selectedGameWeek + 1 > this.gameWeek;

		return (
			<div className='waiver-history-pane'>
				<div className='waiver-history-pane__heading'>
					<Header headerLevel={'h1'} text={'Waiver History'} />
				</div>
				<div className='waiver-history-pane__toggle'>
					<GameWeekToggle disablePrev={disablePrev} disableNext={disableNext} onToggleClick={this.onToggleClick} currentGameWeek={this.state.selectedGameWeek} />
				</div>
				{this.state.waiversToShow.length === 0 ?
					<div className='waiver-history-pane__empty'>
						<EmptyState heading='No Waivers for GameWeek' />
					</div> :
					<div className='waiver-history-pane__waivers'>
						{this.state.waiversToShow.map(waiver => <Transfer key={`${waiver.playerIn.gameWeek}-${waiver.playerIn.playerId}`} hideCta={true} showManager={true} type={'waiver'} data={this.transformData(waiver)}/>)}
					</div>
				}
				{this.props.isFetching ? <SpinnerLayer/> : null}
			</div>
		);
	}
}

/**
 * TODO:
 * Fix it so when I fetch an endpoint I direct the relevant query to the right reducer and spread the values in.
 * @param state
 * @returns {{currentGameWeek: *, selectedLeague: *, players: *, users: *}}
 */

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		players: state.players,
		selectedLeague: state.selectedLeague,
		waivers: state.waivers
	}
};

export default connect(mapStateToProps)(WaiverHistoryPane);
