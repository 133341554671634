import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_SCORES_REQUEST = 'FETCH_SCORES_REQUEST';
export const FETCH_SCORES_SUCCESS = 'FETCH_SCORES_SUCCESS';
export const FETCH_SCORES_FAILURE = 'FETCH_SCORES_FAILURE';

export function fetchScoresRequest ( isFetching ) {
	return {
		type: FETCH_SCORES_REQUEST,
		isFetching
	};
}

export function fetchScoresFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_SCORES_FAILURE,
		response: err,
		error: true
	};
}

export function fetchScoresSuccess( data ) {
	return {
		type: FETCH_SCORES_SUCCESS,
		data
	};
}

// TODO remove hard coded season type
export function fetchScores (type = 'REG') {
	return ( dispatch ) => {
		dispatch(fetchScoresRequest(true));

		const url = `${Constants.urls.SCORES}/${type}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchScoresRequest(false));

			return response.data;
		}).then(data => dispatch(fetchScoresSuccess(data))).catch(err => dispatch(fetchScoresFailure(err)));
	};
}
