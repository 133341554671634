import React, {useContext, useState} from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import {Constants} from '../../helpers/Constants';
import { GA } from '../../helpers/ga';
import Hero from '../../components/Common/Hero';
import CreateLeagueForm from '../../components/Form/CreateLeagueForm';
import SetLeagueRulesForm from '../../components/Form/SetLeagueRulesForm';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import UserContext from '../../context/UserContext';
import './LeagueCreator.scss';

const LeagueCreator = ({history}) => {
	const userContext = useContext(UserContext);
	const userId = userContext.userObj ? userContext.userObj.email : null;
	const [ formState, setFormState ] = useState({});
	const [ teamFormState, setTeamFormState ] = useState({});
	const [ showCreateState, setShowCreateState ] = useState(true);
	const [ showSpinner, setShowSpinner ] = useState(false);

	ReactGA.event({
		category: GA.CATEGORY.LEAGUE_CREATION,
		action: GA.ACTION.VIEW_CREATE_LEAGUE
	});

	const onFormSubmit = (form, teamForm) => {
		setFormState(form);
		setTeamFormState(teamForm);
		setShowCreateState(false);
		ReactGA.event({
			category: GA.CATEGORY.LEAGUE_CREATION,
			action: GA.ACTION.SUBMIT_CREATE_LEAGUE
		});
	};

	const onCreateLeague = (evt) => {
		evt.preventDefault();

		// User not authenticated, redirect
		if (!userId) {
			history.push('/login');
			return
		}

		ReactGA.event({
			category: GA.CATEGORY.LEAGUE_CREATION,
			action: GA.ACTION.SUBMIT_CREATE_LEAGUE_FINAL
		});

		//TODO add ability to modify rules
		setShowSpinner(true);

		axios.post(Constants.urls.LEAGUE, formState).then(response => {
			const leagueId = response.data.leagueId;
			const joinData = {
				season: Constants.currentSeason,
				userId,
			};

			axios.post(`${Constants.urls.LEAGUE}/join/${leagueId}`, {...joinData, ...teamFormState}).then(joinResponse => {
				console.log('successfully joined');
				setTimeout(() => {
					history.push('/');
				}, 800);
			})
		}).catch(err => {
			//TODO handle errors
			setShowCreateState(true);
			console.error(err);
			setShowSpinner(false);
		});
	};

	return (
		<div className='league-creator'>
			<Helmet>
				<title>Shakitz - Create League</title>
				<link rel="canonical" href="https://shakitz.com/league/create" />
				<meta name="description" content="Shakitz Fantasy Football. Create your own league in standard/.5/1ppr format to play now!" />
			</Helmet>
			<Hero bubbles joinJourney text="Create your fantasy football league" secondaryText='designed for fun' />
			{ showCreateState ? <CreateLeagueForm onSubmit={onFormSubmit}/> : <SetLeagueRulesForm formatType={formState.formatType} onSubmit={onCreateLeague}/>}
			{ showSpinner ? <SpinnerLayer/> : null}
		</div>
	);

};

export default withRouter(LeagueCreator);
