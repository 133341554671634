import React from 'react';
import {TeamSVGMap} from '../../../helpers/TeamSVGMap';
import getPlayerShortName from '../../../helpers/getPlayerShortName';
import PlayerTeamPosInfo from '../../Common/Players/PlayerTeamPosInfo';
import './H2HPlayerCard.scss';

const H2HPlayerCard = ({player}) => {
	const TeamSVG = TeamSVGMap.teamMap[player.nflTeam];
	const points = !player.isOrHasPlayed && player.calculatedStats.points === 0 ? '-' : player.calculatedStats.points;
	return (
		<div className='h2h-player-card' key={player.playerId}>
			<div className='h2h-player-card__name'>{getPlayerShortName(player)}</div>
			<PlayerTeamPosInfo noSvg position={player.pos} team={player.nflTeam}/>
			<div className='h2h-player-card__score'>{points}</div>
			<div className='h2h-player-card__team-svg'>
				<TeamSVG />
			</div>
		</div>
	);
};

export default H2HPlayerCard;
