import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { postDraftPick } from '../actions/postDraftPick';
import { Constants } from '../helpers/Constants';
import SpinnerLayer from '../components/SpinnerLayer/SpinnerLayer';
import calculateDraftPicks from '../helpers/calculateDraftPicks';
import isDraftComplete from '../helpers/isDraftComplete';
import mergeDraftData from '../helpers/mergeDraftData';
import getDraftTopic from '../helpers/getDraftTopic';
import hasDraftStarted from '../helpers/hasDraftStarted';
import getNextDraftUser from '../helpers/getNextDraftUser';

const withDraftPlayer = (WrappedComponent) => {
	// ...and returns another component...
	 return class extends React.Component {
		constructor(props) {
			super(props);
			this.canDraft = this.canDraft.bind(this);
			this.generateDraftPick = this.generateDraftPick.bind(this);
			this.numRounds = Constants.numStarters + Constants.numBench;
			this.numOfUsers = this.props.draftData.users.length;
		}

		canDraft(userId) {
			const draftData = this.props.draftData;
			const mergedData = mergeDraftData(this.numRounds, this.numOfUsers, this.props.draftPicks, draftData);
			const picksMade = calculateDraftPicks(mergedData);
			const draftComplete = isDraftComplete(mergedData, this.numOfUsers);
			const draftIsPaused = this.props.draftClock && this.props.draftClock.isPaused;
			const currentlyEditingPick = !!this.props.editDraftPick.pick;

			if(draftIsPaused) {
				return currentlyEditingPick;
			}

			if(hasDraftStarted(draftData.draft.draftStartDate) && picksMade === 0 && draftData.draft.priority && draftData.draft.priority[0].userId === userId) {
				return true;
			}

			return !draftComplete && this.props.draftClock.user && this.props.draftClock.user.userId === userId;
		}

		generateDraftPick (player, userId) {
			const draftTopic = getDraftTopic(this.props.draftData.draft.draftId, this.props.draftSocketData.topic);

			const mergedData = mergeDraftData(this.numRounds, this.numOfUsers, this.props.draftPicks, this.props.draftData);
			const picksMade = calculateDraftPicks(mergedData);
			const editDraftPick = this.props.editDraftPick;

			const currentPick = picksMade + 1;
			const round = Math.ceil(currentPick / this.numOfUsers) || 1;

			// Handle scenario where we don't have any data for the first user who picks
			const currentUser = this.props.draftData.draft.priority.find(user => user.userId === userId);

			if(editDraftPick.pick) {
				const pickObj = { ...editDraftPick, ...player }
				delete pickObj.userName;
				delete pickObj.order;

				return {
					draftId: this.props.draftData.draft.draftId,
					leagueId: this.props.draftData.draft.leagueId,
					season: this.props.draftData.draft.season,
					draftPick: pickObj,
					topic: draftTopic,
					userId: this.props.draftClock.user.userId,
					nextUserId: this.props.draftClock.next.userId,
					isEdit: true
				}
			}

			const nextUser = getNextDraftUser(currentUser, this.props.draftData.draft.priority, currentPick);
			const subsequentUser = getNextDraftUser(nextUser, this.props.draftData.draft.priority, currentPick+1);

			return {
				draftId: this.props.draftData.draft.draftId,
				leagueId: this.props.draftData.draft.leagueId,
				season: this.props.draftData.draft.season,
				draftPick: {
					...player,
					userId,
					round,
					pick: currentPick
				},
				topic: draftTopic,
				userId: nextUser.userId,
				nextUserId: subsequentUser.userId,
				isEdit: false
			};
	    }

		render() {
			return (
				<>
					<WrappedComponent generateDraftPick={this.generateDraftPick} canDraft={this.canDraft} {...this.props} />
					{this.props.draftPickIsPosting ? <SpinnerLayer/> : null}
				</>
			);
		}
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		postDraftPick: (draftPickObj) => dispatch(postDraftPick(draftPickObj))
	};
};

const mapStateToProps = ( state ) => {
	return {
		draftClock: state.draftClock,
		draftData: state.draftData,
		draftPicks: state.draftPicks,
		draftPickHasErrored: state.postDraftPickFailure,
		draftPickIsPosting: state.postDraftPickRequest,
		draftSocketData: state.authDraft,
		editDraftPick: state.editDraftPick,
		seasonUserTeams: state.seasonUserTeams,
		isFetching: state.gameWeekTeamRequest,
		hasErrored: state.gameWeekTeamFailure
	};
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withDraftPlayer);
