import axios from 'axios';
import { Constants } from '../helpers/Constants';
import { fetchWaivers } from '../actions/fetchWaivers';

export const DELETE_WAIVER_REQUEST = 'DELETE_WAIVER_REQUEST';
export const DELETE_WAIVER_SUCCESS = 'DELETE_WAIVER_SUCCESS';
export const DELETE_WAIVER_FAILURE = 'DELETE_WAIVER_FAILURE';

export function deleteWaiverRequest ( isPosting ) {
	return {
		type: DELETE_WAIVER_REQUEST,
		isPosting
	};
}

export function deleteWaiverFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: DELETE_WAIVER_FAILURE,
		response: err,
		error: true
	};
}

export function deleteWaiverSuccess( data ) {
	return {
		type: DELETE_WAIVER_SUCCESS,
		data
	};
}

export function deleteWaiver ( leagueId, waiverId ) {
	return ( dispatch ) => {
		dispatch(deleteWaiverRequest(true));

		const url = `${Constants.urls.WAIVERS}/${leagueId}/${waiverId}`;
		axios.delete(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.DELETE ) {
				throw Error(response.statusText);
			}

			setTimeout(() => {
				dispatch(deleteWaiverRequest(false));
			}, 1100);

			return { message: 'Waiver deleted'};
		}).then(data => dispatch(deleteWaiverSuccess(data)))
		.then(() => dispatch(fetchWaivers(leagueId, null, null, waiverId)))
		.catch(err => dispatch(deleteWaiverFailure(err)));
	};
}
