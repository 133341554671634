import React, { useState } from 'react';
import Header from '../Common/Header';
import './ErrorScreen.scss';
import ErrorIcon from '../svgs/ErrorIcon';
import Button from '../Common/Button';

const ErrorScreen = ({error, resetErrorBoundary}) => {
	const [showTrace, setShowTrace] = useState(false);
	return (
		<div className='error-screen'>
			<div className='error-screen__header'>
				<ErrorIcon fill={'#000'} opacity={1} />
				<Header headerLevel={'h1'} text={'Sorry, there\'s been a problem'}/>
			</div>
			<div className='error-screen__text'>
				<p>If you keep getting this screen, please try logging out and back in again</p>
				<p>{error.message}</p>
			</div>
			<div className='error-screen__stack'>
				<Button onClick={() => setShowTrace(!showTrace)} type={'secondary'} text={showTrace ? 'Hide' : 'Show stack trace'} />
				{showTrace && <code>{error.stack}</code>}
			</div>
		</div>
	)
};

export default ErrorScreen;
