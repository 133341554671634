import { PUT_USER_FAVOURITE_TEAM_FAILURE, PUT_USER_FAVOURITE_TEAM_REQUEST, PUT_USER_FAVOURITE_TEAM_SUCCESS} from '../actions/setFavouriteTeam';

export function setFavouriteTeamFailure(state = false, action) {
	switch (action.type) {
		case PUT_USER_FAVOURITE_TEAM_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function setFavouriteTeamRequest(state = false, action) {
	switch (action.type) {
		case PUT_USER_FAVOURITE_TEAM_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function setFavouriteTeam(state = false, action) {
	switch (action.type) {
		case PUT_USER_FAVOURITE_TEAM_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
