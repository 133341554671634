import React from 'react';

const CloseIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30" {...props}>
		<circle cx="15" cy="15" r="14" fill="#fff" fillOpacity=".1" stroke="#fff" strokeWidth="2"/>
		<rect width="2" height="17.308" x="20.238" y="8.414" fill="#fff" rx="1" transform="rotate(45 20.238 8.414)"/>
		<rect width="2" height="17.308" x="20.238" y="8.414" stroke="#fff" rx="1" transform="rotate(45 20.238 8.414)"/>
		<rect width="2" height="17.308" x="8.238" y="9.414" fill="#fff" rx="1" transform="rotate(-45 8.238 9.414)"/>
		<rect width="2" height="17.308" x="8.238" y="9.414" stroke="#fff" rx="1" transform="rotate(-45 8.238 9.414)"/>
	</svg>
);

export default CloseIcon;
