import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_PERFECT_TEAM_REQUEST = 'FETCH_PERFECT_TEAM_REQUEST';
export const FETCH_PERFECT_TEAM_SUCCESS = 'FETCH_PERFECT_TEAM_SUCCESS';
export const FETCH_PERFECT_TEAM_FAILURE = 'FETCH_PERFECT_TEAM_FAILURE';

export function fetchPerfectTeamRequest ( isFetching ) {
	return {
		type: FETCH_PERFECT_TEAM_REQUEST,
		isFetching
	};
}

export function fetchPerfectTeamFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_PERFECT_TEAM_FAILURE,
		response: err,
		error: true
	};
}

export function fetchPerfectTeamSuccess( data ) {
	return {
		type: FETCH_PERFECT_TEAM_SUCCESS,
		data: data.perfectTeam
	};
}

export function fetchPerfectTeam ( season, seasonType, format, gameWeek ) {
	return ( dispatch ) => {
		dispatch(fetchPerfectTeamRequest(true));

		const url = Constants.urls.GRAPHQL;
		const formatSchema = `passing {
          name
          description
          value
        }
        defensive{
          name
          description
          value
        }
        offensive{
          name
          description
          value
        }
        kicking{
          name
          description
          value
        }
        points`;
		const formatTypes = {
			standard: "standard",
			'0.5ppr': "halfPPR",
			'1ppr': "fullPPR"
		};
		const query = `query {
			perfectTeam(type:"${seasonType}", season:"${season}", gameWeek:"${gameWeek}") {
			    idInfo {
			      nflId
			      name
			      team
			      season
			      week
			    }
			    calculated{
			      ${formatTypes[format]} {
			        ${formatSchema}
			      }
			    }
			  }
			}
		`;
		axios.post(url, {
			query: query.replace(/\t+/g, "")
		}).then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchPerfectTeamRequest(false));

			return response.data.data;
		}).then(data => dispatch(fetchPerfectTeamSuccess(data))).catch(err => dispatch(fetchPerfectTeamFailure(err)));
	};
}
