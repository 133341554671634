import { POST_TRADE_FAILURE, POST_TRADE_REQUEST, POST_TRADE_SUCCESS} from '../actions/submitTrade';

export function submitTradeFailure(state = false, action) {
	switch (action.type) {
		case POST_TRADE_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function submitTradeRequest(state = false, action) {
	switch (action.type) {
		case POST_TRADE_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}

// NB: We only store the last picked draft here
export function submitTrade(state = false, action) {
	switch (action.type) {
		case POST_TRADE_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
