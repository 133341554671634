import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import Keys from 'shakitz-keys';
import classNames from 'classnames';
import CloseIcon from '../../components/svgs/CloseIcon';
import Head2Head from '../../components/svgs/Head2Head';
import { H2HShapeGroupRight, H2HShapeGroupLeft } from '../../components/svgs/logo';
import {fetchGameWeekTeam} from '../../actions/gameWeekTeam';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import H2HRoster from '../../components/H2HModal/H2HRoster';
import calculatePlayersPlayed from '../../helpers/calculatePlayersPlayed';
import WinPercentage from '../../components/H2HModal/WinPercentage';
import calculateWinPercentage from '../../helpers/calculateWinPercentage';
import H2HStreak from '../../components/H2HModal/H2HStreak';
import './H2HModal.scss';

const calculateStreak = (teamId, leagueFixtures, gameWeek) => {
	return leagueFixtures.filter(f => f.gameWeek < gameWeek && (f.homeTeamId === teamId || f.awayTeamId === teamId))
	.sort((a, b) => a.gameWeek - b.gameWeek)
	.map(h2hFixture => {
		const isHome = h2hFixture.homeTeamId === teamId;
		const { homeTeamScore, awayTeamScore } = h2hFixture;

		if(homeTeamScore === awayTeamScore) {
			return 'draw';
		} else if(isHome) {
			return homeTeamScore > awayTeamScore ? 'win' : 'loss';
		} else {
			return awayTeamScore > homeTeamScore ? 'win' : 'loss';
		}
	});
};

const calculateScore = (previousLeg, isHome, score) => {
	const currentScore = score || 0;
	if(isHome && previousLeg && previousLeg.homeTeamScore) {
		const cumulativeScore = previousLeg.homeTeamScore + currentScore;
		const roundedScore = Math.round(cumulativeScore * 100) / 100;
		return <span className='h2h-modal__score'><span className='h2h-modal__score h2h-modal__score--previous'>({previousLeg.homeTeamScore}) + ({currentScore})</span> {roundedScore}</span>;
	} else if(!isHome && previousLeg && previousLeg.awayTeamScore) {
		const cumulativeScore = previousLeg.awayTeamScore + currentScore;
		const roundedScore = Math.round(cumulativeScore * 100) / 100;
		return <span className='h2h-modal__score'><span className='h2h-modal__score h2h-modal__score--previous'>({previousLeg.awayTeamScore}) + ({currentScore})</span> {roundedScore}</span>;
	} else {
		return <span className='h2h-modal__score'>{currentScore}</span>;
	}
};

const H2HModal = ({currentGameWeek, data, fetchGameWeekTeam, h2hFixtures, gameWeekTeams, isLoading, players, schedule, seasonPlayerStats, selectedLeague, standings, onClose}) => {
	const { homeTeam, homeManager, homeManagerId, homeTeamScore, homeTeamId, gameWeek,
		awayTeam, awayManager, awayManagerId, awayTeamScore, awayTeamId, season, previousLeg } = data;
	const { leagueId, type } = selectedLeague;
	const leagueStandings = standings[leagueId].standings;
	const leagueFixtures = h2hFixtures[leagueId][season];
	const homeRecord = leagueStandings.find(team => team.managerId === homeManagerId).record;
	const awayRecord = leagueStandings.find(team => team.managerId === awayManagerId).record;

	const homeGameWeekTeamId = Keys.generateGameWeekTeam({leagueId, gameWeek, seasonUserTeamId: homeTeamId });
	const awayGameWeekTeamId = Keys.generateGameWeekTeam({leagueId, gameWeek, seasonUserTeamId: awayTeamId });
	const homeStreak = calculateStreak(homeTeamId, leagueFixtures, gameWeek);
	const awayStreak = calculateStreak(awayTeamId, leagueFixtures, gameWeek);
	const homeRoster = gameWeekTeams.find(team => team.gameWeekTeamId === homeGameWeekTeamId);
	const awayRoster = gameWeekTeams.find(team => team.gameWeekTeamId === awayGameWeekTeamId);

	const scheduleData = schedule[type][gameWeek] || { games: [] };
	const games = scheduleData.games;

	const homePlayersPlayed = calculatePlayersPlayed(games, gameWeek, gameWeekTeams, players, { seasonUserTeamId: homeTeamId }, selectedLeague);
	const awayPlayersPlayed = calculatePlayersPlayed(games, gameWeek, gameWeekTeams, players, { seasonUserTeamId: awayTeamId }, selectedLeague);

	const isThisWeek = currentGameWeek[type] === gameWeek;

	useEffect(() => {

		if(isLoading) {
			return;
		}
		if(!homeRoster) {
			fetchGameWeekTeam(homeGameWeekTeamId, true, season);
		}

		if(!awayRoster) {
			fetchGameWeekTeam(awayGameWeekTeamId, true, season);
		}
	}, [isLoading, homeRoster, awayRoster, fetchGameWeekTeam]);

	const clsName = classNames('h2h-modal', {
		'h2h-modal--limited-view': (homeRecord === '0-0' && awayRecord === '0-0') || !isThisWeek,
		'h2h-modal--past-week':  !isThisWeek
	});

	const { homePercentage, awayPercentage } = calculateWinPercentage(games, gameWeek, players, seasonPlayerStats, homeRoster, awayRoster, previousLeg);

	return (
		<div className={clsName}>
			<div className='h2h-modal__header-wrapper'>
				<div className='h2h-modal__close-container' onClick={onClose}>
					<CloseIcon/>
				</div>
				<div className='h2h-modal__bubbles'>
					<H2HShapeGroupLeft />
					<H2HShapeGroupRight />
				</div>
				<div className='h2h-modal__icon-wrapper'>
					<Head2Head/>
				</div>
				<div className='h2h-modal__team-info'>
					<div className='h2h-modal__team-names'>
						<span className='h2h-modal__team-name'>{homeTeam}</span>
						<span className='h2h-modal__team-name'>{awayTeam}</span>
					</div>
					<div className='h2h-modal__team-delta'>
						<div className='h2h-modal__team h2h-modal__team--home'>
							<span className='h2h-modal__manager-name'>{homeManager}</span>
							{calculateScore(previousLeg, true, homeTeamScore)}
							<span className='h2h-modal__record'>{homeRecord}</span>
							<H2HStreak streak={homeStreak} />
						</div>
						<div className='h2h-modal__team h2h-modal__team--away'>
							<span className='h2h-modal__manager-name'>{awayManager}</span>
							{calculateScore(previousLeg, false, awayTeamScore)}
							<span className='h2h-modal__record'>{awayRecord}</span>
							<H2HStreak streak={awayStreak} />
						</div>
					</div>
				</div>
				<div className='h2h-modal__win-percentage'>
					{isThisWeek && <WinPercentage homePercentage={homePercentage} awayPercentage={awayPercentage}/>}
				</div>
				<div className='h2h-modal__yet-to-play-wrapper'>
					<span className='h2h-modal__yet-to-play h2h-modal__yet-to-play--home'>Yet to play: <span className='h2h-modal__yet-to-play-value'>{10 - homePlayersPlayed}</span></span>
					<span className='h2h-modal__yet-to-play h2h-modal__yet-to-play--away'>Yet to play: <span className='h2h-modal__yet-to-play-value'>{10 - awayPlayersPlayed}</span></span>
				</div>
			</div>
			<div className='h2h-modal__roster-wrapper'>
				{!isLoading && <H2HRoster homeRoster={homeRoster} awayRoster={awayRoster} games={games} players={players} />}
			</div>
			{isLoading && <SpinnerLayer />}
		</div>
	);
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchGameWeekTeam: (gameWeekTeamId, withStats, season) => dispatch(fetchGameWeekTeam(gameWeekTeamId, withStats, season))
	};
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		isLoading: state.gameWeekTeamRequest || state.scheduleRequest,
		h2hFixtures: state.h2hFixtures,
		gameWeekTeams: state.gameWeekTeams,
		players: state.players,
		seasonPlayerStats: state.seasonPlayerStats,
		schedule: state.schedule,
		standings: state.leagueStandings,
		selectedLeague: state.selectedLeague
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(H2HModal);
