import { FETCH_LEAGUE_STANDINGS_FAILURE, FETCH_LEAGUE_STANDINGS_REQUEST, FETCH_LEAGUE_STANDINGS_SUCCESS} from '../actions/fetchLeagueStandings';

export function leagueStandingsFailure(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_STANDINGS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function leagueStandingsRequest(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_STANDINGS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function leagueStandings(state = {}, action) {
	switch (action.type) {
		case FETCH_LEAGUE_STANDINGS_SUCCESS:
			const clonedState = { ...state };
			if (!clonedState.hasOwnProperty(action.data.leagueId)) {
				clonedState[action.data.leagueId] = [];
			}
			clonedState[action.data.leagueId] = action.data.data.standings;
			return clonedState;
		default:
			return state;
	}
}
