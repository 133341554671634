import React, {Component} from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet';
import { GA } from '../../helpers/ga';
import Header from '../../components/Common/Header';
import Hero from '../../components/Common/Hero';
import VisualSeparator from '../../components/Common/VisualSeparator';
import Transfer from '../../components/Transfer';
import { Constants } from '../../helpers/Constants';
import { confirmTrade } from '../../actions/confirmTrade';
import { deleteTrade } from '../../actions/deleteTrade';
import { fetchLeagueTradeData } from '../../actions/fetchLeagueTradeData';
import { rejectTrade } from '../../actions/rejectTrade';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import Countdown from '../../components/Common/Countdown';
import getTradeDeadlineCountdown from '../../helpers/getTradeDeadline';
import './Trades.scss';
import hasSeasonStarted from '../../helpers/hasSeasonStarted';
import SeasonNotStarted from '../../components/SeasonNotStarted';
import ShapeGroup1 from '../../components/svgs/logo/ShapeGroup1';
import ErrorIcon from '../../components/svgs/ErrorIcon';
import ShapeGroup2 from '../../components/svgs/logo/ShapeGroup2';
import EmptyState from '../../components/Common/EmptyState';

class Trades extends Component {
	constructor(props) {
		super(props);
		this.state = {
			needsToFetch: false
		};
		this.onCancel = this.onCancel.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	componentDidMount() {
		if(!this.props.selectedLeague || !this.props.selectedLeague.leagueId || !this.props.leagueTrades.hasOwnProperty(this.props.selectedLeague.leagueId)) {
			this.setState({needsToFetch: true});
			return;
		}

		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if(this.state.needsToFetch && this.props.selectedLeague.leagueId) {
			this.fetchData();
		}

		if(prevProps.selectedLeague.leagueId !== this.props.selectedLeague.leagueId) {
			this.fetchData();
			return;
		}

		if(prevProps.deleteTradeData !== this.props.deleteTradeData || prevProps.confirmTradeData !== this.props.confirmTradeData || prevProps.rejectTradeData !== this.props.rejectTradeData || prevProps.submitTradeData !== this.props.submitTradeData) {
			this.fetchData();
		}
	}

	fetchData() {
		const { leagueId, type, season } = this.props.selectedLeague;
		const leagueSeason = type === 'PRE' ? `PRE-${season}` : season;
		this.props.fetchLeagueTradeData({leagueId, season: leagueSeason, type});
		this.setState({needsToFetch: false });
	}

	getGameWeek() {
		const leagueId = this.props.selectedLeague.leagueId;
		return leagueId && this.props.leagueTrades[leagueId] ? this.props.leagueTrades[leagueId].gameWeek : null;
	}

	getTradeDeadline() {
		const leagueId = this.props.selectedLeague.leagueId;
		const leagueData = this.props.leagueTrades[leagueId];
		if(!leagueData || !leagueData.schedule) {
			return false;
		}

		const tradeDeadline = getTradeDeadlineCountdown(leagueData.schedule.games[0]);
		return new Date(tradeDeadline);
	}

	getTradeStatus (outcome) {
		if(typeof outcome === "undefined" || outcome === null) {
			return Constants.tradeStates.PENDING;
		} else if(outcome) {
			return Constants.tradeStates.ACCEPTED;
		} else {
			return Constants.tradeStates.REJECTED;
		}
	}

	hasTradeDeadlinePassed () {
		const tradeDeadline = this.getTradeDeadline();
		if(!tradeDeadline) {
			return false;
		}

		return (new Date().getTime() - tradeDeadline.getTime()) > 0;
	}

	onCancel(tradeId, isDelete) {
		const action = isDelete ? GA.ACTION.TRADE_DELETED : GA.ACTION.TRADE_REJECTED;
		if(isDelete) {
			this.props.deleteTrade(this.props.selectedLeague.leagueId, tradeId);
		} else {
			this.props.rejectTrade(this.props.selectedLeague.leagueId, tradeId);
		}

		ReactGA.event({
			category: GA.CATEGORY.TRADE,
			action
		});
	}

	onConfirm(tradeId) {
		this.props.confirmTrade(this.props.selectedLeague.leagueId, tradeId);
		ReactGA.event({
			category: GA.CATEGORY.TRADE,
			action: GA.ACTION.TRADE_CONFIRMED
		});
	}

	transformTradeData (data, players = []) {
		if(players.length === 0) {
			return [];
		}

		const { leagueUsers, trades, seasonUserTeams } = data;
		return trades.map(trade => {
			const { gameWeek, season } = trade;
			const teamA = seasonUserTeams.find(team => team.seasonUserTeamId === trade.seasonUserTeamAId);
			const teamB = seasonUserTeams.find(team => team.seasonUserTeamId === trade.seasonUserTeamBId);

			const playerAId = trade.playerAId.split(`-${season}-${gameWeek}`)[0];
			const playerBId = trade.playerBId.split(`-${season}-${gameWeek}`)[0];

			const teamAUser = leagueUsers.find(u => u.userId === teamA.hashedUser);
			const teamBUser = leagueUsers.find(u => u.userId === teamB.hashedUser);
			trade.teamA = {
				...trade.teamA,
				...{
					player: players.find(player => player.statId === playerAId || player.playerId === playerAId),
					shortTeamName: teamA.shortTeamName,
					teamName: teamA.teamName,
					userName: teamAUser.name,
					user: teamAUser.userId
				}
			};
			trade.teamB = {
				...trade.teamB,
				...{
					player: players.find(player => player.statId === playerBId || player.playerId === playerBId),
					shortTeamName: teamB.shortTeamName,
					teamName: teamB.teamName,
					userName: teamBUser.name,
					user: teamBUser.userId
				}
			};
			trade.status = this.getTradeStatus(trade.outcome);

			return trade;
		});
	}

	renderPendingTrades(filteredData) {
		if(filteredData.length === 0) {
			return (
				<div className='trades__empty-explainer'>
					<EmptyState heading='You currently have no pending trades' secondaryText='Once you have a trade to action, they will appear here' isRoster={true} />
				</div>
			);
		}

		return filteredData.map(pendingTrade => {
			const canCancel = this.props.shakitzUser.userId === pendingTrade.teamA.user;
			const canAct = canCancel || this.props.shakitzUser.userId === pendingTrade.teamB.user;

			return <Transfer key={pendingTrade.tradeId} canCancel={canCancel} data={pendingTrade} hideCta={!canAct} onCancel={this.onCancel} onConfirm={this.onConfirm} type={'trade'} />
		});
	}

	renderCompletedTrades(data) {
		return data.filter(trade => trade.status !== Constants.tradeStates.PENDING).map(completedTrade => <Transfer key={completedTrade.tradeId} data={completedTrade} hideCta={true} type={'trade'} />);
	}

	renderCountdown() {
		const gameWeek = this.getGameWeek();
		if(gameWeek === 1) {
			return null;
		}

		// TODO figure out deadline
		const tradeDeadline = this.getTradeDeadline(gameWeek);
		if(!tradeDeadline) {
			return;
		}

		const deadlinePassed = gameWeek > Constants.tradeDeadline || (this.hasTradeDeadlinePassed() && gameWeek === Constants.tradeDeadline);
		if(deadlinePassed && gameWeek >= Constants.tradeDeadline) {
			return <Hero text={"🔒 Trades are locked for the season 🔒"} />;
		} else if (deadlinePassed) {
			return <Hero text={"🔒 Trades are locked for this game week 🔒"} />;
		}

		if (gameWeek === 12) {
			return <Countdown alternate={true} timeToCountdownTo={tradeDeadline} type={'Trades Lock For Season In:'} />
		} else {
			return <Countdown alternate={true} timeToCountdownTo={tradeDeadline} type={'Trades Lock In:'} />
		}
	}

	render () {
		const tradeData = this.props.leagueTrades[this.props.selectedLeague.leagueId] || { leagueUsers: [], seasonUserTeams: [], trades: [] };
		const transformedData = this.transformTradeData(tradeData, this.props.players);
		const gameWeek = this.getGameWeek();
		const tradeDeadlinePassed = gameWeek > Constants.tradeDeadline || (this.hasTradeDeadlinePassed() && gameWeek === Constants.tradeDeadline);
		const filteredData = transformedData.filter(trade => trade.status === Constants.tradeStates.PENDING);

		console.log(transformedData, filteredData);
		console.log(tradeDeadlinePassed);
		return (
			<div className='trades'>
				<Helmet>
					<title>Shakitz - Trades</title>
					<link rel="canonical" href="https://shakitz.com/team" />
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				{!hasSeasonStarted(this.props.selectedLeague) ? <SeasonNotStarted /> : (
					<>
						<div className='trades__heading'>
							<Header headerLevel={'h1'} text={'Trades'} />
							<div className='trades__bubbles'>
								<ShapeGroup1 width='100' height='152' />
								<ShapeGroup2 width='100' height='152' />
							</div>
							{this.renderCountdown()}
						</div>
						<div className='trades__content'>
						{!tradeDeadlinePassed && gameWeek !== 1 && filteredData.length !== 0 && (
							<>
								<div className='trades__pending'>
									{this.renderPendingTrades(filteredData)}
								</div>
							</>
						)}
						{gameWeek === 1 && (
							<div className='trades__closed'>
								<ErrorIcon fill={'#000'} opacity={1} />
								<Header headerLevel={'h3'} text={"🔒 Trades opens game week 2 🔒"} />
							</div>
						)}
						{tradeData.trades.filter(t => t.hasOwnProperty('outcome')).length > 0 ?
							<div className='trades__history'>
								<Header headerLevel={'h1'} text={'Trades History'} />
								{this.renderCompletedTrades(transformedData)}
							</div> : null}
						</div>
					</>
				)}

				{this.props.isFetching ? <SpinnerLayer/> : null}
			</div>
		);
	}
}

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchLeagueTradeData: (params) => dispatch(fetchLeagueTradeData(params)),
		confirmTrade: (leagueId, tradeId) => dispatch(confirmTrade(leagueId, tradeId)),
		deleteTrade: (leagueId, tradeId) => dispatch(deleteTrade(leagueId, tradeId)),
		rejectTrade: (leagueId, tradeId) => dispatch(rejectTrade(leagueId, tradeId))
	};
};

const mapStateToProps = ( state ) => {
	return {
		confirmTradeData: state.confirmTrade,
		deleteTradeData: state.deleteTrade,
		submitTradeData: state.submitTrade,
		leagueTrades: state.leagueTradeData,
		players: state.players,
		rejectTradeData: state.rejectTrade,
		selectedLeague: state.selectedLeague,
		isFetching: state.leagueTradeDataRequest || state.confirmTradeRequest || state.rejectTradeRequest || state.deleteTradeRequest,
		hasErrored: state.leagueTradeDataFailure || state.confirmTradeFailure || state.rejectTradeFailure || state.deleteTradeFailure
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Trades);
