import React from 'react';

const LeftArrow = ({width = '12', height = '12', fill = '#fff', disabled = false, className = ''}) => (
	<svg xmlns="http://www.w3.org/2000/svg" disabled={disabled} className={className} width={width} height={height} fill={fill} viewBox="0 0 12 12">
		<path d="M6 12l1.058-1.057L2.872 6.75H12v-1.5H2.873l4.184-4.192L6 0 0 6l6 6z"/>
	</svg>

);

export default LeftArrow;
