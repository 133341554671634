import { REFRESH_CORE_DATA } from '../actions/refreshCoreData';

export function refreshCoreData (state = false, action) {
	switch (action.type) {
		case REFRESH_CORE_DATA:
			return action.refresh;
		default:
			return state;
	}
}
