import React from 'react';
import './SeasonNotStarted.scss';
import SpinnerLayer from '../SpinnerLayer/SpinnerLayer';

const SeasonNotStarted = () => {

	return <SpinnerLayer />;
	/*
	return (
		<div className='season-not-started'>
			<ErrorIcon width={100} height={100} fill='#fff' opacity={1}/>
			<Header headerLevel='h1' text='This screen will become active a few minutes after your draft finishes' secondaryText='Please complete your draft first' />
		</div>
	)*/
}

export default SeasonNotStarted
