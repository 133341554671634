import React from 'react';
import './DraftPlayersLeft.scss';

const DraftPlayersLeft = ({playersLeft}) => {
	if (playersLeft === 0) {
		return null;
	}

	return (
		<div className='draft-players-left'>
			<div className='draft-players-left__text'>{playersLeft} more required</div>
		</div>
	);
};

export default DraftPlayersLeft;
