import React from 'react';
import './TableHead.scss';

const TableHead = ({columns, clsName}) => (
	<thead className={`table__head ${clsName}__head`}>
	<tr className={`table__row ${clsName}__row`}>
		{columns.map(column => {
			const suffixCls = `table__header-entry table__header-entry--${column.suffix} ${clsName}__header-entry ${clsName}__header-entry--${column.suffix}`;

			return <th key={`table-head-${column.suffix}`} className={suffixCls}>{column.text}</th>
		})}
	</tr>
	</thead>
);

export default TableHead;
