import { PUT_USER_NAME_FAILURE, PUT_USER_NAME_REQUEST, PUT_USER_NAME_SUCCESS} from '../actions/setUserName';

export function setUserNameFailure(state = false, action) {
	switch (action.type) {
		case PUT_USER_NAME_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function setUserNameRequest(state = false, action) {
	switch (action.type) {
		case PUT_USER_NAME_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function setUserName(state = false, action) {
	switch (action.type) {
		case PUT_USER_NAME_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
