import React from 'react';
import './TableBody.scss';

const renderTwoLevel = (baseEntryCls, entry, row) => (
	<div className={row.modifierCls ? `table__two-level table__two-level--${row.modifierCls}` : 'table__two-level'}>
		{row.suffix.map((suffix, index) => {
			const suffixCls = `${baseEntryCls} table__entry--${suffix} ${baseEntryCls}--${suffix}`;
			return <span key={`trow_two-level_${suffix}_index`} className={suffixCls}>{entry[row.key[index]]}</span>})}
	</div>
);

const TableBody = ({rowData, data, clsName}) => (
	<tbody className={`table__body ${clsName}__body`}>
	{data.map((entry, index) => {
		const baseEntryCls = `table__entry ${clsName}__entry`;

		return (
			<tr key={entry.managerId || entry.player} className={`table__row ${clsName}__row`}>
				{rowData.map(row => {
					const suffix = row.isTwoLevel ? 'split-level' : row.suffix;
					const suffixCls = `${baseEntryCls} table__entry--${suffix} ${clsName}__entry--${suffix}`;
					const cls = row.canClick ? `${suffixCls} table__entry--clickable` : suffixCls;

					const shouldClick = row.canClick && entry.onClick;
					const clickFnc = shouldClick ? (() => entry.onClick()) : null;
					return (
						<td key={`trow_${row.key}__index`} className={cls} onClick={clickFnc}>
							{row.isIndex ? index+1 : entry[row.key]}
							{row.isTwoLevel ?
								renderTwoLevel(baseEntryCls, entry, row) : null }
						</td>
					)
				})}
			</tr>
		);
	})}
	</tbody>
);

export default TableBody
