import React from 'react';
import './DraftPriorityCard.scss';

const DraftPriorityCard = ({index, userName, userId, lengthOfUsers}) => {
	const indexOrder = index + 1;
	return (
		<div className='draft-priority-card'>
			<input type='number' defaultValue={indexOrder} min={1} max={lengthOfUsers} data-user-id={userId} data-user-nickname={userName} required />
			<span className='draft-priority-card__user-name'>{userName}</span>
		</div>
	);
};

export default DraftPriorityCard;
