import React, {useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";
import Header from '../../components/Common/Header';
import Dropdown from '../../components/Common/Dropdown';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import StatPlayerPool from '../StatPlayerPool';
import ShakitzContext from '../../context/ShakitzContext';
import mergeSeasonStatsWithPlayers from '../../reducers/mergeSeasonStatsWithPlayers';
import ShapeGroup1 from '../../components/svgs/logo/ShapeGroup1';
import {fetchLeagueBenchData} from '../../actions/fetchLeagueBenchData';
import Standings from '../../components/Standings/Standings';
import './Stats.scss';
import InfoIcon from '../../components/svgs/InfoIcon';
import {fetchSeasonPlayerStats} from '../../actions/fetchSeasonPlayerStats';

const Stats = ({currentGameWeek, fetchSeasonPlayerStats, isFetching, isFetchingCore, fetchLeagueBenchData, leagueBenchData, players, seasonPlayerStats, selectedLeague, unavailablePlayers}) => {
	const statsContainers = [{
		name: 'Top Points Scorers'
	}, {
		name: 'Bench of the Week'
	}];
	const { leagueId, format, season, type } = selectedLeague;
	const defaultStat = statsContainers[0].name;
	const [transformedPlayers, setTransformedPlayers] = useState([]);
	const [activeStat, setActiveStat] = useState(defaultStat);
	const [hasRequestedBenchData, setHasRequestedBenchData] = useState(false);

	const shakitzContext = useContext(ShakitzContext);
	const shakitzUser = shakitzContext ? shakitzContext.userId : null;

	useEffect(() => {
		if(seasonPlayerStats.length > 0) {
			setTransformedPlayers(mergeSeasonStatsWithPlayers(seasonPlayerStats, players));
		} else if (!isFetching && navigator.userAgent !== 'ReactSnap') {
			fetchSeasonPlayerStats(season, type, format);
		}
	}, [isFetching, season, type, format, players, seasonPlayerStats]);

	useEffect(() => {
		if(activeStat === 'Bench of the Week' && !hasRequestedBenchData) {
			const gameWeek = currentGameWeek[type];
			setHasRequestedBenchData(true);
			fetchLeagueBenchData(leagueId, season, gameWeek);
		}
	}, [activeStat, hasRequestedBenchData])

	const toggleOption = (stat) => {
		if(stat && stat.name) {
			setActiveStat(stat.name);
		}
	};

	let benchData = [];
	if(leagueBenchData[leagueId] && leagueBenchData[leagueId][season]) {
		benchData = Object.values(leagueBenchData[leagueId][season]).sort((a, b) => b.totalBenchPoints - a.totalBenchPoints).map((entry, index) => {
			entry.rank = index + 1;
			return entry;
		});
	}

	return (
		<div className='stats-container'>
			<Helmet>
				<title>Shakitz - Stats</title>
				<link rel="canonical" href="https://shakitz.com/stats" />
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<div className='stats-container__header'>
				<Header headerLevel={'h1'} text={'Statistics'} />
				<div className='stats-container__bubbles'>
					<ShapeGroup1 width='100' height='152' />
				</div>
			</div>
			<div className='stats-container__content'>
				<div className='stats-container__dropdown'>
					<Dropdown isChunky onSelect={toggleOption} options={statsContainers}/>
				</div>

				<div className='stats-container__info-wrapper'>
					{activeStat === 'Top Points Scorers' && transformedPlayers.length > 0 && (
						<StatPlayerPool customPlayers={transformedPlayers} playersToFilter={transformedPlayers} inWaiverMode={false} statView={true} userId={shakitzUser} />
					)}
					{activeStat === 'Bench of the Week' && (
						<>
							<div className='stats-container__botw-explainer'>
								<span>Bench of the week is when your team beats your starters</span>
								<div className='stats-container__svg-wrapper'>
									<InfoIcon />
								</div>
							</div>
							<Standings data={benchData} gwState={'statBenchView'} />
						</>
					)}
				</div>
			</div>

			{isFetching || isFetchingCore ? <SpinnerLayer /> : null}
		</div>
	);
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchLeagueBenchData: (leagueId, season, gameWeek) => dispatch(fetchLeagueBenchData(leagueId, season, gameWeek)),
		fetchSeasonPlayerStats: (season, seasonType, format) => dispatch(fetchSeasonPlayerStats(season, seasonType, format)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		isFetching: state.leagueBenchDataRequest || state.seasonPlayerStatsRequest,
		leagueBenchData: state.leagueBenchData,
		players: state.players,
		seasonPlayerStats: state.seasonPlayerStats,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
