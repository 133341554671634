import * as TeamSVGs from '../components/svgs/teams';

export const TeamSVGMap = {
	teamMap: {
		ARI: TeamSVGs.ARI,
		ATL: TeamSVGs.ATL,
		BAL: TeamSVGs.BAL,
		BUF: TeamSVGs.BUF,
		CAR: TeamSVGs.CAR,
		CHI: TeamSVGs.CHI,
		CIN: TeamSVGs.CIN,
		CLE: TeamSVGs.CLE,
		DAL: TeamSVGs.DAL,
		DEN: TeamSVGs.DEN,
		DET: TeamSVGs.DET,
		GB: TeamSVGs.GB,
		HOU: TeamSVGs.HOU,
		IND: TeamSVGs.IND,
		JAG: TeamSVGs.JAX,
		JAX: TeamSVGs.JAX,
		KC: TeamSVGs.KC,
		LA: TeamSVGs.LA,
		LAC: TeamSVGs.LAC,
		LV: TeamSVGs.LV,
		MIA: TeamSVGs.MIA,
		MIN: TeamSVGs.MIN,
		NE: TeamSVGs.NE,
		NO: TeamSVGs.NO,
		NYG: TeamSVGs.NYG,
		NYJ: TeamSVGs.NYJ,
		OAK: TeamSVGs.LV,
		PHI: TeamSVGs.PHI,
		PIT: TeamSVGs.PIT,
		SEA: TeamSVGs.SEA,
		SF: TeamSVGs.SF,
		TB: TeamSVGs.TB,
		TEN: TeamSVGs.TEN,
		WAS: TeamSVGs.WAS
	}
};
