import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_LEAGUE_GAME_WEEK_TEAMS_REQUEST = 'FETCH_LEAGUE_GAME_WEEK_TEAMS_REQUEST';
export const FETCH_LEAGUE_GAME_WEEK_TEAMS_SUCCESS = 'FETCH_LEAGUE_GAME_WEEK_TEAMS_SUCCESS';
export const FETCH_LEAGUE_GAME_WEEK_TEAMS_FAILURE = 'FETCH_LEAGUE_GAME_WEEK_TEAMS_FAILURE';

export function fetchLeagueGameWeekTeamsRequest ( isFetching ) {
	return {
		type: FETCH_LEAGUE_GAME_WEEK_TEAMS_REQUEST,
		isFetching
	};
}

export function fetchLeagueGameWeekTeamsFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_LEAGUE_GAME_WEEK_TEAMS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchLeagueGameWeekTeamsSuccess( data, leagueId ) {
	return {
		type: FETCH_LEAGUE_GAME_WEEK_TEAMS_SUCCESS,
		leagueId,
		data: data.leagueGameWeekTeams
	};
}

export function fetchLeagueGameWeekTeams ( leagueId, gameWeek ) {
	return ( dispatch ) => {
		dispatch(fetchLeagueGameWeekTeamsRequest(true));

		const url = Constants.urls.GRAPHQL;
		const query = `query {
			leagueGameWeekTeams(leagueId: "${leagueId}", gameWeek:"${gameWeek}") {
				gameWeekTeamId
				starters {
					nflTeam
					playerId
					statId
				}
			}
		}`;
		axios.post(url, {
			query: query.replace(/\t+/g, "")
		}).then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				return response.data.data;
			}).then(data => {
				dispatch(fetchLeagueGameWeekTeamsSuccess(data, leagueId));
			}).catch(err => dispatch(fetchLeagueGameWeekTeamsFailure(err)))
		.finally(() => dispatch(fetchLeagueGameWeekTeamsRequest(false)))
	};
}
