import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const PUT_USER_NAME_REQUEST = 'PUT_USER_NAME_REQUEST';
export const PUT_USER_NAME_SUCCESS = 'PUT_USER_NAME_SUCCESS';
export const PUT_USER_NAME_FAILURE = 'PUT_USER_NAME_FAILURE';

export function setUserNameRequest ( isPosting ) {
	return {
		type: PUT_USER_NAME_REQUEST,
		isPosting
	};
}

export function setUserNameFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: PUT_USER_NAME_FAILURE,
		response: err,
		error: true
	};
}

export function setUserNameSuccess( data ) {
	return {
		type: PUT_USER_NAME_SUCCESS,
		data
	};
}

export function setUserName ( userId, userName ) {
	return ( dispatch ) => {
		dispatch(setUserNameRequest(true));

		const url = `${Constants.urls.USER}/`;
		axios.put(url, { userId, userName } )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(setUserNameRequest(false));

			return response.data.Attributes;
		}).then(data => dispatch(setUserNameSuccess(data.userName)))
		.catch(err => dispatch(setUserNameFailure(err)));
	};
}
