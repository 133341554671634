import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer'
import { connect } from 'react-redux';
import Keys from 'shakitz-keys';
import Header from '../Common/Header';
import { Constants } from '../../helpers/Constants';
import sortByPosition from '../../helpers/sortByPosition';
import PlayerBox from '../Common/Players/PlayerBox';
import InfoIcon from '../svgs/InfoIcon';
import {fetchPerfectTeam} from '../../actions/fetchPerfectTeam';
import Spinner from '../svgs/Spinner';
import './PerfectTeam.scss';

const getStarters = (isFetching, season, gameWeek, format, type, players, perfectTeam) => {
	if(isFetching || !perfectTeam || perfectTeam.length === 0) {
		return [];
	}

	const formatMap = {
		'standard': 'standard',
		'0.5ppr': 'halfPPR',
		'1ppr': 'fullPPR'
	};

	const mappedPlayers = perfectTeam.map(p => {
		const player = players.find(pl => pl.statId === p.idInfo.nflId || pl.playerId === p.idInfo.nflId);
		const obj = { ...p, ...player };
		return {
			...obj,
			...{ gameWeekPlayerId: Keys.generateGameWeekPlayer({ playerId: obj.statId ||obj.playerId, season, gameWeek, leagueType: type })}
		};
	}).map(p => {
		p.calculatedStats = p.calculated[formatMap[format]] || { points: 0 };
		return p;
	});

	const sorter = (a, b) => b.calculatedStats.points - a.calculatedStats.points;
	const topQB = mappedPlayers.filter(p => p.pos === 'QB').sort(sorter);
	const topRB = mappedPlayers.filter(p => p.pos === 'RB').sort(sorter);
	const topWR = mappedPlayers.filter(p => p.pos === 'WR').sort(sorter);
	const topTE = mappedPlayers.filter(p => p.pos === 'TE').sort(sorter);
	const topK = mappedPlayers.filter(p => p.pos === 'K').sort(sorter);
	const topD = mappedPlayers.filter(p => p.pos === 'D').sort(sorter);

	let starters = [
		...topQB.splice(0, 2),
		...topRB.splice(0, 2),
		...topWR.splice(0, 2),
		...topTE.splice(0, 1),
		...topK.splice(0, 1),
		topD[0]
	];

	const flex = topWR.concat(topTE).concat(topK).sort(sorter);
	starters.push(flex[0]);

	return starters.filter(s => s);
};

const PerfectTeam = ({currentGameWeek, isFetching, fetchPerfectTeam, perfectTeam, headerText = 'Perfect Team', players, selectedLeague, gameWeekOverride = null}) => {
	const [ref, inView] = useInView();
	const { format, season, type } = selectedLeague;
	const gameWeek = gameWeekOverride || currentGameWeek[type];

	useEffect(() => {
		if(inView && !isFetching && !perfectTeam) {
			fetchPerfectTeam(season, type, format, gameWeek);
		}
	}, [fetchPerfectTeam, season, type, format, gameWeek, inView, isFetching, perfectTeam]);

	const starters = getStarters(isFetching, season, gameWeek, format, type, players, perfectTeam);

	return (
		<div className='perfect-team' ref={ref}>
			<div className='perfect-team__header'>
				<Header headerLevel={'h1'} text={headerText} />
				<div className='perfect-team__player-explainer'>
					<span>Tap player for more info</span>
					<div className='perfect-team__svg-wrapper'>
						<InfoIcon />
					</div>
				</div>
			</div>
			<div className='perfect-team__container'>
				{isFetching && <Spinner/>}
				{starters.length === Constants.numStarters && sortByPosition(starters).map(starter => <PlayerBox key={`playerbox-${starter.playerId}`} canEdit={false} gameWeek={gameWeek} isPerfect={true} player={starter} />)}
			</div>
		</div>
	)
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchPerfectTeam: (season, seasonType, format, gameWeek) => dispatch(fetchPerfectTeam(season, seasonType, format, gameWeek))
	};
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		players: state.players,
		scoreData: state.scores,
		selectedLeague: state.selectedLeague,
		isFetching: state.perfectTeamRequest,
		perfectTeam: state.perfectTeam
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PerfectTeam);
