const _sumStats = (stats) => {
	const cumulativeStats = [];
	stats.forEach(stat => {
		const existingStat = cumulativeStats.findIndex(cStat => cStat.name === stat.name);
		if(existingStat === -1) {
			cumulativeStats.push(stat);
		} else {
			cumulativeStats[existingStat].value += stat.value;
			cumulativeStats[existingStat].points += stat.points;
		}
	});

	return cumulativeStats;
};

const transformPlayerSeasonStats = (historicPlayerStats = {}, key) => {
	if(!historicPlayerStats.hasOwnProperty(key)) {
		return null;
	}

	//Clone data so we don't mutate on re-opening
	const clonedStats = JSON.parse(JSON.stringify(historicPlayerStats[key]));
	const calculatedStats = clonedStats.gameWeekPlayers.reduce((prev, curr) => prev.concat(curr), []).map(gw => {
		return gw.calculatedStats;
	}).reduce((prev, curr) => ({
		defensive: prev.defensive.concat(curr.defensive),
		kicking: prev.kicking.concat(curr.kicking),
		passing: prev.passing.concat(curr.passing),
		offensive: prev.offensive.concat(curr.offensive)
	}), { defensive: [], kicking: [], passing: [], offensive: []});

	return {
		defensive: _sumStats(calculatedStats.defensive),
		kicking: _sumStats(calculatedStats.kicking),
		passing: _sumStats(calculatedStats.passing),
		offensive: _sumStats(calculatedStats.offensive)
	};
};

export default transformPlayerSeasonStats;
