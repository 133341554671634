import { PUT_AUTO_DRAFT_FAILURE, PUT_AUTO_DRAFT_REQUEST, PUT_AUTO_DRAFT_SUCCESS} from '../actions/setAutoDraft';

export function setAutoDraftFailure(state = false, action) {
	switch (action.type) {
		case PUT_AUTO_DRAFT_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function setAutoDraftRequest(state = false, action) {
	switch (action.type) {
		case PUT_AUTO_DRAFT_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function setAutoDraft(state = [], action) {
	switch (action.type) {
		case PUT_AUTO_DRAFT_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
