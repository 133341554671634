import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const POST_TRADE_REQUEST = 'POST_TRADE_REQUEST';
export const POST_TRADE_SUCCESS = 'POST_TRADE_SUCCESS';
export const POST_TRADE_FAILURE = 'POST_TRADE_FAILURE';

export function submitTradeRequest ( isPosting ) {
	return {
		type: POST_TRADE_REQUEST,
		isPosting
	};
}

export function submitTradeFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_TRADE_FAILURE,
		response: err,
		error: true
	};
}

export function submitTradeSuccess( data ) {
	return {
		type: POST_TRADE_SUCCESS,
		data
	};
}

export function submitTrade ( tradeData ) {

	return ( dispatch ) => {
		dispatch(submitTradeRequest(true));

		const url = Constants.urls.TRADES;
		axios.post(url, tradeData )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			setTimeout(() => {
				dispatch(submitTradeRequest(false));
			}, 1100);

			return response.data;
		}).then(data => dispatch(submitTradeSuccess(data)))
		.catch(err => dispatch(submitTradeFailure(err)));
	};
}
