import React from 'react';

const Shape5 = ({fill = '#A838FF'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
		<path fill={fill} d="M18 9c0 4.97-4.03 9-9 9C4.03 17.998 0 13.97 0 9c0-4.97 4.03-9 9-9 4.97.001 9 4.03 8.999 9z"/>
	</svg>
);

export default Shape5;

