import React from 'react';

const CountdownIcon = ({fill = '#0FB7FF', width = '32', height = '32'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 32 33">
		<path fill={fill} d="M30.451 14.973c-.855 0-1.549.698-1.549 1.557C28.904 23.682 23.115 29.5 16 29.5c-7.115 0-12.904-5.818-12.904-12.97C3.096 9.378 8.886 3.56 16 3.56c.855 0 1.549-.698 1.549-1.556 0-.86-.694-1.556-1.549-1.556C7.178.448 0 7.662 0 16.53s7.178 16.083 16 16.083 16-7.215 16-16.083c0-.86-.694-1.557-1.549-1.557z"/>
		<path fill={fill} d="M16.825 11.392c-.44.646-.684 1.361-.85 2.028-1.699.015-3.071 1.401-3.071 3.11 0 1.719 1.387 3.112 3.096 3.112 1.71 0 3.096-1.394 3.096-3.112 0-.413-.085-.807-.23-1.17.549-.41 1.111-.913 1.538-1.57.458-.745.685-1.72 1.1-2.565.397-.857.81-1.705 1.15-2.529.358-.814.667-1.59.936-2.254.529-1.332.839-2.247.839-2.247.023-.07.01-.152-.042-.21-.078-.09-.213-.099-.3-.02 0 0-.72.64-1.742 1.644-.506.504-1.099 1.09-1.71 1.735-.628.632-1.246 1.343-1.881 2.042-.614.709-1.422 1.292-1.93 2.006z"/>
	</svg>
);

export default CountdownIcon;
