import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {GA} from '../helpers/ga';
import {Constants} from '../helpers/Constants';
import fuzzysearch from 'fuzzysearch';
import {debounce} from 'debounce';
import addRank from '../helpers/addRank';
import PlayerPoolSearch from '../components/PlayerPoolSearch';

import './withPlayerPool.scss';
const withPlayerPool = (WrappedComponent) => {
	// ...and returns another component...
	return class extends Component {
		constructor(props) {
			super(props);
			this.MAX_PAGES_TO_SHOW = 5;
			this.PAGE_SIZE = 20;
			this.toggleOptions = ['ALL', 'QB', 'RB', 'WR', 'TE', 'K', 'D'];
			this.state = {
				activePage: 0,
				activeOption: this.toggleOptions[0],
				playersToShow: this.sortOnRank(this.filterPlayers()),
				searchInFocus: false,
				searchValue: ''
			};
			this.inDraftView = this.props.hasOwnProperty('generateDraftPick');

			this.handleChange = debounce(this.handleChange.bind(this), 75);
			this.onBlur = this.onBlur.bind(this);
			this.onChange = this.onChange.bind(this);
			this.onFocus = this.onFocus.bind(this);
			this.onPageToggleClick = this.onPageToggleClick.bind(this);
			this.onToggleClick =this.onToggleClick.bind(this);
		}

		componentDidUpdate(prevProps) {
			if(!this.props.playersToFilter) {
				return;
			}

			if(prevProps.playersToFilter && prevProps.playersToFilter.length !== this.props.playersToFilter.length) {
				this.setState({
					playersToShow: this.sortOnRank(this.filterPlayersOnSearch(this.state.searchValue, this.state.activeOption))
				});
			}

		}

		handleChange(value) {
			const playersToShow = this.sortOnRank(this.filterPlayersOnSearch(value.toLowerCase()));
			this.setState({
				playersToShow
			});
		}

		filterPlayers() {
			const players = addRank(this.props.selectedLeague.format, this.props.customPlayers || this.props.players);
			if(!this.props.playersToFilter) {
				return players;
			}

			return players.filter(player => this.props.playersToFilter.indexOf(player.personId) === -1);
		}

		filterPlayersOnPosition(pos) {
			if(pos === 'ALL') {
				return this.filterPlayers().map((p, index) => {
					p.filteredRank = index + 1;
					return p;
				}).filter(p => p.hideUnavailable !== true);
			}

			return this.filterPlayers().filter(player => player.pos === pos).map((p, index) => {
				p.filteredRank = index + 1;
				return p;
			}).filter(p => p.hideUnavailable !== true);
		}

		filterPlayersOnSearch(searchVal, newPosition) {
			const preFilteredPlayers = this.filterPlayersOnPosition(newPosition || this.state.activeOption);
			return preFilteredPlayers.filter(player => {
				const { display, first, last } = player.playerName;
				const playerName = display || `${first} ${last}` || '';

				let hasMatchingTeam = false;

				// Don't start searching for team fuzzy matches too early to limit false positives
				if(searchVal.length > 6) {
					const playerTeam = Constants.teamMap[player.nflTeam];
					hasMatchingTeam = playerTeam ? fuzzysearch(searchVal, playerTeam.toLowerCase()) : null;
				}

				return fuzzysearch(searchVal, playerName.toLowerCase()) || hasMatchingTeam;
			});
		}

		getStartAndEndPage (pages, numOfPlayers) {
			if(pages <= this.MAX_PAGES_TO_SHOW) {
				return { start: 1, end: pages}
			}

			const startPage = Math.max(
				Math.min(
					this.state.activePage - Math.floor(this.MAX_PAGES_TO_SHOW / 2, 10),
					numOfPlayers - this.MAX_PAGES_TO_SHOW - 1
				),
				1);
			const endPage = startPage + this.MAX_PAGES_TO_SHOW -1;
			return { start: startPage, end: endPage };
		}

		generatePaginationToggle (players) {
			if(players.length === 0) {
				return [];
			}

			const pageSize = this.PAGE_SIZE;
			const pages = Math.ceil(players.length / pageSize);

			let toggleOptions = [{ key: 'prev', isIcon: true, isPrev: true}];
			const { start, end } = this.getStartAndEndPage(pages, players.length);
			for(let i = start; i <= end; i++) {
				toggleOptions.push({ page: i, isEllipsis: false});
			}
			toggleOptions.push({ key: 'next', isIcon: true, isPrev: false });

			return toggleOptions;
		}

		onBlur() {
			this.setState({ searchInFocus: false });
		}

		onChange(event) {
			const value = event.target.value;
			this.setState({
				searchValue: value
			});
			this.handleChange(value);
		}

		onFocus() {
			this.setState({ searchInFocus: true });
		}

		onPageToggleClick(evt) {
			let target = evt.target;
			if(target.nodeName === 'path') {
				target = target.parentNode.parentNode.parentNode;
			} else if (target.nodeName === 'svg') {
				target = target.parentNode.parentNode;
			} else if (target.nodeName === 'span') {
				target = target.parentNode;
			}
			const option = this.generatePaginationToggle(this.state.playersToShow).find(option => option.page === Number(target.innerText || target.getAttribute('data-option')));

			const dataKey = target.getAttribute('data-key');
			if(!option && !dataKey) {
				return;
			}

			ReactGA.event({
				category: this.inDraftView ? GA.CATEGORY.DRAFT : GA.CATEGORY.WAIVER,
				action: GA.ACTION.PAGE_TOGGLE
			});
			const pageToSet = option ? option.page - 1 : (dataKey === 'prev' ? this.state.activePage - 1 : this.state.activePage + 1);
			this.setState({
				activePage: pageToSet
			});
		}

		onToggleClick(evt) {
			const option = this.toggleOptions.find(option => option === evt.target.innerText);
			if(!option) {
				return;
			}

			ReactGA.event({
				category: this.inDraftView ? GA.CATEGORY.DRAFT : GA.CATEGORY.WAIVER,
				action: GA.ACTION.POSITION_TOGGLE
			});
			this.setState({
				activeOption: option,
				activePage: 0,
				playersToShow: this.sortOnRank(this.filterPlayersOnSearch(this.state.searchValue, option))
			});
		}

		sortOnRank(players) {
			if(this.props.hasOwnProperty('generateDraftPick')) {
				return players.sort((a, b) => Number(a.rank) - Number(b.rank));
			}
			return players.sort((a, b) => b.totalPoints.points - a.totalPoints.points);
		}

		render() {
			const playersFrom = this.state.activePage * this.PAGE_SIZE;
			const paginationToggleOptions = this.generatePaginationToggle(this.state.playersToShow);

			return (
				<>
					<WrappedComponent activeOption={this.state.activeOption} activePage={this.state.activePage} onPageToggleClick={this.onPageToggleClick} pageSize={this.PAGE_SIZE} paginationToggleOptions={paginationToggleOptions} playersFrom={playersFrom} playersToShow={this.state.playersToShow} searchInFocus={this.state.searchInFocus} {...this.props}>
						<PlayerPoolSearch activeOption={this.state.activeOption} draftView={this.inDraftView} onBlur={this.onBlur} onChange={this.onChange} onFocus={this.onFocus} onToggleClick={this.onToggleClick} searchValue={this.state.searchValue} toggleOptions={this.toggleOptions} />
					</WrappedComponent>
				</>
			);
		}
	};
};

const mapStateToProps = ( state ) => {
	return {
		players: state.players,
		selectedLeague: state.selectedLeague
	};
};

export default compose(connect(mapStateToProps), withPlayerPool);
