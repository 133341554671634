import { SET_DRAFT_CLOCK } from '../actions/setDraftClock';

export function draftClock (state = {}, action) {
	switch (action.type) {
		case SET_DRAFT_CLOCK:
			return { ...state, ...{isPaused: false},...action.clockObj};
		default:
			return state;
	}
}
