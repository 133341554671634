import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_LEAGUE_STANDINGS_REQUEST = 'FETCH_LEAGUE_STANDINGS_REQUEST';
export const FETCH_LEAGUE_STANDINGS_SUCCESS = 'FETCH_LEAGUE_STANDINGS_SUCCESS';
export const FETCH_LEAGUE_STANDINGS_FAILURE = 'FETCH_LEAGUE_STANDINGS_FAILURE';

export function fetchLeagueStandingsRequest ( isFetching ) {
	return {
		type: FETCH_LEAGUE_STANDINGS_REQUEST,
		isFetching
	};
}

export function fetchLeagueStandingsFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_LEAGUE_STANDINGS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchLeagueStandingsSuccess( data ) {
	return {
		type: FETCH_LEAGUE_STANDINGS_SUCCESS,
		data
	};
}

export function fetchLeagueStandings ( leagueId ) {
	return ( dispatch ) => {
		dispatch(fetchLeagueStandingsRequest(true));

		const url = Constants.urls.GRAPHQL;
		const query = `query {
			standings(leagueId: "${leagueId}") {
			    leagueId
			    lastCalculated
			    standings {
			      manager
			      managerId
			      teamName
			      shortTeamName
			      gwPoints
			      gwBenchPoints
			      record
			      totalPoints
			      benchPoints
			    }
		    }
		  }
		`;
		axios.post(url, {
			query: query.replace(/\t+/g, "")
		}).then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			const leagueObj = {};
			leagueObj.leagueId = leagueId;
			leagueObj.data = response.data.data;
			return leagueObj;
		}).then(data => dispatch(fetchLeagueStandingsSuccess(data))).catch(err => dispatch(fetchLeagueStandingsFailure(err))).finally(() => dispatch(fetchLeagueStandingsRequest(false)));
	};
}
