import React from 'react';
import { GA } from '../../helpers/ga';
import classNames from 'classnames';
import Header from '../Common/Header';
import PreviousH2HMeetings from '../PreviousH2HMeetings/PreviousH2HMeetings';
import {ShapeGroup1, ShapeGroup2} from '../svgs/logo';
import SeasonRecord from './SeasonRecord';
import UserTopDraftPicks from './UserTopDraftPicks';
import './UserNextH2HBox.scss';

const _calculateNextFixture = (fixture) => {
	return <p>Next Fixture: <span>{`Game Week ${fixture.gameWeek}`}</span></p>;
};

const UserNextH2HBox = ({draftPicks = { draft: { 'R1': [], 'R2': [], 'R3': []}}, fixture, historicLeagueRecords, standings = [], onUserClick, userId}) => {
	const hasPastData = fixture.hasOwnProperty('previousMeetings');
	const metaData = _calculateNextFixture(fixture);
	const isOpponentHome = fixture.awayManagerId === userId;
	const opponentUserId = isOpponentHome ? fixture.homeManagerId : fixture.awayManagerId;
	const opponentTeamId = isOpponentHome ? fixture.homeTeamId : fixture.awayTeamId;

	let opponentRecord = standings.find(entry => entry.managerId === opponentUserId);
	if(!opponentRecord) {
		const lastSeasonStandings = historicLeagueRecords?.standings || [];
		// In order to get last years record we have to update the season user team id to be of the previous year
		const lastSeason = Number(fixture.season) - 1;
		const lastYearsOpponentTeamId = opponentTeamId.replace(`_${fixture.season}_`, `_${lastSeason}_`);
		opponentRecord = lastSeasonStandings.find(record => record.seasonUserTeamId === lastYearsOpponentTeamId);
	}

	const opponentDraftPicks = [...draftPicks.draft['R1'], ...draftPicks.draft['R2'], ...draftPicks.draft['R3']].filter(draftPick => draftPick.userId === opponentUserId);
	const clsName = classNames('user-next-h2h', { 'user-next-h2h--no-past-data' : !hasPastData});
	return (
		<div className={clsName}>
			<div className='user-next-h2h__header'>
				<Header headerLevel={'h1'} text={'Your Next Game'}/>
			</div>
			<div className='user-next-h2h__box'>
				<div className='user-next-h2h__bubbles'>
					<ShapeGroup2 width='70' height='100' />
					<ShapeGroup1 width='70' height='100' />
				</div>
				<div className='user-next-h2h__opponent' onClick={() => onUserClick(opponentTeamId, GA.CATEGORY.H2H)}>
					<Header headerLevel={'h2'} text={isOpponentHome ? fixture.homeTeam : fixture.awayTeam}/>
					<p>{isOpponentHome ? fixture.homeManager : fixture.awayManager}</p>
				</div>
			</div>
			<div className='user-next-h2h__opponent-streak'>
				{metaData}
			</div>
			{fixture.previousMeetings ?
				(
					<div className='user-next-h2h__previous-meetings'>
						<PreviousH2HMeetings maxFixturesToShow={2} fixture={fixture}/>
					</div>
				) : (
					<div className='user-next-h2h__opponent-context'>
						<SeasonRecord record={opponentRecord} />
						<UserTopDraftPicks picks={opponentDraftPicks} />
					</div>
				)
			}
		</div>
	)
};

export default UserNextH2HBox;
