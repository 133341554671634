import React, {Component} from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import arrayMove from 'array-move';
import { GA } from '../../helpers/ga';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Header from '../../components/Common/Header';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import InfoIcon from '../../components/svgs/InfoIcon';
import Transfer from '../../components/Transfer';
import {addWaiver} from '../../actions/addWaiver';
import {deleteWaiver} from '../../actions/deleteWaiver';
import generateWaiverObject from '../../helpers/generateWaiverObject';
import EmptyState from '../../components/Common/EmptyState';
import './ProposedWaiverPane.scss';

const SortableItem = SortableElement(({value}) => <li>{value}</li>);

const SortableList = SortableContainer(({items}) => {
	return (
		<ul>
			{items.map((value, index) => (
				<SortableItem key={`item-${index}`} index={index} value={value} />
			))}
		</ul>
	);
});

class ProposedWaiverPane extends Component {

	constructor(props) {
		super(props);
		this.onCancel = this.onCancel.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
	}

	onCancel(id, priorityToDelete) {
		ReactGA.event({
			category: GA.CATEGORY.WAIVER,
			action: GA.ACTION.CANCEL_WAIVER
		});

		const newWaivers = this.props.waivers.waiversList.filter(w => w.priority !== priorityToDelete).map((w, index) => {
			w.priority = index + 1;
			return w;
		});
		const postObj = generateWaiverObject(newWaivers, this.props.selectedLeague, this.props.seasonUserTeam.seasonUserTeamId);
		this.props.addWaiver(postObj);
	}

	onSortEnd = ({oldIndex, newIndex}) => {
		if(oldIndex === newIndex) {
			return;
		}
		ReactGA.event({
			category: GA.CATEGORY.WAIVER,
			action: GA.ACTION.REARRANGE_WAIVER
		});

		const newWaivers = arrayMove(this.props.waivers.waiversList, oldIndex, newIndex).map((w, index) => {
			w.priority = index + 1;
			return w;
		});

		const postObj = generateWaiverObject(newWaivers, this.props.selectedLeague, this.props.seasonUserTeam.seasonUserTeamId);
		this.props.addWaiver(postObj);
	};

	transformData(waiver) {
		const playerOut = this.props.players.find(p => p.playerId === waiver.playerOut.playerId || p.statId === waiver.playerOut.playerId);
		const playerIn = this.props.players.find(p => p.playerId === waiver.playerIn.playerId || p.statId === waiver.playerIn.playerId);

		return {
			leagueId: this.props.waivers.leagueId,
			waiversId: this.props.waivers.waiversId,
			priority: waiver.priority,
			teamA: {
				playerId: waiver.playerOut.playerId,
				player: playerOut,
			}, teamB: {
				playerId: waiver.playerIn.playerId,
				player: playerIn
			}
		};
	}

	render () {
		const proposedWaivers = this.props.waivers.waiversList.map(waiver => {
			const canCancel = waiver.seasonUserTeamId === this.props.seasonUserTeam.seasonUserTeamId;
			return <Transfer key={waiver.priority} canCancel={canCancel} data={this.transformData(waiver)} onCancel={this.onCancel} type={'waiver'} />;
		});

		return (
			<div className='proposed-waiver-pane'>
				<div className='proposed-waiver-pane__heading'>
					<Header headerLevel={'h1'} text={'Proposed Waivers'} />
					<div className='proposed-waiver-pane__priority-explainer'>
						<span>Drag & Drop to order priority</span>
						<div className='proposed-waiver-pane__svg-wrapper'>
							<InfoIcon />
						</div>
					</div>
				</div>
				<div className='proposed-waiver-pane__waivers'>
					{proposedWaivers.length === 0 ?
						<div className='proposed-waiver-pane__empty'>
							<EmptyState heading='No Proposed Waivers' secondaryText='Once you have submitted waivers, they will appear here' isRoster={true} />
						</div> :

						<SortableList items={proposedWaivers} onSortEnd={this.onSortEnd} />
					}
				</div>

				{this.props.isPosting ? <SpinnerLayer/> : null}
			</div>
		);
	}
}

const mapDispatchToProps = ( dispatch ) => {
	return {
		addWaiver: (data) => dispatch(addWaiver(data)),
		deleteWaiver: (leagueId, waiversId) => dispatch(deleteWaiver(leagueId, waiversId)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		players: state.players,
		selectedLeague: state.selectedLeague,
		isPosting: state.deleteWaiverRequest || state.addWaiverRequest
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProposedWaiverPane);
