import { FETCH_UNAVAILABLE_PLAYERS_FAILURE, FETCH_UNAVAILABLE_PLAYERS_REQUEST, FETCH_UNAVAILABLE_PLAYERS_SUCCESS} from '../actions/fetchUnavailablePlayers';

export function unavailablePlayersFailure(state = false, action) {
	switch (action.type) {
		case FETCH_UNAVAILABLE_PLAYERS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function unavailablePlayersRequest(state = false, action) {
	switch (action.type) {
		case FETCH_UNAVAILABLE_PLAYERS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function unavailablePlayers(state = {}, action) {
	switch (action.type) {
		case FETCH_UNAVAILABLE_PLAYERS_SUCCESS:
			const clonedState = JSON.parse(JSON.stringify(state));
			if(!clonedState.hasOwnProperty(action.leagueId)) {
				clonedState[action.leagueId] = {};
			}

			clonedState[action.leagueId][action.season] = action.data;
			return clonedState;
		default:
			return state;
	}
}
