import React from 'react';
import Button from '../Button';
import './GameWeekToggle.scss';

const GameWeekToggle = ({disablePrev, disableNext, onToggleClick, currentGameWeek}) => {
	return (
		<div className='gw-toggle'>
			<Button type='directional' disabled={disablePrev} isPrev text={'Prev'} onClick={() => onToggleClick(currentGameWeek - 1)}/>
			<span className='gw-toggle__current-gw'>GameWeek {currentGameWeek}</span>
			<Button type='directional' disabled={disableNext} isNext text={'Next'} onClick={() => onToggleClick(currentGameWeek + 1)} />
		</div>
	);
};

export default GameWeekToggle;
