import hasPlayerPlayed from './hasPlayerPlayed';
import {Constants} from './Constants';

const calculateWinPercentage = (games, gameWeek, players, seasonPlayerStats, homeRoster, awayRoster, previousLeg) => {
	let homePercentage = 50, awayPercentage = 50;

	if(!homeRoster || !awayRoster) {
		return {
			homePercentage,
			awayPercentage
		};
	}

	const mergeData = (player) => {
		const isOrHasPlayed = hasPlayerPlayed(player, players, games);
		let points = player.calculatedStats.points || 0;
		if(!isOrHasPlayed) {
			const seasonStatPlayer = seasonPlayerStats.find(seasonPlayer => seasonPlayer.playerId === player.playerId);
			if(seasonStatPlayer) {
				points = Math.round(seasonStatPlayer.totalPoints.points / (gameWeek - 1));
			}
		}

		const injured = player.injuryStatus && Constants.injuryStates.OUT.includes(player.injuryStatus);
		return {
			injured,
			isOrHasPlayed,
			points
		}
	};

	const addPoints = (prev, curr) => {
		if(curr.injured) {
			return prev;
		}
		return prev + curr.points;
	};

	const mergedHomePlayers = homeRoster.starters.map(mergeData);
	const mergedAwayPlayers = awayRoster.starters.map(mergeData);

	let homeChance = mergedHomePlayers.reduce(addPoints, 0);
	let awayChance = mergedAwayPlayers.reduce(addPoints, 0);

	if(previousLeg && previousLeg.homeTeamScore && previousLeg.awayTeamScore) {
		homeChance += previousLeg.homeTeamScore;
		awayChance += previousLeg.awayTeamScore;
	}

	const homeLeftToPlay = mergedHomePlayers.filter(p => !p.isOrHasPlayed && !p.injured);
	const awayLeftToPlay = mergedAwayPlayers.filter(p => !p.isOrHasPlayed && !p.injured);

	const playerToPlayDifference = homeLeftToPlay.length - awayLeftToPlay.length;

	if(playerToPlayDifference > 0) {
		if(awayLeftToPlay.length === 0 && homeChance > awayChance) {
			homeChance = 99;
			awayChance = 1;
		}
	} else if (playerToPlayDifference < 0) {
		if(homeLeftToPlay.length === 0 && awayChance > homeChance) {
			awayChance = 99;
			homeChance = 1;
		}
	} else if (homeLeftToPlay.length === 0 && awayLeftToPlay.length === 0) {
		if(homeChance > awayChance) {
			homeChance = 100;
			awayChance = 0;
		} else if (awayChance > homeChance) {
			homeChance = 0;
			awayChance = 100;
		}
	}

	if(homeChance !== 0 && awayChance !== 0) {
		if(homeLeftToPlay.length > awayLeftToPlay.length) {
			const homeMultiplier = 1 + ((homeLeftToPlay.length - awayLeftToPlay.length) / 20);
			const awayMultiplier = 2 - homeMultiplier;

			homeChance = homeChance * homeMultiplier;
			awayChance = awayChance * awayMultiplier;
		} else if (awayLeftToPlay.length > homeLeftToPlay.length) {
			const awayMultiplier = 1 + ((awayLeftToPlay.length - homeLeftToPlay.length) / 20);
			const homeMultiplier = 2 - awayMultiplier;

			awayChance = awayChance * awayMultiplier;
			homeChance = homeChance * homeMultiplier;
		}
	}

	const totalScore = homeChance + awayChance;

	homePercentage = Math.round((homeChance / totalScore) * 100);
	awayPercentage = Math.round((awayChance / totalScore) * 100);

	return {
		homePercentage,
		awayPercentage
	}
};

export default calculateWinPercentage;
