import React from 'react';

const BenchOfTheWeekIcon = ({fill = '#0FB7FF', width = '32', height = '32'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 32 33">
		<path fill={fill} d="M16 .433c-8.836 0-16 7.2-16 16.083s7.164 16.08 16 16.08 16-7.197 16-16.08S24.836.433 16 .433zm0 29.65c-7.44 0-13.497-6.088-13.497-13.567 0-7.482 6.056-13.566 13.498-13.566 7.439 0 13.495 6.084 13.495 13.566 0 7.478-6.056 13.566-13.495 13.566z"/>
		<path fill={fill} d="M8.817 21.957c-.489.49-.489 1.287 0 1.777.49.492 1.282.492 1.77 0 2.983-2.998 7.839-2.998 10.824 0 .24.247.562.369.883.369.319 0 .64-.122.884-.37.488-.49.488-1.286 0-1.776-3.957-3.985-10.402-3.985-14.36 0zM11.508 14.858c.979 0 1.775-.796 1.775-1.784 0-.985-.796-1.783-1.775-1.783-.983 0-1.775.796-1.775 1.784.002.985.794 1.783 1.774 1.783zM20.493 14.858c.98 0 1.774-.796 1.774-1.784 0-.985-.792-1.783-1.774-1.783-.978 0-1.776.796-1.776 1.784 0 .985.798 1.783 1.776 1.783z"/>
	</svg>

);

export default BenchOfTheWeekIcon;
