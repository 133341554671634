import React from 'react';
import ExitIcon from '../../svgs/ExitIcon';
import LeftArrow from '../../svgs/LeftArrow';
import RightArrow from '../../svgs/RightArrow';
import './Button.scss';

const Button = ({disabled = false, onClick, text, type = 'primary', isAuth = false, isNext = false, isPrev = false, isSubmit = false, dataAttributes}) => {
	return (
		<button className={`button button--${type}`} type={isSubmit ? 'submit' : 'button'}
	        disabled={disabled}
	        onClick={onClick}
			{...dataAttributes}>
			{isPrev && <LeftArrow className='button__svg button__svg--left' />}
			{text}
			{isNext && <RightArrow className='button__svg button__svg--right'/>}
			{isAuth && <ExitIcon className='button__svg button__svg--exit'/>}
		</button>
	);
};

export default Button;
