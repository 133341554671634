import { POST_PAUSE_DRAFT_FAILURE, POST_PAUSE_DRAFT_REQUEST, POST_PAUSE_DRAFT_SUCCESS} from '../actions/pauseDraft';

export function pauseDraftFailure(state = false, action) {
	switch (action.type) {
		case POST_PAUSE_DRAFT_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function pauseDraftRequest(state = false, action) {
	switch (action.type) {
		case POST_PAUSE_DRAFT_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function pauseDraft(state = false, action) {
	switch (action.type) {
		case POST_PAUSE_DRAFT_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
