import React from 'react';
import {Link} from 'react-router-dom';
import Explainer from '../../components/Explainer';
import {HomeShapeGroupLeft, HomeShapeGroupRight, Shape1, Shape2, Shape3, Shape4, Shape5 } from '../../components/svgs/logo';
import BenchOfTheWeekIcon from '../../components/svgs/BenchOfTheWeekIcon';
import CountdownIcon from '../../components/svgs/CountdownIcon';
import DraftRoomIcon from '../../components/svgs/DraftRoomIcon';
import LeagueOverviewIcon from '../../components/svgs/LeagueOverviewIcon';
import RecordsIcon from '../../components/svgs/RecordsIcon';
import Football from '../../components/svgs/Football';
import TradeIcon from '../../components/svgs/TradeIcon';
import LeagueTypeIcon from '../../components/svgs/LeagueTypeIcon';
import FreeAgencyIcon from '../../components/svgs/FreeAgencyIcon';
import Button from '../../components/Common/Button';
import Emblem from '../../components/svgs/logo/Emblem';
import mobileVisual from './images/visual/mobile.png';
import mobileVisual2x from './images/visual/mobile@2x.png';
import mobileVisual3x from './images/visual/mobile@3x.png'
import desktopVisual from './images/visual/desktop.png'
import desktopVisual2x from './images/visual/desktop@2x.png'
import desktopVisual3x from './images/visual/desktop@3x.png'
import mobileVisualRow from './images/visual-row/mobile.png';
import mobileVisualRow2x from './images/visual-row/mobile@2x.png';
import mobileVisualRow3x from './images/visual-row/mobile@3x.png';
import desktopVisualRow from './images/visual-row/desktop.png';
import desktopVisualRow2x from './images/visual-row/desktop@2x.png';
import desktopVisualRow3x from './images/visual-row/desktop@3x.png';

import './USP.scss';

const uspRow1 = [{
	id: 'waiverCountdown',
	headerText: 'Waiver Countdown',
	coreText: 'Weekly waivers process instantly when the countdown hits zero, turning waiver day into a tense, fun experience with your friends.',
	svg: CountdownIcon
}, {
	id: 'benchOfTheWeek',
	headerText: 'Bench of the Week',
	coreText: 'Nothing worse than regretting that last minute tinker. We highlight the things you did right... as well as the things you did wrong',
	svg: BenchOfTheWeekIcon
},{
	id: 'draftRoom',
	headerText: 'Draft Room',
	coreText: 'We offer the best online draft experience. We let you pause, fix any issues, and filter on the information that matters.',
	svg: DraftRoomIcon
}]

const uspRow2 = [{
	id: 'leagueOverview',
	headerText: 'League Overview',
	coreText: 'Every when, what, who in one simple to understand screen.',
	svg: LeagueOverviewIcon
}, {
	id: 'records',
	headerText: 'Records',
	coreText: 'See your best and worst records ranging from highest bench of the week, to closest victory on our dedicated records page',
	svg: RecordsIcon
},{
	id: 'gameDay',
	headerText: 'Game Day',
	coreText: 'See everything, in real-time. From the perfect team, to the worst. We have everything covered.',
	svg: Football
}]

const uspRow3 = [{
	id: 'trades',
	headerText: 'Trades',
	coreText: 'Choose to trade players in a one-off trade or as a multi-week temporary trade. We lock all trades week 13',
	svg: TradeIcon
}, {
	id: 'freeAgency',
	headerText: 'No Free Agency',
	coreText: 'No free agency means you have to get your waivers right. A chance to prove yourself.',
	svg: FreeAgencyIcon
}, {
	id: 'leagueTypes',
	headerText: 'League Types',
	coreText: 'We have standard/0.5PPR/1PPR with either league standings or your usual H2H set up that you can customise.',
	svg: LeagueTypeIcon
}]

const USP = () => (
	<div className='usp'>
			<div className='usp__visuals'>
				<div className='usp__visual-bubble'>
					<HomeShapeGroupLeft />
				</div>
				<div className='usp__visual'>
					<picture>
						<source srcSet={`${desktopVisual}, ${desktopVisual2x} 2x, ${desktopVisual3x} 3x`} media='(min-width: 768px)' />
						<img alt='Visual of Shakitz Fantasy Football'
						     className='usp__main-image usp__image '
						     src={mobileVisual}
						     srcSet={`${mobileVisual2x} 2x, ${mobileVisual3x} 3x`} />
					</picture>
				</div>
				<div className='usp__visual-bubble'>
					<HomeShapeGroupRight />
				</div>
			</div>
			<div className='usp__teaser'>
				<Explainer type='teaser' coreText="We update in real time. That includes corrections, so no waiting hours to see whether you've beat your friend. And we promise you won't see any ads." headerText='Real time updates. Zero ads.'>
					<Shape3 fill={'#0fb7ff'}/>
					<Shape4 fill={'#fff'} />
					<Shape5 fill={'#a838ff'} />
				</Explainer>
				<picture>
					<source srcSet={`${desktopVisualRow}, ${desktopVisualRow2x} 2x, ${desktopVisualRow3x} 3x`} media='(min-width: 768px)' />
					<img alt='Multiple visuals of Shakitz Fantasy Football'
					     className='usp__teaser-image usp__image'
					     loading="lazy"
					     src={mobileVisualRow}
					     srcSet={`${mobileVisualRow2x} 2x, ${mobileVisualRow3x} 3x`} />
				</picture>
			</div>
			<div className='usp__explainers'>
				<Explainer type='teaser' coreText='Below is just a sample of some of the features we think helps us stand out from the crowd and offer a brilliant experience.' headerText='Features that
enhance your game'>
					<Shape1 fill={'#6834ff'} />
					<Shape2 fill={'#fff'} />
					<Shape3 fill={'#e00d83'} />
				</Explainer>
				<div className='usp__explainer-set'>
					<div className='usp-explainer-badge usp-explainer-badge--primary' />
					{uspRow1.map(item => {
						const SVGIcon = item.svg;
						return <Explainer key={`explainer_${item.id}`} coreText={item.coreText} headerText={item.headerText}><SVGIcon /></Explainer>
					})}
				</div>
				<div className='usp__explainer-set'>
					<div className='usp-explainer-badge usp-explainer-badge--secondary' />
					{uspRow2.map((item, index) => {
						const SVGIcon = item.svg;
						const order = index > 0 ? index + 2 : 1;
						return <Explainer key={`explainer_${item.id}`} coreText={item.coreText} headerText={item.headerText} style={{order}}><SVGIcon width='32' height='32' opacity='1' fill='#A838FF'/></Explainer>
					})}
				</div>
				<div className='usp__explainer-set'>
					<div className='usp-explainer-badge usp-explainer-badge--tertiary'/>
					{uspRow3.map(item => {
						const SVGIcon = item.svg;
						return <Explainer key={`explainer_${item.id}`} coreText={item.coreText} headerText={item.headerText}><SVGIcon width='32' height='32' opacity='1' fill='#E00D83'/></Explainer>
					})}
				</div>
				<div className='usp-reg-box'>
					<div className='usp-reg-box__content'>
						<p className='usp-reg-box__header'>Sounds good right? Get playing today.</p>
						<p className='usp-reg-box__text'>Sign up, tell your friends about us and get your dream fantasy league going today.</p>
					</div>
					<Link className='usp-reg-box__cta' to='/login?login=true'><Button type='quaternary' text='Log in/Register' /></Link>
				</div>
			</div>
			<div className='usp__footer'>
				<span className='usp-logo'><Emblem width='22' height='22'/>Shakitz</span>
				<div className='usp__footer-links'>
					<Link to='/rules'>Rules</Link>
				</div>
			</div>
	</div>
);

export default USP;
