import { SELECT_LEAGUE } from '../actions/selectLeague';

export function selectedLeague(state = false, action) {
	switch (action.type) {
		case SELECT_LEAGUE:
			return {
				format: action.format,
				leagueId: action.leagueId,
				season: action.season,
				seasonToBe: action.seasonToBe,
				type: action.seasonType
			};
		default:
			return state;
	}
}
