import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducers';

export default function configureStore(preloadedState = {}) {

	const persistConfig = {
		key: 'shakitz',
		version: 4,
		storage,
		blacklist: [ 'schedule', 'currentGameWeek' ],
		whitelist:[ 'scores', 'seasonPlayerStats', 'selectedLeague'],
		migrate: (state) => {
			if(state && state._persist && state._persist.version === 2) {
				return Promise.resolve({});
			}
			if(state && state.selectedLeague && state._persist.version === 3) {
				console.log('deleting selected league');
				delete state.selectedLeague;
			}
			if(state && state.leagueUsers && state._persist.version === 4) {
				console.log('deleting selected league');
				delete state.leagueUsers;
			}

			return Promise.resolve(state);
		}
	};

	const persistedReducer = persistReducer(persistConfig, rootReducer);

	const middlewares = [thunkMiddleware];
	const middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer];

	let composedEnhancers = compose(...enhancers);

	if (process.env.NODE_ENV !== 'production') {
		composedEnhancers = composeWithDevTools(...enhancers);
	}

	let store = createStore(persistedReducer, preloadedState, composedEnhancers);
	let persistor = persistStore(store);

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
	}

	return { store, persistor };
}
