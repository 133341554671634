import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_LEAGUE_TRADE_DATA_SUCCESS = 'FETCH_LEAGUE_TRADE_DATA_SUCCESS';
export const FETCH_LEAGUE_TRADE_DATA_REQUEST = 'FETCH_LEAGUE_TRADE_DATA_REQUEST';
export const FETCH_LEAGUE_TRADE_DATA_FAILURE = 'FETCH_LEAGUE_TRADE_DATA_FAILURE';

export function fetchLeagueTradeDataRequest ( isFetching ) {
	return {
		type: FETCH_LEAGUE_TRADE_DATA_REQUEST,
		isFetching
	};
}

export function fetchLeagueTradeDataFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_LEAGUE_TRADE_DATA_FAILURE,
		response: err,
		error: true
	};
}

export function fetchLeagueTradeDataSuccess( data ) {
	return {
		type: FETCH_LEAGUE_TRADE_DATA_SUCCESS,
		data
	};
}


export function fetchLeagueTradeData ({leagueId, gameWeek, type, season}) {
	return ( dispatch ) => {
		dispatch(fetchLeagueTradeDataRequest(true));

		const url = Constants.urls.GRAPHQL;

		if(!leagueId) {
			return;
		}
		axios.post(url, {
			query: `query {
			  schedule(type:"${type}", season:"${season}"){
			    season
			    scheduleId
			    seasonType
			    games{
			      away
			      awayScore
			      home
			      homeScore
			      gameDay
			      gameTime
			      gameWeek
			      id
			    }
			  }
			  leagueUsers(leagueId: "${leagueId}") {
			    userId
			    name
			  }
			  seasonUserTeams(leagueId: "${leagueId}", season: "${season}") {
				seasonUserTeamId
				hashedUser
			    teamName
			    shortTeamName
			  }
			  trades(leagueId: "${leagueId}", season: "${season}") {
				tradeId
			    leagueId
			    season
			    seasonUserTeamAId
			    playerAId
			    seasonUserTeamBId
			    playerBId
			    gameWeek
			    outcome
			  }
			}
			`
		}).then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			const leagueObj = {};
			leagueObj.leagueId = leagueId;
			leagueObj.data = response.data.data;
			return leagueObj;
		}).then(data => dispatch(fetchLeagueTradeDataSuccess(data)))
		.catch(err => dispatch(fetchLeagueTradeDataFailure(err)))
		.finally(() => dispatch(fetchLeagueTradeDataRequest(false)));
	};
}
