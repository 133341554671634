import React from 'react';
import RecordMobileLeft from './RecordMobileLeft';
import RecordMobileCenter from './RecordMobileCenter';
import RecordMobileRight from './RecordMobileRight';

const RecordMobileGroup = () => (
	<>
		<RecordMobileLeft />
		<RecordMobileCenter />
		<RecordMobileRight />
	</>
);

export default RecordMobileGroup;
