import React from 'react';
import { Constants } from '../../../helpers/Constants';
import {TeamSVGMap} from '../../../helpers/TeamSVGMap';
import './PlayerTeam.scss';

const PlayerTeam = ({team, showShort, showName = true}) => {
	const clsName = `player-team ${showShort ? 'player-team--short-only' : ''}`;
	const TeamSVG = TeamSVGMap.teamMap[team];

	return (
		<div className={clsName}>
			<div className='player-team__svg'><TeamSVG /></div>
			{showName && <div className='player-team__team-name'>{team}</div>}
		</div>
	)
};

export default PlayerTeam;
