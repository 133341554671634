import React from 'react';

const InfoIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="6" height="14" viewBox="0 0 6 14">
		<g fill="#000" fillRule="nonzero">
			<path d="M5.7 12.497c-.06.258-.11.486-.168.709a.177.177 0 0 1-.096.1c-.603.217-1.198.48-1.817.626a2.517 2.517 0 0 1-1.599-.148C1.35 13.495.93 12.778.985 12.01c.037-.9.331-1.731.538-2.587.168-.693.351-1.396.496-2.101.034-.248.031-.5-.008-.747-.026-.266-.193-.416-.437-.465a2.042 2.042 0 0 0-1.227.125c-.061.025-.13.036-.224.064.056-.25.099-.473.159-.693a.228.228 0 0 1 .122-.13c.653-.267 1.288-.555 1.987-.644v-.001c.436-.06.879-.013 1.295.139.763.277 1.166 1.01 1.114 1.867-.056.957-.382 1.84-.596 2.754-.155.665-.305 1.332-.45 2-.021.116-.028.235-.021.353.01.507.205.75.678.76v-.001c.33-.01.659-.048.984-.112a2.15 2.15 0 0 0 .305-.094zM4.274.139c.873.017 1.52.636 1.596 1.524.055.621-.306 1.234-.9 1.533-.812.41-1.837.045-2.217-.787-.48-1.067.337-2.293 1.521-2.27z"/>
		</g>
	</svg>
);

export default InfoIcon;
