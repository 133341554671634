import React from 'react';

const HomeShapeGroupLeft = ({ width = '103', height = '139'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 103 139">
		<path fill="url(#homeShapeGroupLeft_paint0_linear)" d="M30.474 93.36l45.028-27.553L89.247 88.34c7.59 12.445 3.664 28.702-8.77 36.31l-22.514 13.777L30.474 93.36z"/>
		<path fill="url(#homeShapeGroupLeft_paint1_linear)" d="M30.474.194L56.782 0c14.53-.107 26.394 11.67 26.5 26.305l.191 26.5-26.308.192c-14.53.107-26.393-11.67-26.5-26.306L30.476.194z"/>
		<path fill="url(#homeShapeGroupLeft_paint2_linear)" d="M36.717 56.74c4.927 7.676 2.697 17.892-4.979 22.819-7.676 4.926-17.892 2.697-22.818-4.979-4.926-7.676-2.697-17.892 4.979-22.82 7.676-4.926 17.892-2.697 22.818 4.98z"/>
		<defs>
			<linearGradient id="homeShapeGroupLeft_paint0_linear" x1="52.988" x2="80.541" y1="79.583" y2="124.611" gradientUnits="userSpaceOnUse">
				<stop stopColor="#6834FF"/>
				<stop offset=".63" stopColor="#6834FF" stopOpacity=".28"/>
				<stop offset="1" stopColor="#6834FF" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="homeShapeGroupLeft_paint1_linear" x1="30.474" x2="83.473" y1="0" y2="52.999" gradientUnits="userSpaceOnUse">
				<stop stopColor="#0FB7FF"/>
				<stop offset=".635" stopColor="#0FB7FF" stopOpacity="0"/>
				<stop offset="1" stopColor="#0FB7FF" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="homeShapeGroupLeft_paint2_linear" x1="13.899" x2="31.739" y1="51.761" y2="79.558" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff"/>
				<stop offset="1" stopColor="#fff" stopOpacity="0"/>
			</linearGradient>
		</defs>
	</svg>

);

export default HomeShapeGroupLeft;

