import React, {Component} from 'react';
import { Constants } from '../../helpers/Constants';
import LeagueStandings from '../LeagueStandings/LeagueStandings';
import LiveScores from '../../components/LiveScores';
import VisualSeparator from '../../components/Common/VisualSeparator';
import UserGWTally from '../../components/UserGWTally/UserGWTally';
import H2HFixtures from '../H2HFixtures/H2HFixtures';
import PerfectTeam from '../../components/PerfectTeam';

class PostGameDay extends Component {

	findUserTeam() {
		return this.props.standings.find(t => t.managerId === this.props.shakitzUser.userId);
	}

	getGameWeekToShow(currentGameWeek) {
		if(this.props.hasModifiedToggle) {
			return this.props.h2hGameWeekToggle;
		}

		if(this.props.gwState === Constants.gameStates.PRE) {
			return currentGameWeek - 1;
		} else {
			return currentGameWeek;
		}
	}

	render () {
		const gameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
		const fixtureGameWeekToShow = this.getGameWeekToShow(gameWeek);
		const seasonType = this.props.selectedLeague.type;
		const userTeam = this.findUserTeam() || {};
		const gwPoints = userTeam.gwPoints || 0;
		let averageTally = this.props.standings.reduce((prev, curr) => prev + curr.gwPoints, 0) / this.props.standings.length;
		if(isNaN(averageTally)) {
			averageTally = 0;
		} else {
			averageTally = Math.round(averageTally * 100) / 100;
		}

		return (
			<>
				<UserGWTally averageTally={averageTally} gameWeek={fixtureGameWeekToShow} gwOver={true} userTally={gwPoints} />
				<div className='game-day__section'>
					<LeagueStandings historicStandings={this.props.historicStandings} gwState={this.props.gwState} onUserClick={this.props.onUserClick} seasonUserTeams={this.props.seasonUserTeams} standings={this.props.standings} />
					<VisualSeparator />
					<H2HFixtures fixtures={this.props.allFixtures} currentGameWeek={gameWeek} gameWeekToShow={fixtureGameWeekToShow} maxGameWeeks={Constants.numOfGameWeeks[seasonType]} hideToggle showScores={true} />
					<VisualSeparator />
				</div>
				<div className='game-day__section'>
					<LiveScores headerText={'Results'} isPostGameDay={true} />
					<VisualSeparator />
					<PerfectTeam gameWeekOverride={fixtureGameWeekToShow} />
					<VisualSeparator />
				</div>
			</>
		);
	}
}

export default PostGameDay;
