import axios from 'axios';
import { Constants } from '../helpers/Constants';
import { setAutoDraftSuccess } from './setAutoDraft';
import { setDraftClock } from './setDraftClock';

export const FETCH_DRAFT_DATA_REQUEST = 'FETCH_DRAFT_DATA_REQUEST';
export const FETCH_DRAFT_DATA_SUCCESS = 'FETCH_DRAFT_DATA_SUCCESS';
export const FETCH_DRAFT_DATA_FAILURE = 'FETCH_DRAFT_DATA_FAILURE';

export function fetchDraftDataRequest ( isFetching ) {
	return {
		type: FETCH_DRAFT_DATA_REQUEST,
		isFetching
	};
}

export function fetchDraftDataFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_DRAFT_DATA_FAILURE,
		response: err,
		error: true
	};
}

export function fetchDraftDataSuccess( data ) {
	return {
		type: FETCH_DRAFT_DATA_SUCCESS,
		data
	};
}

export function fetchDraftData ( leagueId, season = Constants.currentSeason ) {
	return ( dispatch ) => {
		dispatch(fetchDraftDataRequest(true));

		const url = `${Constants.urls.DRAFT}/${leagueId}/${season}`;
		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchDraftDataRequest(false));

			return response.data;
		}).then(data => {
			dispatch(fetchDraftDataSuccess(data));
			if(data.draft && data.draft.clock) {
				dispatch(setDraftClock((data.draft.clock)));
			}
			if(data.draft && data.draft.autoDraftList) {
				dispatch(setAutoDraftSuccess(data.draft.autoDraftList));
			}
		}).catch(err => dispatch(fetchDraftDataFailure(err)));
	};
}
