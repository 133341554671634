import axios from 'axios';
import { Constants } from '../helpers/Constants';
import {fetchHistoricPlayerStatsRequest} from './historicPlayerStats';

export const FETCH_HISTORIC_LEAGUE_STANDINGS_REQUEST = 'FETCH_HISTORIC_LEAGUE_STANDINGS_REQUEST';
export const FETCH_HISTORIC_LEAGUE_STANDINGS_SUCCESS = 'FETCH_HISTORIC_LEAGUE_STANDINGS_SUCCESS';
export const FETCH_HISTORIC_LEAGUE_STANDINGS_FAILURE = 'FETCH_HISTORIC_LEAGUE_STANDINGS_FAILURE';

export function fetchHistoricLeagueStandingsRequest ( isFetching ) {
	return {
		type: FETCH_HISTORIC_LEAGUE_STANDINGS_REQUEST,
		isFetching
	};
}

export function fetchHistoricLeagueStandingsFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_HISTORIC_LEAGUE_STANDINGS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchHistoricLeagueStandingsSuccess( data ) {
	return {
		type: FETCH_HISTORIC_LEAGUE_STANDINGS_SUCCESS,
		data
	};
}

export function fetchHistoricLeagueStandings ( leagueId, season, gameWeek ) {
	return ( dispatch ) => {
		dispatch(fetchHistoricLeagueStandingsRequest(true));

		const url = `${Constants.urls.LEAGUE}/standings/historic/${leagueId}/${season}/${gameWeek}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchHistoricLeagueStandingsRequest(false));

			const leagueObj = {};
			leagueObj.leagueId = leagueId;
			leagueObj.season = season;
			leagueObj.gameWeek = gameWeek;
			leagueObj.data = response.data;
			return leagueObj;
		}).then(data => dispatch(fetchHistoricLeagueStandingsSuccess(data))).catch(err => {
			// If the season is in game week 1 then we won't have historic league standings
			if(err.response && err.response.status === 404) {
				dispatch(fetchHistoricLeagueStandingsRequest(false));
			} else {
				dispatch(fetchHistoricLeagueStandingsFailure(err))
			}
		});
	};
}
