import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const PUT_REJECT_TRADE_REQUEST = 'PUT_REJECT_TRADE_REQUEST';
export const PUT_REJECT_TRADE_SUCCESS = 'PUT_REJECT_TRADE_SUCCESS';
export const PUT_REJECT_TRADE_FAILURE = 'PUT_REJECT_TRADE_FAILURE';

export function rejectTradeRequest ( isPosting ) {
	return {
		type: PUT_REJECT_TRADE_REQUEST,
		isPosting
	};
}

export function rejectTradeFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: PUT_REJECT_TRADE_FAILURE,
		response: err,
		error: true
	};
}

export function rejectTradeSuccess( data ) {
	return {
		type: PUT_REJECT_TRADE_SUCCESS,
		data
	};
}

export function rejectTrade ( leagueId, tradeId ) {
	return ( dispatch ) => {
		dispatch(rejectTradeRequest(true));

		const url = `${Constants.urls.TRADES}/reject`;
		axios.put(url, { leagueId, tradeId })
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			setTimeout(() => {
				dispatch(rejectTradeRequest(false));
			}, 1100);

			return response.data;
		}).then(data => dispatch(rejectTradeSuccess(data)))
		.catch(err => dispatch(rejectTradeFailure(err)));
	};
}
