import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_LEAGUE_BENCH_DATA_REQUEST = 'FETCH_LEAGUE_BENCH_DATA_REQUEST';
export const FETCH_LEAGUE_BENCH_DATA_SUCCESS = 'FETCH_LEAGUE_BENCH_DATA_SUCCESS';
export const FETCH_LEAGUE_BENCH_DATA_FAILURE = 'FETCH_LEAGUE_BENCH_DATA_FAILURE';

export function fetchLeagueBenchDataRequest ( isFetching ) {
	return {
		type: FETCH_LEAGUE_BENCH_DATA_REQUEST,
		isFetching
	};
}

export function fetchLeagueBenchDataFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_LEAGUE_BENCH_DATA_FAILURE,
		response: err,
		error: true
	};
}

export function fetchLeagueBenchDataSuccess( data ) {
	return {
		type: FETCH_LEAGUE_BENCH_DATA_SUCCESS,
		data
	};
}

export function fetchLeagueBenchData ( leagueId, season, gameWeek ) {
	return ( dispatch ) => {
		dispatch(fetchLeagueBenchDataRequest(true));

		const url = `${Constants.urls.LEAGUE}/standings/bench/${leagueId}/${season}/${gameWeek}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchLeagueBenchDataRequest(false));

			const benchObj = {};
			benchObj.leagueId = leagueId;
			benchObj.season = season;
			benchObj.data = response.data;
			return benchObj;
		}).then(data => dispatch(fetchLeagueBenchDataSuccess(data)))
		.catch(err => dispatch(fetchLeagueBenchDataFailure(err)))
		.finally(() => dispatch(fetchLeagueBenchDataRequest(false)));
	};
}
