import React from 'react';
import DraftPriorityCard from '../DraftPriorityCard';
import Button from '../../Common/Button';
import Header from '../../../components/Common/Header';
import CloseIcon from '../../svgs/CloseIcon';

import './DraftPriorityModal.scss';

const DraftPriorityModal = ({users, onClose, onFormSubmit}) => {

	const onStart = (evt) => {
		evt.preventDefault();
		const inputs = [].slice.call(evt.target.querySelectorAll('input'));
		const userPriority = inputs.map(input => {
			return {
				order: input.value,
				userId: input.getAttribute('data-user-id'),
				userName: input.getAttribute('data-user-nickname')
			}
		});

		return onFormSubmit(userPriority);
	};

	return (
		<div className='draft-priority-modal'>
			<div className='draft-priority-modal__close-container' onClick={onClose}>
				<CloseIcon width={'18px'} height={'18px'}/>
			</div>
			<div className='draft-priority-modal__header'>
				<Header headerLevel={'h2'} text={'Confirm draft priority'} />
			</div>
			<p className='draft-priority-modal__explainer'>
				You can not amend the priority or re-start once the draft has started.
			</p>
			<form className='draft-priority-modal__user-list' onSubmit={onStart}>
				{users.map((user, index) => (
					<DraftPriorityCard key={user.userId} index={index} userId={user.userId} userName={user.userName} lengthOfUsers={users.length} />
				))}
				<Button type={'tertiary'} text={'Start Draft'} isSubmit={true} />
			</form>
		</div>
	)
};

export default DraftPriorityModal;
