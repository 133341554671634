import React from 'react';
import './ToggleSwitch.scss';

const ToggleSwitch = ({ name, defaultChecked, labelText, onChange }) => {
	const handleChange = (evt) => {
		onChange(evt.target.checked);
	};

	return (
		<label className='toggle-switch' htmlFor={name}>{labelText}
			<input className='toggle-switch__input' type="checkbox" defaultChecked={defaultChecked} name={name} onChange={handleChange} />
		</label>
	)
};

export default ToggleSwitch;
