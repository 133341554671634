import BoardIcon from '../components/svgs/BoardIcon';
import Helmet from '../components/svgs/Helmet';
import Football from '../components/svgs/Football';
import Jersey from '../components/svgs/Jersey';
import TradeIcon from '../components/svgs/TradeIcon';
import WaiverIcon from '../components/svgs/WaiverIcon';
import BadgeIcon from '../components/svgs/BadgeIcon';
import StatsIcon from '../components/svgs/StatsIcon';
import LeagueIcon from "../components/svgs/LeagueIcon";

const DOMAIN = 'https://api.shakitz.com/';
const BASE_URL = `${DOMAIN}v1`;
export const Constants = {
	auth0: {
		development: {
			clientId: 'tPIUxH5Rr_MuJEfje7Xxy9dS1P0rlqLM',
			domain: 'shakitz.auth0.com',
			uri: 'http://localhost:3000/login/'
		},
		production: {
			clientId: 'tPIUxH5Rr_MuJEfje7Xxy9dS1P0rlqLM',
			domain: 'shakitz.auth0.com',
			uri: 'https://shakitz.com/login/'
		}
	},
	currentSeason: 2021,
	links: [{
		icon: Helmet,
		name: 'Home',
		url: '/',
		authOnly: false
	}, {
		icon: Jersey,
		name: 'My Team',
		url: '/team',
		authOnly: true
	}, {
		icon: Football,
		name: 'Game Day',
		url: '/game-day',
		authOnly: true
	}, {
		icon: WaiverIcon,
		name: 'Waiver Wire',
		url: '/waivers',
		authOnly: true
	}, {
		icon: TradeIcon,
		name: 'Trades',
		url: '/trades',
		authOnly: true
	}, {
		icon: BadgeIcon,
		name: 'Records',
		url: '/records',
		authOnly: true
	},	{
		icon: StatsIcon,
		name: 'Stats',
		url: '/stats',
		authOnly: true
	}, {
		icon: LeagueIcon,
		name: 'Leagues',
		url: '/leagues',
		authOnly: true
	},{
		icon: BoardIcon,
		name: 'Draft Room',
		url: '/draft-room',
		authOnly: true
	}],
	modalAnimationTimeout: 800,
	numStarters: 10,
	numBench: 8,
	numOfGameWeeks: {
		PRE: 4,
		REG: 17
	},
	tradeDeadline: 13,
	unrankedCode: 5000,
	urls: {
		BASE: BASE_URL,
		GRAPHQL: `${DOMAIN}v2`,
		DRAFT: `${BASE_URL}/drafts`,
		LEAGUE: `${BASE_URL}/leagues`,
		GAME_WEEK: `${BASE_URL}/gameWeek`,
		GAME_WEEK_TEAM: `${BASE_URL}/gameWeekTeam`,
		H2H: `${BASE_URL}/headToHead`,
		PERFECT_TEAM: `${BASE_URL}/perfectTeam`,
		PLAYER: `${BASE_URL}/player`,
		RECORDS: `${BASE_URL}/records`,
		SEASON_USER_TEAM: `${BASE_URL}/seasonUserTeams`,
		SCHEDULE: `${BASE_URL}/schedule`,
		SCORES: `${BASE_URL}/scores`,
		STATS: `${BASE_URL}/stats`,
		TRADES: `${BASE_URL}/trades`,
		USER: `${BASE_URL}/users`,
		WAIVERS: `${BASE_URL}/waivers`
	},
	statusCodes: {
		DELETE: 204,
		OK: 200
	},
	positionMap: {
		QB: 'Quarterback',
		RB: 'Running back',
		WR: 'Wide receiver',
		TE: 'Tight end',
		K: 'Kicker',
		D: 'Defence'
	},
	injuryStates: {
		OUT: ['Out'],
		DOUBTFUL: ['Doubtful', 'Did Not Participate In Practice'],
		QUESTIONABLE: ['Questionable', 'Limited Participation in Practice']
	},
	teamMap: {
		ATL: 'Atlanta Falcons',
		ARI: 'Arizona Cardinals',
		BAL: 'Baltimore Ravens',
		BUF: 'Buffalo Bills',
		CAR: 'Carolina Panthers',
		CHI: 'Chicago Bears',
		CIN: 'Cincinnati Bengals',
		CLE: 'Cleveland Browns',
		DAL: 'Dallas Cowboys',
		DEN: 'Denver Broncos',
		DET: 'Detroit Lions',
		GB: 'Green Bay Packers',
		HOU: 'Houston Texans',
		IND: 'Indianapolis Colts',
		JAG: 'Jacksonville Jaguars',
		JAX: 'Jacksonville Jaguars',
		KC: 'Kansas City Chiefs',
		LA: 'Los Angeles Rams',
		LAC: 'Los Angels Chargers',
		LV: 'Las Vegas Raiders',
		OAK: 'Las Vegas Raiders',
		MIA: 'Miami Dolphins',
		MIN: 'Minnesota Vikings',
		NE: 'New England Patriots',
		NO: 'New Orleans Saints',
		NYG:'New York Giants',
		NYJ: 'New York Jets',
		PHI: 'Philadelphia Eagles',
		PIT: 'Pittsburgh Steelers',
		SF: 'San Francisco 49ers',
		SEA: 'Seattle Seahawks',
		TB: 'Tampa Bay Buccaneers',
		TEN: 'Tennessee Titans',
		WAS: 'Washington Football Team'
	},
	teamNicknameMap: {
		ATL: 'Falcons',
		ARI: 'Cardinals',
		BAL: 'Ravens',
		BUF: 'Bills',
		CAR: 'Panthers',
		CHI: 'Bears',
		CIN: 'Bengals',
		CLE: 'Browns',
		DAL: 'Cowboys',
		DEN: 'Broncos',
		DET: 'Lions',
		GB: 'Packers',
		HOU: 'Texans',
		IND: 'Colts',
		JAG: 'Jaguars',
		JAX: 'Jaguars',
		KC: 'Chiefs',
		LA: 'Rams',
		LAC: 'Chargers',
		LV: 'Raiders',
		OAK: 'Raiders',
		MIA: 'Dolphins',
		MIN: 'Vikings',
		NE: 'Patriots',
		NO: 'Saints',
		NYG: 'Giants',
		NYJ: 'Jets',
		PHI: 'Eagles',
		PIT: 'Steelers',
		SF: '49ers',
		SEA: 'Seahawks',
		TB: 'Buccaneers',
		TEN: 'Titans',
		WAS: 'Football Team'
	},
	tradeStates: {
		ACCEPTED: 'A',
		PENDING: 'P',
		REJECTED: 'R'
	},
	gameStates: {
		PRE: 'pre',
		LIVE: 'live',
		POST: 'post'
	},
	statToggleOptions: {
		GAME_WEEK: 'GameWeek',
		SEASON: 'Season'
	}
};
