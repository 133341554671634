import React from 'react';
import { connect } from 'react-redux';
import Header from '../Common/Header';
import NFLScore from '../NFLScore';
import './Schedule.scss';

const Schedule = ({currentGameWeek, schedule, selectedLeague}) => {
	if(!currentGameWeek || !selectedLeague.type) {
		return;
	}
	const gameWeek = currentGameWeek[selectedLeague.type];
	const scheduleData = schedule[selectedLeague.type][gameWeek];
	if(!scheduleData || !scheduleData.games || scheduleData.games.length === 0) {
		return null;
	}

	return (
		<div className='schedule'>
			<div className='schedule__header'>
				<Header headerLevel={'h1'} text={'Schedule'} />
			</div>
			<div className='schedule__container'>
				{scheduleData.games.map(game => <NFLScore key={game.id} gameData={game} type={'schedule'} />)}
			</div>
		</div>
	)
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		schedule: state.schedule,
		selectedLeague: state.selectedLeague
	};
};

export default connect(mapStateToProps)(Schedule);
