import React from 'react';
import classNames from 'classnames';
import DownArrow from '../../svgs/DownArrow';
import './Toggle.scss';

const _renderPageNumber = (option) => {
	if(!option.isIcon) {
		return option.page;
	} else if(option.isPrev) {
		return <DownArrow fill={'#a4a4a4'} className='arrow arrow--prev' />;
	} else {
		return <DownArrow fill={'#a4a4a4'} className={'arrow arrow--next'} />;
	}
};

const PaginationToggle = ({onPageClick, activeOption, toggleOptions, type = null}) => (
	<div className={`toggle toggle--pagination ${type ? `toggle--${type}` : ''}`} onClick={onPageClick}>
		{toggleOptions.map(option => {
			const optionCls = classNames('toggle__option', {
				'toggle__option--active': option.page === activeOption,
				'toggle__option--icon': option.isIcon,
				'toggle__option--disabled': (option.isPrev && activeOption === 1) || (option.isIcon && !option.isPrev && activeOption === toggleOptions[toggleOptions.length - 2].page)
			});
			const dataKey = option.isIcon ? (option.isPrev ? 'prev' : 'next') : '';
			return (
				<div key={`toggle-${option.key || option.page}`}
				     className={optionCls}
				     data-option={option.page}
					 data-key={dataKey}>
					<span>{_renderPageNumber(option)}</span>
				</div>
			);
		})}
	</div>
);

export default PaginationToggle;
