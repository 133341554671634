import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_GAME_WEEK_REQUEST = 'FETCH_GAME_WEEK_REQUEST';
export const FETCH_GAME_WEEK_SUCCESS = 'FETCH_GAME_WEEK_SUCCESS';
export const FETCH_GAME_WEEK_FAILURE = 'FETCH_GAME_WEEK_FAILURE';

export function fetchGameWeekRequest ( isFetching ) {
	return {
		type: FETCH_GAME_WEEK_REQUEST,
		isFetching
	};
}

export function fetchGameWeekFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_GAME_WEEK_FAILURE,
		response: err,
		error: true
	};
}

export function fetchGameWeekSuccess( data ) {
	return {
		type: FETCH_GAME_WEEK_SUCCESS,
		data
	};
}

export function fetchGameWeek (type = 'PRE') {
	return ( dispatch ) => {
		dispatch(fetchGameWeekRequest(true));

		const url = `${Constants.urls.GAME_WEEK}/${type}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			return response.data;
		}).then(data => dispatch(fetchGameWeekSuccess({ gameWeekType: type, gameWeek: data.gameWeek })))
		.catch(err => dispatch(fetchGameWeekFailure(err)))
		.finally(() => dispatch(fetchGameWeekRequest(false)));
	};
}
