import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import shakitzSchedule from 'shakitz-schedule';
import PlayerTeamPosInfo from '../PlayerTeamPosInfo';
import PlayerModal from '../PlayerModal';
import Modal from '../../../../containers/Modal/Modal';
import { Constants } from '../../../../helpers/Constants';
import StarIcon from '../../../svgs/StarIcon';
import getPlayerLongName from '../../../../helpers/getPlayerLongName';
import getGameTimeInfo from '../../../../helpers/getGameTimeInfo';
import getGameState from '../../../../helpers/getGameState';
import whoDoesPlayerBelongTo from '../../../../helpers/whoDoesPlayerBelongTo';
import InjuryStatus from '../InjuryStatus';
import './PlayerBox.scss';

const _renderNextFixture = (hideNextFixture, game, team, showPoints) => {
	if(hideNextFixture || showPoints || !game) {
		return null;
	}

	const opposition = game.home === team ? game.away : `@${game.home}`;
	const { day, time } = getGameTimeInfo(game.gameTime);

	return (
		<div className='player-box__opposition-wrapper'>
			<div className='player-box__opposition'>{opposition}</div>
			<div className='player-box__time'>{day}, {time}</div>
		</div>
	)
};

const _renderPoints = (inSubView, calculatedStats, showPoints) => {
	if(inSubView || !showPoints || !calculatedStats) {
		return null;
	}

	const points = Math.round(calculatedStats.points * 100) / 100;
	return 	<div className='player-box__point-wrapper'><span className='player-box__points'>{points}</span><span className='player-box__points-description'>points</span></div>
};

const PlayerBox = ({canEdit, cannotSubIn, currentGameWeek, draftPick, gameWeek = null, player, inSubView, inTradeView, inNextH2HView, isPerfect, onPlayerClick, onTradeClick, schedule, selectedLeague, unavailablePlayers}) => {
	const [ showModal, setShowModal ] = useState(false);
	console.log(player);
	if(!player.playerId) {
		console.log('no player for: ', player);
		return null;
	}
	const playerLongName = getPlayerLongName(player);
	const modalName = `Player Box - ${playerLongName}`;
	const clsName = classNames('player-box', {
		'player-box--sub-view': inSubView,
		'player-box--ineligible': cannotSubIn,
		'player-box--next-h2h-view': inNextH2HView
	});

	const gameWeekToShow = gameWeek || currentGameWeek[selectedLeague.type];
	const scheduleData = schedule[selectedLeague.type] ? schedule[selectedLeague.type][gameWeekToShow] : { games: [] };
	const games = scheduleData ? scheduleData.games : [];
	const game = shakitzSchedule.getPlayerGame(games, player.nflTeam, gameWeekToShow);
	const subPlayer = (player) => {
		if(!canEdit) {
			return;
		}

		if(shakitzSchedule.canSub(game, games)) {
			return onPlayerClick(player);
		} else {
			//TODO change to be the alert modal
			alert('Deadline has passed');
		}
	};
	const tradePlayer = (player) => onTradeClick(player);

	const canSub = shakitzSchedule.canSub(game, games);
	let onClick = () => setShowModal(true);
	if(inSubView || inTradeView) {
		onClick = inSubView ? subPlayer : tradePlayer;
	}
	const gameState = getGameState(game) ;
	const showPoints = isPerfect || gameState === Constants.gameStates.LIVE || gameState === Constants.gameStates.POST;

	const playerData = unavailablePlayers[selectedLeague.leagueId] ? unavailablePlayers[selectedLeague.leagueId][selectedLeague.season] : [];
	const belongsToData = whoDoesPlayerBelongTo(playerData, player.gameWeekPlayerId);
	const belongsTo = belongsToData && belongsToData[0] ? belongsToData[0] : {};

	return (
		<>
			<div className={clsName} onClick={onClick}>
				{draftPick && <div className='player-box__draft-pick'>{draftPick}</div>}
				<div className='player-box__info-wrapper'>
					<div className='player-box__name'>
						{playerLongName}
						{isPerfect && (
							<span className='player-box__perfect-icon'>
								<StarIcon />
							</span>
						)}
						<InjuryStatus small status={player.injuryStatus} />
					</div>
					<PlayerTeamPosInfo position={player.pos} team={player.nflTeam} />
				</div>
				{_renderPoints(inSubView || inTradeView, player.calculatedStats, showPoints)}
				{_renderNextFixture(inNextH2HView || inTradeView, game, player.nflTeam, showPoints)}
			</div>
			{ showModal ? <Modal name={modalName} onClose={() => setShowModal(!showModal)}><PlayerModal belongsTo={belongsTo} canEdit={canEdit} canSub={canSub} onClose={() => setShowModal(!showModal)} player={player} /></Modal> : null}
		</>
	)
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		schedule: state.schedule,
		selectedLeague: state.selectedLeague,
		unavailablePlayers: state.unavailablePlayers
	};
};

export default connect(mapStateToProps)(PlayerBox);
