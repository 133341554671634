import React from 'react';
import * as Sentry from '@sentry/browser';
import { hydrate, render } from "react-dom";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './store'
import App from './containers/App/App';
import SpinnerLayer from './components/SpinnerLayer/SpinnerLayer';
import './core.scss'
import playersList from './playersList';
import * as serviceWorker from './serviceWorker';

const { persistor, store } = configureStore({players: playersList});
// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
	__PRELOADED_STATE__: store.getState()
});
Sentry.init({dsn: "https://2d01fb884dd9422e8396e79e9ab0ef9e@sentry.io/1521977"});
const rootElement = document.getElementById("root");
const renderApp = (reactSnapMethod) => (
	reactSnapMethod(
		<Provider store={store}>
			<PersistGate loading={<SpinnerLayer />} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
		rootElement
	)
);

if (rootElement.hasChildNodes()) {
	renderApp(hydrate);
} else {
	renderApp(render)
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
	module.hot.accept('./containers/App/App', renderApp)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
