import React from 'react';
import classNames from 'classnames';
import './H2HStreak.scss';

const MAX_GAMES_TO_SHOW = 5;

const H2HStreak = ({teamType, streak = []}) => {
	if(streak.length === 0) {
		return null;
	}

	return (
		<div className='h2h-streak'>
			{streak.slice(0, MAX_GAMES_TO_SHOW).map((type, idx) => {
				const clsName = classNames('streak', {
					'streak--win': type === 'win',
					'streak--loss': type === 'loss',
					'streak--draw': type === 'draw'
				});
				return <span key={`${teamType}_streak_${idx}`} className={clsName}>{type.charAt(0)}</span>
			})}
		</div>
	)

};

export default H2HStreak;
