import React, { useState } from 'react';
import classNames from 'classnames';
import H2HDetailsIcon from '../svgs/H2HDetailsIcon';
import Modal from '../../containers/Modal/Modal';
import H2HModal from '../../containers/H2HModal';
import './Fixture.scss';

const renderActiveOrCompleteFixture = (data) => {
	const resultCls = classNames('fixture__outcome', {
		'fixture__outcome--home-winner': data.homeTeamScore > data.awayTeamScore,
		'fixture__outcome--away-winner': data.awayTeamScore > data.homeTeamScore,
		'fixture__outcome--draw': data.homeTeamScore === data.awayTeamScore
	});
	return (
		<>
			<div className='fixture__context'>
				<div className='fixture__team fixture__team--home'>
					<span className='fixture__team-name'>{data.homeTeam}</span>
					<span className='fixture__team-name-short'>{data.homeTeamShort}</span>
					<span className='fixture__team-manager'>{data.homeManager}</span>
				</div>
				<div className='fixture__outcome-wrapper'>
					<div className={resultCls}>
						<span className='fixture__score fixture__score--home'>{data.homeTeamScore}</span>
						<hr className='fixture__score-divider'></hr>
						<span className='fixture__score fixture__score--away'>{data.awayTeamScore}</span>
					</div>
				</div>
				<div className='fixture__team fixture__team--away'>
					<span className='fixture__team-name'>{data.awayTeam}</span>
					<span className='fixture__team-name-short'>{data.awayTeamShort}</span>
					<span className='fixture__team-manager'>{data.awayManager}</span>
				</div>
			</div>
			<div className='fixture__details-wrapper'>
				<button className='fixture__details'>
					<H2HDetailsIcon /> Match Details
				</button>
			</div>
		</>
	);
};

const renderUpcomingFixture = (data) => {
	return (
		<>
			<div className='fixture__context'>
				<div className='fixture__team fixture__team--home'>
					<span className='fixture__team-name'>{data.homeTeam}</span>
					<span className='fixture__team-name-short'>{data.homeTeamShort}</span>

					<span className='fixture__team-manager'>{data.homeManager}</span>
				</div>
				<div className='fixture__outcome-wrapper'>
					<div className='fixture__outcome'>VS</div>
				</div>
				<div className='fixture__team fixture__team--away'>
					<span className='fixture__team-name'>{data.awayTeam}</span>
					<span className='fixture__team-name-short'>{data.awayTeamShort}</span>
					<span className='fixture__team-manager'>{data.awayManager}</span>
				</div>
			</div>
		</>
	);
};

const isUpcoming = (data) => {
	return typeof data.homeTeamScore === 'undefined' || typeof data.awayTeamScore === 'undefined';
};

const Fixture = ({data, liveMode}) => {
	const [showModal, setShowModal] = useState(false);
	const cls = classNames('fixture', {
		'fixture--active': liveMode,
		'fixture--upcoming': !liveMode
	});

	return (
		<>
			<div className={cls} onClick={() => setShowModal(true)}>
				{!liveMode || isUpcoming(data) ? renderUpcomingFixture(data) : renderActiveOrCompleteFixture(data)}
			</div>
			{showModal && <Modal onClose={() => setShowModal(false)}><H2HModal data={data} onClose={() => setShowModal(false)} /></Modal>}
		</>
	);
};

export default Fixture;
