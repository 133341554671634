import React from 'react';
import Button from '../Common/Button';
import './Form.scss';

const JoinLeagueForm = ({leagueId, onSubmit}) => {

	const formSubmit = (evt) => {
		evt.preventDefault();
		onSubmit({
			"leagueId": evt.target.leagueName.value,
			"shortTeamName": evt.target.shortTeamName.value,
			"teamName": evt.target.teamName.value
		});
	};

	return (
		<form className='form form--join-league' onSubmit={formSubmit}>
			<label className='form__label'>League Name</label>
			<input id='league-name' className='form__input form__input--text' name='leagueName' type='text' placeholder='League name' minLength='4' autoComplete='off' defaultValue={leagueId} required />
			<label className='form__label'>Short Team Name</label>
			<input id='team-name-short' className='form__input form__input--text' name='shortTeamName' type='text' placeholder='Abbreviated name (2-12 characters)' minLength='2' maxLength='12' autoComplete='off' required />
			<label className='form__label'>Long Team Name</label>
			<input id='team-name' className='form__input form__input--text' name='teamName' type='text' placeholder='Long team name (4-40 characters)' minLength='4' maxLength='40' autoComplete='off' required />
			<Button text={'Join'} type={'submit'} isSubmit={true} />
		</form>
	);
};

export default JoinLeagueForm;
