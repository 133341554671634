import React from 'react';

const JoinShapeGroupLeft = ({ width = '103', height = '139'}) => (
	<svg width={width} height={height} viewBox="0 0 58 172" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.5">
			<path d="M-28.994 120.297L21.8165 89.2056L37.326 114.633C45.8917 128.676 41.4612 147.021 27.4302 155.606L2.02495 171.152L-28.994 120.297Z" fill="url(#joinShapeGroupLeft_paint0_linear)"/>
			<path d="M-1.99451 0.370566L27.692 0.152909C44.0874 0.0326999 57.4752 13.3227 57.5945 29.837L57.8106 59.7388L28.1241 59.9564C11.7288 60.0766 -1.65907 46.7866 -1.77842 30.2723L-1.99451 0.370566Z" fill="url(#joinShapeGroupLeft_paint1_linear)"/>
			<path d="M-17.8228 87.422C-25.1004 80.144 -25.1002 68.3442 -17.8224 61.0664C-10.5446 53.7886 1.25486 53.7888 8.53242 61.0668C15.81 68.3448 15.8098 80.1446 8.53202 87.4224C1.25423 94.7002 -10.5452 94.7 -17.8228 87.422Z" fill="url(#joinShapeGroupLeft_paint2_linear)"/>
		</g>
		<defs>
			<linearGradient id="joinShapeGroupLeft_paint0_linear" x1="-3.58876" y1="104.751" x2="27.5028" y2="155.562" gradientUnits="userSpaceOnUse">
				<stop stopColor="#A838FF" stopOpacity="0"/>
				<stop offset="1" stopColor="#A838FF"/>
			</linearGradient>
			<linearGradient id="joinShapeGroupLeft_paint1_linear" x1="-1.99451" y1="0.1521" x2="37.8642" y2="45.6624" gradientUnits="userSpaceOnUse">
				<stop stopColor="#14C4FB"/>
				<stop offset="1" stopColor="#14C4FB" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="joinShapeGroupLeft_paint2_linear" x1="8.53202" y1="87.4224" x2="-17.8232" y2="61.0672" gradientUnits="userSpaceOnUse">
				<stop stopColor="white"/>
				<stop offset="1" stopColor="white" stopOpacity="0"/>
			</linearGradient>
		</defs>
	</svg>
);

export default JoinShapeGroupLeft;

