import React, {useContext, useState} from 'react';
import {connect} from 'react-redux';
import ShakitzContext from '../../context/ShakitzContext';
import Modal from '../../containers/Modal/Modal';
import {TeamSVGMap} from '../../helpers/TeamSVGMap';
import Hero from '../Common/Hero';
import Button from '../Common/Button';
import {setFavouriteTeam} from '../../actions/setFavouriteTeam';
import SpinnerLayer from '../SpinnerLayer/SpinnerLayer';
import './FavouriteTeam.scss';

const _renderTeam = (name, TeamLogo, isSelected) => (
	<div key={name} className='team' data-is-selected={isSelected}>
		<div className='team__logo'>
			{TeamLogo && <TeamLogo />}
		</div>
		<div className='team__name'>
			{name}
		</div>
	</div>
);

const FavouriteTeam = ({setFavouriteTeam, hasErrored, isPosting, team}) => {
	const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
	const shakitzContext = useContext(ShakitzContext) || { favouriteTeam: null, userId: null };
	const { favouriteTeam, userId } = shakitzContext;
	const [selectedTeam, setSelectedTeam] = useState(null);

	if(!isLoggedIn || !userId || favouriteTeam || team || hasErrored) {
		return null;
	}

	const MAX_TEAM_NAME_SIZE = 4;
	const setTeam = (evt) => {
		const team = evt.target.innerText;
		if(team && team.length <= MAX_TEAM_NAME_SIZE) {
			setSelectedTeam(team);
		}
	};

	const onSubmit = () => {
		setFavouriteTeam(userId, selectedTeam);
	};

	return (
		<Modal className='modal--favourite-team'>
			<div className='favourite-team'>
				<Hero bubbles joinJourney text="Select your favourite team" secondaryText='Pick none to ignore' />
				<div className='favourite-team__grid' onClick={setTeam}>
					{Object.keys(TeamSVGMap.teamMap).map(key => {
						if(key === 'JAG') {
							return null;
						}
						const TeamLogo = TeamSVGMap.teamMap[key];
						return _renderTeam(key, TeamLogo, key === selectedTeam);
					})}
					{_renderTeam('None', null, 'None' === selectedTeam)}
				</div>
				<div className='favourite-team__cta'>
					<Button disabled={!selectedTeam} text='Continue' onClick={onSubmit} type='submit' />
				</div>
			</div>
			{isPosting && <SpinnerLayer />}
		</Modal>
	);
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		setFavouriteTeam: (userId, favouriteTeam) => dispatch(setFavouriteTeam(userId, favouriteTeam))
	};
};

const mapStateToProps = ( state ) => {
	return {
		team: state.setFavouriteTeam,
		hasErrored: state.setFavouriteTeamFailure,
		isPosting: state.setFavouriteTeamRequest
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteTeam);

