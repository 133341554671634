import React from 'react';

const BoardIcon = ({fill = '#fff', opacity = '.6'}) => (
	<svg height='20px' width='20px' fill={fill} opacity={opacity} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120" >
		<g><path d="M25.8,36.2H10.2c-1.8,0-3.3,1.6-3.3,3.3v66.5c0,1.8,1.6,3.3,3.3,3.3h15.6c1.8,0,3.3-1.6,3.3-3.3V39.5   C29.2,37.7,27.6,36.2,25.8,36.2z" /><path d="M79,36.2H41.5c-1.8,0-3.3,1.6-3.3,3.3v66.5c0,1.8,1.6,3.3,3.3,3.3H79c1.8,0,3.3-1.6,3.3-3.3V39.5   C82.3,37.7,80.8,36.2,79,36.2z" /><path d="M110.2,9.4H9.8C8,9.4,6.4,11,6.4,12.7v11.2c0,1.8,1.6,3.3,3.3,3.3h100.4c1.8,0,3.3-1.6,3.3-3.3V12.7   C113.3,11,112,9.4,110.2,9.4z" /><path d="M110.2,36.2H94.6c-1.8,0-3.3,1.6-3.3,3.3v66.5c0,1.8,1.6,3.3,3.3,3.3h15.6c1.8,0,3.3-1.6,3.3-3.3V39.5   C113.6,37.7,112,36.2,110.2,36.2z" />
		</g>
	</svg>
);

export default BoardIcon;
