import React from 'react';

const LeagueOverviewIcon = ({fill = '#A838FF', width = '32', height = '32'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 50 46">
		<path fill={fill} d="M47.77 46h-46C.791 46 0 45.208 0 44.23V1.77C0 .791.792 0 1.77 0h46c.468 0 .92.186 1.25.517.333.332.518.783.518 1.252v42.462c0 .469-.185.92-.517 1.252-.332.331-.783.517-1.252.517zM3.537 42.462H46V3.538H3.538v38.924z"/>
		<path fill={fill} d="M47.77 14.154h-46c-.978 0-1.77-.792-1.77-1.77 0-.977.792-1.768 1.77-1.768h46c.977 0 1.768.791 1.768 1.769 0 .977-.791 1.769-1.769 1.769zM31.846 8.846c-.593.007-1.148-.287-1.475-.78-.33-.492-.385-1.117-.152-1.661.086-.217.212-.416.371-.584.71-.655 1.802-.655 2.512 0 .157.172.281.37.372.584.232.544.177 1.17-.153 1.66-.327.494-.882.788-1.475.781zM37.154 8.846c-.593.007-1.148-.287-1.475-.78-.33-.491-.385-1.117-.153-1.661.086-.217.212-.416.372-.584.172-.157.369-.281.583-.372.66-.27 1.416-.123 1.929.372.157.172.28.37.372.584.283.659.134 1.422-.372 1.928-.172.157-.37.281-.584.372-.212.09-.44.139-.672.141zM42.462 8.846c-.593.007-1.148-.287-1.476-.78-.33-.492-.384-1.117-.152-1.661.086-.217.212-.416.371-.584.71-.655 1.803-.655 2.513 0 .159.168.285.367.371.584.1.21.148.44.142.672.004.471-.18.924-.513 1.256-.173.157-.37.281-.584.372-.212.09-.44.139-.672.141zM23.482 32.813c-3.056 0-5.814-1.84-6.984-4.667-1.172-2.824-.526-6.075 1.637-8.238 2.16-2.163 5.414-2.81 8.238-1.64 2.826 1.17 4.668 3.925 4.668 6.983-.004 4.174-3.385 7.555-7.559 7.561zm0-11.582c-1.625 0-3.093.98-3.715 2.481-.624 1.504-.279 3.233.871 4.384 1.15 1.15 2.88 1.494 4.384.87 1.501-.62 2.481-2.089 2.481-3.715-.004-2.22-1.8-4.016-4.02-4.02z"/>
		<path fill={fill} d="M33.615 37.154c-.468 0-.92-.186-1.251-.518l-5.79-5.79c-.67-.694-.662-1.798.022-2.479.68-.683 1.785-.692 2.479-.022l5.79 5.79.002-.002c.504.506.657 1.267.383 1.928-.275.662-.918 1.093-1.635 1.093z"/>
	</svg>
);

export default LeagueOverviewIcon;
