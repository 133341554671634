import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_SEASON_USER_TEAM_REQUEST = 'FETCH_SEASON_USER_TEAM_REQUEST';
export const FETCH_SEASON_USER_TEAM_SUCCESS = 'FETCH_SEASON_USER_TEAM_SUCCESS';
export const FETCH_SEASON_USER_TEAM_FAILURE = 'FETCH_SEASON_USER_TEAM_FAILURE';

export function fetchSeasonUserTeamRequest ( isFetching ) {
	return {
		type: FETCH_SEASON_USER_TEAM_REQUEST,
		isFetching
	};
}

export function fetchSeasonUserTeamFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_SEASON_USER_TEAM_FAILURE,
		response: err,
		error: true
	};
}

export function fetchSeasonUserTeamSuccess( data ) {
	return {
		type: FETCH_SEASON_USER_TEAM_SUCCESS,
		data
	};
}

export function fetchSeasonUserTeam ( leagueId, season = Constants.currentSeason ) {
	return ( dispatch ) => {
		dispatch(fetchSeasonUserTeamRequest(true));

		const url = `${Constants.urls.SEASON_USER_TEAM}/${leagueId}/${season}`;

		axios.get(url)
			.then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				dispatch(fetchSeasonUserTeamRequest(false));

				return dispatch(fetchSeasonUserTeamSuccess(response.data));
			})
			.catch(err => dispatch(fetchSeasonUserTeamFailure(err)));
	};
}
