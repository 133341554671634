import React, { useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import { Constants } from '../../helpers/Constants';
import Home from '../Home/Home';
import Login from '../Login/Login';
import DraftRoom from '../DraftRoom';
import GameDay from '../GameDay';
import Error404 from '../../components/Error404/Error404';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar/NavBar';
import Rules from '../Rules/Rules';
import Stats from '../Stats/Stats';
import Trades from '../Trades/Trades';
import Waivers from '../Waivers/Waivers'
import UserTeam from '../UserTeam/UserTeam';
import LeagueViewer from '../LeagueViewer/LeagueViewer';
import LeagueCreator from '../LeagueCreator/LeagueCreator';
import LeagueJoiner from '../../components/LeagueJoiner/LeagueJoiner';
import ShakitzContext from '../../context/ShakitzContext';
import UserContext from '../../context/UserContext';
import withAuth from '../../HOC/withAuth';
import withCoreData from '../../HOC/withCoreData';
import withGameDay from '../../HOC/withGameDay';
import withTracker from '../../HOC/withTracker';
import ErrorScreen from '../../components/ErrorScreen';
import FavouriteTeam from '../../components/FavouriteTeam';
import Records from '../Records';

const App = () => {
	const tokenPayloadStr = localStorage.getItem('tokenPayload');
	const parsedToken = tokenPayloadStr ? JSON.parse(tokenPayloadStr) : {};
	const [ user, setUser] = useState({
		isAuthenticated: parsedToken.expiresAt ? new Date().getTime() < new Date(parsedToken.expiresAt * 1000).getTime() : navigator.userAgent === 'ReactSnap',
		userObj: parsedToken.gmail || {}
	});

	localStorage.setItem('isLoggedIn', user.isAuthenticated);
	const [shakitzUser, setShakitzUser] = useState({
		userId: null,
		userLeagues: []
	});

	// Send JWT token on all requests, and set isAuth to false if valid
	axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('szaud')}`;

	const setShakitzContext = (shakitzUser) => setShakitzUser(shakitzUser);
	const setUserContext = (user) => setUser(user);
	const HomeWithAuthAndTracking = withTracker(withAuth(Home));
	const UserTeamWithTracking = withTracker(withCoreData((withAuth(UserTeam))));
	const LoginWithTracking = withTracker(Login);
	const DraftRoomWithTracking = withTracker(withAuth(DraftRoom));
	const GameDayWithTracking = withTracker(withCoreData(withGameDay(withAuth(GameDay))));
	const TradesWithTracking = withTracker(withAuth(Trades));
	const WaiversWithTracking = withTracker(withCoreData(withAuth(Waivers)));
	const LeagueViewerWithAuthAndTracking = withTracker(withAuth(LeagueViewer));
	const StatsWithAuthAndTracking = withTracker(withCoreData(withAuth(Stats)));
	const RecordsWithAuthAndTracking = withTracker(withCoreData(withAuth(Records)));

	return (
		<Router>
			<UserContext.Provider value={user}>
				<ShakitzContext.Provider value={shakitzUser}>
				<Route render={({ history, location}) => (
					<div className={location.pathname === '/' ? 'base base--home': 'base'}>
						<NavBar links={Constants.links} history={history} location={location}/>

						<ErrorBoundary FallbackComponent={ErrorScreen}>
						<div className='content'>
							<Switch>
								<Route exact path='/' render={(props) => <HomeWithAuthAndTracking history={props.history} setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} {...props} /> } />
								<Route path='/login' render={(props) => <LoginWithTracking {...props} setUserContext={setUserContext} user={user} /> } />
								<Route exact path='/team' render={(props) => <UserTeamWithTracking {...props} setShakitzContext={setShakitzContext} shakitzUserId={shakitzUser.userId} user={user} /> } />
								<Route path='/team/:seasonUserTeam' render={(props) => <UserTeamWithTracking {...props} setShakitzContext={setShakitzContext} shakitzUserId={shakitzUser.userId} user={user} isOtherTeam={true} /> } />
								<Route path='/trades' render={(props) => <TradesWithTracking setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} {...props} userObj={user.userObj} history={props.history} /> } />
								<Route path='/game-day' render={(props) => <GameDayWithTracking setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} {...props} userObj={user.userObj} history={props.history} /> } />
								<Route path='/stats' render={(props) => <StatsWithAuthAndTracking {...props} setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} />} />
								<Route path='/draft-room' render={(props) => <DraftRoomWithTracking history={props.history} setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} {...props} userObj={user.userObj} /> } />
								<Route path='/leagues' render={(props) => <LeagueViewerWithAuthAndTracking {...props} setShakitzContext={setShakitzContext} /> } />
								<Route path='/league/create' component={withTracker(LeagueCreator)} />
								<Route path='/league/join' component={withTracker(LeagueJoiner)} />
								<Route path='/records'  render={(props) => <RecordsWithAuthAndTracking setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} {...props} userObj={user.userObj} history={props.history} /> } />
								<Route path='/waivers' render={(props) => <WaiversWithTracking setShakitzContext={setShakitzContext} shakitzUser={shakitzUser} {...props} userObj={user.userObj} history={props.history} /> } />
								<Route path='/rules' component={withTracker(Rules)} />
								<Route component={withTracker(Error404)} />
							</Switch>
						</div>
						</ErrorBoundary>
					<Footer />
					<FavouriteTeam />
				</div>
				)} />
				</ShakitzContext.Provider>
			</UserContext.Provider>
		</Router>
	)
};

export default App;
