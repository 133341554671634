import { FETCH_H2H_FIXTURES_FAILURE, FETCH_H2H_FIXTURES_REQUEST, FETCH_H2H_FIXTURES_SUCCESS} from '../actions/fetchH2HFixtures';

export function h2hFixturesFailure(state = false, action) {
	switch (action.type) {
		case FETCH_H2H_FIXTURES_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function h2hFixturesRequest(state = false, action) {
	switch (action.type) {
		case FETCH_H2H_FIXTURES_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function h2hFixtures(state = {}, action) {
	switch (action.type) {
		case FETCH_H2H_FIXTURES_SUCCESS:
			if(!state.hasOwnProperty(action.leagueId)) {
				state[action.leagueId] = {};
			}


			state[action.leagueId][action.season] = action.data.headToHead;
			return state;
		default:
			return state;
	}
}
