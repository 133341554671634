import { POST_DRAFT_PICK_FAILURE, POST_DRAFT_PICK_REQUEST, POST_DRAFT_PICK_SUCCESS} from '../actions/postDraftPick';

export function postDraftPickFailure(state = false, action) {
	switch (action.type) {
		case POST_DRAFT_PICK_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function postDraftPickRequest(state = false, action) {
	switch (action.type) {
		case POST_DRAFT_PICK_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}

// NB: We only store the last picked draft here
export function postDraftPick(state = false, action) {
	switch (action.type) {
		case POST_DRAFT_PICK_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
