import React from 'react';
import Fixture from '../../components/H2HFixture/Fixture';
import Header from '../../components/Common/Header';
import './PreviousH2HMeetings.scss';

const PreviousH2HMeetings = ({fixture, maxFixturesToShow = 2}) => (
	<div className='previous-h2h-meetings'>
		<div className='previous-h2h-meetings__header'>
			<Header headerLevel={'h3'} text={'Previous Meetings'} />
		</div>

			{fixture.previousMeetings.map((previousFixture, index) => {
			if ( index >= maxFixturesToShow ) {
				return null;
			}
			return <Fixture key={`PREV_H2HF_${previousFixture.gw}_${previousFixture.homeTeamId}_${previousFixture.awayTeamId}`} data={previousFixture} />
	})}
	</div>
);

export default PreviousH2HMeetings;
