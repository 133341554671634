import React from 'react';
import classNames from 'classnames';
import TradeIcon from '../svgs/TradeIcon';
import CloseIcon from '../svgs/CloseIcon';
import TickIcon from '../svgs/TickIcon';
import Button from '../Common/Button';
import PlayerTeamPosInfo from '../Common/Players/PlayerTeamPosInfo';
import { Constants } from '../../helpers/Constants';
import getPlayerLongName from '../../helpers/getPlayerLongName';

import './Transfer.scss';

const _renderIcon = (status) => {
	if (status === Constants.tradeStates.ACCEPTED) {
		return <TickIcon />;
	} else if (status === Constants.tradeStates.REJECTED) {
		return <CloseIcon fill={'#fff'} width={'14px'} height={'14px'} />;
	} else {
		return null;
	}
};

const _renderStatusMessage = (status) => {
	if (status === Constants.tradeStates.ACCEPTED) {
		return 'Successful';
	} else if (status === Constants.tradeStates.REJECTED) {
		return 'Rejected';
	} else {
		return 'Pending';
	}
};

const _renderCta = (canCancel, onCancel, onConfirm, type, data) => {
	let text = 'Cancel Waiver';
	if(type === 'trade') {
		text = canCancel ? 'Cancel Trade' : 'Reject Trade';
	}
	const id = type === 'trade' ? data.tradeId : data.waiversId;

	return (
		<>
			{type === 'waiver' ? <div className='transfer__priority'><span>{data.priority}</span></div> : null}
			{type === 'trade' && !canCancel && data.status === Constants.tradeStates.PENDING ? <Button text={'Accept Trade'} type={'secondary'} onClick={() => onConfirm(id)} /> : null}
			{<Button text={text} type={'secondary button--cancel'} onClick={() => onCancel(id, data.priority)}/>}
		</>
	)
};

const Transfer = React.memo(function Transfer({canCancel, data, hideCta, onCancel, onConfirm, showManager, type}) {
	const clsName = classNames('transfer', {
		'transfer--accepted': data.status === Constants.tradeStates.ACCEPTED,
		'transfer--pending': data.status === Constants.tradeStates.PENDING,
		'transfer--rejected': data.status === Constants.tradeStates.REJECTED,
		'transfer--no-cta': hideCta,
		'transfer--waiver': type === 'waiver'
	});


	console.log('in transfer');
	const subHeadingTextA = type === 'waiver' ? 'Outgoing Player' : `${data.teamA.userName} offered`;
	const subHeadingTextB = type === 'waiver' ? 'Incoming Player' : `${data.teamB.userName} receives`;
	return (
		<div className={clsName}>
			<div className='transfer__cta'>
				{showManager && (<div className='transfer__user-name'><span>User: </span>{data.teamA.userName}</div>)}
				{hideCta ? null : _renderCta(canCancel, onCancel, onConfirm, type, data)}
			</div>
			<div className='transfer__info transfer__info--from'>
				<div className='transfer__sub-heading'>
					{subHeadingTextA}
				</div>
				<div className='transfer__player-info'>
					<span className='transfer__player-name'>{getPlayerLongName(data.teamA.player)}</span>
					<PlayerTeamPosInfo team={data.teamA.player.nflTeam} position={data.teamA.player.pos} />
				</div>
			</div>
			<div className='transfer__separator'>
				<div className='transfer__separator-line'></div>
				{type === 'trade' && (
				<div className='transfer__trade-icon'>
					 <TradeIcon fill={''} opacity={'1'} />
				</div>)}
			</div>
			<div className='transfer__info transfer__info--to'>
				<div className='transfer__sub-heading'>
					{subHeadingTextB}
				</div>
				<div className='transfer__player-info'>
					<span className='transfer__player-name'>{getPlayerLongName(data.teamB.player)}</span>
					<PlayerTeamPosInfo team={data.teamB.player.nflTeam} position={data.teamB.player.pos} />
				</div>
			</div>
			{type === 'trade' ?
			<div className='transfer__status'>
				<div></div>
				<div className='transfer__status-context'>
					Status: {_renderStatusMessage(data.status)}
				</div>
				<div className='transfer__status-icon'>
					{_renderIcon(data.status)}
				</div>
			</div> : null }
		</div>
	)
});

export default Transfer;
