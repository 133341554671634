import React from 'react';
import classNames from 'classnames';
import getPlayerShortName from '../../../helpers/getPlayerShortName';
import PlayerTeam from '../PlayerTeam';
import PlusIcon from '../../svgs/PlusIcon';
import './DraftTeamPlayers.scss';

const _renderPlayer = (player, isEmptyRosterSpot) => {
	const clsName = classNames('draft-team-players__player', {
		'draft-team-players__player--empty-spot': isEmptyRosterSpot
	});

	return (
		<div key={`draft-team-players_${player.round}=${player.playerId}`} className={clsName}>
			<div className='draft-team-players__draft-position'>
				<span>{isEmptyRosterSpot ? <PlusIcon /> : player.pick}</span>
			</div>
			<div className='draft-team-players__player-info'>
				<span className='draft-team-players__player-name'>{getPlayerShortName(player) || 'Empty roster spot'}</span>
				{isEmptyRosterSpot ? null : <PlayerTeam team={player.nflTeam} />}
			</div>
		</div>
	)
};

const _renderEmptyRosterSpots = (emptyRosterSpots) => {
	const emptyRosterSpotDivs = [];
	const emptyPlayer = { playerName: 'Empty Roster Spot', round: 'empty'};
	for(let i = 0; i < emptyRosterSpots; i++) {
		emptyPlayer.playerId = `empty-spot-${i}`;
		emptyRosterSpotDivs.push(_renderPlayer(emptyPlayer, true));
	}

	return emptyRosterSpotDivs;
};

const DraftTeamPlayers = ({type, players, minRequired}) => {
	const isComplete = players.length >= minRequired;
	const emptyRosterSpots = !isComplete ? minRequired - players.length : 0;

	return (
		<div className='draft-team-players'>
			<div className='draft-team-players__header'>
				<div className='draft-team-players__type'>{type}</div>
				<div className='draft-team-players__tally'>{!isComplete ? `${players.length}/${minRequired}` : ''}</div>
			</div>
			{players.map(player => _renderPlayer(player))}
			{emptyRosterSpots ? _renderEmptyRosterSpots(emptyRosterSpots) : null}
		</div>
	);
};

export default DraftTeamPlayers;
