import React from 'react';
import RoundRecord from '../RoundRecord';
import './SeasonRecord.scss';

const SeasonRecord = ({record}) => {
	if(!record || !record.record) return null;

	const recordParts = record.record.split('-');
	const wins = recordParts[0];
	const draws = recordParts.length > 2 ? recordParts[1] : 0;
	const losses = recordParts.length > 2 ? recordParts[2] : recordParts[1];

	return (
		<div className='season-record'>
			<p className='season-record__header'>Last Season Record</p>
			<div className='season-record__results'>
				<RoundRecord type='wins' value={wins} />
				<RoundRecord type='draws' value={draws} />
				<RoundRecord type='losses' value={losses} />
			</div>
		</div>
	)
};

export default SeasonRecord;
