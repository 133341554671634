import React from 'react';
import {connect} from 'react-redux';
import Header from '../Common/Header';
import NFLScore from '../NFLScore';
import './LiveScores.scss';

const LiveScores = ({currentGameWeek, isPostGameDay, schedule, scoreData, selectedLeague, headerText = 'Live Scores'}) => {
	if (!scoreData || !scoreData.scores) {
		return null;
	} else if(!currentGameWeek || !selectedLeague.type) {
		return null;
	}
	const gameWeek = currentGameWeek[selectedLeague.type];
	const scheduleData = schedule[selectedLeague.type][gameWeek];

	if(!scheduleData || !scheduleData.games || scheduleData.games.length === 0) {
		return null;
	}

	let allGameData;

	if(isPostGameDay) {
		allGameData = scoreData.scores;
	} else {
		allGameData = scheduleData.games.filter(game => {
			return !scoreData.scores.find(score => score.homeTeam === game.home || score.homeTeam === game.away);
		}).concat(scoreData.scores)
	}

	const allGames = allGameData.map(game => {
		game.gameTime = game.gameTime || game.isoTime;
		game.isoTime = game.gameTime;
		return game;
	}).sort((a, b) => new Date(a.gameTime).getTime() - new Date(b.gameTime).getTime());

	const scoreMap = {};
	allGames.forEach(game => {
		let day = new Date(game.gameTime).getUTCDay();
		if(day > 2) {
			day = 0 - day;
		}
		if (scoreMap[day]) {
			scoreMap[day].push(game);
		} else {
			scoreMap[day] = [game];
		}
	});

	return (
		<div className='live-scores'>
			<div className='live-scores__header'>
				<Header headerLevel={'h1'} text={headerText}/>
			</div>
			{Object.keys(scoreMap).sort((a, b) => a - b).map(dayKey => {
				const gameDay = new Intl.DateTimeFormat('en-US', {
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric'
				}).format(new Date(scoreMap[dayKey][0].gameTime));
				return (
					<div key={'day-wrapper-' + dayKey} className='live-scores__day-wrapper'>
						<p className='live-scores__day-header'>{gameDay}</p>
						<div className='live-scores__container'>
							{scoreMap[dayKey].map(game => {
								if(game.score) {
									return <NFLScore key={game.gameId} scoreData={game}/>
								} else {
									return <NFLScore key={game.id} gameData={game} type='schedule'/>
								}
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentGameWeek: state.currentGameWeek,
		scoreData: state.scores,
		schedule: state.schedule,
		selectedLeague: state.selectedLeague
	};
};

export default connect(mapStateToProps)(LiveScores);
