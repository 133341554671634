import React, {useContext, useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';
import queryString from 'query-string';
import Hero from '../../components/Common/Hero';
import JoinLeagueForm from '../../components/Form/JoinLeagueForm';
import {Constants} from '../../helpers/Constants';
import UserContext from '../../context/UserContext';
import './LeagueJoiner.scss';

const LeagueJoiner = ({history, location}) => {
	const userContext = useContext(UserContext);
	const userId = userContext.userObj ? userContext.userObj.email : null;
	const [ leagueId, setLeagueId ] = useState('');

	// Pre-populate input with id if user has shared link
	useEffect(() => {
		const queryParams = queryString.parse(location.search);

		if (queryParams.leagueId) {
			setLeagueId(queryParams.leagueId);
		}
	}, [ location.search, setLeagueId ]);

	const onJoinLeague = (formState) => {
		//TODO this flow is shit. If user goes to site via join link they fill it all out then get redirected and have to re-fill

		// User not authenticated, redirect
		if (!userId) {
			history.push('/login');
			return
		}

		const joinData = {
			season: Constants.currentSeason,
			shortTeamName: formState.shortTeamName,
			teamName: formState.teamName,
			userId
		};

		axios.post(`${Constants.urls.LEAGUE}/join/${formState.leagueId}`, joinData).then(() => {
			console.log('successfully joined');
			history.push('/');
		}).catch(err => {
			//TODO handle errors
			console.error(err)
		});
	};

	return (
		<div className='league-joiner'>
			<Helmet>
				<title>Shakitz - Join League</title>
				<link rel="canonical" href="https://shakitz.com/league/join" />
				<meta name="description" content="Shakitz Fantasy Football. Join a league now to access a unique ad free fantasy football experience" />
			</Helmet>
			<Hero bubbles joinJourney text='Join a fantasy football league focussed on its players first' secondaryText='enter league details below' />
			<JoinLeagueForm leagueId={leagueId} onSubmit={onJoinLeague} />
		</div>
	);

};

export default withRouter(LeagueJoiner);
