import { Constants } from './Constants';

const getGameWeekState = (schedule = { games: []}) => {
	const games = schedule.games || [];
	if(games.length === 0) {
		return Constants.gameStates.PRE;
	}

	const sortedGames = games.sort((a, b) => new Date(a.gameTime).getTime() - new Date(b.gameTime).getTime());
	const now = new Date().getTime();
	const firstGame = new Date(sortedGames[0].gameTime).getTime();

	if(now < firstGame) {
		return Constants.gameStates.PRE;
	}

	const lastGame = new Date(sortedGames[sortedGames.length-1].gameTime).getTime();
	if(now > firstGame && now < lastGame) {
		return Constants.gameStates.LIVE;
	}

	return Constants.gameStates.POST;
};

export default getGameWeekState;
