import React from 'react';

const SearchIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22">
		<path fillRule="nonzero" d="M20.611 19.744l-4.017-3.994a9.418 9.418 0 0 0 2.428-6.296C19.022 4.241 14.754 0 9.512 0 4.268 0 0 4.242 0 9.454c0 5.213 4.268 9.455 9.51 9.455a9.523 9.523 0 0 0 5.04-1.444l4.176 4.152c.25.248.59.383.931.383.34 0 .681-.136.931-.383a1.295 1.295 0 0 0 .023-1.873zM2.655 9.454c0-3.746 3.065-6.814 6.855-6.814 3.792 0 6.833 3.068 6.833 6.814S13.28 16.27 9.511 16.27s-6.856-3.047-6.856-6.815z"/>
	</svg>

);

export default SearchIcon;
