import rankHalfPpr from '../suggestedRank-0.5';
import rankStandard from '../suggestedRank-standard';
const _returnPlayer = (suggestedRank, player) => {
	const matches = suggestedRank.filter(sr => sr.name === player.playerName.display);

	if(matches.length > 1) {
		return matches.find(m => m.team === player.nflTeam);
	}

	return matches[0];
};

const addRank = (rankType, playersList) => {
	const suggestedRank = rankType === 'standard' ? rankStandard : rankHalfPpr;
	return playersList.map(player => {
		const match = _returnPlayer(suggestedRank, player);
		return {
			...player,
			rank: match ? match.rank : 5000
		}
	}).filter(p => p.playerId);
};

export default addRank;
