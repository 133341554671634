const isDraftLive = (clock) => {
	if(!clock || !clock.startTime) {
		return false;
	}

	const transformedDate = new Date(clock.startTime);
	return new Date().valueOf() >= new Date(transformedDate).valueOf();
};

export default isDraftLive;
