import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_GAME_WEEK_TEAM_REQUEST = 'FETCH_GAME_WEEK_TEAM_REQUEST';
export const FETCH_GAME_WEEK_TEAM_SUCCESS = 'FETCH_GAME_WEEK_TEAM_SUCCESS';
export const FETCH_GAME_WEEK_TEAM_FAILURE = 'FETCH_GAME_WEEK_TEAM_FAILURE';

export function fetchGameWeekTeamRequest ( isFetching ) {
	return {
		type: FETCH_GAME_WEEK_TEAM_REQUEST,
		isFetching
	};
}

export function fetchGameWeekTeamFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_GAME_WEEK_TEAM_FAILURE,
		response: err,
		error: true
	};
}

export function fetchGameWeekTeamSuccess( data ) {
	return {
		type: FETCH_GAME_WEEK_TEAM_SUCCESS,
		data
	};
}

const getQuery = (gameWeekTeamId, withStats, season) => {
	const calculatedPlayerStat = `
		calculatedStats {
			offensive {
			  points
			  name
			  description
			  value
			}
			passing {
			  points
			  name
			  description
			  value
			}
			kicking {
			  points
			  name
			  description
			  value
			}
			defensive {
			  points
			  name
			  description
			  value
			}
			points
		}
		stats {
	        passingYds
	        passingTds
	        passingTwoptm
	        passingInts
	        sacksConceded
	        safetyConceded
	        receivingRec
	        receivingYds
	        receivingTds
	        receivingTwoptm
	        rushingYds
	        rushingTds
	        rushingTwoptm
	        fumblesLost
	        defenceFumblesWon
	        defenceTouchdowns
	        defenceSacksMade
	        defenceInterceptions
	        safetyWon
	        defencePointsConceded
	        kickingFgm
	        kickingXpmade
	     }
     `;

	return `query {
		gameWeekTeam(id: "${gameWeekTeamId}", season:"${season}") {
		    gameWeekTeamId
		    leagueId
		    seasonUserTeamId
		    gameWeek
		    starters {
		      gameWeekPlayerId
		      playerId
		      injuryStatus
		      ${withStats ? calculatedPlayerStat : ''}
		    }
		    bench {
		      gameWeekPlayerId
		      playerId
		      injuryStatus
		      ${withStats ? calculatedPlayerStat : ''}
		    }
			${withStats ? `
			currentBenchPoints
            currentGameWeekPoints` : ''}
	    }
	}`;
};

//TODO remove hard coded season type
export function fetchGameWeekTeam ( gameWeekTeamId, withStats = false, season = Constants.currentSeason ) {
	return ( dispatch ) => {
		dispatch(fetchGameWeekTeamRequest(true));

		const url = Constants.urls.GRAPHQL;
		let query = getQuery(gameWeekTeamId, withStats, season);

		axios.post(url, {
			query: query.replace(/\t+/g, "")
		}).then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				return response.data.data.gameWeekTeam;
			}).then(data => {
				if(withStats) {
					const gameWeekTeamData = { gameWeekTeamId, ...data};
					dispatch(fetchGameWeekTeamSuccess(gameWeekTeamData));
				} else {
					dispatch(fetchGameWeekTeamSuccess(data));
				}
			}).catch(err => dispatch(fetchGameWeekTeamFailure(err)))
			.finally(() => dispatch(fetchGameWeekTeamRequest(false)))
	};
}
