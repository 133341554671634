import { ACTIVE_GAME_WEEK_TEAM } from '../actions/activeGameWeekTeam';

export function activeGameWeekTeam (state = false, action) {
	switch (action.type) {
		case ACTIVE_GAME_WEEK_TEAM:
			return action.gameWeekTeam;
		default:
			return state;
	}
}
