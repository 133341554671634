import React from 'react';
import classNames from 'classnames';
import './WinPercentage.scss';
import TrophyIcon from '../../svgs/TrophyIcon';

const WinPercentage = ({homePercentage, awayPercentage}) => {
	const homeWin = homePercentage > awayPercentage;
	const awayWin = awayPercentage > homePercentage;
	const draw = homePercentage === awayPercentage;

	const homeAmountCls = classNames('win-percentage__amount', {
		'win-percentage__amount--draw': draw,
		'win-percentage__amount--winner': homeWin,
		'win-percentage__amount--loser': awayWin
	});
	const awayAmountCls = classNames('win-percentage__amount', {
		'win-percentage__amount--draw': draw,
		'win-percentage__amount--winner': awayWin,
		'win-percentage__amount--loser': homeWin
	});

	const trophyCls = classNames('win-percentage__trophy', {
		'win-percentage__trophy--draw': draw,
		'win-percentage__trophy--homeWin': homeWin,
		'win-percentage__trophy--awayWin': awayWin
	});

	let homeWidth = homeWin ? `100%` : `${homePercentage}%`;
	const awayWidth = awayWin ? `100%` : `${awayPercentage}%`;

	if(draw) {
		homeWidth = `${homePercentage}% - 3.2rem`;
	}

	return (
		<div className='win-percentage'>
			<div className='win-percentage__title'>Win Percentage Odds</div>
			<div className='win-percentage__display'>
				<span className={homeAmountCls} style={{width: `calc(${homeWidth})`}}>{homePercentage}%</span>
				<span className={trophyCls}><TrophyIcon /></span>
				<span className={awayAmountCls} style={{width: `calc(${awayWidth})`}}>{awayPercentage}%</span>
			</div>
		</div>
	);
};

export default WinPercentage;
