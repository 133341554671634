import { DELETE_WAIVER_FAILURE, DELETE_WAIVER_REQUEST, DELETE_WAIVER_SUCCESS} from '../actions/deleteWaiver';

export function deleteWaiverFailure(state = false, action) {
	switch (action.type) {
		case DELETE_WAIVER_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function deleteWaiverRequest(state = false, action) {
	switch (action.type) {
		case DELETE_WAIVER_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function deleteWaiver(state = false, action) {
	switch (action.type) {
		case DELETE_WAIVER_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
