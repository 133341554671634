import React from 'react';

const H2HDetailsIcon = ({fill = '#6834FF', width = '10', height = '10'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 10 10">
		<path fill={fill} d="M3.75 10h2.5V0h-2.5v10zM0 10h2.5V5H0v5zm7.5-6.875V10H10V3.125H7.5z"/>
	</svg>

);

export default H2HDetailsIcon;
