import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import Hero from '../../components/Common/Hero';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import USP from '../USP';
import ShakitzContext from '../../context/ShakitzContext';
import UserContext from '../../context/UserContext';
import getLeagueSeason from '../../helpers/getLeagueSeason';
import {selectLeague} from '../../actions/selectLeague';
import './Home.scss';

const Home = (props) => {
	const userContext = useContext(UserContext);
	const shakitzContext = useContext(ShakitzContext);

	const userId = userContext.userObj ? userContext.userObj.email : null;
	const shakitzUser = shakitzContext ? shakitzContext.userId : null;

	if (userId && shakitzUser) {
		if(props.isPosting) {
			return (
				<div className='home-container'>
					<SpinnerLayer />
				</div>
			)
		}
		const { format, leagueId, season, seasonToBe, type } = getLeagueSeason(shakitzContext.userLeagues); // Use most recent season as default
		props.selectLeague(format, leagueId, season, seasonToBe, type);
	}

	return (
		<div className='home-container home-container--no-auth'>
			<Helmet>
				<link rel="canonical" href="https://shakitz.com" />
			</Helmet>
			<Hero bubbles text={'Simple. Beautiful. In real time.'} secondaryText={'We are perfect for people who just want to get started and enjoy fantasy football as if its 2020'}  />
			<USP />
			<div id='chatButton' />
			{props.isPosting ? <SpinnerLayer /> : null}
		</div>
	)
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		selectLeague: (format, leagueId, season, seasonToBe, type) => dispatch(selectLeague(format, leagueId, season, seasonToBe, type))
	};
};

const mapStateToProps = ( state ) => {
	return {
		hasErrored: state.setUserNameFailure || state.seasonUserTeamFailure || state.draftDataFailure,
		isPosting: state.setUserNameRequest || state.fetchingUser
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
