import { Component } from 'react';
import ReactDOM from 'react-dom';
import withEscListener from '../../HOC/withEscListener';
import ReactGA from 'react-ga';
import './Modal.scss';

class Modal extends Component {

	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.el.className = `modal ${props.className}`;
		this.lockScroll = this.lockScroll.bind(this);
		this.unlockScroll = this.unlockScroll.bind(this);
	}

	componentDidMount() {
		ReactGA.modalview(this.props.name);
		this.originalOverflow = document.body.style.overflow || '';
		this.originalScroll = window.scrollY;
		document.body.appendChild(this.el);
		this.lockScroll();

	}

	componentWillUnmount() {
		document.body.removeChild(this.el);
		this.unlockScroll();
	}

	lockScroll() {
		document.body.style.position = 'fixed';
		document.body.style.left = '0px';
		document.body.style.right = '0px';
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.overflow = 'hidden';
	}

	unlockScroll() {
		document.body.style.position = '';
		document.body.style.top = '';
		document.body.style.left = '';
		document.body.style.right = '';
		document.body.style.overflow = this.originalOverflow;
		window.scrollTo(0, this.originalScroll);
	}

	render () {
		return ReactDOM.createPortal(
			this.props.children,
			this.el,
		);
	}
}

export default withEscListener(Modal);
