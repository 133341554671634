import { FETCH_LEAGUE_BENCH_DATA_FAILURE, FETCH_LEAGUE_BENCH_DATA_REQUEST, FETCH_LEAGUE_BENCH_DATA_SUCCESS} from '../actions/fetchLeagueBenchData';

export function leagueBenchDataFailure(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_BENCH_DATA_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function leagueBenchDataRequest(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_BENCH_DATA_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function leagueBenchData(state = {}, action) {
	switch (action.type) {
		case FETCH_LEAGUE_BENCH_DATA_SUCCESS:
			const { leagueId, season, data } = action.data;
			const clonedState = JSON.parse(JSON.stringify(state));
			if (!clonedState.hasOwnProperty(leagueId)) {
				clonedState[leagueId] = {};
			}
			if (!clonedState[leagueId].hasOwnProperty(season)) {
				clonedState[leagueId][season] = {};
			}

			clonedState[leagueId][season] = data;
			return clonedState;
		default:
			return state;
	}
}
