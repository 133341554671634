import React from 'react';

const LeagueTypeIcon = ({fill = '#E00D83', width = '32', height = '32'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 40 46">
		<path fill={fill} d="M9.2 46H3.067C1.377 46 0 44.624 0 42.933V32.2c0-1.69 1.376-3.067 3.067-3.067H9.2c1.69 0 3.067 1.377 3.067 3.067v10.733C12.267 44.623 10.89 46 9.2 46zM3.067 32.2v10.733h6.135L9.2 32.2H3.067zM23 16.867h-6.133c-1.69 0-3.067-1.377-3.067-3.067V3.067C13.8 1.377 15.176 0 16.867 0H23c1.69 0 3.067 1.376 3.067 3.067V13.8c0 1.69-1.377 3.067-3.067 3.067zm-6.133-13.8V13.8h6.135L23 3.067h-6.133zM36.8 46h-6.133c-1.69 0-3.067-1.376-3.067-3.067V32.2c0-1.69 1.376-3.067 3.067-3.067H36.8c1.69 0 3.067 1.377 3.067 3.067v10.733c0 1.69-1.377 3.067-3.067 3.067zm-6.133-13.8v10.733h6.135L36.8 32.2h-6.133z"/>
		<path fill={fill} d="M19.933 23c-.847 0-1.533-.686-1.533-1.533v-6.134c0-.847.686-1.533 1.533-1.533.848 0 1.534.686 1.534 1.533v6.134c0 .847-.686 1.533-1.534 1.533zM6.133 32.2c-.847 0-1.533-.686-1.533-1.533V23c0-.847.686-1.533 1.533-1.533.847 0 1.534.686 1.534 1.533v7.667c0 .847-.687 1.533-1.534 1.533zM33.733 32.2c-.847 0-1.533-.686-1.533-1.533V23c0-.847.686-1.533 1.533-1.533.848 0 1.534.686 1.534 1.533v7.667c0 .847-.687 1.533-1.534 1.533z"/>
		<path fill={fill} d="M33.733 24.534h-27.6c-.847 0-1.533-.687-1.533-1.534s.686-1.533 1.533-1.533h27.6c.848 0 1.534.686 1.534 1.533 0 .847-.687 1.534-1.534 1.534z"/>
	</svg>

);

export default LeagueTypeIcon;
