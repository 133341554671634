import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Constants } from '../helpers/Constants';
import {fetchGameWeekTeamSuccess} from './gameWeekTeam';

export const SET_GAME_WEEK_TEAM_REQUEST = 'SET_GAME_WEEK_TEAM_REQUEST';
export const SET_GAME_WEEK_TEAM_SUCCESS = 'SET_GAME_WEEK_TEAM_SUCCESS';
export const SET_GAME_WEEK_TEAM_FAILURE = 'SET_GAME_WEEK_TEAM_FAILURE';

export function setGameWeekTeamRequest ( isPosting ) {
	return {
		type: SET_GAME_WEEK_TEAM_REQUEST,
		isPosting
	};
}

export function setGameWeekTeamFailure( err, errorState = true ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: SET_GAME_WEEK_TEAM_FAILURE,
		response: err,
		error: errorState
	};
}

export function setGameWeekTeamSuccess( data ) {
	return {
		type: SET_GAME_WEEK_TEAM_SUCCESS,
		data
	};
}

export function setGameWeekTeam ( gameWeekTeam ) {
	return ( dispatch ) => {
		dispatch(setGameWeekTeamRequest(true));

		const url = `${Constants.urls.GAME_WEEK_TEAM}/update`;

		axios.put(url, gameWeekTeam)
			.then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				dispatch(setGameWeekTeamRequest(false));

				return response.data;
			}).then(data => {
				dispatch(setGameWeekTeamSuccess(data));
				return data.data; // 🙄
			}).then(data => {
				// Update our redux store to show successful changes
				const transformedData = { gameWeekTeamId: gameWeekTeam.gameWeekTeamId, ...data };
				dispatch(fetchGameWeekTeamSuccess(transformedData));
			}).catch(err => {
				Sentry.captureException(err);
				dispatch(setGameWeekTeamFailure(err));
				dispatch(setGameWeekTeamRequest(false));
			});
	};
}
