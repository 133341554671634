import { FETCH_HISTORIC_PLAYER_STATS_FAILURE, FETCH_HISTORIC_PLAYER_STATS_REQUEST, FETCH_HISTORIC_PLAYER_STATS_SUCCESS} from '../actions/historicPlayerStats';

export function historicPlayerStatsFailure(state = false, action) {
	switch (action.type) {
		case FETCH_HISTORIC_PLAYER_STATS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function historicPlayerStatsRequest(state = false, action) {
	switch (action.type) {
		case FETCH_HISTORIC_PLAYER_STATS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function historicPlayerStats(state = {}, action) {
	switch (action.type) {
		case FETCH_HISTORIC_PLAYER_STATS_SUCCESS:
			const clonedState = JSON.parse(JSON.stringify(state));
			const statData = action.data;
			const key = Object.keys(statData)[0];

			clonedState[key] = statData[key];
			return clonedState;
		default:
			return state;
	}
}
