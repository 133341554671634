import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WaiverIcon from '../../svgs/WaiverIcon';
import './Countdown.scss';

class Countdown extends Component {
	static defaultProps = {
		draftMode: false,
		preDraftMode: false,
		reminderText: '',
		reminderSecondaryText: '',
		timeToCountdownTo: new Date(),
		type: ''
	};

	constructor(props) {
		super(props);

		this.renderCountdownHeader = this.renderCountdownHeader.bind(this);
		this.state = {
			days: '0',
			hours: '0',
			minutes: '0',
			seconds: '0',
			warningCls: ''
		};
	}

	componentDidMount () {
		this.updateCountdown();
		this.countdownTimer = setInterval(() => {
			this.updateCountdown();
		}, 999);
	}

	componentWillUnmount() {
		if (this.countdownTimer) {
			this.countdownTimer = clearInterval(this.countdownTimer);
		}
	}

	calculateTimeRemaining (timeToCountdownTo) {
		const now = new Date().getTime();
		let delta = Math.floor(timeToCountdownTo.getTime() - now) / 1000;

		if ( now > timeToCountdownTo.getTime()) {
			return {
				days: '0',
				hours: '0',
				minutes: '0',
				seconds: '0'
			};
		} else {
			const days = Math.floor(delta / 86400);
			delta -= days * 86400;

			const hours = Math.floor(delta / 3600) % 24;
			delta -= hours * 3600;

			const minutes = Math.floor(delta / 60) % 60;
			delta -=minutes * 60;

			const seconds = Math.floor(delta % 60);

			return {
				days: days.toString(),
				hours: hours.toString(),
				minutes: minutes.toString(),
				seconds: seconds.toString()
			};
		}
	}

	updateCountdown() {
		const { days, hours, minutes, seconds } = this.calculateTimeRemaining(this.props.timeToCountdownTo);
		const state = {
			days,
			hours,
			minutes,
			seconds
		};

		const secondsLeft = Number(minutes) * 60 + Number(seconds);
		if (this.props.draftMode && secondsLeft <= 150 && minutes >= 1) {
			state.warningCls = 'warn';
		} else if (this.props.draftMode && minutes === '0' && Number(seconds) > 0) {
			state.warningCls = 'danger';
		} else {
			state.warningCls = null;
		}

		this.setState(state);
	}

	renderSection(clockPart, amount) {
		const amountParts = amount.split('');
		const firstPart = amountParts.length === 1 ? '0' : amountParts[0];
		const secondPart = amountParts.length === 1 ? amountParts[0] : amountParts[1];

		const isExpired = firstPart === '0' && secondPart === '0';

		return (
			<div className={isExpired ? 'countdown__wrapper countdown__wrapper--expired' : 'countdown__wrapper'}>
				<div className='countdown__section-wrapper'>
					<div className={`countdown__section countdown__section--${clockPart}`}>
						<span className='countdown__section-text'>{firstPart}</span>
						<span className='countdown__section-text'>{secondPart}</span>
					</div>
				</div>
				<div className='countdown__text'>{clockPart}</div>
			</div>
		);
	}

	renderCountdownHeader() {
		if(this.props.draftMode || this.props.preDraftMode) {
			return null;
		}

		return (
			<div className='countdown__header'>
				<WaiverIcon height='21' width='16' fill='#E00D83' opacity='1' />
				<span className='countdown__header-text'>{this.props.type}</span>
				<WaiverIcon height='21' width='16' fill='#E00D83' opacity='1' />
			</div>
		)
	}

	render() {
		const { days, hours, minutes, seconds } = this.state;
		const clsName = classNames('countdown', {
			'countdown--draft-mode': this.props.draftMode || this.props.preDraftMode,
			'countdown--warn': this.state.warningCls === 'warn',
			'countdown--danger': this.state.warningCls === 'danger',
			'countdown--opener': this.props.type === 'Season Starts In',
			'countdown--alternate': this.props.alternate
		});

		return (
			<div className={clsName}>
				{this.renderCountdownHeader()}
				<div className='countdown__core'>
					{this.props.draftMode ? null : this.renderSection('days', days)}
					{this.props.draftMode ? null : this.renderSection('hours', hours)}
					{this.renderSection('minutes', minutes)}
					{this.props.draftMode ? this.renderSection('seconds', seconds) : null}
				</div>
				<div className='countdown__reminder-text'>
					{this.props.reminderText} <span className='countdown__reminder-text--secondary'>{this.props.reminderSecondaryText}</span>
				</div>
			</div>
		);
	}
};

Countdown.propTypes = {
	reminderText: PropTypes.string,
	reminderSecondaryText: PropTypes.string,
	timeToCountdownTo: PropTypes.object,
	type: PropTypes.string
};

export default Countdown;
