import React from 'react';

const InjuryIcon = ({fill = "#FF4646"}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 20 20">
		<circle cx="10" cy="10" r="9" fill={fill} fillOpacity=".04" stroke={fill} strokeWidth="2"/>
		<path fill={fill} d="M14.475 8.899H11.1V5.524c0-.29-.235-.524-.525-.524H9.423c-.29 0-.525.235-.525.524V8.9H5.524c-.29 0-.524.234-.524.524v1.154c0 .29.235.524.524.524h3.374v3.374c0 .29.235.525.525.525h1.153c.29 0 .525-.235.525-.524V11.1h3.374c.29 0 .524-.234.524-.524V9.423c0-.29-.234-.524-.524-.524z"/>
	</svg>
);

export default InjuryIcon;

