import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_AUTH_DRAFT_REQUEST = 'FETCH_AUTH_DRAFT_REQUEST';
export const FETCH_AUTH_DRAFT_SUCCESS = 'FETCH_AUTH_DRAFT_SUCCESS';
export const FETCH_AUTH_DRAFT_FAILURE = 'FETCH_AUTH_DRAFT_FAILURE';

export function authDraftRequest ( isPosting ) {
	return {
		type: FETCH_AUTH_DRAFT_REQUEST,
		isPosting
	};
}

export function authDraftFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_AUTH_DRAFT_FAILURE,
		response: err,
		error: true
	};
}

export function authDraftSuccess( data ) {
	return {
		type: FETCH_AUTH_DRAFT_SUCCESS,
		auth: data
	};
}

export function authDraft ( userId ) {
	return ( dispatch ) => {
		dispatch(authDraftRequest(true));

		const url = `${Constants.urls.DRAFT}/auth`;
		axios.post(url, { userId} )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(authDraftRequest(false));

			return response.data;
		}).then(data => dispatch(authDraftSuccess(data)))
		.catch(err => dispatch(authDraftFailure(err)));
	};
}
