import React from 'react';
import ReactGA from 'react-ga';
import { GA } from '../../../helpers/ga';
import Button from '../../Common/Button';
import Header from '../../Common/Header';
import SpinnerLayer from '../../SpinnerLayer/SpinnerLayer';
import CloseIcon from '../../svgs/CloseIcon';
import './NameEdit.scss';

const NameEdit = ({currentValue, label = 'Name: ', headerText = 'Change your name', isPosting, onClose, onSubmit}) => {

	const submitForm = (evt) => {
		evt.preventDefault();
		ReactGA.event({
			category: GA.CATEGORY.USER,
			action: GA.ACTION.EDIT_USER_NAME
		});

		onSubmit({
			name: evt.target.name.value
		});
	};

	return (
		<div className='name-edit'>
			<div className='name-edit__header'>
				<Header headerLevel={'h2'} text={headerText} />
				<div className='name-edit__close-container' onClick={onClose}><CloseIcon width={'18px'} height={'18px'}/></div>
			</div>
			<form onSubmit={submitForm}>
				<div className='name-edit__form-input'>
					<label className='name-edit__label' htmlFor='nameEdit'>{label}</label>
					<input className='name-edit__input' id='name-edit' type={'text'} defaultValue={currentValue} name={'name'} required minLength={3} maxLength={12} />
				</div>
				<div className='name-edit__form-submit'>
					<Button text={'Save'} type={'submit'} isSubmit={true} disabled={isPosting} />
				</div>
			</form>
			{isPosting ? <SpinnerLayer/> : null}
		</div>
	);

};

export default NameEdit;
