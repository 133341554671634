import React from 'react';
import {Constants} from '../../helpers/Constants';
import BadgeIcon from '../svgs/BadgeIcon';
import SpoonIcon from '../svgs/SpoonIcon';
import SadPandaIcon from '../svgs/SadPandaIcon';
import WorstIcon from '../svgs/WorstIcon';
import PlayerTeamPosInfo from '../Common/Players/PlayerTeamPosInfo';
import getPlayerShortName from '../../helpers/getPlayerShortName';
import BenchIcon from '../svgs/BenchIcon';
import TopScorer from '../svgs/TopScorer';
import FireIcon from '../svgs/FireIcon';
import SnowIcon from '../svgs/SnowIcon';
import TrashIcon from '../svgs/TrashIcon';
import './Record.scss';

const READABLE_RECORD_NAMES = {
	highestPlayerScorer: 'Highest scoring player',
	highestBenchPlayerScorer: 'Highest scoring player on bench',
	highestBenchScore: 'Highest scoring bench',
	highestScore: 'Highest scoring week',
	lowestPlayerScorer: 'Lowest scoring player',
	lowestScore: 'Lowest scoring week',
	winStreak: 'Longest win streak',
	winlessStreak: 'Longest streak with no wins'
}

const RECORD_SVG_MAP = {
	highestPlayerScorer: TopScorer,
	highestBenchPlayerScorer: SadPandaIcon,
	highestBenchScore: BenchIcon,
	highestScore: BadgeIcon,
	lowestPlayerScorer: TrashIcon,
	lowestScore: WorstIcon,
	winStreak: FireIcon,
	winlessStreak: SnowIcon
};

const _calculateGameWeek = (gameWeek, results = []) => {
	if(gameWeek || results.length === 0) {
		return gameWeek;
	}

	return `${results[0].gameWeek} till GameWeek ${results[results.length-1].gameWeek}`;
}

const _renderPlayer = (player, players) => {
	let matchingPlayer = players.find(p => p.statId === player.id || p.playerId === player.id);

	// Try to find player couple different ways due to differences in data across years
	if(!matchingPlayer ) {
		if(!player.gameWeekPlayerId) {
			return;
		}

		const statIdFallback = player.gameWeekPlayerId.split('-');
		const statId = `${statIdFallback[0]}-${statIdFallback[1]}`;
		matchingPlayer = players.find(p => p.statId === statId || p.playerId === statId);

		if(!matchingPlayer) {
			return;
		}
	}

	return (
		<div className='record__player-wrapper'>
			<span className='record__player-name'>{matchingPlayer.pos !== 'D' && getPlayerShortName(matchingPlayer)}</span>
			<PlayerTeamPosInfo team={matchingPlayer.nflTeam} position={Constants.positionMap[matchingPlayer.pos]}/>
		</div>
	)
};

const Record = ({isPersonal, name, data, players}) => {
	const score = data.score || data?.results?.length || 0;
	const season = data.results && data.results.length > 0 ? data.results[0].season : data.season;
	const SVGIcon = RECORD_SVG_MAP[name];
	return (
		<div className='record'>
			<div className='record__icon'><SVGIcon /></div>
			<span className='record__name'>{READABLE_RECORD_NAMES[name]}</span>
			<span className='record__score'>{Math.round((score + Number.EPSILON) * 100) / 100}</span>
			{!isPersonal && <span className='record__manager-name'>{data.managerName}</span>}
			{data.player && _renderPlayer(data.player, players)}
			<span className='record__time'>GameWeek {_calculateGameWeek(data.gameWeek, data.results)} - {season}</span>
		</div>
	)
};

export default Record;
