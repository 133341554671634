/* eslint react-hooks/rules-of-hooks: 0 */
import React, {useContext, useEffect} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import axios from 'axios';
import UserContext from '../context/UserContext';
import ShakitzContext from '../context/ShakitzContext';
import {Constants} from '../helpers/Constants';
import getUserName from '../helpers/getUserName';
import {fetchingUser} from '../actions/fetchingUser';

const fetchShakitzData = ({isFetchingUser, history, setShakitzContext}) => {
	const userContext = useContext(UserContext);
	const shakitzContext = useContext(ShakitzContext);

	const userId = userContext.userObj ? userContext.userObj.email : null;
	const userName = getUserName(userContext.userObj, shakitzContext);
	const shakitzUser = shakitzContext ? shakitzContext.userId : null;

	if (!userId) {
		return;
	}
	axios.interceptors.response.use(
		response => {
			const errors = response.data.errors;
			if(errors) {
				const needsToLogin = errors.find(error => error.message.includes('401') || error.message.includes('403'));
				if(needsToLogin) {
					history.push('/login?login=true');
				}
			}
			return Promise.resolve(response);
		},
		error => {
			console.error(error);
			if (error.message === 'Network Error' || error.message === 'Unauthorised request' || error.message.includes('401')) {
				history.push('/login?login=true');
			} else {
				return Promise.reject(error);
			}
		}
	);

	useEffect(() => {
		if (shakitzUser || !userId) {
			return;
		}

		const url = Constants.urls.GRAPHQL;
		const query = `query user {
			user(id: "${userId}", userName: "${userName}") {
				favouriteTeam,
				userId,
			    userName,
			    userLeagues {
			      leagueId,
			      leagueName,
			      leagueType,
			      formatType,
			      season,
			      seasonToBe
			    }
			  }
			}
		`;

		isFetchingUser(true);
		axios.post(url, {
			query: query.replace(/\t+/g, '').replace(/\n+/g, '')
		}).then(response => {
			const data = response.data.data;
			if (response.errors && response.errors.length > 0 && !data.user) {
				axios.post(Constants.urls.USER, {userId, userName}).then(res => {
					setShakitzContext(res.data);
				}).catch(err => {
					console.error(err);
				}).finally(() => isFetchingUser(false));
			} else {
				setShakitzContext(data.user);
				isFetchingUser(false);
			}
		}).catch(err => {
			console.error('error', err);
			isFetchingUser(false);
		});
	});
};

const mapDispatchToProps = (dispatch) => {
	return {
		isFetchingUser: (isFetching) => dispatch(fetchingUser(isFetching))
	};
};

const withAuth = BaseComponent => {
	return (props) => {
		fetchShakitzData(props);
		return <BaseComponent {...props} />;
	};
};

export default compose(connect(null, mapDispatchToProps), withAuth);
