import React from 'react';

const ExitIcon = ({fill = '#fff', width = '14', height = '17'}) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 14 17'>
		<path fill={fill} d='M2.56.031h4.862c.447 0 .81.344.81.77 0 .425-.363.77-.81.77H2.56c-.215 0-.421.08-.573.225-.152.144-.237.34-.237.545v12.318c0 .204.085.4.237.545.152.144.358.225.573.225h4.862c.447 0 .81.345.81.77 0 .426-.363.77-.81.77H2.56c-1.342 0-2.43-1.034-2.43-2.31V2.341C.13 1.065 1.218.03 2.56.03z'/>
		<path fill={fill} d='M11.14 9.27H5.8c-.447 0-.809-.345-.809-.77 0-.425.362-.77.81-.77h5.34L9.276 5.966v.001c-.318-.302-.318-.792 0-1.093.318-.302.833-.302 1.15 0l3.241 3.08c.074.072.132.159.17.254.08.187.08.397 0 .584-.038.095-.096.181-.17.254l-3.24 3.08c-.153.146-.36.228-.576.228-.216 0-.423-.082-.576-.228-.153-.144-.239-.341-.239-.546 0-.206.086-.402.24-.547L11.14 9.27z'/>
	</svg>

);

export default ExitIcon;
