import React from 'react';
import classNames from 'classnames';
import './WaiverToggle.scss';

const WaiverToggle = ({activePane, onToggle}) => {
	const options = [{
		pane: 0,
		text: 'Create Waiver'
	}, {
		pane: 1,
		text: 'Proposed'
	}, {
		pane: 2,
		text: 'History'
	}];

	return (
		<div className='waiver-toggle'>
			<ul className='waiver-toggle__list'>
				{options.map(option => {
					const clsName = classNames('waiver-toggle__list-item', {
						'waiver-toggle__list-item--active': option.pane === activePane
					});
					return <li className={clsName} key={option.pane} onClick={() => onToggle(option.pane)}>{option.text}</li>
				})}
			</ul>
		</div>
	);
};

export default WaiverToggle;
