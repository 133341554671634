import React from 'react';

const RecordMobileLeft = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="61" height="47" fill="none" viewBox="0 0 61 47">
		<path fill="#E00D83" d="M1.238-13L61-12.8l-.142 29.88c-.079 16.503-13.52 29.836-30.023 29.781l-29.881-.1L1.238-13z"/>
	</svg>
);

export default RecordMobileLeft;

