// TODO - Use local storage to remember last picked league instead
const getLeagueSeason = (userLeagues = []) => {
	if(userLeagues.length === 0) {
		return {
			leagueId: null,
			leagueName: null,
			format: null,
			season: null,
			seasonToBe: null,
			leagueType: null
		};
	}

	const latestLeague = userLeagues.sort((a, b) => {
		if(b.seasonToBe > a.seasonToBe) {
			return -1;
		} else if (b.seasonToBe < a.seasonToBe) {
			return 1;
		}
		return 0;
	}).sort((a, b) => {
		if(a.leagueType === 'REG') {
			return -1;
		}
		return 0;
	});

	const { leagueId, formatType, season, seasonToBe, leagueType, leagueName } = latestLeague[0];
	console.log('league to use ', latestLeague[0]);
	return { leagueId, leagueName, format: formatType, season, seasonToBe, type: leagueType };
};

export default getLeagueSeason;
