import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_RECORDS_REQUEST = 'FETCH_RECORDS_REQUEST';
export const FETCH_RECORDS_SUCCESS = 'FETCH_RECORDS_SUCCESS';
export const FETCH_RECORDS_FAILURE = 'FETCH_RECORDS_FAILURE';

export function fetchRecordsRequest ( isFetching ) {
	return {
		type: FETCH_RECORDS_REQUEST,
		isFetching
	};
}

export function fetchRecordsFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_RECORDS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchRecordsSuccess( data ) {
	return {
		type: FETCH_RECORDS_SUCCESS,
		data
	};
}

export function fetchRecords ( leagueId) {
	return ( dispatch ) => {
		dispatch(fetchRecordsRequest(true));

		const url = `${Constants.urls.RECORDS}/${leagueId}`;

		axios.get(url)
			.then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				return response.data;
			}).then(data => dispatch(fetchRecordsSuccess(data)))
		.catch(err => dispatch(fetchRecordsFailure(err)))
		.finally(() => dispatch(fetchRecordsRequest(false)));
	};
}
