import React, { useEffect, useLayoutEffect, useState }  from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import Button from '../Common/Button';
import './NavBar.scss';
import Emblem from '../svgs/logo/Emblem';
import useWindowSize from '../../hooks/withWindowSize';

const renderAuthedMenu = (activeLink, onClickFnc) => (
	<>
	<p className='nav-bar__active-link'>{activeLink ? activeLink.name : ''}</p>
	<div className='burger-menu' onClick={onClickFnc}>
		<div className='burger-menu__wrapper'>
			<span/>
			<span/>
			<span/>
			<span/>
		</div>
	</div>
	</>
);



const renderMenu = () => (
	<>
		<Link className='unauthed-menu-link' to='/login?login=true'><Button type='quaternary' text='Log in/Register' /></Link>
		<Link className='unauthed-menu-link' to='/rules'><Button type='bgless' text='Rules' /></Link>
	</>
);

const renderMenuItems = (links, activeLink, btnText, onClick, authHandler) => (
	<div className='nav-bar__link-content'>
		<ul className='nav-bar__link-wrapper'>
			{links.map(link => {
				const SvgIcon = link.icon;
				const isActiveItem = activeLink && link.name === activeLink.name;

				return (
					<li key={link.name} className={`nav-bar__link ${isActiveItem ? 'nav-bar__link--active': ''}`} onClick={onClick}>
						<Link to={link.url}>
							<SvgIcon fill='#fff' opacity='.6'/>
							{link.name}
						</Link>
					</li>
				);
			})}
		</ul>
		<div className='nav-bar__auth-wrapper'>
			<Button type='auth' text={btnText} isAuth={true} onClick={authHandler}/>
		</div>
	</div>
);

const MOBILE_MAX_WIDTH = 1024;
const NavBar = ({history, location, links = []}) => {
	const [width] = useWindowSize();
	const [isMobileView, setIsMobileView] = useState(width < MOBILE_MAX_WIDTH);
	const [isOpen, setIsOpen] = useState(false);
	const onBurgerClick = () => setIsOpen(!isOpen)

	useLayoutEffect(() => {
		setIsMobileView(width < MOBILE_MAX_WIDTH);
	}, [width, setIsMobileView]);

	useEffect(() => {
		if(isMobileView) {
			document.body.style.overflow = isOpen ? 'hidden' : '';
		}
	}, [isOpen])
	const navClsName = classNames('nav-bar', {
		'nav-bar--open': isOpen,
		'nav-bar--home': location.pathname === '/'
	});
	const activeLink = links.find(link => link.url === location.pathname);

	const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'
	const authHandler = () => {
		if (isLoggedIn) {
			history.push('/login?login=false');
			setIsOpen(!isOpen);
		} else {
			history.push('/login?login=true');
		}
	};

	const onItemClick = () => setIsOpen(!isOpen);

	const btnText = isLoggedIn ? 'Log out' : 'Login';
	return (
		<nav>
			<div className={navClsName}>
				<div className='nav-bar__heading-wrapper'>
					<Link to={'/'}>
						<h1 className='nav-bar__heading'><Emblem width='14' height='14'/>Shakitz</h1>
					</Link>
					<div className='nav-bar__menu-wrapper'>
						{isLoggedIn && isMobileView && renderAuthedMenu(activeLink, onBurgerClick)}
						{isLoggedIn && !isMobileView && renderMenuItems(links, activeLink, btnText, onItemClick, authHandler)}
						{!isLoggedIn && renderMenu()}
					</div>
				</div>
				{isLoggedIn && isMobileView && renderMenuItems(links, activeLink, btnText, onItemClick, authHandler)}
			</div>
		</nav>
	);
};

export default NavBar;
