import React, {useContext} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Keys from 'shakitz-keys';
import {setAutoDraft} from '../../../actions/setAutoDraft';
import {Constants} from '../../../helpers/Constants';
import TickIcon from '../../svgs/TickIcon';
import './AutoDraft.scss';
import ShakitzContext from '../../../context/ShakitzContext';

const AutoDraft = ({autoDraft, isCommissioner, isPosting, selectedLeague, setAutoDraft, user}) => {
	const shakitzContext = useContext(ShakitzContext);
	const onClick = () => {
		if(isPosting || (!isCommissioner && shakitzContext.userId !== user.userId)) {
			return false;
		}

		const { leagueId } = selectedLeague;
		const draftId = Keys.generateDraft({ leagueId, season: Constants.currentSeason});
		const newAutoDraftStatus = !autoDraft.includes(user.userId);
		setAutoDraft(draftId, leagueId, user.userId, newAutoDraftStatus);
	};

	const autoDraftEnabled = autoDraft.includes(user.userId);
	const clsName = classNames('auto-draft', {
		'auto-draft--enabled': autoDraftEnabled,
		'auto-draft--saving': isPosting
	});

	if(!autoDraftEnabled && !isCommissioner) {
		return null;
	}

	return (
		<button className={clsName} onClick={onClick} title='Toggle autodraft for user'>
			{autoDraftEnabled && <TickIcon fill='#27ae60'/>}
			(A)
		</button>
	);
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		setAutoDraft: (draftId, leagueId, userId, autoDraft) => dispatch(setAutoDraft(draftId, leagueId, userId, autoDraft)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		autoDraft: state.setAutoDraft,
		hasFailed: state.setAutoDraftFailure,
		isPosting: state.setAutoDraftRequest,
		selectedLeague: state.selectedLeague,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoDraft);
