import React from 'react';
import ReactGA from 'react-ga';
import { GA } from '../../../../helpers/ga';
import Button from '../../../Common/Button';
import Header from '../../../Common/Header';
import SpinnerLayer from '../../../SpinnerLayer/SpinnerLayer';
import CloseIcon from '../../../svgs/CloseIcon';
import '../../../YourData/NameEdit/NameEdit.scss';

const TeamNameEdit = ({shortTeamName, teamName,  isPosting, onClose, onSubmit}) => {

	/** TODO: Duplicate CSS in NameEdit, duplicate HTML in leagueJoiner **/
	const submitForm = (evt) => {
		evt.preventDefault();
		ReactGA.event({
			category: GA.CATEGORY.TEAM,
			action: GA.ACTION.EDIT_TEAM_NAME
		});

		onSubmit({
			shortName: evt.target.shortName.value,
			name: evt.target.name.value
		});
	};

	return (
		<div className='name-edit name-edit--team'>
			<div className='name-edit__header'>
				<Header headerLevel={'h2'} text='Change team name' />
				<div className='name-edit__close-container' onClick={onClose}><CloseIcon width={'18px'} height={'18px'}/></div>
			</div>
			<form onSubmit={submitForm}>
				<div className='name-edit__form-input'>
					<label className='name-edit__label' htmlFor='shortTeamEdit'>Short Team Name: </label>
					<input className='name-edit__input' id='short-name-edit' type={'text'} defaultValue={shortTeamName} name={'shortName'} placeholder='Abbreviated name (2-12 characters)' required minLength={2} maxLength={12} autoComplete='off' />
				</div>
				<div className='name-edit__form-input'>
					<label className='name-edit__label' htmlFor='teamEdit'>Team Name: </label>
					<input className='name-edit__input' id='name-edit' type={'text'} defaultValue={teamName} name={'name'} placeholder='Team name (4-40 characters)'  required minLength={4} maxLength={40} autoComplete='off' />
				</div>
				<div className='name-edit__form-submit'>
					<Button text={'Save'} type={'submit'} isSubmit={true} disabled={isPosting} />
				</div>
			</form>
			{isPosting ? <SpinnerLayer/> : null}
		</div>
	);

};

export default TeamNameEdit;
