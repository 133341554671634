import React, {useContext, useEffect, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import ShakitzContext from '../../context/ShakitzContext';
import {fetchRecords} from '../../actions/fetchRecords';
import {fetchLeagueUsers} from '../../actions/fetchLeagueUsers';
import Dropdown from '../../components/Common/Dropdown';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import Record from '../../components/Record';
import RecordMobileGroup from '../../components/svgs/logo/RecordMobileGroup';
import {GA} from '../../helpers/ga';
import Toggle from '../../components/Common/Toggle';
import './Records.scss';

const calculateTotalRecord = (recordData, leagueUsers = []) => {
	const results = {
		highestScore: { score: 0 },
		lowestScore: { score: null },
		highestBenchScore: { score: 0 },
		winStreak: { results: [] },
		winlessStreak: { results: [] },
		highestPlayerScorer: { score: 0, player: {} },
		lowestPlayerScorer: { score: null, player: {} },
		highestBenchPlayerScorer: { score: 0, player: {} }
	};

	Object.keys(recordData).forEach(key => {
		const teamRecord = recordData[key];
		const managerData = {
			managerId: teamRecord.managerId,
			managerName: leagueUsers.find(user => user.userId === teamRecord.managerId)?.name || 'N/A'
		};

		if(teamRecord.highestBenchPlayerScorer.score > results.highestBenchPlayerScorer.score) {
			results.highestBenchPlayerScorer = { ...teamRecord.highestBenchPlayerScorer, ...managerData};
		}

		if(teamRecord.highestPlayerScorer.score > results.highestPlayerScorer.score) {
			results.highestPlayerScorer = { ...teamRecord.highestPlayerScorer, ...managerData};
		}

		if(results.lowestPlayerScorer.score === null || teamRecord.lowestPlayerScorer.score < results.lowestPlayerScorer.score) {
			results.lowestPlayerScorer = { ...teamRecord.lowestPlayerScorer, ...managerData};
		}

		if(teamRecord.highestScore.score > results.highestScore.score) {
			results.highestScore = { ...teamRecord.highestScore, ...managerData};
		}

		if(results.lowestScore.score === null || teamRecord.lowestScore.score < results.lowestScore.score) {
			results.lowestScore = { ...teamRecord.lowestScore, ...managerData};
		}

		if(teamRecord.highestBenchScore.score > results.highestBenchScore.score) {
			results.highestBenchScore = { ...teamRecord.highestBenchScore, ...managerData};
		}

		if(teamRecord.winStreak.length > results.winStreak.results.length) {
			results.winStreak = { results: teamRecord.winStreak, ...managerData };
		}

		if(teamRecord.winlessStreak.length > results.winlessStreak.results.length) {
			results.winlessStreak = { results: teamRecord.winlessStreak, ...managerData };
		}
	});

	return results;
}

const Records = ({isFetching, fetchLeagueUsers, fetchRecords, leagueUsers, players, records, selectedLeague}) => {
	const toggleOptions = ['League Records', 'Personal Records'];
	const allSeasonOpt = {doubleLabel: 'Filter by season', name: 'All'};
	const [leagueToFetch, setLeagueToFetch] = useState(selectedLeague.leagueName);
	const [seasonToShow, setSeasonToShow] = useState(null);
	const [seasonOptions, setSeasonOptions] = useState([allSeasonOpt]);
	const [recordData, setRecordData] = useState({});
	const [activeToggle, setActiveToggle] = useState(toggleOptions[0]);
	const shakitzContext = useContext(ShakitzContext);
	const leagues = [...new Set(shakitzContext.userLeagues.map(league => league.leagueName))];
	const leagueOptions = leagues.map(league => ({
		doubleLabel: 'Filter by league',
		name: league
	}));

	const initialLeague = shakitzContext.userLeagues.find(league => league.leagueId === selectedLeague.leagueId) || {};

	const onLeagueToggle = (toggledLeague) => {
		const league = shakitzContext.userLeagues.find(league => league.leagueName === toggledLeague?.name);
		if(league && league.leagueId) {
			setLeagueToFetch(league.leagueId);
		}
	};

	const onSeasonToggle = (seasonOpt) => {
		if(seasonOpt && seasonOpt.name) {
			setSeasonToShow(seasonOpt.name);
		}
	};

	const onToggleClick = (evt) => {
		ReactGA.event({
			category: GA.CATEGORY.RECORDS,
			action: GA.ACTION.TOGGLE_RECORD
		});
		const toggleToSelect = toggleOptions.find(option => option === evt.target.innerText) || activeToggle;
		setActiveToggle(toggleToSelect);
	};

	// Fetch league id on load + toggle
	useEffect(() => {
		const leagueId = leagueToFetch || initialLeague.leagueId;
		if(leagueId) {
			fetchLeagueUsers(leagueId);
			fetchRecords(leagueId);
		}
	}, [leagueToFetch, initialLeague, fetchLeagueUsers, fetchRecords]);

	// Populate season dropdown after fetch
	useEffect(() => {
		const years = Object.keys(records).sort((a, b) => Number(b) - Number(a));
		const opts = [allSeasonOpt].concat(years.map(year => ({ doubleLabel: allSeasonOpt.doubleLabel, name: year })));
		setSeasonOptions(opts);
	}, [records, setSeasonOptions]);


	// Get the relevant record data for season selected
	useEffect(() => {
		const allData = Object.keys(records).reduce((prev, currKey) => ({ ...prev, ...records[currKey]}), {});
		const isPersonal = activeToggle.toLowerCase().includes('personal');

		if (seasonToShow === allSeasonOpt.name || seasonToShow === null) {
			if(isPersonal) {
				const userOnlyData = Object.keys(allData).filter(key => key.includes(shakitzContext.userId)).map(key => allData[key]);
				setRecordData(userOnlyData);
			} else {
				setRecordData(allData);
			}
		} else if (seasonToShow) {
			if(isPersonal) {
				const userOnlyData = Object.keys(records[seasonToShow]).filter(key => key.includes(shakitzContext.userId)).map(key => records[seasonToShow][key]);
				setRecordData(userOnlyData);
			} else {
				setRecordData(records[seasonToShow]);
			}
		}

	}, [records, setRecordData, seasonToShow, activeToggle ])


	const calculatedRecords = calculateTotalRecord(recordData, leagueUsers);
	return (
		<div className='records'>
			<Helmet>
				<title>Shakitz - Records</title>
				<link rel="canonical" href="https://shakitz.com/records" />
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<div className='records__heading'>
				<h1 className='records__header'>Records</h1>
				<div className='records__svg'>
					{window.innerWidth <= 768 && <RecordMobileGroup />}
				</div>
				<Toggle activeOption={activeToggle} onToggleClick={onToggleClick} toggleOptions={toggleOptions} type='no-box' />
			</div>
			<div className='records__content'>

				<div className='records__options'>
					<Dropdown isDoubleLabel={true} initialOption={initialLeague.leagueName} options={leagueOptions} onSelect={onLeagueToggle} />
					<Dropdown isDoubleLabel={true} initialOption={allSeasonOpt.name} options={seasonOptions} onSelect={onSeasonToggle} />
				</div>

				{Object.keys(records).length === 0 ? (
					<div className='records__no-data'>
						There are currently no records available for this league's season.
					</div>
					) : (
						<div className='records__list'>
							{Object.keys(calculatedRecords).map(record => <Record key={record} isPersonal={activeToggle.toLowerCase().includes('personal')} name={record} data={calculatedRecords[record]} players={players} />)}
						</div>
					)
				}
			</div>
			{isFetching && <SpinnerLayer />}
		</div>
	);
};
const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchLeagueUsers: (leagueId) => dispatch(fetchLeagueUsers(leagueId)),
		fetchRecords: (leagueId) => dispatch(fetchRecords(leagueId))
	};
};

const mapStateToProps = ( state ) => {
	return {
		isFetching: state.leagueUsersRequest || state.recordsRequest,
		leagueUsers: state.leagueUsers,
		players: state.players,
		records: state.records,
		selectedLeague: state.selectedLeague
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Records);
