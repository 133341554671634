import { FETCH_SCORES_FAILURE, FETCH_SCORES_REQUEST, FETCH_SCORES_SUCCESS} from '../actions/fetchScores';

export function scoresFailure(state = false, action) {
	switch (action.type) {
		case FETCH_SCORES_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function scoresRequest(state = false, action) {
	switch (action.type) {
		case FETCH_SCORES_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function scores(state = [], action) {
	switch (action.type) {
		case FETCH_SCORES_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
