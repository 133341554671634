import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const POST_START_SEASON_REQUEST = 'POST_START_SEASON_REQUEST';
export const POST_START_SEASON_SUCCESS = 'POST_START_SEASON_SUCCESS';
export const POST_START_SEASON_FAILURE = 'POST_START_SEASON_FAILURE';

export function startSeasonRequest ( isPosting ) {
	return {
		type: POST_START_SEASON_REQUEST,
		isPosting
	};
}

export function startSeasonFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_START_SEASON_FAILURE,
		response: err,
		error: true
	};
}

export function startSeasonSuccess( data ) {
	return {
		type: POST_START_SEASON_SUCCESS,
		data
	};
}

export function startSeason ( leagueId, season ) {
	return ( dispatch ) => {
		dispatch(startSeasonRequest(true));

		const url = `${Constants.urls.LEAGUE}/start-season`;
		axios.put(url, { leagueId, season} )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(startSeasonRequest(false));

			return response.data;
		}).then(data => dispatch(startSeasonSuccess(data)))
		.catch(err => dispatch(startSeasonFailure(err)));
	};
}
