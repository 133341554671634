import { SET_GAME_WEEK_TEAM_FAILURE, SET_GAME_WEEK_TEAM_REQUEST, SET_GAME_WEEK_TEAM_SUCCESS} from '../actions/setGameWeekTeam';

export function setGameWeekTeamFailure(state = false, action) {
	switch (action.type) {
		case SET_GAME_WEEK_TEAM_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function setGameWeekTeamRequest(state = false, action) {
	switch (action.type) {
		case SET_GAME_WEEK_TEAM_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function setGameWeekTeam(state = {}, action) {
	switch (action.type) {
		case SET_GAME_WEEK_TEAM_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
