import React from 'react';
import classNames from 'classnames';
import PlayerTeamPosInfo from '../Common/Players/PlayerTeamPosInfo';
import getPlayerShortName from '../../helpers/getPlayerShortName';
import './StatPlayerCard.scss';

const StatPlayerCard = ({player, dataSet, rank, disableOwned, userId}) => {
	const clsName = classNames('stat-player-card', {
		'stat-player-card--disabled': player.alreadyOwned && disableOwned
	});

	return (
		<tr className={clsName} data-player-id={player.playerId}>
			<td className='stat-player-card__player-rank stat-table__fixed-column'><span>{rank}</span></td>
			<td className='stat-table__fixed-column stat-table__fixed-column--secondary'>
				<div className='stat-player-card__player-info'>
					<span className='stat-player-card__player-name'>{getPlayerShortName(player)}</span>
					<PlayerTeamPosInfo position={player.pos} team={player.nflTeam} />
				</div>
			</td>
			{dataSet.map(data => {
				let value = player.stats[data.key];
				if(Array.isArray(value)) {
					value = value.reduce((prev, curr) => prev + curr, 0);
				}

				if(data.hasOwnProperty('secondaryKey')) {
					value = player[data.key][data.secondaryKey];
				}
				return <td key={data.key} className='stat-player-card__player-stat stat-table__data-point'>{value || 0}</td>
			})}
		</tr>
	);
};

export default StatPlayerCard;
