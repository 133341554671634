import getNextDraftUser from './getNextDraftUser';

const mergeClockData = (savedDraft, clockData, users = [], currentPick) => {
	if(Object.keys(savedDraft).length === 0 || !savedDraft.draft.clock) {
		return clockData;
	}

	const savedClockData = savedDraft.draft && savedDraft.draft.clock ? savedDraft.draft.clock : {};
	const mergedData = {...savedClockData, ...clockData };

	const user = users.find(user => user.userId === mergedData.user.userId ||  user.userId === mergedData.user) || {};
	mergedData.user = {...user, ...mergedData.user };

	const priority = savedDraft.draft.priority || mergedData.priority;
	const pick = !isNaN(currentPick ) ? currentPick : mergedData.draftPick;

	if(!mergedData.next) {
		mergedData.next = getNextDraftUser(mergedData.user, priority, pick);
	}

	if(!mergedData.next) {
		mergedData.next = { userId: 'N/A', userName: 'N/A'};
		return mergedData;
	}

	const nextUser = users.find(user => user.userId === mergedData.next.userId) || {};
	mergedData.next = { ...nextUser, ...mergedData.next };

	return mergedData;
};

export default mergeClockData;
