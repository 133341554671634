import { FETCH_PERFECT_TEAM_FAILURE, FETCH_PERFECT_TEAM_REQUEST, FETCH_PERFECT_TEAM_SUCCESS} from '../actions/fetchPerfectTeam';

export function perfectTeamFailure(state = false, action) {
	switch (action.type) {
		case FETCH_PERFECT_TEAM_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function  perfectTeamRequest(state = false, action) {
	switch (action.type) {
		case FETCH_PERFECT_TEAM_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function  perfectTeam(state = null, action) {
	switch (action.type) {
		case FETCH_PERFECT_TEAM_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
