import React, {Component} from 'react';
import Header from '../../components/Common/Header';
import Standings from '../../components/Standings/Standings';
import DownArrow from '../../components/svgs/DownArrow';
import { GA } from '../../helpers/ga';
import calculatePlayersPlayed from '../../helpers/calculatePlayersPlayed';

import './LeagueStandings.scss';

class LeagueStandings extends Component {

	render () {
		const gwMappedData = this.props.standings.map((entry, index) => {
			const team = this.props.seasonUserTeams.find(t => t.hashedUser === entry.managerId) || {};
			entry.onClick = (() => this.props.onUserClick(team.seasonUserTeamId, GA.CATEGORY.STANDINGS));
			if(this.props.historicStandings.standings) {
				const lastPosition = this.props.historicStandings.standings.findIndex(team => team.managerId === entry.managerId);
				entry.lastPosition = lastPosition + 1;
				const posChange = lastPosition - index;
				entry.rank = index + 1;
				entry.rankChange = `(${Math.abs(posChange)})`;

				if(posChange === 0) {
					delete entry.rankChange;
				} else if (posChange > 0) {
					entry.rankIcon = <DownArrow height='6px' width='9px' fill='#1da060' style={{transform: 'rotate(180deg)'}}/>;
				} else {
					entry.rankIcon = <DownArrow height='6px' width='9px' fill='#ff0303'/>;
				}
			} else {
				entry.rank = entry.rank || (entry.gwPoints > 0 ? index + 1 : '-')
			}

			if(this.props.selectedLeague && this.props.games && this.props.currentGameWeek && team && this.props.gameWeekTeams && this.props.gameWeekTeams.length > 0) {
				entry.playersPlayed = calculatePlayersPlayed(this.props.games, this.props.currentGameWeek, this.props.gameWeekTeams, this.props.players, team, this.props.selectedLeague);
			}

			return entry;
		});

		return (
			<div className={'league-standings'}>
				<div className='league-standings__header'>
					<Header headerLevel={'h1'} text={'League Standings'} />
				</div>
				<div className='league-standings__table'>
					<Standings data={gwMappedData} gwState={this.props.gwState} />
				</div>
			</div>
		);
	}
}

export default LeagueStandings;
