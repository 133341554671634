import React, {Component} from 'react';
import classNames from 'classnames';
import Header from '../../components/Common/Header';
import DraftPlayerCard from '../../components/DraftRoom/DraftPlayerCard';

import './DraftBoard.scss';
import AutoDraft from '../../components/DraftRoom/AutoDraft';

class DraftBoard extends Component {
	static defaultProps = {
		leagueUsers: []
	};

	renderRound(roundData, round) {
		const emptyBoxesToCreate = new Array(this.props.leagueUsers.length - roundData.length).fill({});
		const isEvenRound = round % 2 === 0;
		const clsName = classNames('draft-board__round', {
			'draft-board__round--even': isEvenRound,
			'draft-board__round--picking': emptyBoxesToCreate.length > 0
		});

		return (
			<div key={`round-set-${round}`} className={clsName}>
				<div className='draft-board__round-number'>R{round}</div>
				<div className='draft-board__round-picks'>
					{roundData.map(player => <DraftPlayerCard key={player.playerId} manager={this.getManager(player)} player={player} type={'historic'} />)}
					{emptyBoxesToCreate.map((val, index) => <DraftPlayerCard key={`round_${round}_empty-box_${index}`} isNext={index === 0} type={'empty'} />)}
				</div>
			</div>
		);
	}

	getManager(player) {
		if(!player.userId) {
			return '';
		}

		const leagueUsers = this.props.savedDraft.draft.priority || [];
		return leagueUsers.find(user => user.userId === player.userId);
	}

	handleDuplicateIssue(data) {
		const duplicateMap = {};
		data.reduce((prev, curr) => prev.concat(curr), []).forEach(player => {
			if(!duplicateMap.hasOwnProperty(player.playerId)) {
				duplicateMap[player.playerId] = true;
			} else {
				if(window.confirm('The draft room has duplicate picks, please get the commissioner to edit this.')) {
					// Do nothing
				}
			}
		});
	}

	renderBoard(draftData) {
		const data = draftData.length > 0 ? draftData : [[]];
		const leagueUsers = this.props.savedDraft.draft.priority || [];

		this.handleDuplicateIssue(data);

		return (
			<>
				<div className='draft-board__header'>
					{leagueUsers.map(user => {
						return <div className='draft-board__user-header' key={user.userId}>{user.userName}<AutoDraft isCommissioner={this.props.isCommissioner} user={user} /></div>
					})}
				</div>
				<div className='draft-board__rounds'>
				{leagueUsers.length > 0 ? data.map((round, index) => this.renderRound(round, index+1)) : null}
				</div>
			</>
		);
	}
	renderExplainer() {
		return (
			<div className='draft-board__explainer'>
				<Header headerLevel={'h3'} text={'When your commissioner starts the draft they will be able to set draft priority and the draft will begin. This action cannot be undone. Draft priority will snake.'} />
			</div>
		)
	}

	render () {
		return (
			<div className='draft-board'>
				{this.props.hasDraftBegun ? this.renderBoard(this.props.draftData) : this.renderExplainer()}
			</div>
		);
	}
}

export default DraftBoard;
