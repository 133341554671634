import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const POST_PAUSE_DRAFT_REQUEST = 'POST_PAUSE_DRAFT_REQUEST';
export const POST_PAUSE_DRAFT_SUCCESS = 'POST_PAUSE_DRAFT_SUCCESS';
export const POST_PAUSE_DRAFT_FAILURE = 'POST_PAUSE_DRAFT_FAILURE';

export function pauseDraftRequest ( isPosting ) {
	return {
		type: POST_PAUSE_DRAFT_REQUEST,
		isPosting
	};
}

export function pauseDraftFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_PAUSE_DRAFT_FAILURE,
		response: err,
		error: true
	};
}

export function pauseDraftSuccess( data ) {
	return {
		type: POST_PAUSE_DRAFT_SUCCESS,
		data
	};
}

export function pauseDraft ( draftId, data ) {
	return ( dispatch ) => {
		dispatch(pauseDraftRequest(true));

		const url = `${Constants.urls.DRAFT}/pause/${draftId}`;
		axios.post(url, data)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(pauseDraftRequest(false));

			return response.data;
		}).then(data => dispatch(pauseDraftSuccess(data)))
		.catch(err => dispatch(pauseDraftFailure(err)));
	};
}
