import React, { useState } from 'react';
import ReactGA from 'react-ga';
import Toggle from '../../Common/Toggle';
import {GA} from '../../../helpers/ga';
import H2HPlayerCard from '../H2HPlayerCard/H2HPlayerCard';
import './H2HRoster.scss';
import hasPlayerPlayed from '../../../helpers/hasPlayerPlayed';

const renderH2HPlayer = (player) => <H2HPlayerCard key={player.playerId} player={player} />;

const positionSortOrder = {
	'QB': 0,
	'RB': 1,
	'WR': 2,
	'TE': 3,
	'K': 4,
	'D': 5
};

const H2HRoster = ({homeRoster, awayRoster, games, players}) => {
	const toggleOptions = ['Active Roster', 'Bench'];
	const [activeToggle, setActiveToggle] = useState(toggleOptions[0]);

	if(!homeRoster || !awayRoster) {
		return null;
	}

	const onToggleClick = (evt) => {
		ReactGA.event({
			category: GA.CATEGORY.H2H,
			action: GA.ACTION.TOGGLE_TEAM_ROSTER
		});
		const toggleToSelect = toggleOptions.find(option => option === evt.target.innerText) || activeToggle;
		setActiveToggle(toggleToSelect);
	};

	const transformPlayers = (team, activeToggle) => {
		const playersToShow = activeToggle === toggleOptions[0] ? team.starters : team.bench;

		return playersToShow.map(h2hPlayer => {
			const player = players.find(p => p.statId === h2hPlayer.playerId || p.playerId === h2hPlayer.playerId);
			const isOrHasPlayed = hasPlayerPlayed(h2hPlayer, players, games);

			return {
				...h2hPlayer,
				...player,
				isOrHasPlayed
			};
		}).sort((a, b) => positionSortOrder[a.pos] - positionSortOrder[b.pos]);
	};

	const homePlayers = transformPlayers(homeRoster, activeToggle);
	const awayPlayers = transformPlayers(awayRoster, activeToggle);

	return (
		<div className='h2h-roster'>
			<div className='h2h-roster__toggle'>
				<Toggle onToggleClick={onToggleClick} activeOption={activeToggle} toggleOptions={toggleOptions} type='userTeam' />
			</div>
			<div className='h2h-roster__wrapper'>
				<div className='h2h-roster__players h2h-roster__players--home'>
					{homePlayers.map(renderH2HPlayer)}
				</div>
				<div className='h2h-roster__players h2h-roster__players--away'>
					{awayPlayers.map(renderH2HPlayer)}
				</div>
			</div>
		</div>
	)
};

export default H2HRoster;
