import React from 'react';

const Shape1 = ({fill = '#A838FF'}) => (
	<svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.337368 60.3373C0.151045 27.3866 26.7119 0.523638 59.6626 0.337314C92.6134 0.150991 119.476 26.7118 119.663 59.6626L120 119.325L60.3374 119.663C27.3866 119.849 0.523692 93.2881 0.337368 60.3373Z" fill={fill}/>
	</svg>
);

export default Shape1;

