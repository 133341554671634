import { POST_START_DRAFT_FAILURE, POST_START_DRAFT_REQUEST, POST_START_DRAFT_SUCCESS} from '../actions/startDraft';

export function startDraftFailure(state = false, action) {
	switch (action.type) {
		case POST_START_DRAFT_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function startDraftRequest(state = false, action) {
	switch (action.type) {
		case POST_START_DRAFT_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function startDraft(state = false, action) {
	switch (action.type) {
		case POST_START_DRAFT_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
