import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const POST_START_DRAFT_REQUEST = 'POST_START_DRAFT_REQUEST';
export const POST_START_DRAFT_SUCCESS = 'POST_START_DRAFT_SUCCESS';
export const POST_START_DRAFT_FAILURE = 'POST_START_DRAFT_FAILURE';

export function startDraftRequest ( isPosting ) {
	return {
		type: POST_START_DRAFT_REQUEST,
		isPosting
	};
}

export function startDraftFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_START_DRAFT_FAILURE,
		response: err,
		error: true
	};
}

export function startDraftSuccess( data ) {
	return {
		type: POST_START_DRAFT_SUCCESS,
		data
	};
}

export function startDraft ( draftId, leagueId, userPriority ) {
	return ( dispatch ) => {
		dispatch(startDraftRequest(true));

		const url = `${Constants.urls.DRAFT}/start/${draftId}`;
		axios.post(url, { leagueId, userPriority} )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(startDraftRequest(false));

			return response.data;
		}).then(data => dispatch(startDraftSuccess(data)))
		.catch(err => dispatch(startDraftFailure(err)));
	};
}
