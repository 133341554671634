const hasSeasonStarted = ({season, seasonToBe}) => {
	const seasonAsNumber = Number(season);
	const seasonToBeAsNumber = Number(seasonToBe);

	if(Number.isNaN(seasonAsNumber) || Number.isNaN(seasonToBeAsNumber)) {
		console.error('Unable to parse current season: ', seasonAsNumber, seasonToBeAsNumber);
		return false;
	}

	return seasonAsNumber === seasonToBeAsNumber;
};

export default hasSeasonStarted;
