import React from 'react';

const RosterChangeIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" fill="none" viewBox="0 0 63 65">
		<path fill="#C9D0D8" d="M38.5 12.59L21.86 9.812l3.064 5.307-.03.017c-3.24 2.027-5.886 4.199-7.852 6.537-2.217 2.618-3.734 5.313-4.569 8.085-.659 2.194-.904 4.401-.764 6.621.035.585.08 1.166.17 1.75.434 2.807 1.248 5.657 2.91 8.113.408.606 1.848 2.29 2.254 2.057 0 0-1.022-2.937-.72-7.15.096-1.29.322-2.585.702-3.88l.14.024-.097-.168c.24-.797.523-1.59.908-2.383 1.039-2.142 2.599-4.268 4.678-6.373 1.704-1.722 3.825-3.376 6.307-4.968l.562-.325 3.061 5.311 5.915-15.797z"/>
		<path fill="#DADFE3" d="M24.954 51.996l16.64 2.778-3.063-5.305.032-.018c3.238-2.026 5.883-4.197 7.852-6.538 2.217-2.617 3.734-5.312 4.569-8.084.66-2.195.904-4.401.766-6.62-.037-.586-.08-1.165-.17-1.748-.435-2.808-1.25-5.66-2.91-8.114-.41-.608-1.847-2.292-2.254-2.057 0 0 1.019 2.938.717 7.152-.097 1.287-.322 2.584-.7 3.877l-.14-.023.096.166c-.242.798-.526 1.591-.91 2.384-1.039 2.142-2.599 4.269-4.678 6.373-1.704 1.722-3.824 3.378-6.307 4.968l-.562.325L30.87 36.2l-5.917 15.795z"/>
	</svg>
);

export default RosterChangeIcon;
