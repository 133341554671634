import React from 'react';

const Shape4 = ({fill = '#14C4FB'}) => (
	<svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.300781 0.720827L59.8672 0.284094C92.7648 0.0428932 119.628 26.7095 119.867 59.8457L120.301 119.844L60.7344 120.281C27.8368 120.522 0.973834 93.8554 0.73437 60.7192L0.300781 0.720827Z" fill={fill} />
	</svg>
);

export default Shape4;

