import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import classNames from 'classnames';
import { fetchDraftData } from '../../actions/draftData';
import { fetchSeasonUserTeam, fetchSeasonUserTeamSuccess } from '../../actions/seasonUserTeam';
import { setRefreshCoreData } from '../../actions/refreshCoreData';
import { selectLeague } from '../../actions/selectLeague';
import ShakitzContext from '../../context/ShakitzContext';
import Button from '../../components/Common/Button';
import ErrorIcon from '../../components/svgs/ErrorIcon';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import hasDraftStarted from '../../helpers/hasDraftStarted';
import isDraftLive from '../../helpers/isDraftLive';
import {activeGameWeekTeam} from '../../actions/activeGameWeekTeam';
import YourData from '../../components/YourData/YourData';
import UserContext from '../../context/UserContext';
import './LeagueViewer.scss';
import {Constants} from '../../helpers/Constants';

const _renderLeagueActions = () => (
	<>
		<div className='league-viewer__actions'>
			<Link className='league-viewer__action' to={'/league/create'}><Button disabled text='Create League - Come back Pre/Reg Season 2021!' type='quaternary'/></Link>
			<Link className='league-viewer__action' to={'/league/join'}><Button text='Join League' type='tertiary' /></Link>
		</div>
	</>
);

const _renderYourLeagues = (getData, userLeagues) => (
	<div className='league-viewer__your-leagues'>
		{userLeagues.length === 0 ?
			<div className='league-viewer__no-leagues-message'>
				<ErrorIcon fill={'#000'} opacity={1} />
				<p>You are currently not part of any leagues</p>
			</div> :
			<div className='league-viewer__leagues-list'>
				{userLeagues.filter(league => Number(league.seasonToBe) === Constants.currentSeason).map(league => {
					const { leagueId, leagueName, season, seasonToBe, leagueType } = league;
					const typeText = leagueType === 'PRE' ? ' Pre Season' : 'Regular';
					//We will want to figure out what to show here and whether we need to do a request to get proper data for each
					return <div className='league-viewer__league' key={leagueId} onClick={() => getData(league)}>
						<div className='league-viewer__league-name'>{leagueName}</div>
						<div className='league-viewer__league-meta'>
							<span className='league-viewer__league-type'>{typeText}</span>
							<span className='league-viewer__league-season'> {seasonToBe || season}</span>
						</div>
					</div>
				})}
			</div>
		}
	</div>
);

const _renderSpinnerContainer = (isFetching) => {
	if (isFetching) {
		return <SpinnerLayer />
	}

	return null;
};

const LeagueViewer = ({history, clearSeasonUserTeams, draftData, fetchData, fetchDraftData, isFetching, seasonUserTeams, selectLeague, selectedLeague, setActiveGameWeekTeam, setRefreshCoreData, shouldRefresh }) => {
	const userContext = useContext(UserContext);
	const shakitzContext = useContext(ShakitzContext);
	const [ hasInteracted, setHasInteracted ] = useState(false);
	const fetchSeasonUserTeams = (leagueId, season, seasonToBe, type, format) => {
		if(leagueId !== selectedLeague.leagueId) {
			clearSeasonUserTeams();
		}

		selectLeague(format, leagueId, season, seasonToBe, type);
		fetchData(leagueId, season);
	};

	const getData = (league) => {
		const { formatType, leagueId, season, seasonToBe, leagueType } = league;

		if(!formatType || !leagueId || !seasonToBe || !leagueType) {
			return;
		}

		setRefreshCoreData(true);
		setActiveGameWeekTeam(activeGameWeekTeam || {});
		fetchDraftData(leagueId, seasonToBe);
		fetchSeasonUserTeams(leagueId, season || seasonToBe, seasonToBe , leagueType, formatType);
		setHasInteracted(true);
	};

	useEffect(() => {
		const startDate = draftData.draft ? draftData.draft.draftStartDate : null;

		if(!startDate || !hasInteracted) {
			return;
		}

		// User has no team data or has not yet interacted, so bail early
		if(seasonUserTeams.length === 0 || !hasInteracted) {
			return;
		}

		if(!hasDraftStarted(draftData.draft.draftStartDate) || isDraftLive(draftData.draft.clock)) {
			history.push('/draft-room');
		} else {
			history.push('/team');
		}
	}, [draftData.draft, history, seasonUserTeams, hasInteracted]);

	const clsName = classNames('league-viewer', {
		'league-viewer--no-leagues': shakitzContext.userLeagues.length === 0,
		'league-viewer--fetching': isFetching
	});

	return (
		<div className={clsName}>
			<YourData userObj={userContext.userObj} shakitzUser={shakitzContext} />
			<div className='league-viewer__container'>
				{_renderYourLeagues(getData, shakitzContext.userLeagues)}
				{_renderLeagueActions()}
			</div>
			<div className='league-viewer__spinner-container'>
				{_renderSpinnerContainer(isFetching)}
			</div>
		</div>
	);

};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchData: (leagueId, season) => dispatch(fetchSeasonUserTeam(leagueId, season)),
		fetchDraftData: (leagueId, season) => dispatch(fetchDraftData(leagueId, season)),
		clearSeasonUserTeams: () => dispatch(fetchSeasonUserTeamSuccess([])),
		setRefreshCoreData: (shouldRefresh) => dispatch(setRefreshCoreData(shouldRefresh)),
		selectLeague: (format, leagueId, season, seasonToBe, type) => dispatch(selectLeague(format, leagueId, season, seasonToBe, type)),
		setActiveGameWeekTeam: (gameWeekTeam) => dispatch(activeGameWeekTeam(gameWeekTeam)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		draftData: state.draftData,
		seasonUserTeams: state.seasonUserTeams,
		selectedLeague: state.selectedLeague,
		shouldRefresh: state.refreshCoreData,
		isFetching: state.seasonUserTeamRequest || state.draftDataRequest,
		hasErrored: state.seasonUserTeamFailure
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeagueViewer));
