export const GA = {
	CATEGORY: {
		DRAFT: 'draft',
		H2H: 'h2h',
		LEAGUE_CREATION: 'league creation',
		PLAYER: 'player',
		RECORDS: 'records',
		STANDINGS: 'standings',
		TEAM: 'team',
		TRADE: 'trade',
		USER: 'user',
		WAIVER: 'waiver'
	},
	ACTION: {
		CANCEL_TRADE: 'cancel proposed trade',
		CANCEL_WAIVER: 'cancel proposed waiver',
		DRAFT_VIA_CARD: 'draft via draft player card',
		DRAFT_VIA_MODAL: 'draft via modal',
		EDIT_TEAM_NAME: 'edit team name',
		EDIT_USER_NAME: 'edit user name submitted',
		PAGE_TOGGLE: 'player page toggle',
		PAUSE_DRAFT: 'draft pause toggled',
		POSITION_TOGGLE: 'player position toggle',
		REARRANGE_WAIVER: 're-arrange waiver',
		REQUEST_TIME: 'request more time',
		SELECT_PLAYER_TO_WAIVER_OUT: 'select player out for waiver',
		SUBMIT_CREATE_LEAGUE: 'submit create league form 1',
		SUBMIT_CREATE_LEAGUE_FINAL: 'submit create league form 2',
		SUBMIT_TRADE: 'submit trade',
		SUBMIT_WAIVER: 'submit waiver',
		SUB_PLAYER: 'sub player',
		TEAM_CLICK: 'team click',
		TOGGLE_DRAFT_PANEL: 'toggled draft/player pool panel',
		TOGGLE_H2H_GAME_WEEK: 'toggled h2h game week',
		TOGGLE_PLAYER_STAT_IN_MODAL: 'toggled gameweek/season stat',
		TOGGLE_RECORD: 'toggled league/personal record',
		TOGGLE_TEAM_GAME_WEEK: 'toggled team game week',
		TOGGLE_TEAM_ROSTER: 'toggled active/bench on roster',
		TRADE_CONFIRMED: 'trade confirmed',
		TRADE_DELETED: 'trade deleted',
		TRADE_PLAYER: 'trade player',
		TRADE_REJECTED: 'trade rejected',
		VIEW_CREATE_LEAGUE: 'view create league',
		WAIVER_HISTORY_GW_TOGGLE: 'waiver history gw toggle',
		WAIVER_HIDE_PLAYERS_TOGGLE: 'waiver hide unavailable players',
		WAIVER_PANE_TOGGLE: {
			0: 'create waiver pane',
			1: 'proposed waiver pane',
			2: 'history waiver pane'
		}
	}
};
