import React from 'react';
import './Footer.scss';

const Footer = () => (
	<div className='footer'>
		Copyright © 2020 Shakitz.com
	</div>
);

export default Footer;
