const mergeDraftData = (numOfRounds, numOfUsers, draft, savedDraft) => {
	const roundList = [];

	for(let i = 1; i <= numOfRounds; i++) {
		const key = `R${i}`;

		const round = draft[key];
		const savedRound = savedDraft.draft ? savedDraft.draft[key] || [] : [];

		const mergedRound = round.concat(savedRound.filter(player => {
			return !round.find(p => p.playerId === player.playerId);
		})).sort((a, b) => a.pick - b.pick);

		if(!mergedRound || mergedRound.length === 0) {
			const isPrevRoundFull = roundList.length > 0 ? roundList[roundList.length-1].length === numOfUsers : false;

			// Ensures we will get a new round block appear
			if(isPrevRoundFull) {
				roundList.push(round);
			}
			break;
		}

		roundList.push(mergedRound);
	}

	return roundList;
};

export default mergeDraftData;
