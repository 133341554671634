import { FETCH_SEASON_PLAYER_STATS_FAILURE, FETCH_SEASON_PLAYER_STATS_REQUEST, FETCH_SEASON_PLAYER_STATS_SUCCESS} from '../actions/fetchSeasonPlayerStats';

export function seasonPlayerStatsFailure(state = false, action) {
	switch (action.type) {
		case FETCH_SEASON_PLAYER_STATS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function  seasonPlayerStatsRequest(state = false, action) {
	switch (action.type) {
		case FETCH_SEASON_PLAYER_STATS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function  seasonPlayerStats(state = [], action) {
	switch (action.type) {
		case FETCH_SEASON_PLAYER_STATS_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
