import React from 'react';

import TableHead from './TableHead';
import TableBody from './TableBody';

const Table = ({canClick, clsName, columnData, data, rowData}) => (
	<table className={`table ${clsName}`}>
		<TableHead columns={columnData} clsName={clsName} />
		<TableBody rowData={rowData} data={data} clsName={clsName} />
	</table>
);

export default Table;

