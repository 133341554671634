import React from 'react';
import './Toggle.scss';

const Toggle = ({onToggleClick, activeOption, toggleOptions, type = null}) => (
	<div className={`toggle ${type ? `toggle--${type}` : ''}`} onClick={onToggleClick}>
		{toggleOptions.map(option => {
			return (
				<div key={`toggle-${option}`}
				     className={`toggle__option ${option === activeOption ? 'toggle__option--active' : ''}`}
				     data-option={option}>
					<span>{option}</span>
				</div>
			);
		})}
	</div>
);

export default Toggle;
