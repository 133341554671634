import React, {Component} from 'react';
import ReactGA from 'react-ga';
import { GA } from '../../helpers/ga';
import UserMyTeamGWScoreBoard from '../../components/UserTeam/UserMyTeamGWScoreBoard';
import GreyWrapper from '../../components/Common/GreyWrapper';
import Roster from '../../components/Roster/Roster';
import Toggle from '../../components/Common/Toggle';

class UserGameWeek extends Component {
	constructor(props) {
		super(props);
		this.toggleOptions = ['Active Roster', 'Bench'];
		this.state = {
			activeToggleOption: this.toggleOptions[0],
			boards: [{
				description: 'GameWeek Points:',
				isFocalBoard: true,
				points: this.roundToDecimals(this.props.team.currentGameWeekPoints)
			}, {
				description: 'Bench Points:',
				points: this.roundToDecimals(this.props.team.currentBenchPoints)
			}, {
				description: 'Avg GW Points:',
				points: this.props.avgGWPoints || '-'
			}]
		};
		this.onToggleClick = this.onToggleClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		const shouldUpdate = (prevProps.team.currentGameWeekPoints !== this.props.team.currentGameWeekPoints) || (prevProps.team.currentBenchPoints !== this.props.team.currentBenchPoints) || (prevProps.avgGwPoints !== this.props.avgGwPoints);

		if(shouldUpdate) {
			this.setState({
				boards: [{
					description: 'GameWeek Points:',
					isFocalBoard: true,
					points: this.roundToDecimals(this.props.team.currentGameWeekPoints)
				}, {
					description: 'Bench Points:',
					points: this.roundToDecimals(this.props.team.currentBenchPoints)
				}, {
					description: 'Avg GW Points:',
					points: this.props.avgGWPoints || '-'
				}]
			})
		}
	}

	roundToDecimals (num = 0) {
		return Math.round(num * 100) / 100;
	}

	onToggleClick(evt) {
		ReactGA.event({
			category: GA.CATEGORY.TEAM,
			action: GA.ACTION.TOGGLE_TEAM_ROSTER
		});
		this.setState({
			activeToggleOption: this.toggleOptions.find(option => option === evt.target.innerText) || this.state.activeToggleOption
		});
	}

	render () {
		const { activeToggleOption } = this.state;
		const playersToggled = activeToggleOption === this.toggleOptions[0] ? this.props.team.starters : this.props.team.bench;

		return (
			<>
				<GreyWrapper>
					{this.props.showPoints ? <UserMyTeamGWScoreBoard scoreBoards={this.state.boards} /> : null}
				</GreyWrapper>
				<Toggle onToggleClick={this.onToggleClick} activeOption={activeToggleOption} toggleOptions={this.toggleOptions} type='userTeam' />
				<Roster canEdit={this.props.canEdit} gameWeek={this.props.currentGameWeek} heading={activeToggleOption} players={playersToggled} />
			</>
		);
	}
}

export default UserGameWeek;
