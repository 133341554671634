import { FETCH_WAIVERS_FAILURE, FETCH_WAIVERS_REQUEST, FETCH_WAIVERS_SUCCESS} from '../actions/fetchWaivers';

export function waiversFailure(state = false, action) {
	switch (action.type) {
		case FETCH_WAIVERS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function waiversRequest(state = false, action) {
	switch (action.type) {
		case FETCH_WAIVERS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function waivers(state = {}, action) {
	switch (action.type) {
		case FETCH_WAIVERS_SUCCESS:
			const clonedState = JSON.parse(JSON.stringify(state));
			clonedState[action.leagueId] = action.data;
			return clonedState;
		default:
			return state;
	}
}
