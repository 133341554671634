import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Button from '../../Common/Button';
import CloseIcon from '../../svgs/CloseIcon';
import GreyWrapper from '../../Common/GreyWrapper';
import Header from '../../Common/Header';
import PlayerTeamPosInfo from '../../Common/Players/PlayerTeamPosInfo';
import PlayerStat from '../../Common/Players/PlayerStat';
import SpinnerLayer from '../../SpinnerLayer/SpinnerLayer';
import { fetchHistoricPlayerStats } from '../../../actions/historicPlayerStats';
import { Constants } from '../../../helpers/Constants';
import { GA } from '../../../helpers/ga';
import transformPlayerSeasonStats from '../../../helpers/transformPlayerSeasonStats';
import './DraftPlayerModal.scss';
import getPlayerLongName from '../../../helpers/getPlayerLongName';

const _renderStats = (seasonStats) => {
	if (!seasonStats) {
		return <div className='player-stat player-stat--empty'>N/A</div>;
	}

	const statsToRender = Object.keys(seasonStats).map(type => {
		if (type === 'points') {
			return null;
		}

		return seasonStats[type].map(stat => <PlayerStat key={`type_${stat.name}`} stat={stat}
		                                                 statType={type}/>);
	});

	// Make sure the player has any stats to show otherwise display N/A
	if(statsToRender.reduce((prev, curr) => prev.concat(curr), []).length === 0) {
		return <div className='player-stat player-stat--empty'>N/A</div>
	} else {
		return statsToRender;
	}
};

const DraftPlayerModal = (props) => {
	const {player, canDraft, onDraft, onClose} = props;
	const { fetchHistoricPlayerStats, historicPlayerStats, isPosting } = props;
	const [ statLength, setStatLength ] = useState(0);

	useEffect(() => {
		if(!historicPlayerStats.hasOwnProperty(player.statId) && !isPosting) {
			// TODO fix historic fetch!!
			fetchHistoricPlayerStats(player.statId, Constants.currentSeason-1, 'standard');
		} else if(player.pos === 'D' && !historicPlayerStats.hasOwnProperty(player.playerId)) {
			fetchHistoricPlayerStats(player.playerId, Constants.currentSeason-1, 'standard');
		}

		const numOfPlayerStats = document.body.querySelectorAll('.player-stat').length;
		setStatLength(numOfPlayerStats);
	}, [fetchHistoricPlayerStats, historicPlayerStats, isPosting, player.playerId, player.pos, player.statId]);

	const key = player.statId || player.playerId;
	const seasonStats = transformPlayerSeasonStats(historicPlayerStats, key);

	const draftPlayer = () => {
		ReactGA.event({
			category: GA.CATEGORY.DRAFT,
			action: GA.ACTION.DRAFT_VIA_MODAL
		});
		onDraft(player);
	};

	const playerNameParts = getPlayerLongName(player).split(' ');
	return (
		<div className='draft-player-modal'>
			<div className={`draft-player-modal__header draft-player-modal__header--${player.nflTeam}`}>
				<div className='draft-player-modal__player-info'>
					<div className='draft-player-modal__player-name'>
						<div className='draft-player-modal__player-first-name'>{playerNameParts[0]}&nbsp;</div>
						<div className='draft-player-modal__player-last-name'>{playerNameParts.splice(1, playerNameParts.length)}</div>
					</div>
					<PlayerTeamPosInfo position={player.pos} team={player.nflTeam} />
				</div>
				<div className='draft-player-modal__player-number' data-number={player.info.jerseyNumber}></div>
			</div>
			<div className='draft-player-modal__close-container' onClick={onClose}>
				<CloseIcon width={'32px'} height={'32px'}/>
			</div>
			<GreyWrapper>
				<div className='draft-player-modal__stat-container'>
					<div className='draft-player-modal__personal-stat-wrapper'>
						<div className='draft-player-modal__personal-stat'>
							<div className='draft-player-modal__personal-stat-title'>Date of Birth</div>
							<div className='draft-player-modal__personal-stat-value'>{player.info.dob}</div>
						</div>
						<div className='draft-player-modal__personal-stat'>
							<div className='draft-player-modal__personal-stat-title'>Height</div>
							<div className='draft-player-modal__personal-stat-value'>{player.info.height}</div>
						</div>
						<div className='draft-player-modal__personal-stat'>
							<div className='draft-player-modal__personal-stat-title'>Weight</div>
							<div className='draft-player-modal__personal-stat-value'>{player.info.weight} lbs</div>
						</div>
					</div>
					<div className='draft-player-modal__season-stats'>
						<Header headerLevel={'h2'} text={`Season Stats 2020`} />
						<div className={`draft-player-modal__stats-grid draft-player-modal__stats-grid--${statLength}-stats`}>
							{_renderStats(seasonStats) }
						</div>
					</div>
				</div>
				<div className='draft-player-modal__draft-action'>
					{canDraft ? <Button text={'Draft Player'} onClick={() => draftPlayer(player)} /> : null}
				</div>
				{isPosting ? <SpinnerLayer/> : null}
			</GreyWrapper>
		</div>
	)
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchHistoricPlayerStats: (playerId, season, pprType, seasonType = 'REG') => dispatch(fetchHistoricPlayerStats(playerId, season, pprType, seasonType))
	};
};

const mapStateToProps = ( state ) => {
	return {
		historicPlayerStats: state.historicPlayerStats,
		isPosting: state.historicPlayerStatsRequest || state.postDraftPickRequest,
		hasErrored: state.historicPlayerStatsFailure
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftPlayerModal);
