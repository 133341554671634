const rankStandard = [
	{
		"rank": "1",
		"name": "Christian McCaffrey",
		"team": "CAR"
	},
	{
		"rank": "2",
		"name": "Dalvin Cook",
		"team": "MIN"
	},
	{
		"rank": "3",
		"name": "Derrick Henry",
		"team": "TEN"
	},
	{
		"rank": "4",
		"name": "Alvin Kamara",
		"team": "NO"
	},
	{
		"rank": "5",
		"name": "Ezekiel Elliott",
		"team": "DAL"
	},
	{
		"rank": "6",
		"name": "Davante Adams",
		"team": "GB"
	},
	{
		"rank": "7",
		"name": "Saquon Barkley",
		"team": "NYG"
	},
	{
		"rank": "8",
		"name": "Aaron Jones",
		"team": "GB"
	},
	{
		"rank": "9",
		"name": "Tyreek Hill",
		"team": "KC"
	},
	{
		"rank": "10",
		"name": "Nick Chubb",
		"team": "CLE"
	},
	{
		"rank": "11",
		"name": "Stefon Diggs",
		"team": "BUF"
	},
	{
		"rank": "12",
		"name": "Jonathan Taylor",
		"team": "IND"
	},
	{
		"rank": "13",
		"name": "Travis Kelce",
		"team": "KC"
	},
	{
		"rank": "14",
		"name": "Austin Ekeler",
		"team": "LAC"
	},
	{
		"rank": "15",
		"name": "Calvin Ridley",
		"team": "ATL"
	},
	{
		"rank": "16",
		"name": "DeAndre Hopkins",
		"team": "ARI"
	},
	{
		"rank": "17",
		"name": "Antonio Gibson",
		"team": "WAS"
	},
	{
		"rank": "18",
		"name": "Joe Mixon",
		"team": "CIN"
	},
	{
		"rank": "19",
		"name": "D.K. Metcalf",
		"team": "SEA"
	},
	{
		"rank": "20",
		"name": "A.J. Brown",
		"team": "TEN"
	},
	{
		"rank": "21",
		"name": "Justin Jefferson",
		"team": "MIN"
	},
	{
		"rank": "22",
		"name": "Najee Harris",
		"team": "PIT"
	},
	{
		"rank": "23",
		"name": "Keenan Allen",
		"team": "LAC"
	},
	{
		"rank": "24",
		"name": "Clyde Edwards-Helaire",
		"team": "KC"
	},
	{
		"rank": "25",
		"name": "Allen Robinson II",
		"team": "CHI"
	},
	{
		"rank": "26",
		"name": "Darren Waller",
		"team": "LV"
	},
	{
		"rank": "27",
		"name": "Terry McLaurin",
		"team": "WAS"
	},
	{
		"rank": "28",
		"name": "Patrick Mahomes",
		"team": "KC"
	},
	{
		"rank": "29",
		"name": "J.K. Dobbins",
		"team": "BAL"
	},
	{
		"rank": "30",
		"name": "D'Andre Swift",
		"team": "DET"
	},
	{
		"rank": "31",
		"name": "Chris Carson",
		"team": "SEA"
	},
	{
		"rank": "32",
		"name": "David Montgomery",
		"team": "CHI"
	},
	{
		"rank": "33",
		"name": "CeeDee Lamb",
		"team": "DAL"
	},
	{
		"rank": "34",
		"name": "George Kittle",
		"team": "SF"
	},
	{
		"rank": "35",
		"name": "Mike Evans",
		"team": "TB"
	},
	{
		"rank": "36",
		"name": "Chris Godwin",
		"team": "TB"
	},
	{
		"rank": "37",
		"name": "Amari Cooper",
		"team": "DAL"
	},
	{
		"rank": "38",
		"name": "Robert Woods",
		"team": "LAR"
	},
	{
		"rank": "39",
		"name": "Miles Sanders",
		"team": "PHI"
	},
	{
		"rank": "40",
		"name": "Julio Jones",
		"team": "TEN"
	},
	{
		"rank": "41",
		"name": "D.J. Moore",
		"team": "CAR"
	},
	{
		"rank": "42",
		"name": "Josh Jacobs",
		"team": "LV"
	},
	{
		"rank": "43",
		"name": "Josh Allen",
		"team": "BUF"
	},
	{
		"rank": "44",
		"name": "Darrell Henderson",
		"team": "LAR"
	},
	{
		"rank": "45",
		"name": "Cooper Kupp",
		"team": "LAR"
	},
	{
		"rank": "46",
		"name": "Myles Gaskin",
		"team": "MIA"
	},
	{
		"rank": "47",
		"name": "Diontae Johnson",
		"team": "PIT"
	},
	{
		"rank": "48",
		"name": "Adam Thielen",
		"team": "MIN"
	},
	{
		"rank": "49",
		"name": "Kareem Hunt",
		"team": "CLE"
	},
	{
		"rank": "50",
		"name": "Tyler Lockett",
		"team": "SEA"
	},
	{
		"rank": "51",
		"name": "Kyler Murray",
		"team": "ARI"
	},
	{
		"rank": "52",
		"name": "T.J. Hockenson",
		"team": "DET"
	},
	{
		"rank": "53",
		"name": "Mike Davis",
		"team": "ATL"
	},
	{
		"rank": "54",
		"name": "Mark Andrews",
		"team": "BAL"
	},
	{
		"rank": "55",
		"name": "Lamar Jackson",
		"team": "BAL"
	},
	{
		"rank": "56",
		"name": "Travis Etienne",
		"team": "JAC"
	},
	{
		"rank": "57",
		"name": "Kenny Golladay",
		"team": "NYG"
	},
	{
		"rank": "58",
		"name": "Kyle Pitts",
		"team": "ATL"
	},
	{
		"rank": "59",
		"name": "Dak Prescott",
		"team": "DAL"
	},
	{
		"rank": "60",
		"name": "Brandon Aiyuk",
		"team": "SF"
	},
	{
		"rank": "61",
		"name": "Chase Edmonds",
		"team": "ARI"
	},
	{
		"rank": "62",
		"name": "Tee Higgins",
		"team": "CIN"
	},
	{
		"rank": "63",
		"name": "Ja'Marr Chase",
		"team": "CIN"
	},
	{
		"rank": "64",
		"name": "Melvin Gordon III",
		"team": "DEN"
	},
	{
		"rank": "65",
		"name": "Odell Beckham Jr.",
		"team": "CLE"
	},
	{
		"rank": "66",
		"name": "Damien Harris",
		"team": "NE"
	},
	{
		"rank": "67",
		"name": "Russell Wilson",
		"team": "SEA"
	},
	{
		"rank": "68",
		"name": "Raheem Mostert",
		"team": "SF"
	},
	{
		"rank": "69",
		"name": "Javonte Williams",
		"team": "DEN"
	},
	{
		"rank": "70",
		"name": "James Robinson",
		"team": "JAC"
	},
	{
		"rank": "71",
		"name": "Aaron Rodgers",
		"team": "GB"
	},
	{
		"rank": "72",
		"name": "Ronald Jones II",
		"team": "TB"
	},
	{
		"rank": "73",
		"name": "Courtland Sutton",
		"team": "DEN"
	},
	{
		"rank": "74",
		"name": "Chase Claypool",
		"team": "PIT"
	},
	{
		"rank": "75",
		"name": "Justin Herbert",
		"team": "LAC"
	},
	{
		"rank": "76",
		"name": "JuJu Smith-Schuster",
		"team": "PIT"
	},
	{
		"rank": "77",
		"name": "David Johnson",
		"team": "HOU"
	},
	{
		"rank": "78",
		"name": "Dallas Goedert",
		"team": "PHI"
	},
	{
		"rank": "79",
		"name": "D.J. Chark Jr.",
		"team": "JAC"
	},
	{
		"rank": "80",
		"name": "Michael Carter",
		"team": "NYJ"
	},
	{
		"rank": "81",
		"name": "Leonard Fournette",
		"team": "TB"
	},
	{
		"rank": "82",
		"name": "Noah Fant",
		"team": "DEN"
	},
	{
		"rank": "83",
		"name": "Robby Anderson",
		"team": "CAR"
	},
	{
		"rank": "84",
		"name": "Zack Moss",
		"team": "BUF"
	},
	{
		"rank": "85",
		"name": "James Conner",
		"team": "ARI"
	},
	{
		"rank": "86",
		"name": "Trey Sermon",
		"team": "SF"
	},
	{
		"rank": "87",
		"name": "Jerry Jeudy",
		"team": "DEN"
	},
	{
		"rank": "88",
		"name": "Tyler Boyd",
		"team": "CIN"
	},
	{
		"rank": "89",
		"name": "Ryan Tannehill",
		"team": "TEN"
	},
	{
		"rank": "90",
		"name": "Deebo Samuel",
		"team": "SF"
	},
	{
		"rank": "91",
		"name": "Brandin Cooks",
		"team": "HOU"
	},
	{
		"rank": "92",
		"name": "Robert Tonyan",
		"team": "GB"
	},
	{
		"rank": "93",
		"name": "Will Fuller V",
		"team": "MIA"
	},
	{
		"rank": "94",
		"name": "Kenyan Drake",
		"team": "LV"
	},
	{
		"rank": "95",
		"name": "Logan Thomas",
		"team": "WAS"
	},
	{
		"rank": "96",
		"name": "Tom Brady",
		"team": "TB"
	},
	{
		"rank": "97",
		"name": "Matthew Stafford",
		"team": "LAR"
	},
	{
		"rank": "98",
		"name": "AJ Dillon",
		"team": "GB"
	},
	{
		"rank": "99",
		"name": "Jalen Hurts",
		"team": "PHI"
	},
	{
		"rank": "100",
		"name": "Curtis Samuel",
		"team": "WAS"
	},
	{
		"rank": "101",
		"name": "Tyler Higbee",
		"team": "LAR"
	},
	{
		"rank": "102",
		"name": "DeVonta Smith",
		"team": "PHI"
	},
	{
		"rank": "103",
		"name": "Gus Edwards",
		"team": "BAL"
	},
	{
		"rank": "104",
		"name": "Jarvis Landry",
		"team": "CLE"
	},
	{
		"rank": "105",
		"name": "Devin Singletary",
		"team": "BUF"
	},
	{
		"rank": "106",
		"name": "Jamaal Williams",
		"team": "DET"
	},
	{
		"rank": "107",
		"name": "Mike Gesicki",
		"team": "MIA"
	},
	{
		"rank": "108",
		"name": "Laviska Shenault Jr.",
		"team": "JAC"
	},
	{
		"rank": "109",
		"name": "Nyheim Hines",
		"team": "IND"
	},
	{
		"rank": "110",
		"name": "Tony Pollard",
		"team": "DAL"
	},
	{
		"rank": "111",
		"name": "Joe Burrow",
		"team": "CIN"
	},
	{
		"rank": "112",
		"name": "Michael Thomas",
		"team": "NO"
	},
	{
		"rank": "113",
		"name": "Latavius Murray",
		"team": "NO"
	},
	{
		"rank": "114",
		"name": "Antonio Brown",
		"team": "TB"
	},
	{
		"rank": "115",
		"name": "Mike Williams",
		"team": "LAC"
	},
	{
		"rank": "116",
		"name": "Michael Gallup",
		"team": "DAL"
	},
	{
		"rank": "117",
		"name": "Corey Davis",
		"team": "NYJ"
	},
	{
		"rank": "118",
		"name": "Irv Smith Jr.",
		"team": "MIN"
	},
	{
		"rank": "119",
		"name": "DeVante Parker",
		"team": "MIA"
	},
	{
		"rank": "120",
		"name": "Evan Engram",
		"team": "NYG"
	},
	{
		"rank": "121",
		"name": "Jonnu Smith",
		"team": "NE"
	},
	{
		"rank": "122",
		"name": "Phillip Lindsay",
		"team": "HOU"
	},
	{
		"rank": "123",
		"name": "Matt Ryan",
		"team": "ATL"
	},
	{
		"rank": "124",
		"name": "Marquise Brown",
		"team": "BAL"
	},
	{
		"rank": "125",
		"name": "Michael Pittman Jr.",
		"team": "IND"
	},
	{
		"rank": "126",
		"name": "Trevor Lawrence",
		"team": "JAC"
	},
	{
		"rank": "127",
		"name": "Marvin Jones Jr.",
		"team": "JAC"
	},
	{
		"rank": "128",
		"name": "J.D. McKissic",
		"team": "WAS"
	},
	{
		"rank": "129",
		"name": "Alexander Mattison",
		"team": "MIN"
	},
	{
		"rank": "130",
		"name": "Jaylen Waddle",
		"team": "MIA"
	},
	{
		"rank": "131",
		"name": "James White",
		"team": "NE"
	},
	{
		"rank": "132",
		"name": "Darnell Mooney",
		"team": "CHI"
	},
	{
		"rank": "133",
		"name": "Kirk Cousins",
		"team": "MIN"
	},
	{
		"rank": "134",
		"name": "Rob Gronkowski",
		"team": "TB"
	},
	{
		"rank": "135",
		"name": "Hunter Henry",
		"team": "NE"
	},
	{
		"rank": "136",
		"name": "Tarik Cohen",
		"team": "CHI"
	},
	{
		"rank": "137",
		"name": "T.Y. Hilton",
		"team": "IND"
	},
	{
		"rank": "138",
		"name": "Blake Jarwin",
		"team": "DAL"
	},
	{
		"rank": "139",
		"name": "Mecole Hardman",
		"team": "KC"
	},
	{
		"rank": "140",
		"name": "Baker Mayfield",
		"team": "CLE"
	},
	{
		"rank": "141",
		"name": "Cole Kmet",
		"team": "CHI"
	},
	{
		"rank": "142",
		"name": "Cole Beasley",
		"team": "BUF"
	},
	{
		"rank": "143",
		"name": "Tevin Coleman",
		"team": "NYJ"
	},
	{
		"rank": "144",
		"name": "Henry Ruggs III",
		"team": "LV"
	},
	{
		"rank": "145",
		"name": "Adam Trautman",
		"team": "NO"
	},
	{
		"rank": "146",
		"name": "Rashaad Penny",
		"team": "SEA"
	},
	{
		"rank": "147",
		"name": "Tua Tagovailoa",
		"team": "MIA"
	},
	{
		"rank": "148",
		"name": "Austin Hooper",
		"team": "CLE"
	},
	{
		"rank": "149",
		"name": "Jalen Reagor",
		"team": "PHI"
	},
	{
		"rank": "150",
		"name": "Gerald Everett",
		"team": "SEA"
	},
	{
		"rank": "151",
		"name": "Nelson Agholor",
		"team": "NE"
	},
	{
		"rank": "152",
		"name": "Jared Cook",
		"team": "LAC"
	},
	{
		"rank": "153",
		"name": "Ryan Fitzpatrick",
		"team": "WAS"
	},
	{
		"rank": "154",
		"name": "Giovani Bernard",
		"team": "TB"
	},
	{
		"rank": "155",
		"name": "Pittsburgh Steelers",
		"team": "PIT"
	},
	{
		"rank": "156",
		"name": "Los Angeles Rams",
		"team": "LAR"
	},
	{
		"rank": "157",
		"name": "Daniel Jones",
		"team": "NYG"
	},
	{
		"rank": "158",
		"name": "Rashod Bateman",
		"team": "BAL"
	},
	{
		"rank": "159",
		"name": "Russell Gage",
		"team": "ATL"
	},
	{
		"rank": "160",
		"name": "Sterling Shepard",
		"team": "NYG"
	},
	{
		"rank": "161",
		"name": "John Brown",
		"team": "LV"
	},
	{
		"rank": "162",
		"name": "Ben Roethlisberger",
		"team": "PIT"
	},
	{
		"rank": "163",
		"name": "Tre'Quan Smith",
		"team": "NO"
	},
	{
		"rank": "164",
		"name": "Baltimore Ravens",
		"team": "BAL"
	},
	{
		"rank": "165",
		"name": "Jamison Crowder",
		"team": "NYJ"
	},
	{
		"rank": "166",
		"name": "Elijah Moore",
		"team": "NYJ"
	},
	{
		"rank": "167",
		"name": "Christian Kirk",
		"team": "ARI"
	},
	{
		"rank": "168",
		"name": "Gabriel Davis",
		"team": "BUF"
	},
	{
		"rank": "169",
		"name": "Justin Tucker",
		"team": "BAL"
	},
	{
		"rank": "170",
		"name": "Anthony Firkser",
		"team": "TEN"
	},
	{
		"rank": "171",
		"name": "San Francisco 49ers",
		"team": "SF"
	},
	{
		"rank": "172",
		"name": "Zach Ertz",
		"team": "PHI"
	},
	{
		"rank": "173",
		"name": "Washington Football Team",
		"team": "WAS"
	},
	{
		"rank": "174",
		"name": "Indianapolis Colts",
		"team": "IND"
	},
	{
		"rank": "175",
		"name": "Eric Ebron",
		"team": "PIT"
	},
	{
		"rank": "176",
		"name": "Derek Carr",
		"team": "LV"
	},
	{
		"rank": "177",
		"name": "Parris Campbell",
		"team": "IND"
	},
	{
		"rank": "178",
		"name": "Justin Fields",
		"team": "CHI"
	},
	{
		"rank": "179",
		"name": "Marlon Mack",
		"team": "IND"
	},
	{
		"rank": "180",
		"name": "Sony Michel",
		"team": "NE"
	},
	{
		"rank": "181",
		"name": "Emmanuel Sanders",
		"team": "BUF"
	},
	{
		"rank": "182",
		"name": "Harrison Butker",
		"team": "KC"
	},
	{
		"rank": "183",
		"name": "Younghoe Koo",
		"team": "ATL"
	},
	{
		"rank": "184",
		"name": "Kenneth Gainwell",
		"team": "PHI"
	},
	{
		"rank": "185",
		"name": "Salvon Ahmed",
		"team": "MIA"
	},
	{
		"rank": "186",
		"name": "Breshad Perriman",
		"team": "DET"
	},
	{
		"rank": "187",
		"name": "Tampa Bay Buccaneers",
		"team": "TB"
	},
	{
		"rank": "188",
		"name": "Jakobi Meyers",
		"team": "NE"
	},
	{
		"rank": "189",
		"name": "New England Patriots",
		"team": "NE"
	},
	{
		"rank": "190",
		"name": "Rondale Moore",
		"team": "ARI"
	},
	{
		"rank": "191",
		"name": "Greg Zuerlein",
		"team": "DAL"
	},
	{
		"rank": "192",
		"name": "Darius Slayton",
		"team": "NYG"
	},
	{
		"rank": "193",
		"name": "Buffalo Bills",
		"team": "BUF"
	},
	{
		"rank": "194",
		"name": "Sam Darnold",
		"team": "CAR"
	},
	{
		"rank": "195",
		"name": "Allen Lazard",
		"team": "GB"
	},
	{
		"rank": "196",
		"name": "New Orleans Saints",
		"team": "NO"
	},
	{
		"rank": "197",
		"name": "Darrel Williams",
		"team": "KC"
	},
	{
		"rank": "198",
		"name": "Kansas City Chiefs",
		"team": "KC"
	},
	{
		"rank": "199",
		"name": "Wil Lutz",
		"team": "NO"
	},
	{
		"rank": "200",
		"name": "A.J. Green",
		"team": "ARI"
	},
	{
		"rank": "201",
		"name": "Darrynton Evans",
		"team": "TEN"
	},
	{
		"rank": "202",
		"name": "Jason Sanders",
		"team": "MIA"
	},
	{
		"rank": "203",
		"name": "Hayden Hurst",
		"team": "ATL"
	},
	{
		"rank": "204",
		"name": "Joshua Kelley",
		"team": "LAC"
	},
	{
		"rank": "205",
		"name": "Sammy Watkins",
		"team": "BAL"
	},
	{
		"rank": "206",
		"name": "Bryan Edwards",
		"team": "LV"
	},
	{
		"rank": "207",
		"name": "Zach Wilson",
		"team": "NYJ"
	},
	{
		"rank": "208",
		"name": "Trey Lance",
		"team": "SF"
	},
	{
		"rank": "209",
		"name": "Amon-Ra St. Brown",
		"team": "DET"
	},
	{
		"rank": "210",
		"name": "Denzel Mims",
		"team": "NYJ"
	},
	{
		"rank": "211",
		"name": "Carson Wentz",
		"team": "IND"
	},
	{
		"rank": "212",
		"name": "Chicago Bears",
		"team": "CHI"
	},
	{
		"rank": "213",
		"name": "Marquez Valdes-Scantling",
		"team": "GB"
	},
	{
		"rank": "214",
		"name": "Randall Cobb",
		"team": "GB"
	},
	{
		"rank": "215",
		"name": "Damien Williams",
		"team": "CHI"
	},
	{
		"rank": "216",
		"name": "Los Angeles Chargers",
		"team": "LAC"
	},
	{
		"rank": "217",
		"name": "Matt Prater",
		"team": "ARI"
	},
	{
		"rank": "218",
		"name": "Jared Goff",
		"team": "DET"
	},
	{
		"rank": "219",
		"name": "Denver Broncos",
		"team": "DEN"
	},
	{
		"rank": "220",
		"name": "Rodrigo Blankenship",
		"team": "IND"
	},
	{
		"rank": "221",
		"name": "Terrace Marshall Jr.",
		"team": "CAR"
	},
	{
		"rank": "222",
		"name": "Chuba Hubbard",
		"team": "CAR"
	},
	{
		"rank": "223",
		"name": "Cam Newton",
		"team": "NE"
	},
	{
		"rank": "224",
		"name": "Cleveland Browns",
		"team": "CLE"
	},
	{
		"rank": "225",
		"name": "Robbie Gould",
		"team": "SF"
	},
	{
		"rank": "226",
		"name": "Miami Dolphins",
		"team": "MIA"
	},
	{
		"rank": "227",
		"name": "Malcolm Brown",
		"team": "MIA"
	},
	{
		"rank": "228",
		"name": "Benny Snell Jr.",
		"team": "PIT"
	},
	{
		"rank": "229",
		"name": "Tyler Bass",
		"team": "BUF"
	},
	{
		"rank": "230",
		"name": "Dawson Knox",
		"team": "BUF"
	},
	{
		"rank": "231",
		"name": "Carlos Hyde",
		"team": "JAC"
	},
	{
		"rank": "232",
		"name": "Josh Reynolds",
		"team": "TEN"
	},
	{
		"rank": "233",
		"name": "Justin Jackson",
		"team": "LAC"
	},
	{
		"rank": "234",
		"name": "Jason Myers",
		"team": "SEA"
	},
	{
		"rank": "235",
		"name": "Mark Ingram II",
		"team": "HOU"
	},
	{
		"rank": "236",
		"name": "Ryan Succop",
		"team": "TB"
	},
	{
		"rank": "237",
		"name": "O.J. Howard",
		"team": "TB"
	},
	{
		"rank": "238",
		"name": "Green Bay Packers",
		"team": "GB"
	},
	{
		"rank": "239",
		"name": "Van Jefferson",
		"team": "LAR"
	},
	{
		"rank": "240",
		"name": "Seattle Seahawks",
		"team": "SEA"
	},
	{
		"rank": "241",
		"name": "Jameis Winston",
		"team": "NO"
	},
	{
		"rank": "242",
		"name": "Mason Crosby",
		"team": "GB"
	},
	{
		"rank": "243",
		"name": "Michael Badgley",
		"team": "LAC"
	},
	{
		"rank": "244",
		"name": "Tyrell Williams",
		"team": "DET"
	},
	{
		"rank": "245",
		"name": "Minnesota Vikings",
		"team": "MIN"
	},
	{
		"rank": "246",
		"name": "DeSean Jackson",
		"team": "LAR"
	},
	{
		"rank": "247",
		"name": "Jeff Wilson Jr.",
		"team": "SF"
	},
	{
		"rank": "248",
		"name": "Tim Patrick",
		"team": "DEN"
	},
	{
		"rank": "249",
		"name": "Anthony Miller",
		"team": "HOU"
	},
	{
		"rank": "250",
		"name": "Taysom Hill",
		"team": "NO"
	},
	{
		"rank": "251",
		"name": "Boston Scott",
		"team": "PHI"
	},
	{
		"rank": "252",
		"name": "Hunter Renfrow",
		"team": "LV"
	},
	{
		"rank": "253",
		"name": "KJ Hamler",
		"team": "DEN"
	},
	{
		"rank": "254",
		"name": "Philadelphia Eagles",
		"team": "PHI"
	},
	{
		"rank": "255",
		"name": "Daniel Carlson",
		"team": "LV"
	},
	{
		"rank": "256",
		"name": "Chris Herndon IV",
		"team": "NYJ"
	},
	{
		"rank": "257",
		"name": "Kadarius Toney",
		"team": "NYG"
	},
	{
		"rank": "258",
		"name": "Dan Arnold",
		"team": "CAR"
	},
	{
		"rank": "259",
		"name": "Brandon McManus",
		"team": "DEN"
	},
	{
		"rank": "260",
		"name": "Jake Elliott",
		"team": "PHI"
	},
	{
		"rank": "261",
		"name": "Kerryon Johnson",
		"team": "PHI"
	},
	{
		"rank": "262",
		"name": "La'Mical Perine",
		"team": "NYJ"
	},
	{
		"rank": "263",
		"name": "Deshaun Watson",
		"team": "HOU"
	},
	{
		"rank": "264",
		"name": "James Washington",
		"team": "PIT"
	},
	{
		"rank": "265",
		"name": "Rhamondre Stevenson",
		"team": "NE"
	},
	{
		"rank": "266",
		"name": "Wayne Gallman",
		"team": "SF"
	},
	{
		"rank": "267",
		"name": "Jerick McKinnon",
		"team": "KC"
	},
	{
		"rank": "268",
		"name": "Joey Slye",
		"team": "CAR"
	},
	{
		"rank": "269",
		"name": "Amari Rodgers",
		"team": "GB"
	},
	{
		"rank": "270",
		"name": "Devontae Booker",
		"team": "NYG"
	},
	{
		"rank": "271",
		"name": "Jack Doyle",
		"team": "IND"
	},
	{
		"rank": "272",
		"name": "Tennessee Titans",
		"team": "TEN"
	},
	{
		"rank": "273",
		"name": "Javian Hawkins",
		"team": "ATL"
	},
	{
		"rank": "274",
		"name": "Matt Breida",
		"team": "BUF"
	},
	{
		"rank": "275",
		"name": "Chris Boswell",
		"team": "PIT"
	},
	{
		"rank": "276",
		"name": "Preston Williams",
		"team": "MIA"
	},
	{
		"rank": "277",
		"name": "Rashard Higgins",
		"team": "CLE"
	},
	{
		"rank": "278",
		"name": "Ka'imi Fairbairn",
		"team": "HOU"
	},
	{
		"rank": "279",
		"name": "Jimmy Graham",
		"team": "CHI"
	},
	{
		"rank": "280",
		"name": "Dustin Hopkins",
		"team": "WAS"
	},
	{
		"rank": "281",
		"name": "New York Giants",
		"team": "NYG"
	},
	{
		"rank": "282",
		"name": "Anthony McFarland Jr.",
		"team": "PIT"
	},
	{
		"rank": "283",
		"name": "New York Jets",
		"team": "NYJ"
	},
	{
		"rank": "284",
		"name": "Zach Pascal",
		"team": "IND"
	},
	{
		"rank": "285",
		"name": "Nico Collins",
		"team": "HOU"
	},
	{
		"rank": "286",
		"name": "Arizona Cardinals",
		"team": "ARI"
	},
	{
		"rank": "287",
		"name": "Ty Johnson",
		"team": "NYJ"
	},
	{
		"rank": "288",
		"name": "Kendrick Bourne",
		"team": "NE"
	},
	{
		"rank": "289",
		"name": "Samaje Perine",
		"team": "CIN"
	},
	{
		"rank": "290",
		"name": "Keke Coutee",
		"team": "HOU"
	},
	{
		"rank": "291",
		"name": "N'Keal Harry",
		"team": "NE"
	},
	{
		"rank": "292",
		"name": "Jimmy Garoppolo",
		"team": "SF"
	},
	{
		"rank": "293",
		"name": "Ke'Shawn Vaughn",
		"team": "TB"
	},
	{
		"rank": "294",
		"name": "Josh Lambo",
		"team": "JAC"
	},
	{
		"rank": "295",
		"name": "Teddy Bridgewater",
		"team": "DEN"
	},
	{
		"rank": "296",
		"name": "Quintez Cephus",
		"team": "DET"
	},
	{
		"rank": "297",
		"name": "Dallas Cowboys",
		"team": "DAL"
	},
	{
		"rank": "298",
		"name": "Matt Gay",
		"team": "LAR"
	},
	{
		"rank": "299",
		"name": "Tyron Johnson",
		"team": "LAC"
	},
	{
		"rank": "300",
		"name": "Keelan Cole Sr.",
		"team": "NYJ"
	},
	{
		"rank": "301",
		"name": "Jacksonville Jaguars",
		"team": "JAC"
	},
	{
		"rank": "302",
		"name": "Scotty Miller",
		"team": "TB"
	},
	{
		"rank": "303",
		"name": "Travis Fulgham",
		"team": "PHI"
	},
	{
		"rank": "304",
		"name": "Drew Lock",
		"team": "DEN"
	},
	{
		"rank": "305",
		"name": "Kyle Rudolph",
		"team": "NYG"
	},
	{
		"rank": "306",
		"name": "Donovan Peoples-Jones",
		"team": "CLE"
	},
	{
		"rank": "307",
		"name": "D'Wayne Eskridge",
		"team": "SEA"
	},
	{
		"rank": "308",
		"name": "Graham Gano",
		"team": "NYG"
	},
	{
		"rank": "309",
		"name": "Atlanta Falcons",
		"team": "ATL"
	},
	{
		"rank": "310",
		"name": "Austin Seibert",
		"team": "CIN"
	},
	{
		"rank": "311",
		"name": "Marquez Callaway",
		"team": "NO"
	},
	{
		"rank": "312",
		"name": "Xavier Jones",
		"team": "LAR"
	},
	{
		"rank": "313",
		"name": "Stephen Gostkowski,",
		"team": "N/A"
	},
	{
		"rank": "314",
		"name": "DeeJay Dallas",
		"team": "SEA"
	},
	{
		"rank": "315",
		"name": "Ian Thomas",
		"team": "CAR"
	},
	{
		"rank": "316",
		"name": "Le'Veon Bell,",
		"team": "N/A"
	},
	{
		"rank": "317",
		"name": "Zane Gonzalez,",
		"team": "N/A"
	},
	{
		"rank": "318",
		"name": "Andy Isabella",
		"team": "ARI"
	},
	{
		"rank": "319",
		"name": "Rex Burkhead",
		"team": "HOU"
	},
	{
		"rank": "320",
		"name": "Carolina Panthers",
		"team": "CAR"
	},
	{
		"rank": "321",
		"name": "Mo Alie-Cox",
		"team": "IND"
	},
	{
		"rank": "322",
		"name": "Jalen Richard",
		"team": "LV"
	},
	{
		"rank": "323",
		"name": "Dalton Schultz",
		"team": "DAL"
	},
	{
		"rank": "324",
		"name": "Dyami Brown",
		"team": "WAS"
	},
	{
		"rank": "325",
		"name": "Todd Gurley II,",
		"team": "N/A"
	},
	{
		"rank": "326",
		"name": "Jordan Howard",
		"team": "PHI"
	},
	{
		"rank": "327",
		"name": "Greg Ward",
		"team": "PHI"
	},
	{
		"rank": "328",
		"name": "Tyrod Taylor",
		"team": "HOU"
	},
	{
		"rank": "329",
		"name": "Duke Johnson Jr.,",
		"team": "N/A"
	},
	{
		"rank": "330",
		"name": "Kalen Ballage",
		"team": "PIT"
	},
	{
		"rank": "331",
		"name": "Brian Hill",
		"team": "TEN"
	},
	{
		"rank": "332",
		"name": "Justice Hill",
		"team": "BAL"
	},
	{
		"rank": "333",
		"name": "Eno Benjamin",
		"team": "ARI"
	},
	{
		"rank": "334",
		"name": "Miles Boykin",
		"team": "BAL"
	},
	{
		"rank": "335",
		"name": "Cairo Santos",
		"team": "CHI"
	},
	{
		"rank": "336",
		"name": "Demarcus Robinson",
		"team": "KC"
	},
	{
		"rank": "337",
		"name": "Jordan Akins",
		"team": "HOU"
	},
	{
		"rank": "338",
		"name": "Ito Smith",
		"team": "ARI"
	},
	{
		"rank": "339",
		"name": "Olamide Zaccheaus",
		"team": "ATL"
	},
	{
		"rank": "340",
		"name": "Dan Bailey,",
		"team": "N/A"
	},
	{
		"rank": "341",
		"name": "Byron Pringle",
		"team": "KC"
	},
	{
		"rank": "342",
		"name": "Qadree Ollison",
		"team": "ATL"
	},
	{
		"rank": "343",
		"name": "Donald Parham Jr.",
		"team": "LAC"
	},
	{
		"rank": "344",
		"name": "Pat Freiermuth",
		"team": "PIT"
	},
	{
		"rank": "345",
		"name": "Will Dissly",
		"team": "SEA"
	},
	{
		"rank": "346",
		"name": "Houston Texans",
		"team": "HOU"
	},
	{
		"rank": "347",
		"name": "Jordan Wilkins",
		"team": "IND"
	},
	{
		"rank": "348",
		"name": "John Ross",
		"team": "NYG"
	},
	{
		"rank": "349",
		"name": "Mac Jones",
		"team": "NE"
	},
	{
		"rank": "350",
		"name": "Adrian Peterson,",
		"team": "N/A"
	},
	{
		"rank": "351",
		"name": "C.J. Uzomah",
		"team": "CIN"
	},
	{
		"rank": "352",
		"name": "Andy Dalton",
		"team": "CHI"
	},
	{
		"rank": "353",
		"name": "Elijah Mitchell",
		"team": "SF"
	},
	{
		"rank": "354",
		"name": "Larry Rountree III",
		"team": "LAC"
	},
	{
		"rank": "355",
		"name": "Cody Parkey",
		"team": "CLE"
	},
	{
		"rank": "356",
		"name": "Jermar Jefferson",
		"team": "DET"
	},
	{
		"rank": "357",
		"name": "Josh Palmer",
		"team": "LAC"
	},
	{
		"rank": "358",
		"name": "David Njoku",
		"team": "CLE"
	},
	{
		"rank": "359",
		"name": "Harrison Bryant",
		"team": "CLE"
	},
	{
		"rank": "360",
		"name": "Jalen Guyton",
		"team": "LAC"
	},
	{
		"rank": "361",
		"name": "Sam Sloman",
		"team": "PIT"
	},
	{
		"rank": "362",
		"name": "Lynn Bowden Jr.",
		"team": "MIA"
	},
	{
		"rank": "363",
		"name": "Peyton Barber",
		"team": "WAS"
	},
	{
		"rank": "364",
		"name": "Mike Boone",
		"team": "DEN"
	},
	{
		"rank": "365",
		"name": "Steven Sims Jr.",
		"team": "WAS"
	},
	{
		"rank": "366",
		"name": "Drew Sample",
		"team": "CIN"
	},
	{
		"rank": "367",
		"name": "Dede Westbrook",
		"team": "MIN"
	},
	{
		"rank": "368",
		"name": "Chris Thompson,",
		"team": "N/A"
	},
	{
		"rank": "369",
		"name": "Deonte Harris",
		"team": "NO"
	},
	{
		"rank": "370",
		"name": "Cordarrelle Patterson",
		"team": "ATL"
	},
	{
		"rank": "371",
		"name": "Golden Tate,",
		"team": "N/A"
	},
	{
		"rank": "372",
		"name": "Jake Funk",
		"team": "LAR"
	},
	{
		"rank": "373",
		"name": "Adam Humphries",
		"team": "WAS"
	},
	{
		"rank": "374",
		"name": "Albert Okwuegbunam",
		"team": "DEN"
	},
	{
		"rank": "375",
		"name": "Randy Bullock",
		"team": "DET"
	},
	{
		"rank": "376",
		"name": "Tyler Johnson",
		"team": "TB"
	},
	{
		"rank": "377",
		"name": "Tutu Atwell",
		"team": "LAR"
	},
	{
		"rank": "378",
		"name": "Tyler Eifert,",
		"team": "N/A"
	},
	{
		"rank": "379",
		"name": "Chris Evans",
		"team": "CIN"
	},
	{
		"rank": "380",
		"name": "Detroit Lions",
		"team": "DET"
	},
	{
		"rank": "381",
		"name": "Tyler Conklin",
		"team": "MIN"
	},
	{
		"rank": "382",
		"name": "Larry Fitzgerald,",
		"team": "N/A"
	},
	{
		"rank": "383",
		"name": "Tylan Wallace",
		"team": "BAL"
	},
	{
		"rank": "384",
		"name": "Devin Duvernay",
		"team": "BAL"
	},
	{
		"rank": "385",
		"name": "Evan McPherson",
		"team": "CIN"
	},
	{
		"rank": "386",
		"name": "Dez Fitzpatrick",
		"team": "TEN"
	},
	{
		"rank": "387",
		"name": "Jacob Hollister",
		"team": "BUF"
	},
	{
		"rank": "388",
		"name": "Collin Johnson",
		"team": "JAC"
	},
	{
		"rank": "389",
		"name": "Bryce Love,",
		"team": "N/A"
	},
	{
		"rank": "390",
		"name": "Cornell Powell",
		"team": "KC"
	},
	{
		"rank": "391",
		"name": "Brevin Jordan",
		"team": "HOU"
	},
	{
		"rank": "392",
		"name": "Las Vegas Raiders",
		"team": "LV"
	},
	{
		"rank": "393",
		"name": "Antonio Gandy-Golden",
		"team": "WAS"
	},
	{
		"rank": "394",
		"name": "Nick Folk",
		"team": "NE"
	},
	{
		"rank": "395",
		"name": "Chris Conley",
		"team": "HOU"
	},
	{
		"rank": "396",
		"name": "Kylin Hill",
		"team": "GB"
	},
	{
		"rank": "397",
		"name": "Jalen Hurd",
		"team": "SF"
	},
	{
		"rank": "398",
		"name": "Royce Freeman",
		"team": "DEN"
	},
	{
		"rank": "399",
		"name": "Cincinnati Bengals",
		"team": "CIN"
	},
	{
		"rank": "400",
		"name": "Cameron Brate",
		"team": "TB"
	},
	{
		"rank": "401",
		"name": "Auden Tate",
		"team": "CIN"
	},
	{
		"rank": "402",
		"name": "Danny Amendola,",
		"team": "N/A"
	},
	{
		"rank": "403",
		"name": "Gardner Minshew II",
		"team": "JAC"
	},
	{
		"rank": "404",
		"name": "Maxx Williams",
		"team": "ARI"
	},
	{
		"rank": "405",
		"name": "Gerrid Doaks",
		"team": "MIA"
	},
	{
		"rank": "406",
		"name": "James O'Shaughnessy",
		"team": "JAC"
	},
	{
		"rank": "407",
		"name": "David Moore",
		"team": "CAR"
	},
	{
		"rank": "408",
		"name": "Khalil Herbert",
		"team": "CHI"
	},
	{
		"rank": "409",
		"name": "Anthony Schwartz",
		"team": "CLE"
	},
	{
		"rank": "410",
		"name": "Alshon Jeffery,",
		"team": "N/A"
	},
	{
		"rank": "411",
		"name": "Dare Ogunbowale",
		"team": "JAC"
	},
	{
		"rank": "412",
		"name": "Tucker McCann",
		"team": "TEN"
	},
	{
		"rank": "413",
		"name": "Cam Sims",
		"team": "WAS"
	},
	{
		"rank": "414",
		"name": "Dazz Newsome",
		"team": "CHI"
	},
	{
		"rank": "415",
		"name": "Mitchell Trubisky",
		"team": "BUF"
	},
	{
		"rank": "416",
		"name": "Tyler Kroft",
		"team": "NYJ"
	},
	{
		"rank": "417",
		"name": "Hunter Long",
		"team": "MIA"
	},
	{
		"rank": "418",
		"name": "Geoff Swaim",
		"team": "TEN"
	},
	{
		"rank": "419",
		"name": "Ihmir Smith-Marsette",
		"team": "MIN"
	},
	{
		"rank": "420",
		"name": "Greg Joseph",
		"team": "MIN"
	},
	{
		"rank": "421",
		"name": "Foster Moreau",
		"team": "LV"
	},
	{
		"rank": "422",
		"name": "Darwin Thompson",
		"team": "KC"
	},
	{
		"rank": "423",
		"name": "Willie Snead IV",
		"team": "LV"
	},
	{
		"rank": "424",
		"name": "Jacob Eason",
		"team": "IND"
	},
	{
		"rank": "425",
		"name": "Damiere Byrd",
		"team": "CHI"
	},
	{
		"rank": "426",
		"name": "Devine Ozigbo",
		"team": "JAC"
	},
	{
		"rank": "427",
		"name": "Albert Wilson",
		"team": "MIA"
	},
	{
		"rank": "428",
		"name": "Jordan Love",
		"team": "GB"
	},
	{
		"rank": "429",
		"name": "Lil'Jordan Humphrey",
		"team": "NO"
	},
	{
		"rank": "430",
		"name": "Jacob Harris",
		"team": "LAR"
	},
	{
		"rank": "431",
		"name": "Isaiah McKenzie",
		"team": "BUF"
	},
	{
		"rank": "432",
		"name": "Taylor Heinicke",
		"team": "WAS"
	},
	{
		"rank": "433",
		"name": "Sam Ficken",
		"team": "TEN"
	},
	{
		"rank": "434",
		"name": "Devin Funchess",
		"team": "GB"
	},
	{
		"rank": "435",
		"name": "Tim Tebow",
		"team": "JAC"
	},
	{
		"rank": "436",
		"name": "Jaret Patterson",
		"team": "WAS"
	},
	{
		"rank": "437",
		"name": "Mohamed Sanu",
		"team": "SF"
	},
	{
		"rank": "438",
		"name": "Kyle Juszczyk",
		"team": "SF"
	},
	{
		"rank": "439",
		"name": "Alex Collins",
		"team": "SEA"
	},
	{
		"rank": "440",
		"name": "Jace Sternberger",
		"team": "GB"
	},
	{
		"rank": "441",
		"name": "Richie James Jr.",
		"team": "SF"
	},
	{
		"rank": "442",
		"name": "Freddie Swain",
		"team": "SEA"
	},
	{
		"rank": "443",
		"name": "Noah Gray",
		"team": "KC"
	},
	{
		"rank": "444",
		"name": "Kenny Stills",
		"team": "BUF"
	},
	{
		"rank": "445",
		"name": "Ryan Griffin",
		"team": "NYJ"
	},
	{
		"rank": "446",
		"name": "Travis Homer",
		"team": "SEA"
	},
	{
		"rank": "447",
		"name": "Jeremy McNichols",
		"team": "TEN"
	},
	{
		"rank": "448",
		"name": "Trayveon Williams",
		"team": "CIN"
	},
	{
		"rank": "449",
		"name": "Richard Rodgers",
		"team": "PHI"
	},
	{
		"rank": "450",
		"name": "Frank Gore,",
		"team": "N/A"
	},
	{
		"rank": "451",
		"name": "Davis Mills",
		"team": "HOU"
	},
	{
		"rank": "452",
		"name": "Raymond Calais",
		"team": "LAR"
	},
	{
		"rank": "453",
		"name": "Kylen Granson",
		"team": "IND"
	},
	{
		"rank": "454",
		"name": "Frank Darby",
		"team": "ATL"
	},
	{
		"rank": "455",
		"name": "Jaylen Samuels",
		"team": "PIT"
	},
	{
		"rank": "456",
		"name": "Kelvin Harmon",
		"team": "WAS"
	},
	{
		"rank": "457",
		"name": "Marquez Stevenson",
		"team": "BUF"
	},
	{
		"rank": "458",
		"name": "Devonta Freeman",
		"team": "NO"
	},
	{
		"rank": "459",
		"name": "J.J. Arcega-Whiteside",
		"team": "PHI"
	},
	{
		"rank": "460",
		"name": "Tommy Tremble",
		"team": "CAR"
	},
	{
		"rank": "461",
		"name": "Equanimeous St. Brown",
		"team": "GB"
	},
	{
		"rank": "462",
		"name": "Kahale Warring",
		"team": "HOU"
	},
	{
		"rank": "463",
		"name": "Ty Montgomery",
		"team": "NO"
	},
	{
		"rank": "464",
		"name": "Nick Boyle",
		"team": "BAL"
	},
	{
		"rank": "465",
		"name": "Quez Watkins",
		"team": "PHI"
	},
	{
		"rank": "466",
		"name": "Zay Jones",
		"team": "LV"
	},
	{
		"rank": "467",
		"name": "Jakeem Grant Sr.",
		"team": "MIA"
	},
	{
		"rank": "468",
		"name": "Braxton Berrios",
		"team": "NYJ"
	},
	{
		"rank": "469",
		"name": "Brycen Hopkins",
		"team": "LAR"
	},
	{
		"rank": "470",
		"name": "Simi Fehoko",
		"team": "DAL"
	},
	{
		"rank": "471",
		"name": "Marcus Mariota",
		"team": "LV"
	},
	{
		"rank": "472",
		"name": "Kene Nwangwu",
		"team": "MIN"
	},
	{
		"rank": "473",
		"name": "Seth Williams",
		"team": "DEN"
	},
	{
		"rank": "474",
		"name": "Chad Beebe",
		"team": "MIN"
	},
	{
		"rank": "475",
		"name": "Sage Surratt",
		"team": "DET"
	},
	{
		"rank": "476",
		"name": "Cedrick Wilson",
		"team": "DAL"
	},
	{
		"rank": "477",
		"name": "JaMycal Hasty",
		"team": "SF"
	},
	{
		"rank": "478",
		"name": "Antonio Callaway",
		"team": "KC"
	},
	{
		"rank": "479",
		"name": "Marquise Goodwin",
		"team": "CHI"
	},
	{
		"rank": "480",
		"name": "Jaelon Darden",
		"team": "TB"
	},
	{
		"rank": "481",
		"name": "Gary Brightwell",
		"team": "NYG"
	},
	{
		"rank": "482",
		"name": "Corey Clement",
		"team": "NYG"
	},
	{
		"rank": "483",
		"name": "Hunter Bryant",
		"team": "DET"
	},
	{
		"rank": "484",
		"name": "Demetric Felton",
		"team": "CLE"
	},
	{
		"rank": "485",
		"name": "Tre' McKitty",
		"team": "LAC"
	},
	{
		"rank": "486",
		"name": "LeSean McCoy,",
		"team": "N/A"
	},
	{
		"rank": "487",
		"name": "Shi Smith",
		"team": "CAR"
	},
	{
		"rank": "488",
		"name": "Dwayne Haskins",
		"team": "PIT"
	},
	{
		"rank": "489",
		"name": "Javon Wims",
		"team": "CHI"
	},
	{
		"rank": "490",
		"name": "Adam Shaheen",
		"team": "MIA"
	},
	{
		"rank": "491",
		"name": "Ross Dwelley",
		"team": "SF"
	},
	{
		"rank": "492",
		"name": "Trey Burton,",
		"team": "N/A"
	},
	{
		"rank": "493",
		"name": "Pooka Williams Jr.",
		"team": "CIN"
	},
	{
		"rank": "494",
		"name": "Darren Fells",
		"team": "DET"
	},
	{
		"rank": "495",
		"name": "Kenny Yeboah",
		"team": "NYJ"
	},
	{
		"rank": "496",
		"name": "Aldrick Rosas",
		"team": "JAC"
	},
	{
		"rank": "497",
		"name": "D'Ernest Johnson",
		"team": "CLE"
	},
	{
		"rank": "498",
		"name": "Josh Oliver",
		"team": "BAL"
	},
	{
		"rank": "499",
		"name": "Phillip Dorsett II",
		"team": "JAC"
	},
	{
		"rank": "500",
		"name": "Andrew Jacas",
		"team": "SF"
	},
	{
		"rank": "501",
		"name": "DaeSean Hamilton",
		"team": "DEN"
	},
	{
		"rank": "502",
		"name": "Cade Johnson",
		"team": "SEA"
	},
	{
		"rank": "503",
		"name": "Cameron Batson",
		"team": "TEN"
	},
	{
		"rank": "504",
		"name": "Stevie Scott III",
		"team": "NO"
	},
	{
		"rank": "505",
		"name": "Nick Vannett",
		"team": "NO"
	},
	{
		"rank": "506",
		"name": "John Hightower",
		"team": "PHI"
	},
	{
		"rank": "507",
		"name": "Devin Asiasi",
		"team": "NE"
	},
	{
		"rank": "508",
		"name": "Josiah Deguara",
		"team": "GB"
	},
	{
		"rank": "509",
		"name": "Trey Ragas",
		"team": "LV"
	},
		{
			"rank": 512,
			"name": "ATL Defence",
			"team": "ATL"
		},
		{
			"rank": 513,
			"name": "ARI Defence",
			"team": "ARI"
		},
		{
			"rank": 514,
			"name": "BAL Defence",
			"team": "BAL"
		},
		{
			"rank": 515,
			"name": "BUF Defence",
			"team": "BUF"
		},
		{
			"rank": 516,
			"name": "CAR Defence",
			"team": "CAR"
		},
		{
			"rank": 517,
			"name": "CHI Defence",
			"team": "CHI"
		},
		{
			"rank": 518,
			"name": "CIN Defence",
			"team": "CIN"
		},
		{
			"rank": 519,
			"name": "CLE Defence",
			"team": "CLE"
		},
		{
			"rank": 520,
			"name": "DAL Defence",
			"team": "DAL"
		},
		{
			"rank": 521,
			"name": "DEN Defence",
			"team": "DEN"
		},
		{
			"rank": 522,
			"name": "DET Defence",
			"team": "DET"
		},
		{
			"rank": 523,
			"name": "GB Defence",
			"team": "GB"
		},
		{
			"rank": 524,
			"name": "HOU Defence",
			"team": "HOU"
		},
		{
			"rank": 525,
			"name": "IND Defence",
			"team": "IND"
		},
		{
			"rank": 527,
			"name": "JAX Defence",
			"team": "JAX"
		},
		{
			"rank": 528,
			"name": "KC Defence",
			"team": "KC"
		},
		{
			"rank": 529,
			"name": "LA Defence",
			"team": "LA"
		},
		{
			"rank": 530,
			"name": "LAC Defence",
			"team": "LAC"
		},
		{
			"rank": 531,
			"name": "LV Defence",
			"team": "LV"
		},
		{
			"rank": 533,
			"name": "MIA Defence",
			"team": "MIA"
		},
		{
			"rank": 534,
			"name": "MIN Defence",
			"team": "MIN"
		},
		{
			"rank": 535,
			"name": "NE Defence",
			"team": "NE"
		},
		{
			"rank": 536,
			"name": "NO Defence",
			"team": "NO"
		},
		{
			"rank": 537,
			"name": "NYG Defence",
			"team": "NYG"
		},
		{
			"rank": 538,
			"name": "NYJ Defence",
			"team": "NYJ"
		},
		{
			"rank": 539,
			"name": "PHI Defence",
			"team": "PHI"
		},
		{
			"rank": 540,
			"name": "PIT Defence",
			"team": "PIT"
		},
		{
			"rank": 541,
			"name": "SF Defence",
			"team": "SF"
		},
		{
			"rank": 542,
			"name": "SEA Defence",
			"team": "SEA"
		},
		{
			"rank": 543,
			"name": "TB Defence",
			"team": "TB"
		},
		{
			"rank": 544,
			"name": "TEN Defence",
			"team": "TEN"
		},
		{
			"rank": 545,
			"name": "WAS Defence",
			"team": "WAS"
		}
];

export default rankStandard;
