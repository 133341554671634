import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { GA } from '../../helpers/ga';
import Hero from '../../components/Common/Hero';
import NameEdit from './NameEdit';
import Modal from '../../containers/Modal/Modal';
import getUserName from '../../helpers/getUserName';
import {setUserName} from '../../actions/setUserName';

import './YourData.scss';

const YourData = ({ isPosting, setUserName, shakitzUser, userObj, userName }) => {
	const [ showModal, setShowModal ] = useState(false);
	const [ hasInteracted, setHasInteracted ] = useState(false);
	const onSubmit = (data) => {
		ReactGA.event({
			category: GA.CATEGORY.TEAM,
			action: GA.ACTION.EDIT_TEAM_NAME
		});
		setHasInteracted(true);
		setUserName(shakitzUser.userId, data.name);
	};

	useEffect(() => {
		if(hasInteracted && userName) {
			setShowModal(false);
			setHasInteracted(false);
		}
	}, [hasInteracted, showModal, userName]);

	const name = userName || getUserName(userObj, shakitzUser);
	const modalName = `User Name Edit - ${shakitzUser.userId}`;
	return (
		<div className='your-data'>
			<Hero bubbles joinJourney text={`Your Leagues`} secondaryText={name}/>
			{showModal ? <Modal name={modalName} onClose={() => setShowModal(false)}><NameEdit currentValue={name} isPosting={isPosting} onClose={() => setShowModal(false)} onSubmit={onSubmit} /></Modal> : null}
		</div>
	)
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		setUserName: (shakitzUserId, name) => dispatch(setUserName(shakitzUserId, name)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		hasErrored: state.setUserNameFailure,
		isPosting: state.setUserNameRequest,
		userName: state.setUserName
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(YourData);
