import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet';
import ReactGA from 'react-ga';
import { GA } from '../../helpers/ga';
import queryString from 'query-string';
import Keys from 'shakitz-keys';
import shakitzWaivers from 'shakitz-waivers';
import Header from '../../components/Common/Header';
import WaiverToggle from '../../components/WaiverToggle';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import { fetchWaivers } from '../../actions/fetchWaivers';
import CreateWaiverPane from '../CreateWaiverPane';
import ProposedWaiverPane from '../ProposedWaiverPane';
import WaiverHistoryPane from '../WaiverHistoryPane';
import Countdown from '../../components/Common/Countdown';
import hasSeasonStarted from '../../helpers/hasSeasonStarted';
import SeasonNotStarted from '../../components/SeasonNotStarted';
import ShapeGroup1 from '../../components/svgs/logo/ShapeGroup1';
import ErrorIcon from '../../components/svgs/ErrorIcon';
import './Waivers.scss';
import mergeSeasonStatsWithPlayers from '../../reducers/mergeSeasonStatsWithPlayers';
import ShapeGroup2 from '../../components/svgs/logo/ShapeGroup2';

class Waivers extends Component {
	CREATE_WAIVER_PANE = 0;
	PROPOSED_WAIVER_PANE  = 1;
	HISTORIC_WAIVER_PANE = 2;

	constructor(props) {
		super(props);
		const queryParams = queryString.parse(props.location.search);
		let pane = queryParams.proposed ? this.PROPOSED_WAIVER_PANE : this.CREATE_WAIVER_PANE;
		this.state = {
			activePane: pane
		};
		this.onToggle = this.onToggle.bind(this);
	}

	componentDidMount() {
		if(Object.keys(this.props.currentGameWeek).length > 0 && this.props.selectedLeague) {
			this.fetchWaivers();
		}
	}

	componentDidUpdate(prevProps) {
		if(!prevProps.selectedLeague && this.props.selectedLeague) {
			this.fetchWaivers();
		}

		const type = this.props.selectedLeague.type;
		if(type && (!prevProps.currentGameWeek[type] && this.props.currentGameWeek[type])) {
			this.fetchWaivers();
		}

		if(prevProps.isPosting && !this.props.isPosting) {
			this.fetchWaivers();
		}
	}

	fetchWaivers() {
		const { leagueId, type, season } = this.props.selectedLeague;

		if(!hasSeasonStarted(this.props.selectedLeague)) {
			return;
		}

		let gameWeek = this.props.currentGameWeek[type];
		const seasonUserTeamId = Keys.generateSeasonUserTeam({ leagueId, season, userId: this.props.shakitzUser.userId});

		console.log('fetch waivers', gameWeek, seasonUserTeamId);
		if(!gameWeek || !seasonUserTeamId) {
			return;
		}
		const areWaiversLocked = shakitzWaivers.areWaiversLocked();
		console.log('are waivers locked', areWaiversLocked);
		if(areWaiversLocked) {
			gameWeek +=1;
		}
		this.props.fetchWaivers(leagueId, season, seasonUserTeamId, gameWeek);
	}

	onToggle(pane) {
		ReactGA.event({
			category: GA.CATEGORY.WAIVER,
			action: GA.ACTION.WAIVER_PANE_TOGGLE[pane]
		});
		this.setState({
			activePane: pane
		});
	}

	renderPane(activePane, players) {
		const { leagueId, type } = this.props.selectedLeague;
		const waivers = this.getWaiverObjForLeague(leagueId);
		const gameWeek = this.props.selectedLeague.type && this.props.currentGameWeek ? this.props.currentGameWeek[type] : null;

		if(gameWeek === 1) {
			return (
				<div className='waivers__closed'>
					<ErrorIcon fill={'#000'} opacity={1} />
					<Header headerLevel={'h3'} text={"🔒 Waiver Wire opens game week 2 🔒"} />
				</div>
			)
		}
		if(activePane === this.CREATE_WAIVER_PANE) {
			return <CreateWaiverPane players={players} selectedLeague={this.props.selectedLeague} unavailablePlayers={this.props.unavailablePlayers} userId={this.props.shakitzUser.userId}/>
		} else if (activePane === this.PROPOSED_WAIVER_PANE) {
			const seasonUserTeam = waivers.seasonUserTeams.find(team => team.hashedUser === this.props.shakitzUser.userId);

			return <ProposedWaiverPane seasonUserTeam={seasonUserTeam} waivers={waivers.waivers.active} userId={this.props.shakitzUser.userId} />
		} else {
			return <WaiverHistoryPane historicWaivers={waivers.waivers.history} userId={this.props.shakitzUser.userId} />
		}
	}

	getWaiverObjForLeague(leagueId) {
		if(!leagueId || !this.props.waivers[leagueId]) {
			return {
				seasonUserTeams: [],
				waivers: {
					active: {
						waiversList: []
					},
					history: []
				}
			}
		}
		return this.props.waivers[leagueId];
	}

	renderCountdown() {
		const { leagueId, type } = this.props.selectedLeague;
		const gameWeek = this.props.selectedLeague.type && this.props.currentGameWeek ? this.props.currentGameWeek[type] : null;

		if(gameWeek === 1) {
			return null
		} else {
			const date = new Date(new Date().toUTCString());
			const nextWednesday = new Date(date.setDate(date.getDate() + (3 + 7 - date.getDay()) % 7));
			nextWednesday.setUTCHours(22);
			nextWednesday.setUTCMinutes(0);
			nextWednesday.setUTCSeconds(0);
			nextWednesday.setUTCMilliseconds(0);

			const waiversList = this.getWaiverObjForLeague(leagueId).waivers.active.waiversList;
			const waiversProposed = waiversList.length;
			const secondaryText = waiversProposed === 0 ? 'Incomplete' : `${waiversProposed} proposed`;
			return <Countdown alternate={true} timeToCountdownTo={nextWednesday} type={'Waiver wire closes in'} reminderText={'Waiver wire status:'} reminderSecondaryText={secondaryText} />
		}
	}

	render () {
		const waiverPlayers = mergeSeasonStatsWithPlayers(this.props.seasonPlayerStats, this.props.players);

		return (
			<div className='waivers'>
				<Helmet>
					<title>Shakitz - Waiver Wire</title>
					<link rel="canonical" href="https://shakitz.com/team" />
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				{!hasSeasonStarted(this.props.selectedLeague) ? <SeasonNotStarted /> : (
					<>
						<div className='waivers__heading'>
							<Header headerLevel={'h1'} text={'Waiver Wire'} />
							{this.renderCountdown()}
							<div className='waivers__bubbles'>
								<ShapeGroup1 width='100' height='152' />
								<ShapeGroup2 width='100' height='152' />
							</div>
							<div className='waivers__toggle'>
								<WaiverToggle activePane={this.state.activePane} onToggle={this.onToggle} />
							</div>
						</div>
						<div className='waivers__content'>
							<div className='waivers__pane-container'>
								{!this.props.isFetching && this.renderPane(this.state.activePane, waiverPlayers)}
							</div>
						</div>
						{this.props.isFetching ? <SpinnerLayer/> : null}
					</>
				)}
			</div>
		);
	}
}

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchWaivers: (leagueId, season, seasonUserTeamId, gameWeek) => dispatch(fetchWaivers(leagueId, season, seasonUserTeamId, gameWeek))
	};
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		players: state.players,
		seasonPlayerStats: state.seasonPlayerStats,
		waivers: state.waivers,
		isFetching: state.currentGameWeekRequest || state.seasonPlayerStatsRequest || state.gameWeekTeamsRequest || state.waiverRequest || !state.activeGameWeekTeam,
		isPosting: state.addWaiverRequest
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Waivers);
