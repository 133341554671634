import React from 'react';

const withEscListener = (WrappedComponent) => {
	// ...and returns another component...
	 return class extends React.Component {
		constructor(props) {
			super(props);
			this.closeModal = this.closeModal.bind(this);
		}

		componentDidMount() {
		    document.addEventListener('keydown', this.closeModal, false);
		}

		componentWillUnmount() {
			document.removeEventListener('keydown', this.closeModal, false);
		}

		 closeModal (evt) {
			 const ESC_KEY = 27;
			 if(evt.keyCode === ESC_KEY) {
				this.props.onClose();
			 }
		 }

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};
};


export default withEscListener;
