import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import {Constants} from '../../helpers/Constants';
import getGameWeekState from '../../helpers/getGameWeekState';
import SeasonNotStarted from '../../components/SeasonNotStarted';
import PreGameDay from './PreGameDay';
import LiveGameDay from './LiveGameDay';
import PostGameDay from './PostGameDay';
import './GameDay.scss';
import hasSeasonStarted from '../../helpers/hasSeasonStarted';

class GameDay extends Component {

	renderCorrectGameDay() {
		if(!hasSeasonStarted(this.props.selectedLeague)) {
			return { Component: SeasonNotStarted, state: {} }
		}

		if(!this.props.selectedLeague.type || !this.props.schedule[this.props.selectedLeague.type] || !this.props.currentGameWeek[this.props.selectedLeague.type]) {
			return { Component: null, state: {} }
		}

		//TODO auto-swap over
		const gameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
		const scheduleData = this.props.schedule[this.props.selectedLeague.type][gameWeek];
		const gameWeekState = getGameWeekState(scheduleData);
		const utcDateString = new Date().toUTCString();
		const utcDate = new Date(utcDateString);

		// Show post game day state until 10pm Wednesday
		const showPostState = utcDate.getUTCHours() <= 22 && utcDate.getUTCDay() !== 0 && utcDate.getUTCDay() <= 3 && gameWeek !== 1;

		if(!showPostState && gameWeekState === Constants.gameStates.PRE) {
			return { Component: PreGameDay, state: gameWeekState };
		} else if (gameWeekState === Constants.gameStates.LIVE) {
			return { Component: LiveGameDay, state: gameWeekState };
		} else {
			return { Component: PostGameDay, state: gameWeekState };
		}
	}

	render () {
		const { Component, state } = this.renderCorrectGameDay();
		return (
			<div className='game-day'>
				<Helmet>
					<title>Shakitz - Game Day</title>
					<link rel="canonical" href="https://shakitz.com/game-day" />
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				{Component && <Component shakitzUser={this.props.shakitzUser} gwState={state} {...this.props} />}
				{this.props.isFetching ? <SpinnerLayer /> : null}
			</div>
		);
	}
}

/** this gets fetched by the withCoreData HOC **/
const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		players: state.players,
		schedule: state.schedule,
		selectedLeague: state.selectedLeague
	};
};

export default connect(mapStateToProps)(GameDay);
