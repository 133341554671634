import React from 'react';
import Header from '../Common/Header';
import PlayerBox from '../Common/Players/PlayerBox';
import InfoIcon from '../svgs/InfoIcon';
import './Roster.scss';

const Roster = ({canEdit, heading, players, gameWeek}) => {
	const qb = players.filter(p => p.pos === 'QB');
	const rb = players.filter(p => p.pos === 'RB');
	const wr = players.filter(p => p.pos === 'WR');
	const te = players.filter(p => p.pos === 'TE');
	const k = players.filter(p => p.pos === 'K');
	const d = players.filter(p => p.pos === 'D');
	return (
		<div className='roster'>
			<div className='roster__header'>
				<Header headerLevel={'h2'} text={heading} />
				<div className='roster__player-explainer'>
					<span>Tap player for more info</span>
					<div className='roster__svg-wrapper'>
						<InfoIcon />
					</div>
				</div>
			</div>
			<div className='roster__list'>
				{qb.map(player => <PlayerBox key={`playerbox-${player.playerId}`} canEdit={canEdit} gameWeek={gameWeek} player={player} />)}
				{rb.map(player => <PlayerBox key={`playerbox-${player.playerId}`} canEdit={canEdit} gameWeek={gameWeek} player={player} />)}
				{wr.map(player => <PlayerBox key={`playerbox-${player.playerId}`} canEdit={canEdit} gameWeek={gameWeek} player={player} />)}
				{te.map(player => <PlayerBox key={`playerbox-${player.playerId}`} canEdit={canEdit} gameWeek={gameWeek} player={player} />)}
				{k.map(player => <PlayerBox key={`playerbox-${player.playerId}`} canEdit={canEdit} gameWeek={gameWeek} player={player} />)}
				{d.map(player => <PlayerBox key={`playerbox-${player.playerId}`} canEdit={canEdit} gameWeek={gameWeek} player={player} />)}
			</div>
		</div>
	)
};

export default Roster;
