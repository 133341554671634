import React, { useLayoutEffect, useState } from 'react';
import SearchIcon from '../svgs/SearchIcon';
import Toggle from '../Common/Toggle';
import {debounce} from 'debounce';

const _renderSearchBox = (onBlur, onChange, onFocus, searchValue) => (
	<div className='player-pool__search-box'>
		<input id='player-search' type='text' autoComplete='off' placeholder='Search player or full team name' value={searchValue} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
		<label htmlFor='player-search'>
			<SearchIcon />
		</label>
	</div>
);

const _renderDesktopView = (activeOption, onBlur, onChange, onFocus, onToggleClick, searchValue, toggleOptions) => (
	<>
		{_renderSearchBox(onBlur, onChange, onFocus, searchValue)}
		<Toggle activeOption={activeOption} toggleOptions={toggleOptions} onToggleClick={onToggleClick} />
	</>
);

const _renderMobileView = (activeOption, onBlur, onChange, onFocus, onToggleClick, searchValue, toggleOptions) => (
	<>
		<div className='player-pool__search-container'>
			{_renderSearchBox(onBlur, onChange, onFocus, searchValue)}
		</div>
		<div className='player-pool__toggle-container'>
			<div className='player-pool__label'>Filter by position:</div>
			<Toggle activeOption={activeOption} toggleOptions={toggleOptions} onToggleClick={onToggleClick} />
		</div>
	</>
);

const PlayerPoolSearch = ({activeOption, onBlur, onChange, onFocus, onToggleClick, searchValue, draftView = true, toggleOptions}) => {
	const [inResponsiveView, setInResponsiveView] = useState(window.innerWidth <= 900);
	const [listenerAdded, setListenerAdded] = useState(false);
	const onResize = () => {
		if(window.innerWidth <= 900) {
			setInResponsiveView(true);
		} else {
			setInResponsiveView(false);
		}
	};

	const resizeListener = debounce(onResize, 75);
	useLayoutEffect(() => {
		if(!listenerAdded) {
			window.addEventListener('resize', resizeListener);
			setListenerAdded(true);
		}
	});
	return (
		<div className='player-pool__header'>
			{inResponsiveView ? _renderMobileView(activeOption, onBlur, onChange, onFocus, onToggleClick, searchValue, toggleOptions) : _renderDesktopView(activeOption, onBlur, onChange, onFocus, onToggleClick, searchValue, toggleOptions)}
		</div>
	);
};

export default PlayerPoolSearch;
