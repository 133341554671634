import { FETCH_RECORDS_FAILURE, FETCH_RECORDS_REQUEST, FETCH_RECORDS_SUCCESS} from '../actions/fetchRecords';

export function recordsFailure(state = false, action) {
	switch (action.type) {
		case FETCH_RECORDS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function recordsRequest(state = false, action) {
	switch (action.type) {
		case FETCH_RECORDS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function records(state = {}, action) {
	switch (action.type) {
		case FETCH_RECORDS_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
