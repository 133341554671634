import axios from 'axios';
import { Constants } from '../helpers/Constants';
export const FETCH_NEXT_H2H_DATA_REQUEST = 'FETCH_NEXT_H2H_DATA_REQUEST';
export const FETCH_NEXT_H2H_DATA_SUCCESS = 'FETCH_NEXT_H2H_DATA_SUCCESS';
export const FETCH_NEXT_H2H_DATA_FAILURE = 'FETCH_NEXT_H2H_DATA_FAILURE';

export function fetchNextH2HDataRequest ( isFetching ) {
	return {
		type: FETCH_NEXT_H2H_DATA_REQUEST,
		isFetching
	};
}

export function fetchNextH2HDataFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_NEXT_H2H_DATA_FAILURE,
		response: err,
		error: true
	};
}

export function fetchNextH2HDataSuccess( data, leagueId ) {
	return {
		type: FETCH_NEXT_H2H_DATA_SUCCESS,
		data,
		leagueId
	};
}

export function fetchNextH2HData (leagueId, season, gameWeek = '17') {
	return ( dispatch ) => {
		dispatch(fetchNextH2HDataRequest(true));
		const url = Constants.urls.GRAPHQL;

		const standingsQuery = season < Constants.currentSeason ?
			`historicStandings(leagueId: "${leagueId}",gameWeek: "${gameWeek}", season: "${season}") {
			    standings {
			      manager
			      record
			      seasonUserTeamId
			    }
			}` : '';
		const query = `
			query {
				${standingsQuery}
				draft(leagueId: "${leagueId}", season:"${season}") {
				  draft{
				    R1{
				      playerName {
				        display
				      }
				      pick
				      pos
				      nflTeam
				      playerId
				      userId
				    }
				    R2 {
				      playerName {
				        display
				      }
				      pick
				      pos
				      nflTeam
				      playerId
				      userId
				    }
				    R3 {
				      playerName{
				        display
				      }
				      pick
				      pos
				      nflTeam
				      playerId
				      userId
				    }
				    draftId
				  }
				}
			  }
		`;
		axios.post(url, {
			query: query.replace(/\t+/g, "")
		})
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchNextH2HDataRequest(false));

			return response.data.data;
		}).then(data => dispatch(fetchNextH2HDataSuccess(data, leagueId))).catch(err => {
			dispatch(fetchNextH2HDataRequest(false));
			dispatch(fetchNextH2HDataFailure(err))
		});
	};
}
