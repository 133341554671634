import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const PUT_CONFIRM_TRADE_REQUEST = 'PUT_CONFIRM_TRADE_REQUEST';
export const PUT_CONFIRM_TRADE_SUCCESS = 'PUT_CONFIRM_TRADE_SUCCESS';
export const PUT_CONFIRM_TRADE_FAILURE = 'PUT_CONFIRM_TRADE_FAILURE';

export function confirmTradeRequest ( isPosting ) {
	return {
		type: PUT_CONFIRM_TRADE_REQUEST,
		isPosting
	};
}

export function confirmTradeFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: PUT_CONFIRM_TRADE_FAILURE,
		response: err,
		error: true
	};
}

export function confirmTradeSuccess( data ) {
	return {
		type: PUT_CONFIRM_TRADE_SUCCESS,
		data
	};
}

export function confirmTrade ( leagueId, tradeId ) {
	return ( dispatch ) => {
		dispatch(confirmTradeRequest(true));

		const url = `${Constants.urls.TRADES}/confirm`;
		axios.put(url, { leagueId, tradeId })
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			setTimeout(() => {
				dispatch(confirmTradeRequest(false));
			}, 1100);

			return response.data;
		}).then(data => dispatch(confirmTradeSuccess(data)))
		.catch(err => dispatch(confirmTradeFailure(err)));
	};
}
