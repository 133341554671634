import React, {Component} from 'react';
import Header from '../../components/Common/Header';
import Fixture from '../../components/H2HFixture/Fixture';
import LeftArrow from '../../components/svgs/LeftArrow';
import RightArrow from '../../components/svgs/RightArrow';
import './H2HFixtures.scss';

class H2HFixtures extends Component {

	showScores () {
		if(this.props.liveMode || this.props.showScores) {
			return true;
		}

		// If in pre mode, this will be future games. If in live mode, liveMode will ensure scores show anyway
		if(this.props.gameWeekToShow >= this.props.currentGameWeek) {
			return false;
		}

		if(this.props.gameWeekToShow < this.props.currentGameWeek) {
			return true;
		}

		return false;
	}

	render () {
		const validFixtures = this.props.fixtures.filter(fixture => fixture.gameWeek === this.props.gameWeekToShow);
		const prevDisabled = this.props.gameWeekToShow - 1 <= 0;
		const nextDisabled = this.props.gameWeekToShow + 1 > this.props.maxGameWeeks;
		const text = this.props.gameWeekToShow >= this.props.currentGameWeek && !this.props.showScores ? 'Fixtures' : 'Results';

		return (
			<div className='h2h-fixtures'>
				<div className='h2h-fixtures__header'>
					<Header headerLevel={'h1'} text={`GW${this.props.gameWeekToShow} ${text}`} />
					{this.props.liveMode || this.props.hideToggle ? null : (
					<div className='h2h-fixtures__navigation'>
						<div className='h2h-fixtures__arrow-container' onClick={() => this.props.onToggle(this.props.gameWeekToShow-1)}>
							<LeftArrow disabled={prevDisabled} />
						</div>
						<hr className='h2h-fixtures__separator' />
						<div className='h2h-fixtures__arrow-container' onClick={() => this.props.onToggle(this.props.gameWeekToShow+1)}>
							<RightArrow disabled={nextDisabled} />
						</div>
					</div>
					)}
				</div>
				<div className='h2h-fixtures__fixture-list'>
					{}
					{validFixtures.length === 0 ?
						<div className='h2h-fixtures__empty'>No Fixtures</div>
					: validFixtures.map(fixture => {
							return (
								<div key={`H2HF_${fixture.gameWeek}_${fixture.homeTeamId}_${fixture.awayTeamId}`} className='h2h-fixtures__fixture'>
									<Fixture data={fixture} liveMode={this.showScores()}/>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	}
}

export default H2HFixtures;
