import * as StatSVGs from '../components/svgs/stats';

const PassingPointsStrategy = [{
	name: 'passingYds',
	description: '1 point per # yards (decimal)',
	defaultValue: 30,
	icon: StatSVGs.PassingYds,
	text: 'Passing Yds',
	min: 0
}, {
	name: 'passingTds',
	description: 'Points per passing touchdowns',
	defaultValue: 4,
	icon: StatSVGs.Touchdown,
	text: 'Passing Tds',
	min: 0
}, {
	name: 'passing2pts',
	description: 'Points per passing 2pt conversions',
	defaultValue: 2,
	icon: StatSVGs.Passing2pts,
	text: 'Passing 2ptm',
	min: 0
}, {
	name: 'intsThrown',
	description: 'Points per interception thrown',
	defaultValue: -2,
	icon: StatSVGs.Interception,
	text: 'Ints thrown',
	min: -30
}, {
	name: 'sacksConceded',
	description: 'Points per sack conceded',
	defaultValue: -1,
	icon: StatSVGs.Sack,
	text: 'Times sacked',
	min: -30
}, {
	name: 'safetyConceded',
	description: 'Points per safety conceded',
	defaultValue: -2,
	icon: StatSVGs.Safety,
	text: 'Safeties conceded',
	min: -30
}];

const OffensivePointsStrategy= [{
	name: 'receivingRec',
	description: 'Points per reception made',
	defaultValue: 0,
	icon: StatSVGs.Reception,
	text: 'Receptions',
	pprStat: true
}, {
	name: 'receivingYds',
	description: '1 point per # yards (decimal)',
	defaultValue: 10,
	icon: StatSVGs.ReceivingYds,
	text: 'Receiving Yds',
	min: 0
}, {
	name: 'receivingTds',
	description: 'Points per receiving touchdowns',
	defaultValue: 6,
	icon: StatSVGs.Touchdown,
	text: 'Receiving Tds',
	min: 0
}, {
	name: 'receiving2pts',
	description: 'Points per receiving 2pt conversions',
	defaultValue: 2,
	icon: StatSVGs.Receiving2pts,
	text: 'Receiving 2ptm',
	min: 0
}, {
	name: 'rushingYds',
	description: '1 point per # yards (decimal)',
	defaultValue: 10,
	icon: StatSVGs.RushingYds,
	text: 'Rushing Yds',
	min: 0
}, {
	name: 'rushingTds',
	description: 'Points per rushing touchdowns',
	defaultValue: 6,
	icon: StatSVGs.Touchdown,
	text: 'Rushing Tds',
	min: 0
}, {
	name: 'rushing2pts',
	description: 'Points per rushing 2pt conversions',
	defaultValue: 2,
	icon: StatSVGs.Rushing2pts,
	text: 'Rushing 2ptm',
	min: 0
}, {
	name: 'fumblesLost',
	description: 'Points per fumbles lost',
	defaultValue: -2,
	icon: StatSVGs.Fumble,
	text: 'Fumbles lost',
	min: -30
}];

const DefensivePointsStrategy= [{
	name: 'fumblesWon',
	description: 'Points per fumbles won',
	defaultValue: 2,
	icon: StatSVGs.Fumble,
	text: 'Fumbles won',
	min: 0
}, {
	name: 'defensiveTds',
	description: 'Points per defensive touchdown scored',
	defaultValue: 6,
	icon: StatSVGs.Touchdown,
	text: 'Defensive Tds',
	min: 0
}, {
	name: 'kickReturnTds',
	description: 'Points per kick return touchdown scored',
	defaultValue: 6,
	icon: StatSVGs.Touchdown,
	text: 'Kick Return Tds',
	min: 0
},{
	name: 'kickOffReturnTds',
	description: 'Points per kick off return touchdown scored',
	defaultValue: 6,
	icon: StatSVGs.Touchdown,
	text: 'Kick Off Return Tds',
	min: 0
},{
	name: 'puntReturnTds',
	description: 'Points per punt return touchdown scored',
	defaultValue: 6,
	icon: StatSVGs.Touchdown,
	text: 'Punt Return Tds',
	min: 0
},{
	name: 'sacksMade',
	description: 'Points per sacks made',
	defaultValue: 1,
	icon: StatSVGs.Sack,
	text: 'Sacks made',
	min: 0
}, {
	name: 'intsCaught',
	description: 'Points per interception caught',
	defaultValue: 2,
	icon: StatSVGs.Interception,
	text: 'Interceptions caught',
	min: 0
}, {
	name: 'safetyWon',
	description: 'Points per safety won',
	defaultValue: 2,
	icon: StatSVGs.Safety,
	text: 'Safeties',
	min: 0
}, {
	name: 'ptsConceded0',
	description: 'Points for defensive score of 0',
	defaultValue: 10,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: 0
}, {
	name: 'ptsConceded1to6',
	description: 'Points for defensive score of 1-6',
	defaultValue: 7,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: -30
}, {
	name: 'ptsConceded7to13',
	description: 'Points for defensive score of 7-13',
	defaultValue: 4,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: -30
}, {
	name: 'ptsConceded14to20',
	description: 'Points for defensive score of 14-20',
	defaultValue: 1,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: -30
}, {
	name: 'ptsConceded21to27',
	description: 'Points for defensive score of 21-27',
	defaultValue: 0,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: -30
}, {
	name: 'ptsConceded28to34',
	description: 'Points for defensive score of 28-34',
	defaultValue: -1,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: -30
}, {
	name: 'ptsConceded35More',
	description: 'Points for defensive score of 35+',
	defaultValue: -4,
	icon: StatSVGs.PointsConceded,
	text: 'Points conceded',
	min: -30
}];

const KickingPointsStrategy= [{
	name: 'fgKicked',
	description: 'Points per field goal scored',
	defaultValue: 3,
	icon: StatSVGs.FieldGoal,
	text: 'Field Goals scored',
	min: 0
}, {
	name: 'expFgKicked',
	description: 'Points per extra point scored',
	defaultValue: 1,
	icon: StatSVGs.FieldGoal,
	text: 'XP scored',
	min: 0
}];

const PointsStrategy = {
	passing: PassingPointsStrategy,
	offensive: OffensivePointsStrategy,
	defensive: DefensivePointsStrategy,
	kicking: KickingPointsStrategy
};

export default PointsStrategy;
