import React from 'react';

const TrophyIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#trophy_filter0_d)">
			<circle cx="16" cy="14" r="12" fill="url(#trophy_paint0_linear)"/>
		</g>
		<path d="M21.36 8.60794C20.9198 8.51077 20.4114 8.57299 19.9332 8.75371C19.9373 8.49537 19.9362 8.24235 19.929 8H11.722C11.7148 8.24235 11.7131 8.49476 11.7178 8.75371C11.239 8.57358 10.7312 8.51076 10.291 8.60794C9.6398 8.75134 9.19778 9.19869 9.04668 9.86708C8.84878 10.7393 9.28429 11.7395 10.2738 12.6839C11.053 13.4275 12.0988 14.0462 13.2032 14.4289C13.6606 15.0742 14.2467 15.5939 14.9896 15.9097C14.8812 16.3055 14.5008 17.452 13.5824 18.1536L15.8251 18.1524H18.0696C17.1506 17.4509 16.7702 16.3043 16.6624 15.9085C17.4054 15.5927 17.9914 15.073 18.4488 14.4278C19.5527 14.0456 20.5985 13.427 21.3782 12.6833C22.3671 11.7388 22.8032 10.7393 22.6053 9.8665C22.4525 9.19872 22.0104 8.75135 21.3598 8.60796L21.36 8.60794ZM10.9314 11.9936C10.2163 11.3104 9.85896 10.5947 9.97627 10.078C10.045 9.7728 10.2103 9.60215 10.4965 9.53875C10.874 9.4552 11.3658 9.582 11.771 9.83264C11.8629 10.9265 12.0792 12.0682 12.4833 13.0815C11.9014 12.7793 11.3669 12.4096 10.9315 11.9936L10.9314 11.9936ZM20.7188 11.9936C20.2833 12.4096 19.7488 12.7793 19.167 13.0815C19.5712 12.0683 19.7874 10.9259 19.8793 9.83266C20.2846 9.58203 20.7764 9.45581 21.1538 9.53876C21.4394 9.60157 21.6047 9.77282 21.674 10.078C21.7914 10.5947 21.4341 11.3104 20.7189 11.9936L20.7188 11.9936Z" fill="white"/>
		<path d="M12.9431 18.7539H18.7073V20H12.9431V18.7539Z" fill="white"/>
		<defs>
			<filter id="trophy_filter0_d" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
				<feOffset dy="2"/>
				<feGaussianBlur stdDeviation="2"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
			</filter>
			<linearGradient id="trophy_paint0_linear" x1="8" y1="5.5" x2="22.5" y2="23.5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#341051"/>
				<stop offset="1" stopColor="#552878"/>
			</linearGradient>
		</defs>
	</svg>
);

export default TrophyIcon;
