import axios from 'axios';
import { Constants } from '../helpers/Constants';
import { fetchLeagueTradeDataSuccess } from '../actions/fetchLeagueTradeData';

export const DELETE_TRADE_REQUEST = 'DELETE_TRADE_REQUEST';
export const DELETE_TRADE_SUCCESS = 'DELETE_TRADE_SUCCESS';
export const DELETE_TRADE_FAILURE = 'DELETE_TRADE_FAILURE';

export function deleteTradeRequest ( isPosting ) {
	return {
		type: DELETE_TRADE_REQUEST,
		isPosting
	};
}

export function deleteTradeFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: DELETE_TRADE_FAILURE,
		response: err,
		error: true
	};
}

export function deleteTradeSuccess( data ) {
	return {
		type: DELETE_TRADE_SUCCESS,
		data
	};
}

export function deleteTrade ( leagueId, tradeId ) {
	return ( dispatch ) => {
		dispatch(deleteTradeRequest(true));

		const url = `${Constants.urls.TRADES}/${leagueId}/${tradeId}`;
		axios.delete(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.DELETE ) {
				throw Error(response.statusText);
			}

			setTimeout(() => {
				dispatch(deleteTradeRequest(false));
			}, 1100);

			return { message: 'Trade deleted'};
		}).then(data => dispatch(deleteTradeSuccess(data)))
		.then(() => dispatch(fetchLeagueTradeDataSuccess({ tradeId, tradeType: 'cancel' })))
		.catch(err => dispatch(deleteTradeFailure(err)));
	};
}
