import { SET_PLAYERS } from '../actions/setPlayers';

export function players (state = [], action) {
	switch (action.type) {
		case SET_PLAYERS:
			return action.players;
		default:
			return state;
	}
}
