import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const PUT_TEAM_NAME_REQUEST = 'PUT_TEAM_NAME_REQUEST';
export const PUT_TEAM_NAME_SUCCESS = 'PUT_TEAM_NAME_SUCCESS';
export const PUT_TEAM_NAME_FAILURE = 'PUT_TEAM_NAME_FAILURE';

export function setTeamNameRequest ( isPosting ) {
	return {
		type: PUT_TEAM_NAME_REQUEST,
		isPosting
	};
}

export function setTeamNameFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: PUT_TEAM_NAME_FAILURE,
		response: err,
		error: true
	};
}

export function setTeamNameSuccess( data ) {
	return {
		type: PUT_TEAM_NAME_SUCCESS,
		data
	};
}

export function setTeamName ( seasonUserTeamId, userId, shortTeamName, teamName ) {

	return ( dispatch ) => {
		dispatch(setTeamNameRequest(true));

		const url = `${Constants.urls.SEASON_USER_TEAM}/${seasonUserTeamId}`;
		axios.put(url, { userId, shortTeamName, teamName } )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(setTeamNameRequest(false));

			return response.data.Attributes;
		}).then(data => dispatch(setTeamNameSuccess({ shortTeamName, teamName })))
		.catch(err => dispatch(setTeamNameFailure(err)));
	};
}
