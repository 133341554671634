import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../../Common/Header';
import EditIcon from '../../svgs/EditIcon';
import {setTeamName} from '../../../actions/setTeamName';
import Modal from '../../../containers/Modal/Modal';
import TeamNameEdit from './TeamNameEdit';
import { ShapeGroup1, ShapeGroup2 } from '../../svgs/logo';
import './UserMyTeamHeader.scss';

const UserMyTeamHeader = (props) => {
	const {canEdit, shortTeamName, teamName, gwPoints, shakitzUserId} = props;
	const { isPosting, savedTeamName, seasonUserTeamId, setTeamName } = props;
	const [ showModal, setShowModal ] = useState(false);
	const [ hasInteracted, setHasInteracted ] = useState(false);
	const onSubmit = (data) => {
		setHasInteracted(true);
		setTeamName(seasonUserTeamId, shakitzUserId, data.shortName, data.name);
	};

	useEffect(() => {
		if(hasInteracted && savedTeamName) {
			setShowModal(false);
			setHasInteracted(false);
		}
	}, [hasInteracted, showModal, savedTeamName]);

	const tName = savedTeamName && canEdit ? savedTeamName.teamName : teamName;
	const shortTName = savedTeamName && canEdit ? savedTeamName.shortTeamName : shortTeamName;
	const modalName = `Team Name Edit = ${shakitzUserId}`;
	return (
		<div className='user-header'>
			<div className='user-header__bubbles'>
				<ShapeGroup2 width='70' height='100' />
				<ShapeGroup1 width='70' height='100' />
			</div>
			<div className='user-header__content'>
				<div className='user-header__header-wrapper'>
					<Header headerLevel={'h1-alternate'} text={tName} />
					{canEdit ? <EditIcon fill={'#FFF'} hoverFill={'#7c7c7c'} width={'16px'} height={'16px'} onClick={() => setShowModal(true)} /> : null}
				</div>
				<div className='user-header__points-wrapper'>
					<div className='user-header__points-title'>Overall Points:</div>
					<div className='user-header__points'>{isNaN(gwPoints) || gwPoints === 0 ? '-' : gwPoints}</div>
				</div>
			</div>
			{showModal ? <Modal name={modalName} onClose={() => setShowModal(false)}><TeamNameEdit shortTeamName={shortTName} teamName={tName} isPosting={isPosting} onClose={() => setShowModal(false)} onSubmit={onSubmit} /></Modal> : null}
		</div>
	)
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		setTeamName: (seasonUserTeamId, shakitzUserId, shortName, name) => dispatch(setTeamName(seasonUserTeamId, shakitzUserId, shortName, name)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		hasErrored: state.setTeamNameFailure,
		isPosting: state.setTeamNameRequest,
		savedTeamName: state.setTeamName
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMyTeamHeader);
