import { EDIT_DRAFT_PICK } from '../actions/editDraftPick';

export function editDraftPick (state = {}, action) {
	switch (action.type) {
		case EDIT_DRAFT_PICK:
			if(!action) {
				return {};
			}

			const newPick = { ...action.pick, ...action.manager };
			if(state.pick && JSON.stringify(state) === JSON.stringify(newPick)) {
				return {};
			}
			return newPick;
		default:
			return state;
	}
}
