export const SELECT_LEAGUE = 'SELECTED_LEAGUE';

export function selectLeague ( format, leagueId, season, seasonToBe, seasonType ) {
	if(navigator.userAgent === 'ReactSnap') return { type: '' };

	return {
		type: SELECT_LEAGUE,
		format,
		leagueId,
		season,
		seasonToBe,
		seasonType
	};
}
