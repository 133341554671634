import { FETCH_LEAGUE_GAME_WEEK_TEAMS_FAILURE, FETCH_LEAGUE_GAME_WEEK_TEAMS_REQUEST, FETCH_LEAGUE_GAME_WEEK_TEAMS_SUCCESS} from '../actions/fetchLeagueGameWeekTeams';

export function leagueGameWeekTeamsFailure(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_GAME_WEEK_TEAMS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function leagueGameWeekTeamsRequest(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_GAME_WEEK_TEAMS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function leagueGameWeekTeams(state = {}, action) {
	switch (action.type) {
		case FETCH_LEAGUE_GAME_WEEK_TEAMS_SUCCESS:
			const clonedState = JSON.parse(JSON.stringify(state));
			clonedState[action.leagueId] = action.data;
			return clonedState;
		default:
			return state;
	}
}
