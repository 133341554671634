import axios from 'axios';
import { Constants } from '../helpers/Constants';
import {editDraftPick} from './editDraftPick';

export const POST_DRAFT_PICK_REQUEST = 'POST_DRAFT_PICK_REQUEST';
export const POST_DRAFT_PICK_SUCCESS = 'POST_DRAFT_PICK_SUCCESS';
export const POST_DRAFT_PICK_FAILURE = 'POST_DRAFT_PICK_FAILURE';

export function postDraftPickRequest ( isPosting ) {
	return {
		type: POST_DRAFT_PICK_REQUEST,
		isPosting,
		response: null,
		error: false
	};
}

export function postDraftPickFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_DRAFT_PICK_FAILURE,
		response: err,
		isPosting: false,
		error: true
	};
}

export function postDraftPickSuccess( data ) {
	return {
		type: POST_DRAFT_PICK_SUCCESS,
		data
	};
}

export function postDraftPick ( { draftId, isEdit, leagueId, topic, userId, nextUserId, draftPick } ) {
	return ( dispatch ) => {
		dispatch(postDraftPickRequest(true));

		const url = `${Constants.urls.DRAFT}/pick/${draftId}`;

		axios.put(url, { draftPick, isEdit, leagueId, topic, userId, nextUserId })
			.then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				dispatch(postDraftPickRequest(false));
				dispatch(editDraftPick(null));
				return response.data;
			}).then(data => dispatch(postDraftPickSuccess(data)))
			.catch(err => dispatch(postDraftPickFailure(err)));
	};
}
