import React from 'react';
import Header from '../../components/Common/Header';
import Button from '../Common/Button';
import PointsStrategy from '../../helpers/PointsStrategy';
import './SetLeagueRulesForm.scss';
import './Form.scss';

const _getPPRMultiplier = (formatType) => {
	if(formatType === '0.5ppr') {
		return 0.5
	} else if (formatType === '1ppr') {
		return 1;
	}
	return 0;
};

const _renderRule = (formatType, rule) => {
	const value = rule.name === 'receivingRec' ? _getPPRMultiplier(formatType) : rule.defaultValue;

	return (
		<div className='league-rules-form__rule' key={`rule_type_${rule.name}`}>
			<label className='form__label' >{rule.description}</label>
			<input className='form__input form__input--number' name={rule.name} type='number' min={rule.min} defaultValue={value} autoComplete='off' disabled required/>
		</div>
	);
};


const SetLeagueRulesForm = ({formatType, onSubmit}) => {
	const pointTypes = Object.keys(PointsStrategy);

	return (
		<form className='league-rules-form form form--set-league-rules' onSubmit={onSubmit}>
			<Header headerLevel={'h2'} text={'Timelines'} />
			<div className='league-rules-form__timelines'>
				<span className='league-rules-form__time-desc'>Waiver deadline</span>
				<span className='league-rules-form__time'>Wednesday 10pm UTC</span>
			</div>
			<div className='league-rules-form__timelines'>
				<span className='league-rules-form__time-desc'>Trade deadline</span>
				<span className='league-rules-form__time'>Wednesday 10pm UTC</span>
			</div>
			<div className='league-rules-form__timelines'>
				<span className='league-rules-form__time-desc'>Team selection deadline</span>
				<span className='league-rules-form__time'>5 minutes before first match of the day</span>
			</div>
			<div className='league-rules-form__scoring'>
				<Header headerLevel={'h2'} text={'Scoring'} />
				{pointTypes.map(type => {
					return (
						<div key={`point_type_${type}`}>
							<Header headerLevel={'h3'} text={type} />
							{PointsStrategy[type].map(rule => _renderRule(formatType, rule))}
						</div>
					);
				})}
			</div>
			<Button text={'Create League'} type={'submit'} isSubmit={true} />
		</form>
	);
};

export default SetLeagueRulesForm;
