import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_HISTORIC_PLAYER_STATS_REQUEST = 'FETCH_HISTORIC_PLAYER_STATS_REQUEST';
export const FETCH_HISTORIC_PLAYER_STATS_SUCCESS = 'FETCH_HISTORIC_PLAYER_STATS_SUCCESS';
export const FETCH_HISTORIC_PLAYER_STATS_FAILURE = 'FETCH_HISTORIC_PLAYER_STATS_FAILURE';

export function fetchHistoricPlayerStatsRequest ( isFetching ) {
	return {
		type: FETCH_HISTORIC_PLAYER_STATS_REQUEST,
		isFetching
	};
}

export function fetchHistoricPlayerStatsFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_HISTORIC_PLAYER_STATS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchHistoricPlayerStatsSuccess( playerId, data ) {
	const obj = {};
	obj[playerId] = data;

	return {
		type: FETCH_HISTORIC_PLAYER_STATS_SUCCESS,
		data: obj
	};
}

export function fetchHistoricPlayerStats ( playerId, season, pprType, seasonType ) {
	return ( dispatch ) => {
		dispatch(fetchHistoricPlayerStatsRequest(true));

		const url = `${Constants.urls.PLAYER}/historic/stats/season/${season}/${seasonType}/${pprType}/${playerId}`;
		axios.get(url)
			.then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}

				dispatch(fetchHistoricPlayerStatsRequest(false));

				return response.data;
			}).then(data => {
				dispatch(fetchHistoricPlayerStatsSuccess(playerId, data));
			}).catch(err => dispatch(fetchHistoricPlayerStatsFailure(err)));
	};
}
