import React from 'react';

const TradeIcon = ({width = '17', height = '18', fill = '#fff', opacity = '.6'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 47 46" opacity={opacity}>
		<path fill={fill} d="M9.21 24.51c-2.173 0-4.131-1.309-4.964-3.316-.83-2.008-.372-4.318 1.165-5.855 1.536-1.537 3.846-1.995 5.855-1.165 2.007.833 3.316 2.792 3.316 4.963 0 1.426-.566 2.792-1.574 3.8-1.007 1.006-2.373 1.572-3.798 1.572zm0-7.675c-.931 0-1.772.56-2.128 1.422-.355.86-.16 1.85.498 2.51.66.658 1.65.853 2.51.498.861-.357 1.422-1.197 1.422-2.127 0-1.272-1.03-2.303-2.303-2.303z"/>
		<path fill={fill} d="M16.884 32.184H1.534C.688 32.184 0 31.497 0 30.649c0-3.29 1.756-6.33 4.605-7.975 2.849-1.645 6.36-1.645 9.209 0 2.849 1.646 4.605 4.685 4.605 7.975 0 .407-.162.798-.45 1.086-.287.288-.679.449-1.085.449zm-13.615-3.07h11.88c-.706-2.701-3.146-4.587-5.94-4.587-2.793 0-5.234 1.886-5.94 4.587zM36.837 24.51c-2.172 0-4.13-1.309-4.963-3.316-.831-2.008-.372-4.318 1.164-5.855 1.537-1.537 3.847-1.995 5.856-1.165 2.007.833 3.315 2.792 3.315 4.963 0 1.426-.566 2.792-1.573 3.8-1.007 1.006-2.373 1.572-3.799 1.572zm0-7.675c-.93 0-1.77.56-2.128 1.422-.355.86-.159 1.85.5 2.51.66.658 1.65.853 2.509.498.861-.357 1.421-1.197 1.421-2.127 0-1.272-1.03-2.303-2.302-2.303z"/>
		<path fill={fill} d="M44.511 32.184H29.163c-.849 0-1.535-.687-1.535-1.535 0-3.29 1.755-6.33 4.604-7.975 2.85-1.645 6.36-1.645 9.21 0 2.849 1.646 4.604 4.685 4.604 7.975 0 .407-.161.798-.449 1.086-.288.288-.679.449-1.086.449zm-13.614-3.07h11.88c-.706-2.701-3.147-4.587-5.94-4.587-2.794 0-5.234 1.886-5.94 4.587zM15.61 45.998c-.444.002-.864-.202-1.136-.553-1.013-1.228-1.827-5.172-2.134-6.815-.157-.848.403-1.661 1.251-1.818.848-.158 1.662.403 1.819 1.25.29 1.871.806 3.702 1.535 5.45.512.673.382 1.634-.292 2.148-.293.238-.666.357-1.043.338z"/>
		<path fill={fill} d="M13.814 39.858c-.472.002-.92-.213-1.213-.583-.499-.646-.405-1.571.215-2.103 1.228-.997 5.188-1.81 6.83-2.118.848-.153 1.658.41 1.811 1.259.154.848-.41 1.657-1.258 1.811-1.87.293-3.701.808-5.45 1.535-.287.153-.611.222-.935.2z"/>
		<path fill={fill} d="M23.422 43.22c-3.824-.01-7.533-1.309-10.53-3.684-.32-.255-.525-.625-.571-1.03-.046-.407.07-.814.326-1.134.53-.666 1.498-.775 2.164-.246 2.448 1.953 5.487 3.018 8.618 3.018 3.132 0 6.17-1.065 8.619-3.018.662-.522 1.62-.412 2.148.246.522.662.413 1.621-.245 2.149-2.99 2.39-6.702 3.695-10.53 3.699zM32.6 9.16c-.746.014-1.392-.51-1.534-1.242-.29-1.87-.806-3.701-1.535-5.45-.512-.673-.382-1.634.292-2.148.646-.499 1.57-.405 2.102.215.998 1.228 1.811 5.172 2.118 6.815.171.82-.347 1.627-1.166 1.81H32.6z"/>
		<path fill={fill} d="M26.461 10.957c-.848.076-1.596-.55-1.673-1.397-.077-.848.549-1.597 1.397-1.673 1.87-.294 3.7-.808 5.449-1.535.673-.512 1.635-.382 2.149.292.489.644.395 1.556-.215 2.087-1.228 1.013-5.188 1.826-6.83 2.133l-.277.092z"/>
		<path fill={fill} d="M13.445 9.16c-.65-.003-1.225-.415-1.44-1.03-.213-.613-.018-1.294.489-1.701 2.99-2.39 6.702-3.695 10.53-3.7 3.827-.013 7.543 1.288 10.529 3.685.666.53.775 1.498.245 2.164-.53.665-1.498.775-2.164.245-2.446-1.95-5.481-3.012-8.61-3.012-3.13 0-6.165 1.063-8.61 3.012-.275.22-.617.34-.968.338z"/>
	</svg>
);


export default TradeIcon;
