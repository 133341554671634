import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const POST_REQUEST_TIME_REQUEST = 'POST_REQUEST_TIME_REQUEST';
export const POST_REQUEST_TIME_SUCCESS = 'POST_REQUEST_TIME_SUCCESS';
export const POST_REQUEST_TIME_FAILURE = 'POST_REQUEST_TIME_FAILURE';

export function requestTimeRequest ( isPosting ) {
	return {
		type: POST_REQUEST_TIME_REQUEST,
		isPosting
	};
}

export function requestTimeFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_REQUEST_TIME_FAILURE,
		response: err,
		error: true
	};
}

export function requestTimeSuccess( data ) {
	return {
		type: POST_REQUEST_TIME_SUCCESS,
		data
	};
}

export function requestTime ( draftId, data ) {
	return ( dispatch ) => {
		dispatch(requestTimeRequest(true));

		const url = `${Constants.urls.DRAFT}/request-time/${draftId}`;
		axios.post(url, data)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(requestTimeRequest(false));

			return response.data;
		}).then(data => dispatch(requestTimeSuccess(data)))
		.catch(err => dispatch(requestTimeFailure(err)));
	};
}
