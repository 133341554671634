const getTradeDeadlineCountdown = (firstGame) => {
	if(!firstGame) {
		return true;
	}

	const gameTime = new Date(firstGame.gameTime);
	const tradeDeadline = gameTime.setHours(gameTime.getHours() - 1);

	return new Date(tradeDeadline);
};

export default getTradeDeadlineCountdown;
