import { FETCH_LEAGUE_USERS_FAILURE, FETCH_LEAGUE_USERS_REQUEST, FETCH_LEAGUE_USERS_SUCCESS} from '../actions/fetchLeagueUsers';

export function leagueUsersFailure(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_USERS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function leagueUsersRequest(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_USERS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function leagueUsers(state = [], action) {
	switch (action.type) {
		case FETCH_LEAGUE_USERS_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
