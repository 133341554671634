import React, {useCallback, useContext} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import PlayerTeam from '../PlayerTeam';
import ShakitzContext from '../../../context/ShakitzContext';
import { Constants } from '../../../helpers/Constants';
import InfoIcon from '../../svgs/InfoIcon';
import PlusIcon from '../../svgs/PlusIcon';
import EditIcon from '../../svgs/EditIcon';
import {editDraftPick} from '../../../actions/editDraftPick';
import './DraftPlayerCard.scss';

const DraftPlayerCard = ({commissionerId, currentEditPick, editDraftPick, isPaused, player, canDraft, isNext, type, manager}) => {
	const shakitzContext = useContext(ShakitzContext);
	const animationKey = player ? `draftPick-${player.pick}` : 'NO_KEY';
	const hasAnimated = localStorage.getItem(animationKey);
	const clsName = classNames('draft-player-card', {
		'draft-player-card--empty': type === 'empty',
		'draft-player-card--pool': type === 'pool',
		'draft-player-card--wait': typeof canDraft === 'function' && !canDraft(),
		'draft-player-card--animate': !isPaused && type !== 'pool' && player && !hasAnimated
	});

	const cardRef = useCallback(node => {
		if(node !== null && isNext) {
			node.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		}
	}, [isNext]);


	if(isNext) {
		let text = 'Picking Now';
		if(isPaused) {
			text = currentEditPick.pick ? `Commissioner is editing pick ${currentEditPick.pick}` : 'Paused';
		}

		return (
			<div className={clsName} ref={cardRef}>
				<div className='draft-player-card__picking'>{text}</div>
			</div>
		);
	}

	if(type === 'empty') {
		return <div className={clsName}></div>;
	}

	if(type !== 'pool' && !hasAnimated) {
		setTimeout(() => {
			localStorage.setItem(animationKey, 'true');
		}, 2300);
	}

	const onEdit = (evt) => {
		const { playerInfo, managerInfo } = evt.currentTarget.dataset;
		const playerPick = JSON.parse(playerInfo);
		const manager = JSON.parse(managerInfo);
		editDraftPick(playerPick, manager);
		return false;
	}

	const renderPlayerInfo = (player, manager) => {
		const nameParts = player.playerName.display.split(' ');
		const firstName = nameParts[0] || player.playerName.first;
		const lastName = nameParts.slice(1, nameParts.length).join(' ') || player.playerName.last;

		const canEdit = shakitzContext.userId === commissionerId && isPaused && type !== 'pool';
		return (
			<>
				<div className='draft-player-card__player-info'>
					{player.pick ?
						<div className='draft-player-card__pick'>{player.pick}</div> :
						<div className='draft-player-card__pick draft-player-card__pick--suggested-rank' title='suggested rank'>{player.rank < Constants.unrankedCode ? player.rank : ''}</div>
					}
					<div className='draft-player-card__name'>
						<span className='draft-player-card__first-name'>{firstName}</span>
						<span className='draft-player-card__last-name' title={lastName}>{lastName}</span>
						<span className='draft-player-card__team-name'>{player.nflTeam}</span>
						<div className='draft-player-card__team-mobile'>
							<PlayerTeam team={player.nflTeam} showShort={true} />
						</div>
					</div>
					<div className="draft-player-card__misc-info">
						<div className='draft-player-card__position'>
							<span className='draft-player-card__full-position'>{Constants.positionMap[player.pos]}</span>
							<span className='draft-player-card__short-position'>{player.pos}</span>
						</div>
					</div>
				</div>
				<div className='draft-player-card__player-team'>
					<PlayerTeam team={player.nflTeam} showName={false} showShort={true} />
					{canEdit && <button title={'Edit user\'s pick'} className='draft-player-card__edit-pick' onClick={onEdit} data-player-info={JSON.stringify(player)} data-manager-info={JSON.stringify(manager)}><EditIcon fill='#15191d'/></button>}
				</div>
				{manager && (
					<div className='draft-player-card__manager-info'>{manager.userName}</div>
				)}
			</>
		)
	};

	const renderPoolPlayer = (player) => (
		<>
			{renderPlayerInfo(player)}

			<div className='draft-player-card__draft-actions'>
				<div className='draft-player-card__action' data-player-id={player.playerId} data-action-type='info'>
					<InfoIcon/>
				</div>
				<div className='draft-player-card__separator'></div>
				<div className='draft-player-card__action' data-player-id={player.playerId} data-action-type='draft'>
					<PlusIcon/>
				</div>
			</div>
		</>
	);

	return (
		<div className={clsName}>
			{type !== 'pool' ? renderPlayerInfo(player, manager) : renderPoolPlayer(player)}
		</div>
	);
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		editDraftPick: (pick, manager) => dispatch(editDraftPick(pick, manager)),
	};
};

const mapStateToProps = ( state ) => {
	return {
		commissionerId: state.draftData.league.commissionerId,
		currentEditPick: state.editDraftPick,
		isPaused: state.draftClock.isPaused || false,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftPlayerCard);
