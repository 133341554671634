import Keys from 'shakitz-keys';
import hasPlayerPlayed from './hasPlayerPlayed';

const calculatePlayersPlayed = (games = [], gameWeek, gameWeekTeams = [], players = [], seasonUserTeam, selectedLeague) => {
	const gameWeekTeamId = Keys.generateGameWeekTeam({
		gameWeek: gameWeek,
		leagueId: selectedLeague.leagueId,
		seasonUserTeamId: seasonUserTeam.seasonUserTeamId
	});

	const gameWeekTeam = gameWeekTeams.find(g => g.gameWeekTeamId === gameWeekTeamId);
	if(!gameWeekTeam) {
		return 0;
	}
	const hasPlayed = gameWeekTeam.starters.filter(p => {
		return hasPlayerPlayed(p, players, games);
	});

	return hasPlayed.length;
};

export default calculatePlayersPlayed;
