import React from 'react';

const ShapeGroup1 = ({ width = '209', height = '314'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 209 314">
		<path fill="url(#shapeGroup1-paint0_linear)" d="M19.463 256.323c-10.63-31.385 6.196-65.446 37.582-76.076 31.385-10.63 65.446 6.196 76.076 37.582l19.247 56.829-56.83 19.247c-31.385 10.63-65.445-6.196-76.075-37.582z"/>
		<path fill="url(#shapeGroup1-paint1_linear)" d="M63.638 0l112.456 40.422-20.211 56.229c-11.162 31.054-45.385 47.179-76.44 36.017-31.053-11.163-47.179-45.386-36.016-76.44L63.637 0z"/>
		<path fill="url(#shapeGroup1-paint2_linear)" d="M190.633 154.413c9.717 15.14 5.32 35.29-9.82 45.007-15.139 9.716-35.289 5.32-45.005-9.82-9.716-15.14-5.32-35.29 9.82-45.006 15.14-9.717 35.289-5.321 45.005 9.819z"/>
		<defs>
			<linearGradient id="shapeGroup1-paint0_linear" x1="21.097" x2="149.243" y1="211.15" y2="271.442" gradientUnits="userSpaceOnUse">
				<stop offset=".281" stopColor="#A838FF" stopOpacity="0"/>
				<stop offset="1" stopColor="#A838FF"/>
				<stop offset="1" stopColor="#A838FF"/>
			</linearGradient>
			<linearGradient id="shapeGroup1-paint1_linear" x1="119.866" x2="79.444" y1="20.211" y2="132.668" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E00D83"/>
				<stop offset=".531" stopColor="#E00D83" stopOpacity=".34"/>
				<stop offset="1" stopColor="#E00D83" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="shapeGroup1-paint2_linear" x1="145.628" x2="180.815" y1="144.594" y2="199.419" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff" stopOpacity="0"/>
				<stop offset="1" stopColor="#fff"/>
			</linearGradient>
		</defs>
	</svg>
);

export default ShapeGroup1;

