import React from 'react';

const RecordMobileCenter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="47" fill="none" viewBox="0 0 60 47">
        <path fill="#919AAF" d="M.169 17.169C.076.693 13.356-12.738 29.83-12.831c16.476-.093 29.907 13.187 30 29.662L60 46.663l-29.831.168C13.693 46.924.262 33.644.169 17.17z"/>
    </svg>
);

export default RecordMobileCenter;

