import { FETCH_HISTORIC_LEAGUE_STANDINGS_FAILURE, FETCH_HISTORIC_LEAGUE_STANDINGS_REQUEST, FETCH_HISTORIC_LEAGUE_STANDINGS_SUCCESS} from '../actions/fetchHistoricLeagueStandings';

export function historicLeagueStandingsFailure(state = false, action) {
	switch (action.type) {
		case FETCH_HISTORIC_LEAGUE_STANDINGS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function historicLeagueStandingsRequest(state = false, action) {
	switch (action.type) {
		case FETCH_HISTORIC_LEAGUE_STANDINGS_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function historicLeagueStandings(state = {}, action) {
	switch (action.type) {
		case FETCH_HISTORIC_LEAGUE_STANDINGS_SUCCESS:
			const { leagueId, season, gameWeek, data } = action.data;
			const clonedState = JSON.parse(JSON.stringify(state));
			if (!clonedState.hasOwnProperty(leagueId)) {
				clonedState[leagueId] = {};
			}
			if (!clonedState[leagueId].hasOwnProperty(season)) {
				clonedState[leagueId][season] = {};
			}

			clonedState[leagueId][season][gameWeek] = data;
			return clonedState;
		default:
			return state;
	}
}
