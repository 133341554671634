import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_SEASON_PLAYER_STATS_REQUEST = 'FETCH_SEASON_PLAYER_STATS_REQUEST';
export const FETCH_SEASON_PLAYER_STATS_SUCCESS = 'FETCH_SEASON_PLAYER_STATS_SUCCESS';
export const FETCH_SEASON_PLAYER_STATS_FAILURE = 'FETCH_SEASON_PLAYER_STATS_FAILURE';

export function fetchSeasonPlayerStatsRequest ( isFetching ) {
	return {
		type: FETCH_SEASON_PLAYER_STATS_REQUEST,
		isFetching
	};
}

export function fetchSeasonPlayerStatsFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_SEASON_PLAYER_STATS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchSeasonPlayerStatsSuccess( data ) {
	return {
		type: FETCH_SEASON_PLAYER_STATS_SUCCESS,
		data
	};
}

export function fetchSeasonPlayerStats ( season, seasonType, format ) {
	return ( dispatch ) => {
		dispatch(fetchSeasonPlayerStatsRequest(true));

		const url = `${Constants.urls.STATS}/${season}/${seasonType}/${format}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			return response.data;
		}).then(data => dispatch(fetchSeasonPlayerStatsSuccess(data))).catch(err => dispatch(fetchSeasonPlayerStatsFailure(err))).finally(() => dispatch(fetchSeasonPlayerStatsRequest(false)));
	};
}
