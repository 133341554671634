import React from 'react';

const H2HShapeGroupLeft = ({width = '52', height = '131'}) => {
	return (
		<svg width={width} height={height} viewBox="0 0 52 131" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M-27.0005 106.533C-31.4185 93.4886 -24.4253 79.3323 -11.3807 74.9143C1.66391 70.4963 15.8201 77.4896 20.2381 90.5342L28.2376 114.153L4.61832 122.153C-8.42627 126.571 -22.5825 119.578 -27.0005 106.533Z" fill="url(#h2h_left_paint0_linear)"/>
			<path d="M-8.64038 0L38.0988 16.8004L29.6986 40.17C25.0593 53.0766 10.8355 59.7787 -2.07119 55.1394C-14.9779 50.5001 -21.6799 36.2762 -17.0406 23.3696L-8.64038 0Z" fill="url(#h2h_left_paint1_linear)"/>
			<path d="M44.1415 64.1772C48.1798 70.4696 46.3525 78.8445 40.0602 82.8829C33.7679 86.9213 25.3934 85.0941 21.3551 78.8017C17.3169 72.5093 19.1441 64.1344 25.4364 60.096C31.7287 56.0576 40.1033 57.8848 44.1415 64.1772Z" fill="url(#h2h_left_paint2_linear)"/>
			<defs>
				<linearGradient id="h2h_left_paint0_linear" x1="-26.3214" y1="87.7581" x2="26.939" y2="112.817" gradientUnits="userSpaceOnUse">
					<stop stopColor="#6834FF" stopOpacity="0"/>
					<stop offset="1" stopColor="#6834FF" stopOpacity="0.65"/>
				</linearGradient>
				<linearGradient id="h2h_left_paint1_linear" x1="14.7292" y1="8.4002" x2="-2.07119" y2="55.1394" gradientUnits="userSpaceOnUse">
					<stop stopColor="#14C4FB" stopOpacity="0.65"/>
					<stop offset="1" stopColor="#14C4FB" stopOpacity="0"/>
				</linearGradient>
				<linearGradient id="h2h_left_paint2_linear" x1="25.4364" y1="60.096" x2="40.0609" y2="82.8824" gradientUnits="userSpaceOnUse">
					<stop stopColor="white" stopOpacity="0"/>
					<stop offset="1" stopColor="white"/>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default H2HShapeGroupLeft;
