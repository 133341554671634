import { FETCH_DRAFT_DATA_FAILURE, FETCH_DRAFT_DATA_REQUEST, FETCH_DRAFT_DATA_SUCCESS} from '../actions/draftData';

export function draftDataFailure(state = false, action) {
	switch (action.type) {
		case FETCH_DRAFT_DATA_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function draftDataRequest(state = false, action) {
	switch (action.type) {
		case FETCH_DRAFT_DATA_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function draftData(state = {}, action) {
	switch (action.type) {
		case FETCH_DRAFT_DATA_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
