import React from 'react';
import Spinner from '../svgs/Spinner';
import './SpinnerLayer.scss';

const SpinnerLayer = () => (
	<div className="spinner-layer">
		<div className='spinner-layer__container'>
			<Spinner />
		</div>
	</div>
);

export default SpinnerLayer;
