import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_H2H_FIXTURES_REQUEST = 'FETCH_H2H_FIXTURES_REQUEST';
export const FETCH_H2H_FIXTURES_SUCCESS = 'FETCH_H2H_FIXTURES_SUCCESS';
export const FETCH_H2H_FIXTURES_FAILURE = 'FETCH_H2H_FIXTURES_FAILURE';

export function fetchH2HFixturesRequest ( isFetching ) {
	return {
		type: FETCH_H2H_FIXTURES_REQUEST,
		isFetching
	};
}

export function fetchH2HFixturesFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_H2H_FIXTURES_FAILURE,
		response: err,
		error: true
	};
}

export function fetchH2HFixturesSuccess( data, leagueId, season ) {
	return {
		type: FETCH_H2H_FIXTURES_SUCCESS,
		data,
		leagueId,
		season
	};
}

export function fetchH2HFixtures ( leagueId, season = Constants.currentSeason ) {
	return ( dispatch ) => {
		dispatch(fetchH2HFixturesRequest(true));

		const url = Constants.urls.GRAPHQL;
		const query = ` query {
			headToHead(season:"${season}", leagueId: "${leagueId}") {
				awayTeamId
				awayTeamScore
				awayTeamBenchScore
				homeTeamId
				homeTeamScore
				homeTeamBenchScore
				gameWeek
				headToHeadFixtureId
				leagueId
				season
				previousLeg {
					awayTeamScore
					homeTeamScore
				}
			}
		}`;
		axios.post(url, {
			query: query.replace(/\t+/g, "")
		}).then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			return response.data.data;
		}).then(data => dispatch(fetchH2HFixturesSuccess(data, leagueId, season)))
		.catch(err => dispatch(fetchH2HFixturesFailure(err)))
		.finally(() => dispatch(fetchH2HFixturesRequest(false)));
	};
}
