import React from 'react';

const Shape2 = ({fill = "#6834FF"}) => (
	<svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.3988 0.0727539L120.899 0.491426L120.6 60.241C120.435 93.2398 93.5499 119.897 60.5509 119.781C27.5519 119.666 0.934765 92.8212 1.09986 59.8224L1.3988 0.0727539Z" fill={fill}/>
	</svg>
);

export default Shape2;

