import React from 'react';

const JoinShapeGroupRight = ({ width = '103', height = '139'}) => (
	<svg width={width} height={height} viewBox="0 0 76 188" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.5">
			<path d="M11.0633 154.487C4.95319 136.446 14.6248 116.868 32.6654 110.758C50.7059 104.648 70.2839 114.32 76.394 132.36L87.4572 165.026L54.7919 176.089C36.7513 182.199 17.1733 172.527 11.0633 154.487Z" fill="url(#joinShapeGroupRight_paint0_linear)"/>
			<path d="M39.0261 0L110.818 25.8057L97.9154 61.7017C90.7893 81.5265 68.9413 91.8209 49.1165 84.6949C29.2917 77.5689 18.9973 55.7209 26.1233 35.896L39.0261 0Z" fill="url(#joinShapeGroupRight_paint1_linear)"/>
			<path d="M102.593 93.3366C108.178 102.039 105.651 113.621 96.9486 119.206C88.2464 124.792 76.6645 122.265 71.0796 113.562C65.4947 104.86 68.0218 93.2775 76.724 87.6923C85.4262 82.1072 97.0082 84.6342 102.593 93.3366Z" fill="url(#joinShapeGroupRight_paint2_linear)"/>
		</g>
		<defs>
			<linearGradient id="joinShapeGroupRight_paint0_linear" x1="12.0025" y1="128.521" x2="85.6612" y2="163.177" gradientUnits="userSpaceOnUse">
				<stop stopColor="#6834FF" stopOpacity="0"/>
				<stop offset="1" stopColor="#6834FF"/>
			</linearGradient>
			<linearGradient id="joinShapeGroupRight_paint1_linear" x1="74.9222" y1="12.9028" x2="49.1165" y2="84.6949" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E00D83"/>
				<stop offset="1" stopColor="#E00D83" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="joinShapeGroupRight_paint2_linear" x1="76.724" y1="87.6923" x2="96.9496" y2="119.206" gradientUnits="userSpaceOnUse">
				<stop stopColor="white" stopOpacity="0"/>
				<stop offset="1" stopColor="white"/>
			</linearGradient>
		</defs>
	</svg>
);

export default JoinShapeGroupRight;

