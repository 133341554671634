import { DELETE_TRADE_FAILURE, DELETE_TRADE_REQUEST, DELETE_TRADE_SUCCESS} from '../actions/deleteTrade';

export function deleteTradeFailure(state = false, action) {
	switch (action.type) {
		case DELETE_TRADE_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function deleteTradeRequest(state = false, action) {
	switch (action.type) {
		case DELETE_TRADE_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function deleteTrade(state = false, action) {
	switch (action.type) {
		case DELETE_TRADE_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
