import { FETCH_SCHEDULE_FAILURE, FETCH_SCHEDULE_REQUEST, FETCH_SCHEDULE_SUCCESS} from '../actions/fetchSchedule';

export function scheduleFailure(state = false, action) {
	switch (action.type) {
		case FETCH_SCHEDULE_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function scheduleRequest(state = false, action) {
	switch (action.type) {
		case FETCH_SCHEDULE_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function schedule(state = {}, action) {
	switch (action.type) {
		case FETCH_SCHEDULE_SUCCESS:
			const clonedState = JSON.parse(JSON.stringify(state));
			const { seasonType, gameWeek } = action.data;

			if(!clonedState.hasOwnProperty(seasonType)) {
				clonedState[seasonType] = {};
			}
			clonedState[seasonType][gameWeek] = action.data;
			return clonedState;
		default:
			return state;
	}
}
