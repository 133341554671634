import React, { useContext, useState } from 'react';
import Button from '../Common/Button';
import UserContext from '../../context/UserContext';
import Toggle from '../Common/Toggle';
import './Form.scss';

const CreateLeagueForm = ({onSubmit}) => {
	const userContext = useContext(UserContext);
	const leagueOptions = [ 'Regular', 'Pre Season'];
	const scoringOptions = [ 'standard', '0.5ppr', '1ppr'];
	const [ leagueType, setLeagueType ] = useState(leagueOptions[0]);
	const [ leagueScoring, setLeagueScoring ] = useState(scoringOptions[0]);

	const onLeagueToggle = (evt) => {
		const option = leagueOptions.find(option => option === evt.target.innerText);
		if(option) {
			setLeagueType(option);
		}
	};

	const onScoringToggle = (evt) => {
		const option = scoringOptions.find(option => option === evt.target.innerText);
		if(option) {
			setLeagueScoring(option);
		}
	};

	const formSubmit = (evt) => {
		evt.preventDefault();

		const utcDate = new Date(evt.target.draftStartDate.value).toUTCString();
		const leagueTypeAbbrev = leagueType === 'Pre Season' ? 'PRE' : 'REG';

		onSubmit({
			"leagueName": evt.target.leagueName.value,
			"leagueType": leagueTypeAbbrev,
			"commissionerId": userContext.userObj.email,
			"formatType": leagueScoring,
			"maxPlayers": evt.target.leaguePlayersMax.value,
			"draftStartDate": utcDate
		}, {
			"shortTeamName": evt.target.shortTeamName.value,
			"teamName": evt.target.teamName.value,
		});
	};

	return (
		<form className='form form--create-league' onSubmit={formSubmit}>
			<label className='form__label'>League Name</label>
			<input id='league-name' className='form__input form__input--text' name='leagueName' type='text' placeholder='League name' minLength='4' autoComplete='off' required />
			<label className='form__label' >League Type</label>
			<Toggle activeOption={leagueType} toggleOptions={leagueOptions} onToggleClick={onLeagueToggle} />

			<label className='form__label'>Draft Date</label>
			<input id='league-date' className='form__input form__input--date' name='draftStartDate' type='datetime-local' defaultValue="2020-09-05T19:30" autoComplete='off' required />
			<label className='form__label' >League Scoring</label>
			<Toggle activeOption={leagueScoring} toggleOptions={scoringOptions} onToggleClick={onScoringToggle} />

			<label className='form__label' >Max # of players</label>
			<input id='league-players-max' className='form__input form__input--number' name='leaguePlayersMax' type='number' min='1' defaultValue={8} max='32' autoComplete='off' required/>
			<label className='form__label'>Your Short Team Name</label>
			<input id='team-name-short' className='form__input form__input--text' name='shortTeamName' type='text' placeholder='Abbreviated name (2-12 characters)' minLength='2' maxLength='12' autoComplete='off' required />
			<label className='form__label'>Your Team Name</label>
			<input id='team-name' className='form__input form__input--text' name='teamName' type='text' placeholder='Team name (4-40 characters)' minLength='4' maxLength='40' autoComplete='off' required />
			<Button text={'Next'} type={'submit'} isSubmit={true} />
		</form>
	);
};

export default CreateLeagueForm;
