import { FETCHING_USER } from '../actions/fetchingUser';

export function fetchingUser (state = false, action) {
	switch (action.type) {
		case FETCHING_USER:
			return action.isFetching;
		default:
			return state;
	}
}
