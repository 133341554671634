import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import Header from '../Header';
import Logo from '../Logo';
import JoinShapeGroupLeft from '../../svgs/logo/JoinShapeGroupLeft';
import JoinShapeGroupRight from '../../svgs/logo/JoinShapeGroupRight';
import './Hero.scss';

// TODO-UI can we simplify this
const Hero = ({text, secondaryText, ctaText, ctaOnClick, showLogo, bubbles, joinJourney}) => {
	const clsName = classNames('hero', {
		'hero--with-logo': showLogo,
		'hero--bubbles': bubbles,
		'hero--join-journey': joinJourney
	});
	return (
		<div className={clsName}>
			{joinJourney && (
				<div className='hero__visual-bubble'>
					<JoinShapeGroupLeft width={180} height={180} />
				</div>
			)}
			<Header headerLevel={'h1'} text={text} secondaryText={secondaryText}/>
			{ctaText ?
				<Button onClick={() => ctaOnClick()} text={ctaText}/> : null}
			{showLogo && <Logo/>}
			{joinJourney && (
				<div className='hero__visual-bubble'>
					<JoinShapeGroupRight width={180} height={180} />
				</div>
			)}
		</div>
	);
};

export default Hero;
