import { FETCH_NEXT_H2H_DATA_FAILURE, FETCH_NEXT_H2H_DATA_REQUEST, FETCH_NEXT_H2H_DATA_SUCCESS} from '../actions/fetchNextH2HData';

export function nextH2HDataFailure(state = false, action) {
	switch (action.type) {
		case FETCH_NEXT_H2H_DATA_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function nextH2HDataRequest(state = false, action) {
	switch (action.type) {
		case FETCH_NEXT_H2H_DATA_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function nextH2HData(state = {}, action) {
	switch (action.type) {
		case FETCH_NEXT_H2H_DATA_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
