import React, {Component} from 'react';
import Header from '../../components/Common/Header';
import DraftPlayersLeft from '../../components/DraftRoom/DraftPlayersLeft'
import DraftTeamPlayers from '../../components/DraftRoom/DraftTeamPlayers';
import { Constants } from '../../helpers/Constants';

import './DraftTeam.scss';

class DraftTeam extends Component {
	static defaultProps = {
		gameWeekTeams: []
	};

	constructor(props) {
		super(props);
		this.calculatePlayersLeftNeeded = this.calculatePlayersLeftNeeded.bind(this);
	}

	calculatePlayersLeftNeeded(qb, rb, wr, te, k, def) {
		return (Constants.numStarters + Constants.numBench) - qb.length - rb.length - wr.length - te.length - k.length - def.length;
	}

	getPlayersByPosition(data, pos) {
		return data.filter(pick => pick.pos === pos);
	}

	render () {
		const userPicks = this.props.draftData.reduce((a, b) => a.concat(b), []).filter(pick => pick.userId === this.props.userId);
		const qb = this.getPlayersByPosition(userPicks, 'QB');
		const rb = this.getPlayersByPosition(userPicks, 'RB');
		const wr = this.getPlayersByPosition(userPicks, 'WR');
		const te = this.getPlayersByPosition(userPicks, 'TE');
		const k = this.getPlayersByPosition(userPicks, 'K');
		const def = this.getPlayersByPosition(userPicks, 'D');

		return (
			<div className='draft-team'>
				<div className='draft-team__header'>
					<Header headerLevel={'h2'} text={'Your Team'} />
					<DraftPlayersLeft playersLeft={this.calculatePlayersLeftNeeded(qb, rb, wr, te, k, def)}/>
				</div>
				<div className='draft-team__roster'>
					<DraftTeamPlayers type={'Quarterbacks'} players={qb} minRequired={2} />
					<DraftTeamPlayers type={'Running Backs'} players={rb} minRequired={2} />
					<DraftTeamPlayers type={'Wide Receivers'} players={wr} minRequired={2} />
					<DraftTeamPlayers type={'Tight Ends'} players={te} minRequired={1} />
					<DraftTeamPlayers type={'Kickers'} players={k} minRequired={1} />
					<DraftTeamPlayers type={'Defence'} players={def} minRequired={1} />
				</div>
			</div>
		);
	}
}

export default DraftTeam;
