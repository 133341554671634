import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { GA } from '../../helpers/ga';
import Header from '../../components/Common/Header';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import ToggleSwitch from '../../components/Common/ToggleSwitch';
import StatPlayerPool from '../StatPlayerPool';
import './CreateWaiverPane.scss';

const getAvailablePlayers = (players, selectedLeague, unavailablePlayers) => {
	const playerData = unavailablePlayers[selectedLeague.leagueId] ? unavailablePlayers[selectedLeague.leagueId][selectedLeague.season] : [];
	const rosteredPlayers = playerData.reduce((prev, curr) => prev.concat(curr.players), []);

	return players.filter(p => p.hasOwnProperty('playerName')).map(player => {
		const alreadyOwned = rosteredPlayers.find(rostered => {
			const idParts = rostered.split('-');
			const id = idParts[0].includes('Defence') ? idParts[0] : `${idParts[0]}-${idParts[1]}`;

			return player.playerId === id || player.statId === id
		});

		return {
			alreadyOwned,
			...{ gameWeekPlayerId: alreadyOwned },
			...player
		};
	});
};

const CreateWaiverPane = ({isFetching, players, selectedLeague, unavailablePlayers, userId}) => {
	const [hideUnavailable, setHideUnavailable] = useState(true);
	const [transformedPlayers, setTransformedPlayers] = useState([]);

	const onHideChange = shouldHide => {
		ReactGA.event({
			category: GA.CATEGORY.WAIVER,
			action: GA.ACTION.WAIVER_HIDE_PLAYERS_TOGGLE
		});
		setHideUnavailable(shouldHide);
	};

	useEffect(() => {
		setTransformedPlayers(getAvailablePlayers(players, selectedLeague, unavailablePlayers));
	}, [players, selectedLeague, unavailablePlayers])

	return (
		<div className='create-waiver-pane'>
			<div className='create-waiver-pane__heading'>
				<Header headerLevel={'h1'} text={'Create Waiver'} />
				<ToggleSwitch labelText='Hide unavailable players' defaultChecked={hideUnavailable} onChange={onHideChange} name='unavailablePlayers' />
			</div>
			<div className='create-waiver-pane__toggle'>
				<StatPlayerPool customPlayers={transformedPlayers} hideUnavailable={hideUnavailable} playersToFilter={transformedPlayers} inWaiverMode={true} statView={true} userId={userId} />
			</div>

			{isFetching ? <SpinnerLayer/> : null}
		</div>
	);
};
export default CreateWaiverPane;
