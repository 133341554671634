import React from 'react';
import './DraftRound.scss';

const DraftRound = ({round, pick}) => {
	return (
		<div className='draft-round'>
			<span className='draft-round__round'>Round {round}: </span>
			<span className='draft-round__pick'>Pick {pick} overall</span>
		</div>
	);
};

export default DraftRound;
