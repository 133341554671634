import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const PUT_USER_FAVOURITE_TEAM_REQUEST = 'PUT_USER_FAVOURITE_TEAM_REQUEST';
export const PUT_USER_FAVOURITE_TEAM_SUCCESS = 'PUT_USER_FAVOURITE_TEAM_SUCCESS';
export const PUT_USER_FAVOURITE_TEAM_FAILURE = 'PUT_USER_FAVOURITE_TEAM_FAILURE';

export function setFavouriteTeamRequest ( isPosting ) {
	return {
		type: PUT_USER_FAVOURITE_TEAM_REQUEST,
		isPosting
	};
}

export function setFavouriteTeamFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: PUT_USER_FAVOURITE_TEAM_FAILURE,
		response: err,
		error: true
	};
}

export function setFavouriteTeamSuccess( data ) {
	return {
		type: PUT_USER_FAVOURITE_TEAM_SUCCESS,
		data
	};
}

export function setFavouriteTeam ( userId, favouriteTeam ) {
	return ( dispatch ) => {
		dispatch(setFavouriteTeamRequest(true));

		const url = `${Constants.urls.USER}/team`;
		axios.put(url, { userId, favouriteTeam } )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			return response.data.Attributes;
		}).then(data => dispatch(setFavouriteTeamSuccess(data.favouriteTeam)))
		.catch(err => dispatch(setFavouriteTeamFailure(err)))
		.finally(() => dispatch(setFavouriteTeamRequest(false)))
	};
}
