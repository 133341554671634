import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_UNAVAILABLE_PLAYERS_REQUEST = 'FETCH_UNAVAILABLE_PLAYERS_REQUEST';
export const FETCH_UNAVAILABLE_PLAYERS_SUCCESS = 'FETCH_UNAVAILABLE_PLAYERS_SUCCESS';
export const FETCH_UNAVAILABLE_PLAYERS_FAILURE = 'FETCH_UNAVAILABLE_PLAYERS_FAILURE';

export function fetchUnavailablePlayersRequest ( isFetching ) {
	return {
		type: FETCH_UNAVAILABLE_PLAYERS_REQUEST,
		isFetching
	};
}

export function fetchUnavailablePlayersFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_UNAVAILABLE_PLAYERS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchUnavailablePlayersSuccess( data, leagueId, season ) {
	return {
		type: FETCH_UNAVAILABLE_PLAYERS_SUCCESS,
		data,
		leagueId,
		season
	};
}

export function fetchUnavailablePlayers (leagueId, season) {
	return ( dispatch ) => {
		dispatch(fetchUnavailablePlayersRequest(true));

		const url = `${Constants.urls.PLAYER}/unavailable/${leagueId}/${season}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(fetchUnavailablePlayersRequest(false));

			return response.data;
		}).then(data => dispatch(fetchUnavailablePlayersSuccess(data, leagueId, season))).catch(err => dispatch(fetchUnavailablePlayersFailure(err)));
	};
}
