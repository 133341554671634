import { FETCH_SEASON_USER_TEAM_FAILURE, FETCH_SEASON_USER_TEAM_REQUEST, FETCH_SEASON_USER_TEAM_SUCCESS} from '../actions/seasonUserTeam';

export function seasonUserTeamFailure(state = false, action) {
	switch (action.type) {
		case FETCH_SEASON_USER_TEAM_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function seasonUserTeamRequest(state = false, action) {
	switch (action.type) {
		case FETCH_SEASON_USER_TEAM_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function seasonUserTeams(state = [], action) {
	switch (action.type) {
		case FETCH_SEASON_USER_TEAM_SUCCESS:
			return action.data;
		default:
			return state;
	}
}
