import React from 'react';

const Jersey = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22">
		<g fill="#FFF" fillRule="nonzero" opacity=".6">
			<path d="M15.497 7.379c-1.142-.413-1.509-.962-1.509-2.337V.458A.459.459 0 0 0 13.531 0h-1.92c-.182 0-.365.138-.456.32-.503 1.743-2.058 2.383-3.2 2.383-1.143 0-2.652-.596-3.2-2.383C4.709.137 4.525 0 4.297 0h-1.92c-.273 0-.456.184-.456.458v4.584c0 1.42-.366 1.97-1.463 2.337a.44.44 0 0 0-.32.413v10.725h15.634V7.792c0-.184-.091-.367-.274-.413zm-5.166 7.93L8 14.07l-2.331 1.237.457-2.612-1.874-1.834 2.605-.367L8 8.112l1.143 2.383 2.605.367-1.874 1.834.457 2.613zM.183 19.48v2.062c0 .229.183.458.457.458h14.674c.274 0 .457-.23.457-.458v-2.063H.183z"/>
		</g>
	</svg>
);

export default Jersey;
