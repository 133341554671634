import React from 'react';
import RosterChangeIcon from '../../svgs/RosterChangeIcon';
import IssueIcon from '../../svgs/IssueIcon';
import './EmptyState.scss';

const EmptyState = ({heading, secondaryText, isRoster}) => (
	<div className='empty-state'>
		<div className='empty-state__icon'>
			{isRoster ? <RosterChangeIcon  /> : <IssueIcon />}
		</div>
		<h3 className='empty-state__heading'>{heading}</h3>
		<span className='empty-state__secondary-text'>{secondaryText}</span>
	</div>
);

export default EmptyState;
