import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_SCHEDULE_REQUEST = 'FETCH_SCHEDULE_REQUEST';
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS';
export const FETCH_SCHEDULE_FAILURE = 'FETCH_SCHEDULE_FAILURE';

export function fetchScheduleRequest ( isFetching ) {
	return {
		type: FETCH_SCHEDULE_REQUEST,
		isFetching
	};
}

export function fetchScheduleFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_SCHEDULE_FAILURE,
		response: err,
		error: true
	};
}

export function fetchScheduleSuccess( data ) {
	return {
		type: FETCH_SCHEDULE_SUCCESS,
		data
	};
}

export function fetchSchedule ( type = 'PRE', season, gameWeek) {
	return ( dispatch ) => {
		dispatch(fetchScheduleRequest(true));

		const url = `${Constants.urls.SCHEDULE}/${type}/${season}/${gameWeek}`;

		axios.get(url)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			return response.data;
		}).then(data => dispatch(fetchScheduleSuccess(data))).catch(err => dispatch(fetchScheduleFailure(err)))
		.finally(() => dispatch(fetchScheduleRequest(false)));
	};
}
