import React from 'react';
import classNames from 'classnames';
import './UserMyTeamGWScoreBoard.scss';

const UserMyTeamGWScoreBoard = ({ scoreBoards }) => (
	<div className='user-gw-score-board'>
		{scoreBoards.map(board => {
			const clsName = classNames('score-board', { 'score-board--focal-board': board.isFocalBoard });

			return (
				<div key={`scoreBoard-${board.description}`} className={clsName}>
					<p className='score-board__description'>{board.description}</p>
					<p className='score-board__points'>{board.points}</p>
				</div>
			)
		})}
	</div>
);

export default UserMyTeamGWScoreBoard;
