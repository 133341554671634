import shakitzSchedule from 'shakitz-schedule';
import getGameState from './getGameState';
import {Constants} from './Constants';

const hasPlayerPlayed = (p, players, games) => {
	let player = p;

	// TODO make sure this doesn't happen in the first place
	player.nflTeam = player.nflTeam || player.team;
	if(!player.nflTeam) {
		player = players.find(playerItem => playerItem.playerId === p.playerId || playerItem.statId === p.playerId);
	}

	const game = shakitzSchedule.getPlayerGame(games, player.nflTeam);
	const gameState = getGameState(game) ;
	return gameState === Constants.gameStates.LIVE || gameState === Constants.gameStates.POST;
};

export default hasPlayerPlayed;
