import { PUT_TEAM_NAME_FAILURE, PUT_TEAM_NAME_REQUEST, PUT_TEAM_NAME_SUCCESS} from '../actions/setTeamName';

export function setTeamNameFailure(state = false, action) {
	switch (action.type) {
		case PUT_TEAM_NAME_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function setTeamNameRequest(state = false, action) {
	switch (action.type) {
		case PUT_TEAM_NAME_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function setTeamName(state = false, action) {
	switch (action.type) {
		case PUT_TEAM_NAME_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
