import React from 'react';
import './Header.scss';

const Header = ({headerLevel, text, secondaryText = ''}) => {
	switch(headerLevel) {
		case 'h3': {
			return <h3 className="header header--mini">{text} {secondaryText ? <span>{secondaryText}</span> : null}</h3>;
		}
		case 'h2': {
			return <h2 className="header header--sub">{text} {secondaryText ? <span>{secondaryText}</span> : null}</h2>;
		}
		case 'h1-alternate': {
			return <h1 className="header header--primary-alternate">{text} {secondaryText ? <span>{secondaryText}</span> : null}</h1>;
		}
		case 'h1':
		default: {
			return <h1 className="header header--primary">{text} {secondaryText ? <span>{secondaryText}</span> : null}</h1>;
		}
	}
};

export default Header;
