import React, {Component} from 'react';
import LeagueStandings from '../LeagueStandings/LeagueStandings';
import H2HFixtures from '../H2HFixtures/H2HFixtures';
import Countdown from '../../components/Common/Countdown';
import UserNextH2HBox from '../../components/UserNextH2HBox/UserNextH2HBox';
import VisualSeparator from '../../components/Common/VisualSeparator';
import { Constants } from '../../helpers/Constants';
import Schedule from '../../components/Schedule';

class PreGameDay extends Component {
	getCountdown () {
		const currentGameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
		const scheduleData = this.props.schedule[this.props.selectedLeague.type][currentGameWeek];
		if(!scheduleData || scheduleData.games.length === 0) {
			return null;
		}
		const timeTillFirstGame = new Date(scheduleData.games.sort((a, b) => new Date(a.gameTime).getTime() - new Date(b.gameTime).getTime())[0].gameTime);
		if(currentGameWeek === 1 && scheduleData.games) {
			return <Countdown timeToCountdownTo={timeTillFirstGame} type={'Season Starts In'} />
		} else {
			const now = new Date();
			const tradeCountdown = new Date('Thu, 15 Aug 2019 11:00:00 GMT');
			const waiverCountdown = new Date('Wed, 14 Aug 2019 22:00:00 GMT');

			if(now.getTime() < waiverCountdown.getTime()) {
				// TODO fix hard coded nature of this
				return <Countdown timeToCountdownTo={waiverCountdown} type={'Waiver Wire'} reminderText={'Waiver wire status:'} reminderSecondaryText={'Incomplete'} />
			} else if (now.getTime() < tradeCountdown.getTime()) {
				return <Countdown timeToCountdownTo={tradeCountdown} type={'Trade Deadline'} reminderText={'Trade status:'} reminderSecondaryText={'0 pending'} />
			} else if (now.getTime() < timeTillFirstGame.getTime()) {
				return <Countdown timeToCountdownTo={timeTillFirstGame} type={'GameWeek Begins In:'} />
			} else {
				return null;
			}
		}
	}

	getNextUserFixture () {
		const currentGameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];

		return this.props.allFixtures.filter(fixture => fixture.homeManagerId === this.props.shakitzUser.userId || fixture.awayManagerId === this.props.shakitzUser.userId).filter(f => f.gameWeek >= currentGameWeek).sort((a, b) => a.gameWeek - b.gameWeek)[0];
	}

	render () {
		const seasonType = this.props.selectedLeague.type;
		const gameWeek = this.props.currentGameWeek[seasonType];
		const userNextFixture = this.getNextUserFixture();
		return (
			<>
				{this.getCountdown()}
				<LeagueStandings historicStandings={this.props.historicStandings} onUserClick={this.props.onUserClick} gwState={this.props.gwState} seasonUserTeams={this.props.seasonUserTeams} standings={this.props.standings} />
				<VisualSeparator />
				{userNextFixture && this.props.nextH2HData ?
					<UserNextH2HBox fixture={userNextFixture} draftPicks={this.props.nextH2HData.draft} historicLeagueRecords={this.props.nextH2HData.historicStandings} standings={this.props.standings} onUserClick={this.props.onUserClick} userId={this.props.shakitzUser.userId}/> : null }
				<VisualSeparator />
				<H2HFixtures currentGameWeek={gameWeek} fixtures={this.props.allFixtures} gameWeekToShow={this.props.h2hGameWeekToggle}
				             maxGameWeeks={Constants.numOfGameWeeks[seasonType]} onToggle={this.props.onH2HFixtureToggle} />
				<VisualSeparator />
				<Schedule />
				<VisualSeparator />
			</>
		);
	}
}
export default PreGameDay;
