import { combineReducers } from 'redux';
import { activeGameWeekTeam } from './activeGameWeekTeam';
import { addWaiver, addWaiverFailure, addWaiverRequest } from './addWaiver';
import { authDraft, authDraftFailure, authDraftRequest } from './authDraft';
import { confirmTrade, confirmTradeFailure, confirmTradeRequest } from './confirmTrade';
import { currentGameWeek, currentGameWeekFailure, currentGameWeekRequest } from './currentGameWeek';
import { deleteTrade, deleteTradeFailure, deleteTradeRequest } from './deleteTrade';
import { deleteWaiver, deleteWaiverFailure, deleteWaiverRequest } from './deleteWaiver';
import { draftClock } from './draftClock';
import { draftData, draftDataFailure, draftDataRequest} from './draftData';
import { draftPicks } from './draftPicks';
import { editDraftPick } from './editDraftPick';
import { schedule, scheduleFailure, scheduleRequest } from './schedule';
import { scores, scoresFailure, scoresRequest } from './scores';
import { fetchingUser } from './fetchingUser';
import { gameWeekTeams, gameWeekTeamFailure, gameWeekTeamRequest} from './gameWeekTeam';
import { h2hFixtures, h2hFixturesFailure, h2hFixturesRequest } from './h2hFixtures';
import { historicLeagueStandings, historicLeagueStandingsFailure, historicLeagueStandingsRequest } from './historicLeagueStandings';
import { historicPlayerStats, historicPlayerStatsFailure, historicPlayerStatsRequest } from './historicPlayerStats';
import { leagueBenchData, leagueBenchDataFailure, leagueBenchDataRequest } from './leagueBenchData';
import { leagueGameWeekTeams, leagueGameWeekTeamsFailure, leagueGameWeekTeamsRequest } from './leagueGameWeekTeams';
import { leagueStandings, leagueStandingsFailure, leagueStandingsRequest } from './leagueStandings';
import { leagueTradeData, leagueTradeDataFailure, leagueTradeDataRequest } from './leagueTradeData';
import { leagueUsers, leagueUsersFailure, leagueUsersRequest } from './leagueUsers';
import { nextH2HData, nextH2HDataFailure, nextH2HDataRequest } from './nextH2HData';
import { pauseDraft, pauseDraftFailure, pauseDraftRequest } from './pauseDraft';
import { perfectTeam, perfectTeamFailure, perfectTeamRequest } from './perfectTeam';
import { players } from './players';
import { postDraftPick, postDraftPickFailure, postDraftPickRequest } from './postDraftPick';
import { records, recordsFailure, recordsRequest } from './records';
import { refreshCoreData } from './refreshCoreData';
import { rejectTrade, rejectTradeFailure, rejectTradeRequest } from './rejectTrade';
import { requestTime, requestTimeFailure, requestTimeRequest } from './requestTime';
import { seasonPlayerStats, seasonPlayerStatsFailure, seasonPlayerStatsRequest } from './seasonPlayerStats';
import { seasonUserTeams, seasonUserTeamFailure, seasonUserTeamRequest} from './seasonUserTeam';
import { selectedLeague } from './selectLeague';
import { setAutoDraft, setAutoDraftFailure, setAutoDraftRequest } from './setAutoDraft';
import { setGameWeekTeam, setGameWeekTeamFailure, setGameWeekTeamRequest } from './setGameWeekTeam';
import { setFavouriteTeam, setFavouriteTeamFailure, setFavouriteTeamRequest } from './setFavouriteTeam';
import { setTeamName, setTeamNameFailure, setTeamNameRequest } from './setTeamName';
import { setUserName, setUserNameFailure, setUserNameRequest } from './setUserName';
import { startDraft, startDraftFailure, startDraftRequest } from './startDraft';
import { startSeason, startSeasonFailure, startSeasonRequest } from './startSeason';
import { submitTrade, submitTradeFailure, submitTradeRequest } from './submitTrade';
import { unavailablePlayers, unavailablePlayersFailure, unavailablePlayersRequest } from './unavailablePlayers';
import { upcomingGameWeekTeam } from './upcomingGameWeekTeam';
import { waivers, waiversFailure, waiversRequest } from './waivers';

export default combineReducers({
	activeGameWeekTeam,
	addWaiver,
	addWaiverFailure,
	addWaiverRequest,
	authDraft,
	authDraftFailure,
	authDraftRequest,
	confirmTrade,
	confirmTradeFailure,
	confirmTradeRequest,
	currentGameWeek,
	currentGameWeekFailure,
	currentGameWeekRequest,
	deleteTrade,
	deleteTradeFailure,
	deleteTradeRequest,
	deleteWaiver,
	deleteWaiverFailure,
	deleteWaiverRequest,
	draftClock,
	draftData,
	draftDataFailure,
	draftDataRequest,
	draftPicks,
	editDraftPick,
	fetchingUser,
	gameWeekTeams,
	gameWeekTeamFailure,
	gameWeekTeamRequest,
	h2hFixtures,
	h2hFixturesFailure,
	h2hFixturesRequest,
	historicLeagueStandings,
	historicLeagueStandingsFailure,
	historicLeagueStandingsRequest,
	historicPlayerStats,
	historicPlayerStatsFailure,
	historicPlayerStatsRequest,
	leagueBenchData,
	leagueBenchDataFailure,
	leagueBenchDataRequest,
	leagueGameWeekTeams,
	leagueGameWeekTeamsFailure,
	leagueGameWeekTeamsRequest,
	leagueStandings,
	leagueStandingsFailure,
	leagueStandingsRequest,
	leagueTradeData,
	leagueTradeDataFailure,
	leagueTradeDataRequest,
	leagueUsers,
	leagueUsersFailure,
	leagueUsersRequest,
	nextH2HData,
	nextH2HDataFailure,
	nextH2HDataRequest,
	pauseDraft,
	pauseDraftFailure,
	pauseDraftRequest,
	perfectTeam,
	perfectTeamFailure,
	perfectTeamRequest,
	players,
	postDraftPick,
	postDraftPickFailure,
	postDraftPickRequest,
	records,
	recordsFailure,
	recordsRequest,
	refreshCoreData,
	rejectTrade,
	rejectTradeFailure,
	rejectTradeRequest,
	requestTime,
	requestTimeFailure,
	requestTimeRequest,
	seasonUserTeams,
	seasonUserTeamFailure,
	seasonUserTeamRequest,
	schedule,
	scheduleFailure,
	scheduleRequest,
	scores,
	scoresFailure,
	scoresRequest,
	seasonPlayerStats,
	seasonPlayerStatsFailure,
	seasonPlayerStatsRequest,
	selectedLeague,
	setAutoDraft,
	setAutoDraftFailure,
	setAutoDraftRequest,
	setFavouriteTeam,
	setFavouriteTeamRequest,
	setFavouriteTeamFailure,
	setGameWeekTeam,
	setGameWeekTeamFailure,
	setGameWeekTeamRequest,
	setTeamName,
	setTeamNameFailure,
	setTeamNameRequest,
	setUserName,
	setUserNameFailure,
	setUserNameRequest,
	startDraft,
	startDraftFailure,
	startDraftRequest,
	startSeason,
	startSeasonFailure,
	startSeasonRequest,
	submitTrade,
	submitTradeFailure,
	submitTradeRequest,
	unavailablePlayers,
	unavailablePlayersFailure,
	unavailablePlayersRequest,
	upcomingGameWeekTeam,
	waivers,
	waiversFailure,
	waiversRequest
});
