export const REFRESH_CORE_DATA = 'REFRESH_CORE_DATA';

export function refreshCoreData (refresh) {
	return {
		type: REFRESH_CORE_DATA,
		refresh
	};
}

export function setRefreshCoreData ( shouldRefresh ) {
	return (dispatch) => {
		dispatch(refreshCoreData(shouldRefresh));
	};
};
