import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const PUT_AUTO_DRAFT_REQUEST = 'PUT_AUTO_DRAFT_REQUEST';
export const PUT_AUTO_DRAFT_SUCCESS = 'PUT_AUTO_DRAFT_SUCCESS';
export const PUT_AUTO_DRAFT_FAILURE = 'PUT_AUTO_DRAFT_FAILURE';

export function setAutoDraftRequest ( isPosting ) {
	return {
		type: PUT_AUTO_DRAFT_REQUEST,
		isPosting
	};
}

export function setAutoDraftFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: PUT_AUTO_DRAFT_FAILURE,
		response: err,
		error: true
	};
}

export function setAutoDraftSuccess( data ) {
	return {
		type: PUT_AUTO_DRAFT_SUCCESS,
		data
	};
}

export function setAutoDraft ( draftId, leagueId, userId, autoDraft ) {
	return ( dispatch ) => {
		dispatch(setAutoDraftRequest(true));

		const url = `${Constants.urls.DRAFT}/auto-draft/${draftId}`;
		axios.put(url, { userId, leagueId, autoDraft} )
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			dispatch(setAutoDraftRequest(false));

			return response.data;
		}).then(data => dispatch(setAutoDraftSuccess(data)))
		.catch(err => dispatch(setAutoDraftFailure(err)));
	};
}
