import React from 'react';

const HomeShapeGroupRight = ({ width = '105', height = '158'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 105 158">
		<path fill="url(#homeShapeGroupRight_paint0_linear)" d="M9.659 128.633c-5.335-15.75 3.11-32.843 18.86-38.178 15.75-5.334 32.844 3.11 38.178 18.86l9.66 28.52-28.52 9.658c-15.75 5.335-32.844-3.109-38.178-18.86z"/>
		<path fill="url(#homeShapeGroupRight_paint1_linear)" d="M31.828 0l56.435 20.286L78.12 48.503C72.52 64.087 55.344 72.18 39.76 66.578s-23.676-22.776-18.075-38.36L31.828 0z"/>
		<path fill="url(#homeShapeGroupRight_paint2_linear)" d="M95.56 77.49c4.875 7.599 2.669 17.71-4.929 22.587-7.597 4.876-17.71 2.67-22.585-4.928-4.876-7.598-2.67-17.71 4.928-22.586 7.597-4.876 17.71-2.67 22.585 4.928z"/>
		<defs>
			<linearGradient id="homeShapeGroupRight_paint0_linear" x1="10.479" x2="74.788" y1="105.963" y2="136.22" gradientUnits="userSpaceOnUse">
				<stop stopColor="#A838FF" stopOpacity="0"/>
				<stop offset=".302" stopColor="#A838FF" stopOpacity="0"/>
				<stop offset="1" stopColor="#A838FF"/>
			</linearGradient>
			<linearGradient id="homeShapeGroupRight_paint1_linear" x1="60.046" x2="39.76" y1="10.143" y2="66.578" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E00D83"/>
				<stop offset=".542" stopColor="#B3136C" stopOpacity=".15"/>
				<stop offset="1" stopColor="#B3136C" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="homeShapeGroupRight_paint2_linear" x1="72.974" x2="90.632" y1="72.563" y2="100.076" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff" stopOpacity="0"/>
				<stop offset="1" stopColor="#fff"/>
			</linearGradient>
		</defs>
	</svg>
);

export default HomeShapeGroupRight;

