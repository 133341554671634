import { FETCH_GAME_WEEK_TEAM_FAILURE, FETCH_GAME_WEEK_TEAM_REQUEST, FETCH_GAME_WEEK_TEAM_SUCCESS} from '../actions/gameWeekTeam';

export function gameWeekTeamFailure(state = false, action) {
	switch (action.type) {
		case FETCH_GAME_WEEK_TEAM_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function gameWeekTeamRequest(state = false, action) {
	switch (action.type) {
		case FETCH_GAME_WEEK_TEAM_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function gameWeekTeams(state = [], action) {
	switch (action.type) {
		case FETCH_GAME_WEEK_TEAM_SUCCESS:
			const clonedState = [].slice.call(state);
			const findIndex = clonedState.findIndex(gw => gw.gameWeekTeamId === action.data.gameWeekTeamId);
			if(findIndex > -1) {
				// TODO Re think this flow - why make the original non calculated call in the first place?
				if(action.data.hasOwnProperty('currentGameWeekPoints')) {
					clonedState[findIndex] = action.data;
				} else {
					clonedState[findIndex] = { ...clonedState[findIndex], ...action.data };
				}
			} else {
				clonedState.push(action.data);
			}

			return clonedState;
		default:
			return state;
	}
}
