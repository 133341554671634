import React from 'react';
import { Constants } from '../../../../helpers/Constants';
import { TeamSVGMap } from '../../../../helpers/TeamSVGMap';
import classNames from 'classnames';
import './PlayerTeamPosInfo.scss';

const PlayerTeamPosInfo = ({centerContent = false, noSvg, position = 'N/A', team}) => {
	const clsName = classNames('player-team-pos-info', { 'player-team-pos-info--center': centerContent});

	const TeamSVG = TeamSVGMap.teamMap[team];
	return (
		<div className={clsName}>
			{!noSvg && <div className='player-team-pos-info__team-svg'><TeamSVG /></div>}
			<div className='player-team-pos-info__short-team-name'>{team}</div>
			<div className='player-team-pos-info__team-name'>{team}</div>
			<span>&nbsp;•&nbsp;</span><div className='player-team-pos-info__position'>{position}</div>
		</div>
	)
};

export default PlayerTeamPosInfo;
