const getPlayerShortName = (player) => {
	if(!player.playerName.first || !player.playerName.last) {
		return player.playerName.display;
	} else if (player.pos === 'D') {
		return `${player.playerName.first} ${player.playerName.last}`;
	}


	return `${player.playerName.display[0]}. ${player.playerName.last}`;
};

export default getPlayerShortName;
