import React from 'react';

const FireIcon = () => (
	<svg preserveAspectRatio="xMinYMax meet" height="100" width="100" fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193 299" fillRule="evenodd" clipRule="evenodd" style={{filter: "drop-shadow(1px 1px 0px #111) drop-shadow(-1px 1px 0px #111) drop-shadow(1px -1px 0px #111) drop-shadow(-1px -1px 0px #111)"}}>
		<path  d="M28 132C1 87 47 69 46 46c22 44-21 49-18 86z"/>
		<path d="M17 148c3 20 11 38 33 49-26-45-14-91 19-110-3 6-7 19 2 25C98 83 105 37 99 0c34 33 53 79 31 120-15 29 9 57 22 0 60 57 58 157-34 179 8-10 57-56 8-108 6 41-27 41-22 81-26-44 30-46-18-110 12 53-66 74-11 136-82-17-89-93-58-150z"/>
	</svg>
);

export default FireIcon;
