import React, {Component} from 'react';
import ReactGA from 'react-ga';
import Pusher from 'pusher-js';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import Keys from 'shakitz-keys';
import DraftTeam from '../DraftTeam';
import DraftHeader from '../DraftHeader';
import DraftBoard from '../DraftBoard';
import DraftPlayerPool from '../DraftPlayerPool';
import { authDraft } from '../../actions/authDraft';
import { fetchDraftData, fetchDraftDataSuccess } from '../../actions/draftData';
import { pauseDraft } from '../../actions/pauseDraft';
import { requestTime } from '../../actions/requestTime';
import { setDraftClock } from '../../actions/setDraftClock';
import { startDraft } from '../../actions/startDraft';
import { startSeason } from '../../actions/startSeason';
import calculateDraftPicks from '../../helpers/calculateDraftPicks';
import { Constants } from '../../helpers/Constants';
import { GA } from '../../helpers/ga';
import getDraftTopic from '../../helpers/getDraftTopic';
import hasDraftStarted from '../../helpers/hasDraftStarted';
import isDraftComplete from '../../helpers/isDraftComplete';
import isDraftLive from '../../helpers/isDraftLive';
import mergeClockData from '../../helpers/mergeClockData';
import mergeDraftData from '../../helpers/mergeDraftData';
import Header from '../../components/Common/Header';
import Modal from '../../containers/Modal/Modal';
import DraftPriorityModal from '../../components/DraftRoom/DraftPriorityModal';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import getLeagueSeason from '../../helpers/getLeagueSeason';
import Toggle from '../../components/Common/Toggle';
import {selectLeague} from '../../actions/selectLeague';
import './DraftRoom.scss';

class DraftRoom extends Component {
	static defaultProps = {
		gameWeekTeams: []
	};

	constructor(props) {
		super(props);
		this.fetchDraftData = this.fetchDraftData.bind(this);
		this.onDraftStart = this.onDraftStart.bind(this);
		this.onMoreTimeRequested = this.onMoreTimeRequested.bind(this);
		this.onSeasonStart = this.onSeasonStart.bind(this);
		this.onToggleClick = this.onToggleClick.bind(this);
		this.onTogglePause = this.onTogglePause.bind(this);
		this.showStartModal = this.showStartModal.bind(this);
		this.onOffline = this.onOffline.bind(this);
		this.onOnline = this.onOnline.bind(this);
		this.numOfRounds = Constants.numStarters + Constants.numBench;
		this.toggleOptions = ['Draft Board', 'Player Pool'];
		this.socket = null;
		this.channel = null;
		this.socketReconnectTimer = null;
		this.createBoardRef = ref => this.boardRef = ref;
		this.state = {
			activePanel: this.toggleOptions[0],
			showStartDraftModal: false,
			showDraftSpinner: false,
			shouldRefresh: false
		};
		this.originalTitle = 'Shakitz - Draft Room';
		this.season = `${Constants.currentSeason}`;
	}

	componentDidMount() {
		if(!this.props.shakitzUser || !this.props.shakitzUser.userId) {
			this.props.history.push('/');
			return;
		}

		if(!this.props.selectedLeague && this.props.shakitzUser.userLeagues.length === 0) {
			this.props.history.push('/');
			return;
		}

		if(!this.props.selectedLeague && this.props.shakitzUser.userLeagues.length > 0) {
			this.props.history.push('/leagues');
			return;
		}

		this.fetchDraftData();
		if(!this.onlineListener) {
			this.onlineListener = window.addEventListener('online', this.onOnline);
		}

		if(!this.offlineListener) {
			this.offlineListener = window.addEventListener('offline', this.onOffline);
		}

		if(!this.props.draftSocketData) {
			this.props.authenticateDraft(this.props.userObj.email);
		} else {
			this.registerSocket();
		}

		if(this.props.savedDraft && this.props.savedDraft.draft) {
			this.resetActiveToggle();
		}

		if(this.props.draftClock.user && this.props.draftClock.user.userId === this.props.shakitzUser.userId) {
			this.initiateTitleFlash();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(!prevProps.savedDraft && this.props.savedDraft) {
			this.resetActiveToggle();
		}

		if(!prevProps.startSeasonData.data && this.props.startSeasonData.data) {
			this.props.history.push('/');
		}

		if(this.props.savedDraft.draft && this.props.draftData && this.props.draftClock.user && !this.props.draftClock.next) {
			const mergedData = mergeDraftData(this.numOfRounds, this.props.savedDraft.users.length, this.props.draftData, this.props.savedDraft);

			const currentPick = (calculateDraftPicks(mergedData) + 1) || 1;
			const mergedClockData = mergeClockData(this.props.savedDraft, this.props.draftClock, this.props.savedDraft.users, currentPick);

			this.props.setDraftClock(mergedClockData);
		}

		if(prevProps.draftClock.user && prevProps.draftClock.user.userId !== this.props.draftClock.user.userId && this.props.draftClock.user.userId === this.props.shakitzUser.userId) {
			this.initiateTitleFlash();
		} else if (this.interval && this.props.draftClock.user.userId !== this.props.shakitzUser.userId) {
			this.interval = clearInterval(this.interval);
			if(this.titleTimeout) {
				this.titleTimeout = clearTimeout(this.titleTimeout);
			}
			document.title = this.originalTitle;
		}

		if(this.socket) {
			return;
		} else if(!prevProps.draftSocketData && this.props.draftSocketData) {
			this.registerSocket();
		}
	}

	componentWillUnmount() {
		if(this.socket) {
			this.socket.disconnect();
		}

		if(this.timeout) {
			this.timeout = clearTimeout(this.timeout);
		}

		if(this.titleTimeout) {
			this.titleTimeout = clearTimeout(this.titleTimeout);
		}

		if(this.interval){
			this.interval = clearInterval(this.interval);

			document.title = this.originalTitle;
		}

		if(this.offlineListener) {
			this.offlineListener = window.removeEventListener('offline', this.onOffline);
		}

		if(this.onlineListener) {
			this.onlineListener = window.removeEventListener('online', this.onOnline);
		}
		this.socket = null;
	}

	fetchDraftData() {
		if(!this.props.selectedLeague.leagueId) {
			const { format, leagueId, seasonToBe, type } = getLeagueSeason(this.props.shakitzUser.userLeagues);


			selectLeague(format, leagueId, this.season, seasonToBe, type);
			this.props.fetchDraftData(leagueId, this.season);
		} else {
			this.props.fetchDraftData(this.props.selectedLeague.leagueId, this.season);
		}
	}

	hasDraftBegun(savedDraft, draftClock) {
		if(savedDraft.draft && savedDraft.draft.clock === null) {
			return true; // its finished
		}

		if( (!savedDraft || !savedDraft.draft) || Object.keys(draftClock).length === 0) {
			return false;
		}

		return isDraftLive(draftClock) || hasDraftStarted(draftClock.startTime) || hasDraftStarted(savedDraft.draft.draftStartDate) || isDraftLive(savedDraft.draft.clock);
	}

	initiateTitleFlash() {
		this.interval = setInterval(() => {
			document.title = 'YOUR PICK';
			this.titleTimeout = setTimeout(() => {
				document.title = this.originalTitle;
			}, 1000)
		}, 2000);
	}

	isCommissioner() {
		if(!this.props.savedDraft || !this.props.savedDraft.league) {
			return false;
		}

		return this.props.savedDraft.league.commissionerId === this.props.shakitzUser.userId;
	}

	onDraftStart(userPriority) {
		if(!this.isCommissioner()) {
			return;
		}

		this.props.startDraft(this.props.savedDraft.draft.draftId, this.props.selectedLeague.leagueId, userPriority);
		this.showStartModal(false);
	}

	onOffline () {
		this.setState({ showDraftSpinner: true, shouldRefresh: true });
	}

	onOnline() {
		if(this.socket && this.socket.connection.state === 'connected') {
			this.fetchDraftData();
			this.setState({ showDraftSpinner: false, shouldRefresh: false })
		} else {
			this.registerSocket();
		}
	}

	onMoreTimeRequested() {
		if(this.props.shakitzUser.userId !== this.props.draftClock.user.userId || !this.topic) {
			return;
		}

		// TODO fix this
		const leagueId = this.props.selectedLeague.leagueId;

		const draftId = Keys.generateDraft({ leagueId, season: this.season});
		const data = { topic: this.topic, leagueId, userId: this.props.draftClock.user.userId, nextUserId: this.props.draftClock.next.userId };
		this.props.requestMoreTime(draftId, data);
		ReactGA.event({
			category: GA.CATEGORY.DRAFT,
			action: GA.ACTION.REQUEST_TIME
		});
	}

	onSeasonStart() {
		if(!this.props.isPosting) {
			this.props.startSeason(this.props.selectedLeague.leagueId, this.season);
		}
	}

	onToggleClick(evt) {
		ReactGA.event({
			category: GA.CATEGORY.DRAFT,
			action: GA.ACTION.TOGGLE_DRAFT_PANEL
		});
		const pane = this.toggleOptions.find(option => option === evt.target.innerText) || this.state.activePanel;
		this.setState({
			activePanel: pane
		});
		if(pane === 'Player Pool') {
			this.boardRef.scrollTo(0,0);
		}
	}

	onTogglePause(evt) {
		const isCurrentlyPaused = evt.target.getAttribute('data-pause') === 'true';
		const draftId = Keys.generateDraft({ leagueId: this.props.selectedLeague.leagueId, season: this.season});

		this.props.pauseDraft(draftId, {
			commissionerId: this.props.shakitzUser.userId,
			leagueId: this.props.selectedLeague.leagueId,
			shouldPause: !isCurrentlyPaused,
			topic: this.topic
		})
		ReactGA.event({
			category: GA.CATEGORY.DRAFT,
			action: GA.ACTION.PAUSE_DRAFT
		});
	}

	registerSocket() {
		if(!this.props.selectedLeague.leagueId || !this.props.selectedLeague.season) {
			this.props.history.push('/leagues');
			return;
		}
		const draftId = Keys.generateDraft({ leagueId: this.props.selectedLeague.leagueId, season: this.season});
		const { id, endpoint, cluster, channel, topic } = this.props.draftSocketData;

		Pusher.logToConsole = true;
		this.socket = new Pusher(id, {
			authEndpoint: `${Constants.urls.BASE}${endpoint}`,
			cluster,
			activityTimeout: 10000,
			auth: {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('szaud')}`
				}
			},
			useTLS: true
		});

		this.channel = this.socket.subscribe(channel);
		this.topic = getDraftTopic(draftId, topic);

		this.channel.bind('error', (error) => {
			console.error(error);
			if(window.confirm('The draft room has lost connection, please refresh')) {
				// Do nothing
			}
		});

		this.channel.bind(this.topic, message => {
			console.log(message);
			if(message.startsWith('started') || message.startsWith('refresh')) {
				this.fetchDraftData();
			}
		}, this);

		this.socket.connection.bind('state_change', states => {
			console.log('we have a SOCKET state change', states);
			this.setState({
				showDraftSpinner: states.current !== 'connected'
			});
		});
	}

	resetActiveToggle() {
		const { draftStartDate } = this.props.savedDraft.draft;
		if(draftStartDate && !hasDraftStarted(draftStartDate)) {
			this.setState({
				activePanel: this.toggleOptions[1]
			})
		}
	}

	showStartModal(shouldShow) {
		if(!shouldShow) {
			document.body.classList.add('animate-modal-out');
			this.timeout = setTimeout(() => {
				this.setState({showStartDraftModal: false});
				document.body.classList.remove('animate-modal-out');
			}, Constants.modalAnimationTimeout);
		} else {
			this.setState({
				showStartDraftModal: shouldShow
			});
		}
	}

	render () {
		const users = this.props.savedDraft.users || [];
		const draftData = mergeDraftData(this.numOfRounds, users.length, this.props.draftData, this.props.savedDraft);
		const playersToFilter = draftData.reduce((prev, curr) => prev.concat(curr), []).map(p => p.personId);
		const picksMade = calculateDraftPicks(draftData);
		const draftComplete = isDraftComplete(draftData, users.length);
		const modalName = `Draft Priority Modal - ${this.props.shakitzUser.userId}`;
		const draftSpinnerName = `Draft Room - Connecting`;

		return (
			<div className='draft-room'>
				<Helmet>
					<title>{this.originalTitle}</title>
					<link rel="canonical" href="https://shakitz.com/draft-room" />
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				{this.props.isPosting ? <SpinnerLayer/> : null}
				<div className='draft-room__board' ref={this.createBoardRef}>
					<DraftHeader isCommissioner={this.isCommissioner()} isDraftComplete={draftComplete} isPaused={this.props.isPaused} togglePause={this.onTogglePause} numOfUsers={users.length} onDraftStart={this.showStartModal} onMoreTimeRequested={this.onMoreTimeRequested} onSeasonStart={this.onSeasonStart} picksMade={picksMade} userId={this.props.shakitzUser.userId} />
					<div className='draft-room__panel-header'>
						<Header headerLevel={'h2'} text={this.state.activePanel} />
						<Toggle activeOption={this.state.activePanel} toggleOptions={this.toggleOptions} onToggleClick={this.onToggleClick} type={'secondary'} />
					</div>
					{this.state.activePanel === 'Draft Board' ?
					<DraftBoard draftData={draftData} leagueUsers={users} hasDraftBegun={this.hasDraftBegun(this.props.savedDraft, this.props.draftClock)} isCommissioner={this.isCommissioner()} savedDraft={this.props.savedDraft} userId={this.props.shakitzUser.userId} /> : <DraftPlayerPool playersToFilter={playersToFilter} userId={this.props.shakitzUser.userId} />}
				</div>
				<div className='draft-room__team'>
					<DraftTeam draftData={draftData} userId={this.props.shakitzUser.userId} />
				</div>
				{this.state.showStartDraftModal ? <Modal name={modalName} onClose={() => this.showStartModal(false)} ><DraftPriorityModal onClose={() => this.showStartModal(false)} onFormSubmit={this.onDraftStart} users={users} /></Modal> : null}
				{this.state.showDraftSpinner ? <Modal className='draft-room__connection-dropped-spinner' name={draftSpinnerName}>
					<div className='draft-room__spinner-text'>
						<h1 className='draft-room__disconnected-text'>Network disconnected</h1>
						<p className='draft-room__refresh-text'>Refresh page if spinner still appears after ~30 seconds</p>
					</div>
					<SpinnerLayer />
				</Modal> : null}

			</div>
		);
	}
}

const mapDispatchToProps = ( dispatch ) => {
	return {
		authenticateDraft: (userId) => dispatch(authDraft(userId)),
		//draft: (round, pick) => dispatch(setDraftPick(round, pick)),
		fetchDraftData: (leagueId, season) => dispatch(fetchDraftData(leagueId, season)),
		pauseDraft: (draftId, data) => dispatch(pauseDraft(draftId, data)),
		requestMoreTime: (draftId, data) => dispatch(requestTime(draftId, data)),
		setDraftClock: (draftClock) => dispatch(setDraftClock(draftClock)),
		selectLeague: (format, leagueId, season, seasonToBe, type) => dispatch(selectLeague(format, leagueId, season, seasonToBe, type)),
		startDraft: (draftId, leagueId, userPriority) => dispatch(startDraft(draftId, leagueId, userPriority)),
		startSeason: (leagueId, season) => dispatch(startSeason(leagueId, season)),
		updateSavedDraft: (data) => dispatch(fetchDraftDataSuccess(data))
	};
};

const mapStateToProps = ( state ) => {
	return {
		draftClock: state.draftClock,
		draftData: state.draftPicks,
		savedDraft: state.draftData,
		draftSocketData: state.authDraft,
		isPaused: state.draftClock.isPaused || false,
		isPosting: state.authDraftRequest || state.startDraftRequest || state.draftDataRequest || state.pauseDraftRequest || state.requestTimeRequest || state.startSeasonRequest,
		hasErrored: state.authDraftFailure || state.startDraftFailure || state.draftDataFailure || state.pauseDraftFailure || state.requestTimeFailure || state.startSeasonFailure,
		selectedLeague: state.selectedLeague,
		startSeasonData: state.startSeason
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftRoom);
