import React from 'react';
import classNames from 'classnames';
import InjuryIcon from '../../../svgs/InjuryIcon';
import {Constants} from '../../../../helpers/Constants';
import './InjuryStatus.scss';

const InjuryStatus = ({showStatus = false, small, status}) => {
	if(!status || status.includes('Full Participation')) {
		return null;
	}

	const clsName = classNames('injury-status', {
		'injury-status--out': Constants.injuryStates.OUT.includes(status),
		'injury-status--doubtful': Constants.injuryStates.DOUBTFUL.includes(status),
		'injury-status--questionable': Constants.injuryStates.QUESTIONABLE.includes(status),
		'injury-status--show-status': showStatus,
		'injury-status--small': small
	});

	let fill = '#ff4646';
	if(Constants.injuryStates.DOUBTFUL.includes(status)) {
		fill = '#ff8946';
	} else if (Constants.injuryStates.QUESTIONABLE.includes(status)) {
		fill = '#0032e3';
	}

	return (
		<div className={clsName}>
			<span className='injury-status__icon'>
				<InjuryIcon fill={fill}/>
			</span>
			{showStatus && <span className='injury-status__status'>Status: {status}</span>}
		</div>
	);
};

export default InjuryStatus;
