import React from 'react';
import './UserGWTally.scss';

const UserGWTally = ({gameWeek, userTally, averageTally, userPlayersPlayed, gwOver = false}) => (
	<div className={`user-gw-tally ${gwOver ? 'user-gw-tally--finished' : ''}`}>
		<div className='user-gw-tally__game-week'>GameWeek {gameWeek}</div>
		<div className='user-gw-tally__context-wrapper'>
			<div className='user-gw-tally__game-week-wrapper'>
				<p className='user-gw-tally__description'>GameWeek Points:</p>
				<p className='user-gw-tally__game-week-points'>{userTally}</p>
			</div>
			<div className='user-gw-tally__points-wrapper'>
				<div className='user-gw-tally__game-week-detail'>
					<p className='user-gw-tally__points-description'>Average Points: <span className='user-gw-tally__points'>{averageTally}</span></p>
				</div>
				{!gwOver ? <div className='user-gw-tally__game-week-detail'>
					<p className='user-gw-tally__points-description'>Players Played: <span className='user-gw-tally__points'>{userPlayersPlayed}</span></p>
				</div> : null}
			</div>
		</div>
	</div>
);

export default UserGWTally;
