import { POST_WAIVERS_FAILURE, POST_WAIVERS_REQUEST, POST_WAIVERS_SUCCESS} from '../actions/addWaiver';

export function addWaiverFailure(state = false, action) {
	switch (action.type) {
		case POST_WAIVERS_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function addWaiverRequest(state = false, action) {
	switch (action.type) {
		case POST_WAIVERS_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function addWaiver(state = false, action) {
	switch (action.type) {
		case POST_WAIVERS_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
