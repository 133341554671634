import { PUT_CONFIRM_TRADE_FAILURE, PUT_CONFIRM_TRADE_REQUEST, PUT_CONFIRM_TRADE_SUCCESS} from '../actions/confirmTrade';

export function confirmTradeFailure(state = false, action) {
	switch (action.type) {
		case PUT_CONFIRM_TRADE_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function confirmTradeRequest(state = false, action) {
	switch (action.type) {
		case PUT_CONFIRM_TRADE_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function confirmTrade(state = false, action) {
	switch (action.type) {
		case PUT_CONFIRM_TRADE_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
