import React, { useContext, useEffect, useState } from 'react';
import { fetchGameWeek } from '../actions/fetchGameWeek';
import { fetchSchedule } from '../actions/fetchSchedule';
import { fetchScores } from '../actions/fetchScores';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ShakitzContext from '../context/ShakitzContext';
import { Constants } from '../helpers/Constants';
import {selectLeague} from '../actions/selectLeague';
import getLeagueSeason from '../helpers/getLeagueSeason';
import {fetchSeasonUserTeam} from '../actions/seasonUserTeam';
import {fetchGameWeekTeam} from '../actions/gameWeekTeam';
import Keys from 'shakitz-keys';
import {fetchUnavailablePlayers} from '../actions/fetchUnavailablePlayers';
import {activeGameWeekTeam} from '../actions/activeGameWeekTeam';
import {refreshCoreData} from '../actions/refreshCoreData';
import {fetchSeasonPlayerStats} from '../actions/fetchSeasonPlayerStats';
import hasSeasonStarted from '../helpers/hasSeasonStarted';

const withCoreData = (WrappedComponent) => {

	const HOC = (props) => {
		const { currentGameWeek, currentGameWeekIsFetching, fetchGameWeek, fetchGameWeekTeam, fetchSeasonPlayerStats, fetchSeasonUserTeams, fetchSchedule, fetchScores, fetchUnavailablePlayers, gameWeekTeams, gameWeekTeamIsFetching, isFetchingCore, refreshCoreData, selectLeague, schedule, scheduleIsFetching, scores, scoresIsFetching, seasonUserTeams, seasonPlayerStats, seasonPlayerStatsIsFetching, seasonUserTeamsIsFetching, selectedLeague, setActiveGameWeekTeam, setRefresh, unavailablePlayers, unavailablePlayersIsFetching } = props;
		const [ hasRequested, setHasRequested ] = useState(false);
		const shakitzContext = useContext(ShakitzContext);

		useEffect(() => {
			if(refreshCoreData) {
				setHasRequested(false);
				setRefresh(false);
				return;
			}

			if(shakitzContext.userLeagues.length === 0) {
				return;
			}

			if(!selectedLeague.leagueId) {
				const { format, leagueId, season, seasonToBe, type } = getLeagueSeason(shakitzContext.userLeagues); // Use most recent season as default
				selectLeague(format, leagueId, season, seasonToBe, type);
				return;
			}

			if(!hasSeasonStarted(selectedLeague)) {
				return;
			}

			if(selectedLeague.type && !currentGameWeek.hasOwnProperty(selectedLeague.type)) {
				if(!currentGameWeekIsFetching) {
					fetchGameWeek('REG');
					fetchGameWeek('PRE');
				}
				return;
			}

			const gameWeek = currentGameWeek[selectedLeague.type];
			if(!gameWeek) {
				return;
			}

			if(!hasRequested) {
				if(!scheduleIsFetching && Object.keys(schedule).length <= 1) {
					const preGameWeek = currentGameWeek['PRE'];
					const regGameWeek = currentGameWeek['REG'];
					if(regGameWeek) {
						fetchSchedule('REG', Constants.currentSeason, regGameWeek);
					}
					if(preGameWeek && selectedLeague.type === 'PRE') {
						fetchSchedule('PRE', Constants.currentSeason, preGameWeek);
					}
				}

				if(!scoresIsFetching && Object.keys(scores).length === 0) {
					fetchScores();
				} else if(!scoresIsFetching && currentGameWeek['REG'] && !scores.hasOwnProperty(currentGameWeek['REG'])) {
					fetchScores();
				}

				if(!unavailablePlayersIsFetching) {
					fetchUnavailablePlayers(selectedLeague.leagueId, selectedLeague.season);
				}

				if(!seasonUserTeamsIsFetching) {
					fetchSeasonUserTeams(selectedLeague.leagueId, selectedLeague.season);
				}

				if(!seasonPlayerStatsIsFetching) {
					fetchSeasonPlayerStats(selectedLeague.season, selectedLeague.type, selectedLeague.format);
				}

				if(!gameWeekTeamIsFetching && seasonUserTeams.length > 0) {
					const userSeasonTeam = seasonUserTeams.find(t => t.hashedUser === shakitzContext.userId);
					const gameWeekTeamId =  Keys.generateGameWeekTeam({
						gameWeek,
						leagueId: selectedLeague.leagueId,
						seasonUserTeamId: userSeasonTeam.seasonUserTeamId
					});
					fetchGameWeekTeam(gameWeekTeamId, true, selectedLeague.season);
					setHasRequested(true);
				}
			}

			if(gameWeekTeams.length > 0) {
				const userSeasonTeam = seasonUserTeams.find(t => t.hashedUser === shakitzContext.userId);
				const gameWeekTeamId =  Keys.generateGameWeekTeam({
					gameWeek,
					leagueId: selectedLeague.leagueId,
					seasonUserTeamId: userSeasonTeam.seasonUserTeamId
				});
				const activeGameWeekTeam = gameWeekTeams.find(gameWeekTeam => gameWeekTeam.gameWeekTeamId === gameWeekTeamId);
				setActiveGameWeekTeam(activeGameWeekTeam || {});
			}

		}, [hasRequested, currentGameWeek, currentGameWeekIsFetching, fetchGameWeek, fetchGameWeekTeam, fetchSchedule, fetchScores, fetchSeasonPlayerStats, fetchSeasonUserTeams, fetchUnavailablePlayers, gameWeekTeams, gameWeekTeamIsFetching, refreshCoreData, selectLeague, schedule, scheduleIsFetching, scores, scoresIsFetching, seasonPlayerStats, seasonPlayerStatsIsFetching, seasonUserTeams, seasonUserTeamsIsFetching, selectedLeague.leagueId, setActiveGameWeekTeam, setHasRequested, shakitzContext.userLeagues, unavailablePlayers, unavailablePlayersIsFetching]);

		return <WrappedComponent {...props} />;
	};

	return HOC;
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchGameWeek: (type) => dispatch(fetchGameWeek(type)),
		fetchGameWeekTeam: (gameWeekTeamId, withStats, season) => dispatch(fetchGameWeekTeam(gameWeekTeamId, withStats, season)),
		fetchSeasonPlayerStats: (season, seasonType, format) => dispatch(fetchSeasonPlayerStats(season, seasonType, format)),
		fetchSeasonUserTeams: (leagueId, season) => dispatch(fetchSeasonUserTeam(leagueId, season)),
		fetchSchedule: (type, season, gameWeek) => dispatch(fetchSchedule(type, season, gameWeek)),
		fetchScores: (type) => dispatch(fetchScores(type)),
		fetchUnavailablePlayers: (leagueId, season) => dispatch(fetchUnavailablePlayers(leagueId, season)),
		setRefresh: (shouldRefresh) => dispatch(refreshCoreData(shouldRefresh)),
		selectLeague: (format, leagueId, season, seasonToBe, type) => dispatch(selectLeague(format, leagueId, season, seasonToBe, type)),
		setActiveGameWeekTeam: (gameWeekTeam) => dispatch(activeGameWeekTeam(gameWeekTeam))
	};
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		gameWeekIsFetching: state.currentGameWeekRequest,
		gameWeekHasFailed: state.currentGameWeekFailure,
		gameWeekTeams: state.gameWeekTeams,
		gameWeekTeamIsFetching: state.gameWeekTeamRequest,
		gameWeekTeamHasFailed: state.gameWeekTeamFailure,
		isFetchingCore: state.currentGameWeekRequest || state.gameWeekTeamRequest || state.seasonUserTeamRequest || state.scheduleRequest || state.scoresRequest || state.seasonPlayerStatsRequest || state.unavailablePlayersRequest,
		players: state.players,
		refreshCoreData: state.refreshCoreData,
		seasonUserTeams: state.seasonUserTeams,
		seasonUserTeamsIsFetching: state.seasonUserTeamRequest,
		seasonUserTeamsHasFailed: state.seasonUserTeamFailure,
		schedule: state.schedule,
		scheduleIsFetching: state.scheduleRequest,
		scheduleHasFailed: state.scheduleFailure,
		scores: state.scores,
		scoresIsFetching: state.scoresRequest,
		scoresHasFailed: state.scoresHasFailed,
		seasonPlayerStats: state.seasonPlayerStats,
		seasonPlayerStatsIsFetching: state.seasonPlayerStatsRequest,
		seasonPlayerStatsHasFailed: state.seasonPlayerStatsFailure,
		selectedLeague: state.selectedLeague,
		unavailablePlayers: state.unavailablePlayers,
		unavailablePlayersIsFetching: state.unavailablePlayersRequest,
		unavailablePlayersHasFailed: state.unavailablePlayersFailure
	};
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withCoreData);
