import { POST_REQUEST_TIME_FAILURE, POST_REQUEST_TIME_REQUEST, POST_REQUEST_TIME_SUCCESS} from '../actions/requestTime';

export function requestTimeFailure(state = false, action) {
	switch (action.type) {
		case POST_REQUEST_TIME_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function requestTimeRequest(state = false, action) {
	switch (action.type) {
		case POST_REQUEST_TIME_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function requestTime(state = false, action) {
	switch (action.type) {
		case POST_REQUEST_TIME_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
