import React from 'react';
import classNames from 'classnames';
import Header from '../Common/Header';
import './Explainer.scss';

const Explainer = ({coreText, headerText, style, type, children}) => {
	const clsName = classNames('explainer', {
		[`explainer--${type}`]: type
	});

	return (
		<div className={clsName} style={style}>
			<div className='explainer__svg'>{children}</div>
			<Header headerLevel='h2' text={headerText} />
			<p className='explainer__text'>{coreText}</p>
		</div>
	)
}

export default Explainer;
