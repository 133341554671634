import React from 'react';

const H2HShapeGroupRight = ({width = '62', height = '131'}) => (
	<svg width={width} height={height} viewBox="0 0 62 131" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M24.2189 87.9644L66.6445 62.0037L79.5945 83.235C86.7467 94.9607 83.0473 110.278 71.3318 117.447L50.119 130.427L24.2189 87.9644Z" fill="url(#h2h_right_paint0_linear)"/>
		<path d="M24.2189 0.182414L49.0064 0.000675562C62.6961 -0.0996959 73.8746 10.9972 73.9743 24.7862L74.1547 49.7534L49.3672 49.9352C35.6775 50.0355 24.499 38.9387 24.3993 25.1497L24.2189 0.182414Z" fill="url(#h2h_right_paint1_linear)"/>
		<path d="M11.0026 72.8683C4.92603 66.7913 4.92618 56.9387 11.003 50.862C17.0797 44.7852 26.932 44.7853 33.0086 50.8623C39.0852 56.9393 39.085 66.7918 33.0082 72.8686C26.9315 78.9454 17.0792 78.9452 11.0026 72.8683Z" fill="url(#h2h_right_paint2_linear)"/>
		<defs>
			<linearGradient id="h2h_right_paint0_linear" x1="45.4317" y1="74.984" x2="71.3924" y2="117.41" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E00D83" stopOpacity="0.65"/>
				<stop offset="1" stopColor="#E00D83" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="h2h_right_paint1_linear" x1="24.2189" y1="0" x2="57.5" y2="38" gradientUnits="userSpaceOnUse">
				<stop stopColor="#A838FF" stopOpacity="0.67"/>
				<stop offset="1" stopColor="#A838FF" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="h2h_right_paint2_linear" x1="33.0082" y1="72.8686" x2="11.0023" y2="50.8626" gradientUnits="userSpaceOnUse">
				<stop stopColor="white"/>
				<stop offset="1" stopColor="white" stopOpacity="0"/>
			</linearGradient>
		</defs>
	</svg>

);

export default H2HShapeGroupRight;
