import getGameWeekState from './getGameWeekState';
import {Constants} from './Constants';

const shouldShowPointData = (schedule, type, gameWeek) => {
	if(!schedule || !type || Object.keys(schedule).length === 0 || !schedule[type]) {
		return false;
	}

	const scheduleData = schedule[type][gameWeek];
	if(!scheduleData || !scheduleData.hasOwnProperty('games') || scheduleData.games.length === 0) {
		return false;
	}

	const gameWeekState = getGameWeekState(scheduleData);
	return gameWeekState === Constants.gameStates.LIVE || gameWeekState === Constants.gameStates.POST;
};

export default shouldShowPointData;
