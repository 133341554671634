import { Constants } from './Constants';

const MAX_ROUNDS = Constants.numStarters + Constants.numBench;

const getNextDraftUser = (currentUser, userPriority, currentPick) => {
	const nextPick = currentPick + 1;
	const currentRound = Math.ceil(currentPick / userPriority.length);
	const nextRound = Math.ceil(nextPick / userPriority.length);
	const isEvenRound = currentRound % 2 === 0;

	const currentUserIndex = userPriority.findIndex(user => user.userId === currentUser.userId);

	if(nextRound > MAX_ROUNDS) {
		return { userId: 'N/A', userName: 'N/A'};
	}

	if(nextRound > currentRound) {
		return userPriority[currentUserIndex];
	}

	const nextIndex = isEvenRound ? currentUserIndex - 1 : currentUserIndex + 1;
	return userPriority[nextIndex];
};

export default getNextDraftUser;
