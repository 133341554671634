import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

// DO not use GA in dev mode
if(process.env.NODE_ENV !== 'development') {
	ReactGA.initialize('UA-144998637-1', { siteSpeedSampleRate: 100});
}

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		ReactGA.set({
			page,
			...options
		});
		ReactGA.pageview(page);
	};

	const HOC = props => {
		useEffect(() => trackPage(props.location.pathname), [
			props.location.pathname
		]);

		return <WrappedComponent {...props} />;
	};

	return HOC;
};

export default withTracker;
