import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import shortHash from 'short-hash';
import ReactGA from 'react-ga';
import Keys from 'shakitz-keys';
import UserMyTeamHeader from '../../components/UserTeam/UserMyTeamHeader';
import GameWeekToggle from '../../components/Common/GameWeekToggle';
import SpinnerLayer from '../../components/SpinnerLayer/SpinnerLayer';
import UserGameWeek from '../UserGameWeek/UserGameWeek';
import { Constants } from '../../helpers/Constants';
import { GA } from '../../helpers/ga';
import { activeGameWeekTeam } from '../../actions/activeGameWeekTeam';
import { fetchGameWeekTeam } from '../../actions/gameWeekTeam';
import shouldShowPointData from '../../helpers/shouldShowPointData';
import {fetchSchedule} from '../../actions/fetchSchedule';
import './UserTeam.scss';
import SeasonNotStarted from '../../components/SeasonNotStarted';
import hasSeasonStarted from '../../helpers/hasSeasonStarted';

class UserTeam extends Component {
	static defaultProps = {
		gameWeekTeams: []
	};

	constructor(props) {
		super(props);
		const { seasonUserTeam, limitAccess } = this.getSeasonUserTeam();

		// Handle use case where we have or do not have data (my team or other person team)
		const gameWeek = this.props.selectedLeague.type && this.props.currentGameWeek ? this.props.currentGameWeek[this.props.selectedLeague.type] : null;
		this.state = {
			limitAccess,
			seasonUserTeam,
			selectedGameWeek: gameWeek
		};
		this.onGameWeekChange = this.onGameWeekChange.bind(this);

		if (gameWeek && seasonUserTeam) {
			this.state = {
				...this.state,
				...{
					currentGameWeekTeamId: this.getGameWeekTeamId(seasonUserTeam, gameWeek)
				}
			};
		}
	}

	componentDidMount() {
		if (!this.state.currentGameWeekTeamId) {
			const { seasonUserTeam, limitAccess } = this.getSeasonUserTeam();

			if(!seasonUserTeam) {
				return;
			}

			this.setState({
				limitAccess,
				currentGameWeekTeamId: this.getGameWeekTeamId(seasonUserTeam)
			});

			return;
		}

		const showPoints = shouldShowPointData(this.props.schedule, this.props.selectedLeague.type, this.state.selectedGameWeek);
		this.props.fetchData(this.state.currentGameWeekTeamId, showPoints, this.state.seasonUserTeam.season);

		// We need to ensure we also get the users next game week team so they can sub appropriately
		if(this.state.selectedGameWeek+1 <= Constants.numOfGameWeeks[this.props.selectedLeague.type]) {
			const currentGameWeekTeamId = this.getGameWeekTeamId(this.state.seasonUserTeam, this.state.selectedGameWeek + 1);
			this.props.fetchData(currentGameWeekTeamId);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Get game week team data when that has updated
		if (prevState.currentGameWeekTeamId !== this.state.currentGameWeekTeamId) {
			this.props.fetchData(this.state.currentGameWeekTeamId, true, this.state.seasonUserTeam.season);
		}

		if(!prevProps.selectedLeague.leagueId !== this.props.selectedLeague.leagueId && !this.state.selectedGameWeek) {
			const currentGameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
			if(currentGameWeek) {
				this.setState({ selectedGameWeek: currentGameWeek });
			}
		}

		// Fetch schedule if we don't have it yet when user changes game week
		if (!this.props.scheduleIsFetching && this.props.selectedLeague && prevState.selectedGameWeek !== this.state.selectedGameWeek) {
			const scheduleData = this.props.schedule[this.props.selectedLeague.type];
			if(!scheduleData || !scheduleData.hasOwnProperty(this.state.selectedGameWeek)) {
				this.props.fetchSchedule(this.props.selectedLeague.type, Constants.currentSeason, this.state.selectedGameWeek);
			}
		}

		// Update state accordingly so we can kick off data fetches
		if(this.state.seasonUserTeam && Object.keys(prevProps.currentGameWeek).length !== Object.keys(this.props.currentGameWeek).length) {
			const currentGameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
			const currentGameWeekTeamId = this.getGameWeekTeamId(this.state.seasonUserTeam, currentGameWeek);

			this.setState({
				currentGameWeekTeamId,
				selectedGameWeek: currentGameWeek
			});
		}

		// Ensure we show a game week team by default
		if (prevProps.gameWeekTeams.length !== this.props.gameWeekTeams.length) {
			this.updateActiveGameWeekTeam();
		}

		// Ensure we refresh data when user views different team
		if(!this.state.currentGameWeekTeamId || (prevProps.match.params.seasonUserTeam !== this.props.match.params.seasonUserTeam)) {
			const { seasonUserTeam, limitAccess } = this.getSeasonUserTeam();
			if(!seasonUserTeam) {
				return;
			}
			const currentGameWeekTeamId = this.getGameWeekTeamId(seasonUserTeam);

			this.setState({
				limitAccess,
				seasonUserTeam,
				currentGameWeekTeamId
			});
		}
	}

	getGameWeekTeamId(seasonUserTeam, currentGameWeek) {
		const gw = currentGameWeek || this.state.selectedGameWeek || this.props.currentGameWeek[this.props.selectedLeague.type];

		return Keys.generateGameWeekTeam({
			gameWeek: gw,
			leagueId: seasonUserTeam.leagueId,
			seasonUserTeamId: seasonUserTeam.seasonUserTeamId
		});
	}

	getSeasonUserTeam() {
		const hashedId = this.props.match.params.seasonUserTeam;
		let limitAccess = this.props.isOtherTeam;
		let seasonUserTeam;
		if(this.props.isOtherTeam) {
			seasonUserTeam = this.props.seasonUserTeams.find(team => shortHash(team.seasonUserTeamId) === hashedId);
		} else {
			seasonUserTeam = this.props.seasonUserTeams.find(team => team.hashedUser === this.props.shakitzUserId);
		}

		if(seasonUserTeam && seasonUserTeam.hashedUser === this.props.shakitzUserId) {
			limitAccess = false;
		}

		return { seasonUserTeam, limitAccess };
	}

	mergePlayerData = (team) => {
		const teamStarters = team.starters || [];
		const teamBench = team.bench || [];
		const starters = teamStarters.map(s => {
			const p = this.props.players.find(p => p.playerId === s.playerId || p.statId === s.playerId);

			return {
				...s,
				...p
			}
		});
		const bench = teamBench.map(s => {
			const p = this.props.players.find(p => p.playerId === s.playerId || p.statId === s.playerId);

			return {
				...s,
				...p
			}
		});

		team.starters = starters;
		team.bench = bench;
		return team;
	};

	onGameWeekChange(gameWeek) {
		ReactGA.event({
			category: GA.CATEGORY.TEAM,
			action: GA.ACTION.TOGGLE_TEAM_GAME_WEEK
		});
		this.props.fetchSchedule(this.props.selectedLeague.type, this.props.selectedLeague.season, gameWeek);
		const currentGameWeekTeamId = this.getGameWeekTeamId(this.state.seasonUserTeam, gameWeek);
		this.setState({
			selectedGameWeek: gameWeek,
			currentGameWeekTeamId
		}, () => {
			this.updateActiveGameWeekTeam();
		});
	}

	updateActiveGameWeekTeam() {
		const activeGameWeekTeam = this.props.gameWeekTeams.find(gameWeekTeam => gameWeekTeam.gameWeekTeamId === this.state.currentGameWeekTeamId);
		this.props.setActiveGameWeekTeam(activeGameWeekTeam || {});
	}

	generateSEO() {
		return (
			<Helmet>
				<title>Shakitz - Team</title>
				<link rel="canonical" href="https://shakitz.com/team" />
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
		)
	}

	render () {
		const { selectedGameWeek, currentGameWeekTeamId, seasonUserTeam = { shortTeamName: '', managerName: '', seasonUserTeamId: '', teamName: ''}, limitAccess } = this.state;

		if(!hasSeasonStarted(this.props.selectedLeague)) {
			return (
			<>
				{this.generateSEO()}
				<SeasonNotStarted/>
			</>);
		}

		const disablePrev = selectedGameWeek <= 1;
		const disableNext = selectedGameWeek + 1 > this.props.currentGameWeek[this.props.selectedLeague.type];
		const team = this.mergePlayerData(this.props.gameWeekTeams.find(team => team.gameWeekTeamId === currentGameWeekTeamId) || { currentGameWeekPoints: 0, currentBenchPoints: 0, starters: [], bench: [] });
		// This gets passed down into a lot of children via userGameWeek - TODO change to context
		const canEdit = !limitAccess && seasonUserTeam && seasonUserTeam.hashedUser === this.props.shakitzUserId;
		const showPoints = shouldShowPointData(this.props.schedule, this.props.selectedLeague.type, this.state.selectedGameWeek);
		const pointsRounded = Math.round(team.currentGameWeekPoints * 100) / 100;
		const { shortTeamName, seasonUserTeamId, teamName } = seasonUserTeam;


		return (
			<>
				{this.generateSEO()}
				<UserMyTeamHeader canEdit={canEdit} shortTeamName={shortTeamName} teamName={teamName} gwPoints={pointsRounded} shakitzUserId={this.props.shakitzUserId} seasonUserTeamId={seasonUserTeamId} />
				<div className='team-core'>
					<GameWeekToggle currentGameWeek={selectedGameWeek} disablePrev={disablePrev} disableNext={disableNext} pullUp onToggleClick={this.onGameWeekChange} />
					<UserGameWeek canEdit={!limitAccess} currentGameWeek={selectedGameWeek} showPoints={showPoints} team={team}/>
				</div>
				{this.props.isFetching ? <SpinnerLayer /> : null}
			</>
		);
	}
}

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchData: (gameWeekTeamId, withStats, season) => dispatch(fetchGameWeekTeam(gameWeekTeamId, withStats, season)),
		fetchSchedule: (type, season, gameWeek) => dispatch(fetchSchedule(type, season, gameWeek)),
		setActiveGameWeekTeam: (gameWeekTeam) => dispatch(activeGameWeekTeam(gameWeekTeam)),
	};
};

/** Inherited state from HOC:
 *
 currentGameWeek: state.currentGameWeek,
 currentGameWeekRequest: state.currentGameWeekRequest,
 currentGameWeekFailure: state.currentGameWeekFailure,
 seasonUserTeams: state.seasonUserTeams,
 seasonUserTeamsIsFetching: state.seasonUserTeamRequest,
 seasonUserTeamsHasFailed: state.seasonUserTeamFailure,
 schedule: state.schedule,
 scheduleIsFetching: state.scheduleRequest,
 scheduleHasFailed: state.scheduleFailure,
 scores: state.scores,
 scoresIsFetching: state.scoresIsFetching,
 scoresHasFailed: state.scoresHasFailed,
 selectedLeague: state.selectedLeague
 */

const mapStateToProps = ( state ) => {
	return {
		activeGameWeekTeam: state.activeGameWeekTeam,
		gameWeekTeams: state.gameWeekTeams,
		isFetching: state.gameWeekTeamRequest || state.scheduleRequest || state.currentGameWeekRequest,
		hasErrored: state.gameWeekTeamFailure,
		players: state.players
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTeam);
