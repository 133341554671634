import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const POST_WAIVERS_REQUEST = 'POST_WAIVERS_REQUEST';
export const POST_WAIVERS_SUCCESS = 'POST_WAIVERS_SUCCESS';
export const POST_WAIVERS_FAILURE = 'POST_WAIVERS_FAILURE';

export function addWaiverRequest ( isPosting ) {
	return {
		type: POST_WAIVERS_REQUEST,
		isPosting
	};
}

export function addWaiverFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: POST_WAIVERS_FAILURE,
		response: err,
		error: true
	};
}

export function addWaiverSuccess( data ) {
	return {
		type: POST_WAIVERS_SUCCESS,
		data
	};
}

export function addWaiver ( data ) {
	return ( dispatch ) => {
		dispatch(addWaiverRequest(true));
		const url = Constants.urls.WAIVERS;
		axios.post(url, data)
		.then(( response ) => {
			if ( response.status !== Constants.statusCodes.OK ) {
				throw Error(response.statusText);
			}

			setTimeout(() => {
				dispatch(addWaiverRequest(false));
			}, 1100);

			return response.data;
		}).then(data => dispatch(addWaiverSuccess(data)))
		.catch(err => dispatch(addWaiverFailure(err)));
	};
}
