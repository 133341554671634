import { UPCOMING_GAME_WEEK_TEAM } from '../actions/upcomingGameWeekTeam';

export function upcomingGameWeekTeam (state = false, action) {
	switch (action.type) {
		case UPCOMING_GAME_WEEK_TEAM:
			return action.gameWeekTeam;
		default:
			return state;
	}
}
