import React from 'react';

const ShapeGroup2 = ({ width = '235', height = '314'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 235 314">
		<path fill="url(#shapeGroup2-paint0_linear)" d="M70.216 211.386L172.168 149l31.12 51.021c17.187 28.178 8.297 64.986-19.856 82.213l-50.976 31.193-62.24-102.041z"/>
		<path fill="url(#shapeGroup2-paint1_linear)" d="M70.216.438l59.566-.436c32.898-.242 59.76 26.425 60 59.561l.433 59.999-59.566.436c-32.898.242-59.76-26.425-60-59.561L70.216.438z"/>
		<path fill="url(#shapeGroup2-paint2_linear)" d="M26.44 175.109c-14.602-14.604-14.602-38.28.001-52.883 14.603-14.603 38.279-14.603 52.881 0 14.603 14.604 14.603 38.28 0 52.883-14.603 14.603-38.28 14.603-52.882 0z"/>
		<defs>
			<linearGradient id="shapeGroup2-paint0_linear" x1="121.192" x2="183.577" y1="180.193" y2="282.145" gradientUnits="userSpaceOnUse">
				<stop stopColor="#6834FF"/>
				<stop offset=".87" stopColor="#6834FF" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="shapeGroup2-paint1_linear" x1="70.216" x2="190.215" y1="0" y2="120" gradientUnits="userSpaceOnUse">
				<stop stopColor="#0FB7FF"/>
				<stop offset=".708" stopColor="#0FB7FF" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="shapeGroup2-paint2_linear" x1="79.322" x2="26.439" y1="175.109" y2="122.227" gradientUnits="userSpaceOnUse">
				<stop stopColor="#fff"/>
				<stop offset="1" stopColor="#fff" stopOpacity="0"/>
			</linearGradient>
		</defs>
	</svg>
);

export default ShapeGroup2;

