import React from 'react';
import './GreyWrapper.scss';

const GreyWrapper = ({children}) => (
	<div className='grey-wrapper'>
		{children}
	</div>
);

export default GreyWrapper;
