import React from 'react';
import PlayerBox from '../../Common/Players/PlayerBox';
import './UserTopDraftPicks.scss';

const UserTopDraftPicks = ({picks}) => {
	return (
		<div className='user-top-draft-picks'>
			<p className='user-top-draft-picks__header'>Top Draft Picks</p>
			{picks.map(pick => <PlayerBox key={`user-next-h2h-${pick.playerId}`} draftPick={pick.pick} player={pick} inNextH2HView />)}
		</div>
	)
};

export default UserTopDraftPicks;
