import React from 'react';
import './PlayerButton.scss';

const PlayerButton = ({disabled = false, type, onClick, text, Icon}) => {

	return (
		<button className={`player-button player-button--${type}`} disabled={disabled} onClick={onClick}>
			<div className='player-button__icon'><Icon opacity={1} /></div>
			<div className='player-button__text'>{text}</div>
			<div className='player-button__empty'></div>
		</button>
	);
};

export default PlayerButton;
