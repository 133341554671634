const _hasMatch = (data, player) => {
	return data.find(starter => starter.playerId === player.playerId);
};

const subPlayer = (playerA, playerB, gameWeekTeam) => {
	const newStarters = gameWeekTeam.starters.slice(0);
	const newBench = gameWeekTeam.bench.slice(0);

	if(_hasMatch(gameWeekTeam.starters, playerA) && _hasMatch(gameWeekTeam.bench, playerB)) {
		const originalStartersIndex = newStarters.findIndex(starter => starter.playerId === playerA.playerId);
		const originalBenchIndex = newBench.findIndex(starter => starter.playerId === playerB.playerId);

		newStarters[originalStartersIndex] = playerB;
		newBench[originalBenchIndex] = playerA;
	} else if(_hasMatch(gameWeekTeam.starters, playerB) && _hasMatch(gameWeekTeam.bench, playerA)) {
		const originalStartersIndex = newStarters.findIndex(starter => starter.playerId === playerB.playerId);
		const originalBenchIndex = newBench.findIndex(starter => starter.playerId === playerA.playerId);

		newStarters[originalStartersIndex] = playerA;
		newBench[originalBenchIndex] = playerB;

	}

	gameWeekTeam.starters = newStarters;
	gameWeekTeam.bench = newBench;

	return gameWeekTeam;
};

export default subPlayer;
