import { FETCH_GAME_WEEK_SUCCESS, FETCH_GAME_WEEK_FAILURE, FETCH_GAME_WEEK_REQUEST} from '../actions/fetchGameWeek';

export function currentGameWeekFailure(state = false, action) {
	switch (action.type) {
		case FETCH_GAME_WEEK_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function currentGameWeekRequest(state = false, action) {
	switch (action.type) {
		case FETCH_GAME_WEEK_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}

export function currentGameWeek (state = {}, action) {
	switch (action.type) {
		case FETCH_GAME_WEEK_SUCCESS:
			const clonedState = JSON.parse(JSON.stringify(state));
			clonedState[action.data.gameWeekType] = action.data.gameWeek;

			return clonedState;
		default:
			return state;
	}
}
