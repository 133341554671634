import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DownArrow from '../../svgs/DownArrow'
import TickIcon from '../../svgs/TickIcon';
import './Dropdown.scss';

const Dropdown = ({isChunky, isDoubleLabel, onSelect, options, initialOption}) => {
	const [ activeOption, setActiveOption ] = useState(initialOption);
	const [ isOpen, setIsOpen ] = useState(0);

	useEffect(() => {
		if(!activeOption) {
			setActiveOption(initialOption);
			onSelect({name: initialOption});
		}
	}, [activeOption, initialOption, setActiveOption]);

	const setOptionAndClose = (evt) => {
		if(!evt.target.innerText) {
			return;
		}

		let value = evt.target.innerText;
		if(isDoubleLabel) {
			const nameMatch = evt.target.getElementsByClassName('dropdown__name');
			if(nameMatch[0] && nameMatch[0].innerText) {
				value = nameMatch[0].innerText;
			}
		}
		setActiveOption(value);
		const selectedOption = options.find(option => option.name === value);
		onSelect(selectedOption);
		setIsOpen(!isOpen);
	};

	const coreClsName = classNames('dropdown', {
		'dropdown--chunky': isChunky,
		'dropdown--open': isOpen,
		'dropdown--double-label': isDoubleLabel,
		'dropdown--single-option': options.length === 1
	});

	const renderItem = (option, index, array, isFirst) => {
		const clsName = classNames('dropdown__item', {
			'dropdown__item--active': !isFirst && activeOption === option.name,
			'dropdown__item--first': isFirst
		});

		return (
			<li key={`${option.name}_${index}`} className={clsName}>
				{option.doubleLabel ? (
					<div className='dropdown__text-wrapper'>
						{isFirst && <span className='dropdown__double-label'>{option.doubleLabel}</span>}
						<span className='dropdown__name'>{option.name}</span>
					</div>
				): <>{option.name}</>}
				{isFirst && <><span className='dropdown__separator' /><DownArrow /></>}
				{!isFirst && activeOption === option.name && <TickIcon fill='#a838ff' />}
			</li>
		);
	}

	const currentOptionItem = options.find(option => option.name === activeOption);

	if(!currentOptionItem) {
		return null;
	}

	return (
		<div className={coreClsName}>
			<ul className='dropdown__list' onClick={setOptionAndClose}>
				{renderItem(currentOptionItem, -1, null,true)}
				{options.map(renderItem)}
			</ul>
		</div>
	);
};

export default Dropdown;
