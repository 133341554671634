import React from 'react';
import { Shape4, Shape1, Shape3 } from  '../../svgs/logo';
import './Logo.scss';

const Logo = () => (
	<div className='logo'>
		<div className='logo__tier'>
			<Shape4 fill={'#6834ff'}/>
			<Shape1 fill={'#e00d83'}/>
		</div>
		<div className='logo__tier'>
			<Shape3 fill={'#14c4fb'}/>
			<Shape3 fill={'transparent'}/>
		</div>
	</div>
);

export default Logo;
