import React from 'react';

const WorstIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="40" height="48" fill="none" viewBox="0 0 40 48">
		<g clipPath="url(#clip0)">
			<path fill="#000" fillRule="evenodd" d="M35.557 10.03c.098.052 2.369 1.335.606 4.712-.304.581-.077 1.296.502 1.598.175.092.363.135.548.135.427 0 .842-.23 1.052-.637 2.83-5.419-1.53-7.867-1.575-7.892-.577-.312-1.295-.098-1.608.477-.312.575-.1 1.294.475 1.607zM19.161 2.227c.01.005.963.567 1.217 1.734.265 1.214-.288 2.777-1.598 4.518-.394.523-.287 1.265.235 1.659.215.16.465.237.713.237.36 0 .714-.162.948-.473 1.76-2.341 2.44-4.508 2.019-6.445-.492-2.253-2.323-3.271-2.4-3.313-.575-.312-1.296-.1-1.609.475-.312.575-.1 1.296.475 1.608zM2.082 18.285c.217.994.694 1.748 1.167 2.284.54.612 1.525.498 1.919-.217.244-.44.175-.98-.154-1.358-.252-.29-.51-.702-.621-1.246-.248-1.21.306-2.76 1.604-4.486 1.76-2.339 2.44-4.508 2.017-6.443-.49-2.254-2.321-3.273-2.4-3.315-.575-.312-1.296-.098-1.609.475-.312.575-.098 1.296.477 1.609.009.004.96.566 1.215 1.733.265 1.214-.288 2.777-1.596 4.519-1.762 2.341-2.442 4.508-2.019 6.445zm28.192 27.346H9.847c-4.07 0-7.499-3.327-7.476-7.392.023-3.983 3.273-7.255 7.265-7.306.748-.008 1.3-.698 1.146-1.429-.11-.519-.167-1.052-.167-1.583 0-4.417 3.794-8.009 8.463-8.017h.03c.724.015 6.101-.016 9.532-3.62.419.775.908 1.85 1.248 3.129.675 2.525.541 4.912-.396 7.127-.519 1.229-1.733 2.023-3.069 2.023h-5.537c-.632 0-1.184.475-1.225 1.106-.044.69.502 1.262 1.183 1.262h9.317c4.037 0 7.406 3.202 7.466 7.235.063 4.105-3.26 7.465-7.354 7.465zM31.13 28.6c1.606-3 1.973-6.348 1.05-9.798-.83-3.11-2.415-5.204-2.484-5.292l-.13-.173c-.436-.569-1.303-.533-1.688.069l-.12.183c-2.59 4.021-8.368 3.95-8.614 3.946l-.052-.002c-5.981 0-10.848 4.66-10.848 10.388 0 .25.009.5.027.75-2.064.308-3.968 1.27-5.46 2.779-1.777 1.796-2.77 4.162-2.808 6.68C-.078 43.559 4.447 48 9.878 48H30.13c5.336 0 9.786-4.234 9.87-9.563.082-5.142-3.853-9.398-8.87-9.837" clipRule="evenodd"/>
		</g>
		<defs>
			<clipPath id="worstIconClip0">
				<path fill="#fff" d="M0 0H40V48H0z"/>
			</clipPath>
		</defs>
	</svg>
);

export default WorstIcon;
