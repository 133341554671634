import { Constants } from './Constants';

const getGameState = (game) => {
	if(!game) {
		return Constants.gameStates.PRE;
	}

	const now = new Date().getTime();
	const gameTime = new Date(game.gameTime).getTime();

	if(now < gameTime) {
		return Constants.gameStates.PRE;
	} else if(now > gameTime) {
		return Constants.gameStates.LIVE;
	} else {
		return Constants.gameStates.POST;
	}
};

export default getGameState;
