import React from 'react';
import Table from '../Common/Table'
import './Standings.scss';

const standingsColumns = [
	{ suffix: 'rank', text: 'Rank', pre: true, live: true, post: true, statBenchView: true},
	{ suffix: 'team', text: 'Team & Manager', pre: true, live: true, post: true, statBenchView: true},
	{ suffix: 'gw', text: 'GW', live: true, post: true},
	{ suffix: 'botw', text: 'Bench of the Week Wins', statBenchView: true},
	{ suffix: 'benchOver100', text: 'Bench >= 100pts', statBenchView: true},
	{ suffix: 'total-bench-points', text: 'Bench Points', statBenchView: true},
	{ suffix: 'record', text: 'Record', pre: true, live: true, post: true},
	{ suffix: 'played', text: 'Played', live: true},
	{ suffix: 'overall-points', text: 'Points', pre: true, live: true, post: true}];
const standingsRows = [
	{ canClick: false, isTwoLevel: true,
		key: ['rank', 'rankChange', 'rankIcon'], suffix: ['rank', 'rankChange', 'rankIcon'], pre: true, live: true, post: true, statBenchView: true},
	{ canClick: true, isTwoLevel: true, modifierCls: 'name',
		key: ['shortTeamName', 'teamName', 'manager'], suffix: ['shortTeam', 'team', 'manager'], pre: true, live: true, post: true, statBenchView: true},
	{ key: 'gwPoints', suffix: 'gw-points', live: true, post: true},
	{ key: 'benchOfTheWeekWins', suffix: 'benchOfTheWeekWins', statBenchView: true},
	{ key: 'benchOver100', suffix: 'benchOver100', statBenchView: true},
	{ key: 'totalBenchPoints', suffix: 'totalBenchPoints', statBenchView: true},
	{ key: 'record', suffix: 'record', pre: true, live: true, post: true},
	{ key: 'playersPlayed', suffix: 'played', live: true},
	{ key: 'totalPoints', suffix: 'overall-points', pre: true, live: true, post: true}];

const Standings = ({data, gwState}) => {
	const columns = standingsColumns.filter(column => column.hasOwnProperty(gwState) ? column[gwState] : false);
	const rows = standingsRows.filter(row => row.hasOwnProperty(gwState) ? row[gwState] : false);

	return (
		<div className='standings'>
			<Table columnData={columns} rowData={rows} data={data} clsName={'standings-table'}  />
		</div>
	);
};


const baseColumnData = [{ suffix: 'rank', text: 'Rank' }, { suffix: 'player', text: 'Player Name'}]
const baseRowData = [{ suffix: 'pos', isIndex: true}, { isTwoLevel: true, key: ['player', 'playerTeam'], suffix: ['player', 'player-team']}];


export default Standings;
