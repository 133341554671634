import { FETCH_AUTH_DRAFT_FAILURE, FETCH_AUTH_DRAFT_REQUEST, FETCH_AUTH_DRAFT_SUCCESS} from '../actions/authDraft';

export function authDraftFailure(state = false, action) {
	switch (action.type) {
		case FETCH_AUTH_DRAFT_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function authDraftRequest(state = false, action) {
	switch (action.type) {
		case FETCH_AUTH_DRAFT_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function authDraft(state = false, action) {
	switch (action.type) {
		case FETCH_AUTH_DRAFT_SUCCESS:
			return action.auth || state;
		default:
			return state;
	}
}
