import React from 'react';
import './PlayerScoreBoard.scss';

const PlayerScoreBoard = ({description, points}) => {

	return (
		<div className='player-score-board'>
			<p className='player-score-board__description'>{description}</p>
			<p className='player-score-board__points'>{points}</p>
		</div>
	)
};

export default PlayerScoreBoard;
