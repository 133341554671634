import React from 'react';
import './DraftClock.scss';

const DraftClock = ({isPaused, onTheClock, upNext}) => {
	if (!onTheClock) {
		return <div className='draft-clock'></div>
	}

	if(isPaused) {
		return (
			<div className='draft-clock'>
				<span className='draft-clock__paused-info'>Draft is currently paused. Only the commissioner can unpause.</span>
			</div>
		);
	}

	return (
		<div className='draft-clock'>
			<div className='draft-clock__on-the-clock'>
				<span className='draft-clock__explainer draft-clock__explainer--active'>On the clock:</span>
				<span className='draft-clock__user-name'>{onTheClock}</span>
			</div>
			{upNext ?
			<div className='draft-clock__up-next'>
				<span className='draft-clock__explainer'>Next pick:</span>
				<span className='draft-clock__user-name'>{upNext}</span>
			</div> : null }
		</div>
	);
};

export default DraftClock;
