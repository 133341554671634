import React from 'react';

const Emblem = ({width = '29', height = '29'}) => (
	<svg width={width} height={height} viewBox='0 0 29 29' fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.2935 5.81093C17.5308 5.77566 22.6156 10.8034 22.6509 17.0406C22.6862 23.2778 17.6585 28.3627 11.4212 28.398L0.127703 28.4618L0.0638431 17.1683C0.0285742 10.9311 5.05627 5.8462 11.2935 5.81093Z" fill="url(#paint0_linear)"/>
		<path d="M17.1275 22.6509C10.8903 22.6861 5.80542 17.6584 5.77015 11.4212C5.73488 5.18395 10.7626 0.0990772 16.9998 0.0638078L28.2933 -5.31072e-05L28.3572 11.2935C28.3925 17.5307 23.3648 22.6156 17.1275 22.6509Z" fill="url(#paint1_linear)"/>
		<defs>
			<linearGradient id="paint0_linear" x1="-1.83287e-05" y1="28.4618" x2="18" y2="8.5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E00D83"/>
				<stop offset="1" stopColor="#E00D83" stopOpacity="0"/>
			</linearGradient>
			<linearGradient id="paint1_linear" x1="28.4211" y1="-5.14123e-05" x2="8.5" y2="19.5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#612DF7"/>
				<stop offset="1" stopColor="#612DF7" stopOpacity="0"/>
			</linearGradient>
		</defs>
	</svg>

);

export default Emblem;
