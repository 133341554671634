import React from 'react';
import Header from '../../components/Common/Header';
import Hero from '../../components/Common/Hero';
import Dropdown from '../../components/Common/Dropdown';
import JumpDownArrow from '../../components/svgs/JumpDownArrow';
import './Rules.scss';
import {Helmet} from 'react-helmet';

const Rules = () => {
	const quickLinks = [{name: 'All rules', hash: ''}, {name: 'Draft', hash: 'draft'}, {
		name: 'Team line ups',
		hash: 'team'
	}, {name: 'Trades', hash: 'trades'}, {name: 'Waivers', hash: 'waivers'}, {name: 'Deadlines', hash: 'deadlines'}];

	const onSelect = (option) => {
		const section = document.getElementById(option.hash);
		if (!section) return;

		const dropdownTransitionTime = 250;
		setTimeout(() => {
			section.scrollIntoView({behavior: 'smooth', block: 'start'});
		}, dropdownTransitionTime + 50)
	};

	return (
		<div className='rules'>
			<Helmet>
				<title>Shakitz - Rules</title>
				<link rel="canonical" href="https://shakitz.com/rules" />
			</Helmet>
			<header className='rules__header'>
				<Hero showLogo text='Rules'/>
			</header>
			<div className='rules__core'>
				<div className='rules__column'>
					<div className='rules__quicklinks'>
						<Dropdown onSelect={onSelect} options={quickLinks}/>
					</div>
					<section id='draft' className='rules__content rules__content--draft'>
						<Header headerLevel='h1' text='Draft'/>
						<Header headerLevel='h2' text='Priority'/>
						<p>Your commissioner will be able to dictate the draft order when they press the start button. The draft
							is snake draft only. No other methods are supported.</p>
						<Header headerLevel='h2' text='Countdowns'/>
						<p>Each player has 5 minutes to make their draft choice. They are allowed to request an additional 5
							minutes once per round. If their time expires their choice will be auto-drafted.</p>
						<p>The commissioner is the only user who can pause the draft for all users.</p>
					</section>
					<section id='team' className='rules__content rules__content--team'>
						<Header headerLevel='h1' text='Team line ups'/>
						<p>You can sub players (that have yet to play) up to one hour before the start of their game. If you
							have a player playing in the late window then you can only sub the player (in the late window) up to
							one hour before the first game of that day starts.</p>
						<p>E.g. You have a player playing Sunday 8pm and the first game is at 6pm. You can sub them in/out up
							until 5pm on the Sunday. Players who play on Monday are not affected until Monday.</p>
					</section>
					<section id='trades' className='rules__content rules__content--trades'>
						<Header headerLevel='h1' text='Trades'/>
						<p>Trade window is open from the beginning of week 1 until week 13. No trades can happen after this
							period to prevent collusion.</p>
						<p>You can request a trade by clicking on the player (on the competitors team, on perfect team widget in
							game day, or on stats page) you want to trade for and pressing the trade button. You can then choose
							which player (from your own team) you want to trade. If you do not see a trade button when clicking
							on a player then they do not belong to anyone and can be waived instead.</p>
						<p>Trades will stay valid until the trade deadline of the next game week (one hour before the first
							match starts of that game week). After which they will be deleted.</p>
						<p>In order to view the status of your trades and/or reject/accept/cancel trade offers go to the Trades
							page.</p>
					</section>
					<section id='waivers' className='rules__content rules__content--waivers'>
						<Header headerLevel='h1' text='Waivers'/>
						<p>The Shakitz Waiver system works in a cascading manner. You need to go to the waivers page to create
							waivers, and the proposed tab to re-order your priority.</p>
						<p>Your priority is based off the inverse of your last game week standing. So if you scored the most
							amount of points (for that specific game week) you would have last waiver priority. Your waiver
							priority does not reset after waiving a player.</p>
						<p>There is no waiver week 1.</p>
						<p>If you have waiver priority 3, and your first choice waiver pick has been taken by someone before you
							then your next choice waiver pick will be attempted until a successful waiver occurs.</p>
					</section>
					<section id='deadlines' className='rules__content rules__content--deadlines'>
						<Header headerLevel='h1' text='Deadlines'/>
						<p>Draft deadline: 12 hours before start of the season</p>
						<p>Trade deadline: 1 hour before the first match of the game week.</p>
						<p>Waiver deadline: 10pm UTC Wednesday</p>
						<p>Team sub deadline: 1 hour before the first game of the day that the player is playing in.</p>
					</section>
				</div>
				<div className='rules__column rules__jump-to'>
					<ul>
						<li>Jump to section</li>
						{quickLinks.slice(1, quickLinks.length).map(link => {
							return <li className='rules__link' onClick={() => onSelect(link)} key={link.hash}><span>{link.name}</span><span><JumpDownArrow /></span></li>
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Rules;
