import { FETCH_LEAGUE_TRADE_DATA_FAILURE, FETCH_LEAGUE_TRADE_DATA_REQUEST, FETCH_LEAGUE_TRADE_DATA_SUCCESS} from '../actions/fetchLeagueTradeData';

export function leagueTradeDataFailure(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_TRADE_DATA_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function leagueTradeDataRequest(state = false, action) {
	switch (action.type) {
		case FETCH_LEAGUE_TRADE_DATA_REQUEST:
			return action.isFetching;
		default:
			return state;
	}
}
export function leagueTradeData(state = {}, action) {
	switch (action.type) {
		case FETCH_LEAGUE_TRADE_DATA_SUCCESS:
			const leagueId = action.data.leagueId;
			const data = action.data.data;
			let clonedState = JSON.parse(JSON.stringify(state));
			if (!clonedState.hasOwnProperty(leagueId)) {
				clonedState[leagueId] = {
					trades: []
				};
			}

			/**
			 * Refactor when you figure out how to move this to server
			 */
			if(data.tradeType) {
				const tradeIdx = clonedState[leagueId].trades.findIndex(t => t.tradeId === data.tradeId);
				clonedState = clonedState.filter((item, idx) => idx !== tradeIdx);
			} else {
				data.trades.forEach(trade => {
					const tradeIdx = clonedState[leagueId].trades.findIndex(t => t.tradeId === trade.tradeId);

					if(tradeIdx === -1) {
						clonedState[leagueId].trades.push(trade);
					} else {
						clonedState[leagueId].trades[tradeIdx] = trade;
					}
				});
				if(data.leagueUsers) {
					clonedState[leagueId].leagueUsers = data.leagueUsers;
				}
				if(data.seasonUserTeams) {
					clonedState[leagueId].seasonUserTeams = data.seasonUserTeams;
				}
				if(data.schedule) {
					clonedState[leagueId].schedule = data.schedule;
					const gwParts = data.schedule.scheduleId.split('-');
					const gameWeek = Number(gwParts[gwParts.length-1]);
					clonedState[leagueId].gameWeek = gameWeek;
				}
			}

			return clonedState;
		default:
			return state;
	}
}
