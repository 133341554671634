import { Constants } from '../helpers/Constants';
import { SET_DRAFT_PICK } from '../actions/setDraftPick';

const defaultState = {};
const rounds = Constants.numStarters + Constants.numBench;

for(let i = 1; i <= rounds; i++) {
	const key = `R${i}`;
	defaultState[key] = [];
}

export function draftPicks (state = defaultState, action) {
	switch (action.type) {
		case SET_DRAFT_PICK:
			const clone = JSON.parse(JSON.stringify(state));
			const key = `R${action.round}`;
			clone[key].push(action.pick);
			return clone;
		default:
			return state;
	}
}
