const generateWaiverObject = (waiversList, selectedLeague, seasonUserTeamId) => {
	return {
		leagueId: selectedLeague.leagueId,
		season: selectedLeague.type === 'PRE' ? `${selectedLeague.type}-${selectedLeague.season}` : selectedLeague.season,
		type: selectedLeague.type,
		seasonUserTeamId,
		waiversList
	};
};

export default generateWaiverObject;
