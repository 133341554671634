import axios from 'axios';
import { Constants } from '../helpers/Constants';

export const FETCH_LEAGUE_USERS_REQUEST = 'FETCH_LEAGUE_USERS_REQUEST';
export const FETCH_LEAGUE_USERS_SUCCESS = 'FETCH_LEAGUE_USERS_SUCCESS';
export const FETCH_LEAGUE_USERS_FAILURE = 'FETCH_LEAGUE_USERS_FAILURE';

export function fetchLeagueUsersRequest ( isFetching ) {
	return {
		type: FETCH_LEAGUE_USERS_REQUEST,
		isFetching
	};
}

export function fetchLeagueUsersFailure( err ) {
	if ( err ) {
		// eslint-disable-next-line no-console
		console.warn(err);
	}

	return {
		type: FETCH_LEAGUE_USERS_FAILURE,
		response: err,
		error: true
	};
}

export function fetchLeagueUsersSuccess( data ) {
	return {
		type: FETCH_LEAGUE_USERS_SUCCESS,
		data
	};
}

export function fetchLeagueUsers ( leagueId) {
	return ( dispatch ) => {
		dispatch(fetchLeagueUsersRequest(true));

		const url = `${Constants.urls.LEAGUE}/users/${leagueId}`;

		axios.get(url)
			.then(( response ) => {
				if ( response.status !== Constants.statusCodes.OK ) {
					throw Error(response.statusText);
				}
				return response.data;
			}).then(data => dispatch(fetchLeagueUsersSuccess(data)))
		.catch(err => dispatch(fetchLeagueUsersFailure(err)))
		.finally(() => dispatch(fetchLeagueUsersRequest(false)));
	};
}
