import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import Keys from 'shakitz-keys';
import shakitzSchedule from 'shakitz-schedule';
import classNames from 'classnames';
import Header from '../../Header';
import PlayerTeamPosInfo from '../PlayerTeamPosInfo';
import PlayerButton from './PlayerButton';
import PlayerScoreBoard from './PlayerScoreBoard';
import PlayerStat from '../PlayerStat';
import PlayerBox from '../PlayerBox';
import TransferConfirm from '../../../TransferConfirm';
import Toggle from '../../../Common/Toggle';
import CloseIcon from '../../../svgs/CloseIcon';
import Button from '../../Button';
import SubIcon from '../../../svgs/SubIcon';
import TradeIcon from '../../../svgs/TradeIcon';
import WaiverIcon from '../../../svgs/WaiverIcon';
import { Constants } from '../../../../helpers/Constants';
import getPlayerLongName from '../../../../helpers/getPlayerLongName';
import { GA } from '../../../../helpers/ga';
import sortByPosition from '../../../../helpers/sortByPosition';
import generateWaiverObject from '../../../../helpers/generateWaiverObject';
import getGameWeekState from '../../../../helpers/getGameWeekState';
import getTradeDeadlineCountdown from '../../../../helpers/getTradeDeadline';
import subPlayer from '../../../../helpers/subPlayer';
import SpinnerLayer from '../../../SpinnerLayer/SpinnerLayer';
import {addWaiver, addWaiverSuccess} from '../../../../actions/addWaiver';
import {setGameWeekTeam, setGameWeekTeamFailure, setGameWeekTeamSuccess} from '../../../../actions/setGameWeekTeam';
import { submitTrade, submitTradeSuccess } from '../../../../actions/submitTrade';
import ShakitzContext from '../../../../context/ShakitzContext';
import InjuryStatus from '../InjuryStatus';
import './PlayerModal.scss';

const _renderStats = (calculatedStats = {}) => {
	const statKeys = Object.keys(calculatedStats).filter(t => t !== 'points' && calculatedStats[t].length > 0);

	return (
		<div className='stat-container'>
			{statKeys.length > 0 ? statKeys.map(type => {
				return calculatedStats[type].map(stat => <PlayerStat key={`type_${stat.name}`} stat={stat}
				                                                     statType={type}/>);
			}) : <p className='stat-container__empty-stat'>N/A</p>}
		</div>
	)
};

const _findPlayerSeasonStats = (seasonPlayerStats, player, players) => {
	if(player.totalPoints) {
		return player;
	}

	let id = player.statId;
	if(!id) {
		const matchingPlayer = players.find(p => p.playerId === player.playerId) || {};
		id = matchingPlayer.statId;
	}

	// playerId for season stats is actually stat id
	const seasonStatPlayer = seasonPlayerStats.find(sPlayer => sPlayer.playerId === id);
	if(!seasonStatPlayer || !seasonStatPlayer.totalPoints) {
		try {
			console.error("Unable to find stats for: ", player.statId, player.playerId);
		} catch(e) {}
		return { totalPoints: { points: 'N/A' } };
	}

	return seasonStatPlayer;
};

const _renderPlayerPane = (canEdit, canSub, player, players, belongsTo, onToggleClick, activeToggleOption, toggleOptions, setActivePane, waiverOnly, seasonPlayerStats, canTrade) => {
	const playerNameParts = getPlayerLongName(player).split(' ');
	const playersSeasonStats = _findPlayerSeasonStats(seasonPlayerStats, player, players);
	const statsToShow = activeToggleOption === 'Season' ? playersSeasonStats.totalPoints : player.calculatedStats;

	return (
		<div className='player-modal__player-pane'>
			<div className={`player-modal__header player-modal__header--${player.nflTeam}`}>
				<h3 className='player-modal__player-name'>
					<span className='player-modal__player-first-name'>{playerNameParts[0]}</span>
					{playerNameParts.splice(1, playerNameParts.length)}
				</h3>
				<PlayerTeamPosInfo centerContent={true} position={Constants.positionMap[player.pos]} noSvg team={player.nflTeam} />
				<div className='player-modal__points-wrapper'>
					<PlayerScoreBoard description={'GameWeek Points:'} points={player.calculatedStats ? player.calculatedStats.points : 'N/A'} />
					<PlayerScoreBoard description={'Season Points:'} points={playersSeasonStats.totalPoints.points} />
				</div>
				<div className='player-modal__injury-status'><InjuryStatus showStatus status={player.injuryStatus} /></div>
				{belongsTo.userName ? <div className='player-modal__owner-container'>Belongs to <span className='player-modal__owner'>{belongsTo.userName}</span></div> : null}
			</div>
			<div className='player-modal__stats'>
				<Toggle onToggleClick={onToggleClick} activeOption={activeToggleOption} toggleOptions={toggleOptions} />
				{_renderStats(statsToShow)}
				<div className='player-actions'>
					{(canTrade && !canEdit && !waiverOnly && belongsTo.userName) ? <PlayerButton Icon={TradeIcon} text={'Trade'} type={'trade'} onClick={() => setActivePane(1)} /> : null }
					{!belongsTo.userName && waiverOnly ? <PlayerButton Icon={WaiverIcon} text={'Waiver'} type={'waiver'} onClick={() => setActivePane(1)} /> : null}
					{canEdit && canSub ? <PlayerButton Icon={SubIcon} text={'Substitute In/Out'} type={'substitute'} onClick={() => setActivePane(1)} />: null }
				</div>
			</div>
		</div>
	);
};

const _renderSecondaryPaneCore = (setActivePane, backPane) => {
	return (
		<>
			<div className='secondary-pane__back' onClick={() => setActivePane(backPane)}>
				<Button type={'secondary'} text={'Back'} isPrev={true} />
			</div>
			<div className='secondary-pane__header'>
				<Header text={'Pick a player'} headerLevel={'h2'} />
			</div>
		</>
	);
};

const _renderSubPane = (canEdit, playersToShow, onPlayerClick, setActivePane, schedule) => {
	return (
		<div className='player-modal__secondary-pane secondary-pane'>
			{_renderSecondaryPaneCore(setActivePane, 0)}
			<div className='secondary-pane__player-list'>
				{sortByPosition(playersToShow).map(player => <PlayerBox key={player.playerId} canEdit={canEdit} cannotSubIn={_cannotSubIn(schedule, player)} inSubView={true} onPlayerClick={() => onPlayerClick(player)} player={player}/>)}
			</div>
		</div>
	);
};

const _renderSecondaryPane = (userId, unavailablePlayers = [], players, onTradeClick, setActivePane) => {
	const playersToTradeFor = unavailablePlayers.find(entry => entry.user === userId).players.map(gwPlayer => {
		const idParts = gwPlayer.split('-');
		const id = idParts[0].includes('Defence') ? idParts[0] : `${idParts[0]}-${idParts[1]}`;
		const p = players.find(p => p.playerId === id|| p.statId === id);

		return {
			...{ gameWeekPlayerId: gwPlayer },
			...p
		}
	});

	return (
		<div className='player-modal__secondary-pane secondary-pane'>
			{_renderSecondaryPaneCore(setActivePane, 0)}
			<div className='secondary-pane__player-list'>
				{sortByPosition(playersToTradeFor.map(player => <PlayerBox key={player.gameWeekPlayerId} inTradeView={true} onTradeClick={() => onTradeClick(player)} player={player}/>))}
			</div>
		</div>
	)
};

const _renderFinalPane = (playerIn, playerOut, cancelTransaction, type, waiverOnly, onConfirm) => {
	const headerText = waiverOnly ? 'Add Waiver' : 'Confirm Trade';
	return (
		<div className='player-modal__tertiary-pane tertiary-pane'>
			<div className='tertiary-pane__back' onClick={() => cancelTransaction(type)}>
				<Button type={'secondary'} text={'Back'} isPrev={true} />
			</div>
			<div className='tertiary-pane__core'>
				<TransferConfirm headerText={headerText} onConfirm={onConfirm} playerIn={playerIn} playerOut={playerOut} confirmText={'Submit'}/>
			</div>
		</div>
	);
};

const _cannotSubIn = (schedule, player) => {
	const game = shakitzSchedule.getPlayerGame(schedule.games, player.nflTeam);
	return !shakitzSchedule.canSub(game, schedule.games);
};

const PlayerModal = (props) => {
	// Component props
	const { canEdit, canSub, defaultToggle = Constants.statToggleOptions.GAME_WEEK, onClose, player, belongsTo = {}, waiverOnly } = props;
	// Redux props
	const { addWaiver, clearSaveError, clearSaveSuccessMessage, clearTradeSuccessMessage, clearWaiverSuccessMessage, currentGameWeek, gameWeekTeam, hasFailed, history, isPosting, players, schedule, seasonPlayerStats, seasonUserTeams, selectedLeague, setGameWeekTeam, setGameWeekStatus, submitTrade, tradeStatus, unavailablePlayers, waivers, waiverStatus } = props;
	const shakitzContext = useContext(ShakitzContext);
	const gameWeek = currentGameWeek[selectedLeague.type];
	const scheduleData = schedule[selectedLeague.type][gameWeek];
	const canTrade = getTradeDeadlineCountdown(scheduleData && scheduleData.games ? scheduleData.games[0] : null);
	const toggleOptions = [Constants.statToggleOptions.GAME_WEEK, Constants.statToggleOptions.SEASON];
	const [ activeToggleOption, setActiveToggle] = useState(defaultToggle);
	const [ activePane, setActivePane ] = useState(0);
	const [ playerToTradeIn, setPlayerToTradeIn ] = useState(null);
	const onToggleClick = (evt) => {
		const option = toggleOptions.find(option => option === evt.target.innerText);
		if(option) {
			ReactGA.event({
				category: GA.CATEGORY.PLAYER,
				action: GA.ACTION.TOGGLE_PLAYER_STAT_IN_MODAL
			});
			setActiveToggle(option);
		}
	};

	const onPlayerClick = (playerToSub) => {
		if(!canEdit) {
			return;
		}
		ReactGA.event({
			category: GA.CATEGORY.TEAM,
			action: GA.ACTION.SUB_PLAYER
		});
		const newGameWeekTeam = subPlayer(player, playerToSub, gameWeekTeam);
		setGameWeekTeam(newGameWeekTeam);
	};

	const onSecondaryClick = (playerToTrade) => {
		setPlayerToTradeIn(playerToTrade);
		setActivePane(activePane+1);
		ReactGA.event({
			category: waiverOnly ? GA.CATEGORY.WAIVER : GA.CATEGORY.TEAM,
			action: waiverOnly ? GA.ACTION.SELECT_PLAYER_TO_WAIVER_OUT : GA.ACTION.TRADE_PLAYER
		});
	};

	const onTradeConfirm = (playerIn, playerOut) => {
		const gwState = getGameWeekState(scheduleData);
		const gw = gwState !== Constants.gameStates.PRE ? gameWeek + 1 : gameWeek;

		const playerAId = Keys.generateGameWeekPlayer({ playerId: playerOut.statId ||playerOut.playerId, season: selectedLeague.season, gameWeek: gw, leagueType: selectedLeague.type });
		const playerBId = Keys.generateGameWeekPlayer({ playerId: playerIn.statId || playerIn.playerId, season: selectedLeague.season, gameWeek: gw, leagueType: selectedLeague.type });
		const data = {
			seasonUserTeamAId: seasonUserTeams.find(t => t.hashedUser === shakitzContext.userId).seasonUserTeamId,
			seasonUserTeamBId: belongsTo.seasonUserTeamId,
			playerAId,
			playerBId,
			gameWeek: gw,
			leagueId: selectedLeague.leagueId,
			season: selectedLeague.type === 'PRE' ? `${selectedLeague.type}-${selectedLeague.season}` : selectedLeague.season
		};

		ReactGA.event({
			category: GA.CATEGORY.TEAM,
			action: GA.ACTION.SUBMIT_TRADE
		});
		submitTrade(data);
	};

	const onWaiverConfirm = (playerIn, playerOut) => {
		const currentWaiverList = waivers?.[selectedLeague.leagueId]?.waivers?.active?.waiversList || [];
		const priority = currentWaiverList.length > 0 ? currentWaiverList[currentWaiverList.length-1].priority + 1 : 1;
		const seasonUserTeamId = seasonUserTeams.find(t => t.hashedUser === shakitzContext.userId).seasonUserTeamId;
		const waiver = {
			priority,
			playerOut: {
				playerId: playerOut.statId || playerOut.playerId
			},
			playerIn: {
				playerId: playerIn.statId || playerIn.playerId
			}
		};
		currentWaiverList.push(waiver);
		const postObj = generateWaiverObject(currentWaiverList, selectedLeague, seasonUserTeamId);
		ReactGA.event({
			category: GA.CATEGORY.WAIVER,
			action: GA.ACTION.SUBMIT_WAIVER
		});
		addWaiver(postObj);
	};

	const cancelTransaction = (type) => {
		const category = type === 'trade' ? GA.CATEGORY.TEAM : GA.CATEGORY.WAIVER;
		const action = type === 'trade' ? GA.ACTION.CANCEL_TRADE : GA.ACTION.CANCEL_WAIVER;
		ReactGA.event({
			category,
			action
		});
		setActivePane(activePane - 1);
	};

	useEffect(() => {
		if(setGameWeekStatus.message === 'team successfully updated') {
			clearSaveSuccessMessage();
			onClose();
			history.push('/team');
		}

		if(tradeStatus.message === 'Trade successfully added to topic') {
			clearTradeSuccessMessage();
			onClose();
			history.push('/trades');
		}

		if(waiverStatus.message === 'Waivers successfully added to topic') {
			clearWaiverSuccessMessage();
			onClose();
			history.push('/waivers?proposed=true');
		}

		if(hasFailed) {
			window.confirm('Invalid team, please try again');
			clearSaveError();
		}
	}, [clearSaveError, clearSaveSuccessMessage, clearTradeSuccessMessage, clearWaiverSuccessMessage, hasFailed, history, onClose, setGameWeekStatus.message, tradeStatus.message, waiverStatus.message]);

	const clsName = classNames('player-modal', {
		'player-modal--player-pane': activePane === 0,
		'player-modal--secondary-pane': activePane === 1,
		'player-modal--tertiary-pane': activePane === 2,
		'player-modal--initial-gw': gameWeek === 1,
		'player-modal--has-injury': player && player.injuryStatus
	});

	const playerData = unavailablePlayers[selectedLeague.leagueId] ? unavailablePlayers[selectedLeague.leagueId][selectedLeague.season] : [];
	const playersToShow = gameWeekTeam.starters.find(starter => starter.playerId === player.playerId) ? gameWeekTeam.bench : gameWeekTeam.starters;

	return (
		<div className={clsName}>
			<div className='player-modal__pane-container'>
				<div className='player-modal__close-container' onClick={onClose}>
					<CloseIcon />
				</div>
				{_renderPlayerPane(canEdit, canSub, player, players, belongsTo, onToggleClick, activeToggleOption,toggleOptions, setActivePane, waiverOnly, seasonPlayerStats, canTrade)}
				{canEdit && canSub && _renderSubPane(canEdit, playersToShow, onPlayerClick, setActivePane, scheduleData)}
				{shakitzContext.userId !== belongsTo.user && _renderSecondaryPane(shakitzContext.userId, playerData, players, onSecondaryClick, setActivePane)}
				{shakitzContext.userId !== belongsTo.user && playerToTradeIn && !waiverOnly && _renderFinalPane(player, playerToTradeIn, cancelTransaction, 'trade', waiverOnly, onTradeConfirm)}
				{shakitzContext.userId !== belongsTo.user && playerToTradeIn && waiverOnly && _renderFinalPane(player, playerToTradeIn, cancelTransaction, 'waiver', waiverOnly, onWaiverConfirm)}
			</div>
			{isPosting ? <SpinnerLayer/> : null}
		</div>
	)
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		addWaiver: (waiverData) => dispatch(addWaiver(waiverData)),
		clearSaveError: () => dispatch(setGameWeekTeamFailure({}, false)),
		clearSaveSuccessMessage: () => dispatch(setGameWeekTeamSuccess({})),
		clearTradeSuccessMessage: () => dispatch(submitTradeSuccess({})),
		clearWaiverSuccessMessage: () => dispatch(addWaiverSuccess({})),
		setGameWeekTeam: (gameWeekTeam) => dispatch(setGameWeekTeam(gameWeekTeam)),
		submitTrade: (tradeData) => dispatch(submitTrade(tradeData))
	};
};

const mapStateToProps = ( state ) => {
	return {
		currentGameWeek: state.currentGameWeek,
		gameWeekTeam: state.activeGameWeekTeam,
		hasFailed: state.setGameWeekTeamFailure || state.addWaiverFailure,
		isPosting: state.setGameWeekTeamRequest || state.submitTradeRequest || state.addWaiverRequest,
		players: state.players,
		seasonPlayerStats: state.seasonPlayerStats,
		seasonUserTeams: state.seasonUserTeams,
		selectedLeague: state.selectedLeague,
		setGameWeekStatus: state.setGameWeekTeam,
		schedule: state.schedule,
		tradeStatus: state.submitTrade,
		unavailablePlayers: state.unavailablePlayers,
		waivers: state.waivers,
		waiverStatus: state.addWaiver
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayerModal));
