import React, {Component} from 'react';
import classNames from 'classnames';
import { Constants } from '../../helpers/Constants';
import getPlayerShortName from '../../helpers/getPlayerShortName';
import withPlayerPool from '../../HOC/withPlayerPool';
import Modal from '../Modal/Modal';
import PlayerModal from '../../components/Common/Players/PlayerModal';
import PaginationToggle from '../../components/Common/Toggle/PaginationToggle';
import StatPlayerCard from '../../components/StatPlayerCard';
import './StatPlayerPool.scss';

class StatPlayerPool extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
		this.statInfo = {
			'ALL': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'receivingYards', text: 'Receiving Yds'},
				{ key: 'receivingTouchdowns', text: 'Receiving Tds'},
				{ key: 'receivingReceptions', text: 'Receptions'},
				{ key: 'rushingYards', text: 'Rushing Yds'},
				{ key: 'rushingTouchdowns', text: 'Rushing Tds'},
				{ key: 'passingYards', text: 'Passing Yds'},
				{ key: 'passingTouchdowns', text: 'Passing Tds'},
			],
			'QB': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'passingYards', text: 'Passing Yds'},
				{ key: 'passingTouchdowns', text: 'Passing Tds'},
				{ key: 'rushingYards', text: 'Rushing Yds'},
				{ key: 'rushingTouchdowns', text: 'Rushing Tds'},
				{ key: 'passingInts', text: 'Interceptions'},
				{ key: 'fumblesLost', text: 'Fumbles'},
				{ key: 'passingSacked', text: 'Times Sacked'}
			],
			'RB': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'rushingYards', text: 'Rushing Yds'},
				{ key: 'rushingTouchdowns', text: 'Rushing Tds'},
				{ key: 'receivingYards', text: 'Receiving Yds'},
				{ key: 'receivingTouchdowns', text: 'Receiving Tds'},
				{ key: 'receivingReceptions', text: 'Receptions'},
				{ key: 'fumblesLost', text: 'Fumbles'}
			],
			'WR': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'receivingYards', text: 'Receiving Yds'},
				{ key: 'receivingTouchdowns', text: 'Receiving Tds'},
				{ key: 'receivingReceptions', text: 'Receptions'},
				{ key: 'fumblesLost', text: 'Fumbles'}
			],
			'TE': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'receivingYards', text: 'Receiving Yds'},
				{ key: 'receivingTouchdowns', text: 'Receiving Tds'},
				{ key: 'receivingReceptions', text: 'Receptions'},
				{ key: 'fumblesLost', text: 'Fumbles'}
			],
			'K': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'kickingFgMade', text: 'Field Goals'},
				{ key: 'kickingXkMade',  text: 'Extra Pts'},
			],
			'D': [
				{ key: 'totalPoints', secondaryKey: 'points', text: 'Total Pts'},
				{ key: 'defensiveForcedFumble', text: 'Fumbles Won'},
				{ key: 'defensiveInterceptions', text: 'Interceptions'},
				{ key: 'defensiveSacks', text: 'Sacks'},
				{ key: 'defensiveSafeties', text: 'Safeties'},
				{ key: 'touchdownsDefense', text: 'Defensive Tds'},
				{ key: 'defencePointsConceded', text: 'Pts Conceded'}
			]
		};

		this.onModalClose = this.onModalClose.bind(this);
		this.onPlayerClick = this.onPlayerClick.bind(this);
	}

	componentWillUnmount () {
		if(this.timeout) {
			this.timeout = clearTimeout(this.timeout);
		}
	}

	onModalClose() {
		document.body.classList.add('animate-modal-out');
		this.timeout = setTimeout(() => {
			this.setState({showModal: false});
			document.body.classList.remove('animate-modal-out');
		}, Constants.modalAnimationTimeout);
	}

	onPlayerClick(evt) {
		evt.preventDefault();
		const MAX_LOOKUPS = 5;
		let target = evt.target;
		let lookups = 0;
		while(target && target.nodeName !== 'TR' && lookups < MAX_LOOKUPS) {
			target = target.parentNode;
		}

		const playerId = target.getAttribute('data-player-id');
		const activePlayer = this.props.playersToShow.find(player => `${player.playerId}` === playerId);
		this.setState({showModal: true, activePlayer });
	}

	render () {
		const clsName = classNames('stat-player-pool', { 'stat-player-pool--focus': this.props.searchInFocus});
		const modalName = `${this.props.inWaiverMode && 'Waiver '}Player Modal - ${this.state.activePlayer ? getPlayerShortName(this.state.activePlayer) : ''}`;
		const canWaiverInWaiverMode = this.props.inWaiverMode && this.state.activePlayer && !this.state.activePlayer.alreadyOwned;

		return (
			<div className={clsName}>
				{this.props.children}
				<div className='stat-player-pool__list'>
					<table className='stat-table'>
						<thead className='stat-table__head'>
							<tr>
								<th className='stat-table__fixed-column'>#</th>
								<th className='stat-table__fixed-column stat-table__fixed-column--secondary'>Player</th>
								{this.statInfo[this.props.activeOption].map(row => <th key={row.key}>{row.text}</th>)}
							</tr>
						</thead>
						<tbody onClick={this.onPlayerClick}>
							{this.props.playersToShow.filter(p => !this.props.hideUnavailable || (this.props.hideUnavailable && !p.alreadyOwned) ).slice(this.props.playersFrom, this.props.playersFrom + this.props.pageSize)
							.map((player, index) => <StatPlayerCard key={player.playerId} disableOwned={true} player={player} rank={player.filteredRank || this.props.playersFrom + index + 1} dataSet={this.statInfo[this.props.activeOption]} />)}
						</tbody>
					</table>
				</div>
				<div className='stat-player-pool__paginate'>
					<PaginationToggle activeOption={this.props.activePage + 1} toggleOptions={this.props.paginationToggleOptions} onPageClick={this.props.onPageToggleClick} />
				</div>
				{this.state.showModal ? <Modal name={modalName} onClose={this.onModalClose}><PlayerModal defaultToggle={Constants.statToggleOptions.SEASON} waiverOnly={canWaiverInWaiverMode} player={this.state.activePlayer} onClose={this.onModalClose} /></Modal> : null}
			</div>
		);
	}
}

export default withPlayerPool(StatPlayerPool);
