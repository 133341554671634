import React from 'react';

const WaiverIcon = ({width = 16, height = '21', fill = '#fff', opacity = '.6' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 16 21">
		<path fill={fill} opacity={opacity} d="M15.963 8.819c-.073-.165-.233-.27-.41-.27H9.32L10.604.535c.032-.203-.07-.404-.253-.492-.182-.088-.398-.041-.531.114L.112 11.508c-.117.137-.145.33-.072.494.073.165.233.272.41.272h6.225L5.39 20.285c-.033.204.07.405.252.493.182.088.399.042.532-.114L15.882 9.31c.12-.133.151-.327.081-.493zM6.535 18.842l1.115-6.956c.022-.134-.015-.272-.103-.377-.087-.103-.213-.162-.347-.16H1.437l8.022-9.371-1.115 6.956c-.021.134.016.271.104.375.087.102.213.161.346.16h5.763l-8.022 9.373z"/>
	</svg>

);

export default WaiverIcon;
