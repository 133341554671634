import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {fetchH2HFixtures} from '../actions/fetchH2HFixtures';
import {fetchLeagueStandings} from '../actions/fetchLeagueStandings';
import {fetchLeagueGameWeekTeams} from '../actions/fetchLeagueGameWeekTeams';
import SpinnerLayer from '../components/SpinnerLayer/SpinnerLayer';
import ReactGA from 'react-ga';
import {GA} from '../helpers/ga';
import shortHash from 'short-hash';
import {Constants} from '../helpers/Constants';
import {fetchHistoricLeagueStandings} from '../actions/fetchHistoricLeagueStandings';
import {fetchNextH2HData} from '../actions/fetchNextH2HData';
import {fetchSchedule} from '../actions/fetchSchedule';
import getGameWeekState from '../helpers/getGameWeekState';

const withGameDay = (WrappedComponent) => {
	// ...and returns another component...
	return class extends Component {
		constructor(props) {
			super(props);
			this.state = {
				h2hGameWeekToggle: null,
				hasModifiedToggle: false,
				needsToFetch: false
			};
			this.onH2HFixtureToggle = this.onH2HFixtureToggle.bind(this);
			this.onUserClick = this.onUserClick.bind(this);
		}

		componentDidMount() {
			if(this.props.selectedLeague.leagueId && this.hasValidGameWeek()) {
				this.fetchData();
			} else {
				this.setState({needsToFetch: true});
			}
		}

		componentDidUpdate(prevProps) {
			if( this.state.needsToFetch && this.hasValidGameWeek() && this.props.selectedLeague.leagueId) {
				this.fetchData();
				const currentGameWeek = this.getCurrentGameWeek();
				this.setState({ h2hGameWeekToggle: currentGameWeek, needsToFetch: false });
			}
		}

		fetchData() {
			const { leagueId, season, type } = this.props.selectedLeague;
			const currentGameWeek = this.getCurrentGameWeek();

			this.props.fetchLeagueGameWeekTeams(leagueId, currentGameWeek);
			this.props.fetchHistoricLeagueStandings(leagueId, season, currentGameWeek);
			if(currentGameWeek - 1 > 0) {
				this.props.fetchHistoricLeagueStandings(leagueId, season, currentGameWeek - 1);
			}
			if(!this.props.schedule[type] || !this.props.schedule[type][currentGameWeek]) {
				this.props.fetchSchedule(type, season, currentGameWeek);
			}
			this.props.fetchLeagueStandings(leagueId);
			this.props.fetchH2HFixtures(leagueId, season);
			if(currentGameWeek < 4) {
				const previousSeason = Number(season) - 1;
				this.props.fetchNextH2HData(leagueId, previousSeason);
			} else {
				this.props.fetchNextH2HData(leagueId, season, currentGameWeek - 1);
			}
		}

		getCurrentGameWeek() {
			let currentGameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
			try {
				const gameWeekState = this.getGWState();
				if(gameWeekState === Constants.gameStates.POST) {
					currentGameWeek--;
				}

				return currentGameWeek;
			} catch(e) {
				return currentGameWeek;
			}
		}

		getGWState() {
			try {
				const currentGameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
				const scheduleData = this.props.schedule[this.props.selectedLeague.type][currentGameWeek];
				return getGameWeekState(scheduleData);
			} catch (e) {
				return;
			}
		}

		getHistoricStandings() {
			if(!this.props.selectedLeague.leagueId || !this.hasValidGameWeek() || this.props.isFetching) {
				return [];
			}
			const { leagueId, season } = this.props.selectedLeague;
			const gameWeek = this.getCurrentGameWeek();

			if(!this.props.historicLeagueStandings.hasOwnProperty(leagueId)) {
				return [];
			}

			return this.props.historicLeagueStandings[leagueId][season][gameWeek-1] || [];
		}

		getH2HFixtures() {
			const { leagueId, season } = this.props.selectedLeague;
			if(!this.props.h2hFixtures.hasOwnProperty(leagueId)) {
				return [];
			} else if(!this.props.h2hFixtures[leagueId].hasOwnProperty(season)) {
				return [];
			} else if(this.props.seasonUserTeams.length === 0 || !this.props.leagueStandings[leagueId] || this.props.leagueStandings[leagueId].length === 0) {
				return [];
			}

			const gameWeekState = this.getGWState();

			// Transform our data points to create what we need
			return this.props.h2hFixtures[leagueId][season].map(fixture => {
				const homeSeasonTeam = this.props.seasonUserTeams.find(team => team.seasonUserTeamId === fixture.homeTeamId);
				const awaySeasonTeam = this.props.seasonUserTeams.find(team => team.seasonUserTeamId === fixture.awayTeamId);

				const homeTeamData = this.props.leagueStandings[leagueId].standings.find(entry => entry.managerId === homeSeasonTeam.hashedUser);
				const awayTeamData = this.props.leagueStandings[leagueId].standings.find(entry => entry.managerId === awaySeasonTeam.hashedUser);
				fixture.homeTeam = homeTeamData.teamName;
				fixture.homeTeamShort = homeTeamData.shortTeamName;
				fixture.homeManager = homeTeamData.manager;
				fixture.homeManagerId = homeTeamData.managerId;
				if(gameWeekState !== Constants.gameStates.PRE) {
					fixture.homeTeamScore = fixture.homeTeamScore || homeTeamData.gwPoints || 0;
					fixture.homeTeamBenchScore = fixture.homeTeamBenchScore || homeTeamData.gwBenchPoints || 0;
					fixture.awayTeamScore = fixture.awayTeamScore || awayTeamData.gwPoints || 0;
					fixture.awayTeamBenchScore = fixture.awayTeamBenchScore || awayTeamData.gwBenchPoints || 0;
				}
				fixture.awayTeam = awayTeamData.teamName;
				fixture.awayTeamShort = awayTeamData.teamName;
				fixture.awayManager = awayTeamData.manager;
				fixture.awayManagerId = awayTeamData.managerId;
				return fixture;
			});
		}

		hasValidGameWeek() {
			const gameWeek = this.props.currentGameWeek[this.props.selectedLeague.type];
			return this.props.currentGameWeek.hasOwnProperty(this.props.selectedLeague.type) && !Number.isNaN(gameWeek);
		}

		onH2HFixtureToggle = (gameWeekToToggleTo) => {
			const seasonType = this.props.selectedLeague.type;
			if(gameWeekToToggleTo === 0 || gameWeekToToggleTo > Constants.numOfGameWeeks[seasonType]) {
				return;
			}

			if(!this.props.schedule[seasonType] || !this.props.schedule[seasonType][gameWeekToToggleTo]) {
				this.props.fetchSchedule(seasonType, Constants.currentSeason, gameWeekToToggleTo);
			}

			ReactGA.event({
				category: GA.CATEGORY.H2H,
				action: GA.ACTION.TOGGLE_H2H_GAME_WEEK
			});
			this.setState({
				hasModifiedToggle: true,
				h2hGameWeekToggle: gameWeekToToggleTo
			});
		};

		onUserClick = (teamId, category) => {
			ReactGA.event({
				category,
				action: GA.ACTION.TEAM_CLICK
			});
			const hash = shortHash(teamId);
			this.props.history.push(`/team/${hash}`);
		};

		render() {
			const leagueId = this.props.selectedLeague.leagueId;
			const standings = this.props.leagueStandings[leagueId] && this.props.leagueStandings[leagueId].lastCalculated ? this.props.leagueStandings[leagueId].standings : [];

			const gameWeek = this.state.h2hGameWeekToggle || this.props.currentGameWeek[this.props.selectedLeague.type];

			return (
				<>
					<WrappedComponent allFixtures={this.getH2HFixtures()} hasModifiedToggle={this.state.hasModifiedToggle} h2hGameWeekToggle={gameWeek} onH2HFixtureToggle={this.onH2HFixtureToggle} onUserClick={this.onUserClick} historicStandings={this.getHistoricStandings()} standings={standings} {...this.props} />
					{this.props.isFetching ? <SpinnerLayer/> : null}
				</>
			);
		}
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchH2HFixtures: (leagueId, season) => dispatch(fetchH2HFixtures(leagueId, season)),
		fetchHistoricLeagueStandings: (leagueId, season, gameWeek) => dispatch(fetchHistoricLeagueStandings(leagueId, season, gameWeek)),
		fetchLeagueGameWeekTeams: (leagueId, gameWeek) => dispatch(fetchLeagueGameWeekTeams(leagueId, gameWeek)),
		fetchLeagueStandings: (leagueId) => dispatch(fetchLeagueStandings(leagueId)),
		fetchNextH2HData: (leagueId, season, gameWeek) => dispatch(fetchNextH2HData(leagueId, season, gameWeek)),
		fetchSchedule: (type, season, gameWeek) => dispatch(fetchSchedule(type, season, gameWeek))
	};
};

/** Inherited state from HOC:
 *
 currentGameWeek: state.currentGameWeek,
 seasonUserTeams: state.seasonUserTeams,
 seasonUserTeamsIsFetching: state.seasonUserTeamRequest,
 seasonUserTeamsHasFailed: state.seasonUserTeamFailure,
 schedule: state.schedule,
 scheduleIsFetching: state.scheduleRequest,
 scheduleHasFailed: state.scheduleFailure,
 scores: state.scores,
 scoresIsFetching: state.scoresIsFetching,
 scoresHasFailed: state.scoresHasFailed,
 selectedLeague: state.selectedLeague
 */
const mapStateToProps = ( state ) => {
	return {
		isFetching: state.currentGameWeekRequest || state.leagueStandingsRequest || state.historicLeagueStandingsRequest || state.scheduleRequest || state.scoresRequest || state.h2hFixturesRequest || state.leagueGameWeekTeamsRequest || state.nextH2HDataRequest,
		hasErrored: state.leagueStandingsFailure || state.h2hFixturesFailure || state.historicLeagueStandingsFailure,
		h2hFixtures: state.h2hFixtures,
		historicLeagueStandings: state.historicLeagueStandings,
		leagueGameWeekTeams: state.leagueGameWeekTeams,
		leagueStandings: state.leagueStandings,
		nextH2HData: state.nextH2HData,
		selectedLeague: state.selectedLeague,
		schedule: state.schedule
	};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withGameDay);
