import React from 'react';

const Shape3 = ({fill = "#E00D83"}) => (
	<svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.47583 0.0828857L121 0.48099L120.716 60.2429C120.559 93.2484 93.6751 119.916 60.6693 119.806L0.907092 119.607L1.47583 0.0828857Z" fill={fill} />
	</svg>

);

export default Shape3;

