import { PUT_REJECT_TRADE_FAILURE, PUT_REJECT_TRADE_REQUEST, PUT_REJECT_TRADE_SUCCESS} from '../actions/rejectTrade';

export function rejectTradeFailure(state = false, action) {
	switch (action.type) {
		case PUT_REJECT_TRADE_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function rejectTradeRequest(state = false, action) {
	switch (action.type) {
		case PUT_REJECT_TRADE_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function rejectTrade(state = false, action) {
	switch (action.type) {
		case PUT_REJECT_TRADE_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
