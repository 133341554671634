const mergeSeasonStatsWithPlayers = (seasonPlayerStats, players) => {
	const transformedPlayers = seasonPlayerStats.map(seasonPlayer => {
		const player = players.find(p => p.statId === seasonPlayer.playerId || p.playerId === seasonPlayer.playerId);
		return {
			...player,
			...seasonPlayer
		};
	});
	const nonPlayingPlayers = players.filter(p => {
		return !transformedPlayers.find(tPlayer => tPlayer.playerId === p.playerId || tPlayer.statId === p.statId);
	}).map(p => {
		return {
			...p,
			...{
				stats: {},
				totalPoints: {
					passing: [],
					offensive: [],
					defensive: [],
					kicking: [],
					points: 0
				}
			}
		}
	});

	// Sort by highest scorers first
	return transformedPlayers.concat(nonPlayingPlayers).filter(p => p.pos).sort((a, b) => b.totalPoints.points - a.totalPoints.points);
};

export default mergeSeasonStatsWithPlayers;
