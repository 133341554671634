import { POST_START_SEASON_FAILURE, POST_START_SEASON_REQUEST, POST_START_SEASON_SUCCESS} from '../actions/startSeason';

export function startSeasonFailure(state = false, action) {
	switch (action.type) {
		case POST_START_SEASON_FAILURE:
			console.error(action);
			return action.error;
		default:
			return state;
	}
}
export function startSeasonRequest(state = false, action) {
	switch (action.type) {
		case POST_START_SEASON_REQUEST:
			return action.isPosting;
		default:
			return state;
	}
}
export function startSeason(state = false, action) {
	switch (action.type) {
		case POST_START_SEASON_SUCCESS:
			return action.data || state;
		default:
			return state;
	}
}
