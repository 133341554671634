import React from 'react';

const Football = ({fill = '#fff', opacity = '.6', width='20', height='20'}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" opacity={opacity} viewBox="0 0 47 46">
		<path fill={fill} d="M13.411 46c-6.04 0-10.407-1.509-10.776-1.64-.466-.166-.829-.531-.993-.993-.28-.783-6.68-19.336 7.855-33.871 14.535-14.533 33.089-8.134 33.87-7.857.465.166.828.531.992.992.28.784 6.68 19.335-7.855 33.87C28.862 44.144 20.11 46 13.411 46zm-8.91-4.49c3.511 1.02 18.056 4.301 29.684-7.326C45.708 22.66 42.48 7.952 41.502 4.489 37.988 3.466 23.445.185 11.815 11.815.296 23.338 3.524 38.047 4.5 41.51z"/>
		<path fill={fill} d="M13.331 45.96c-6.113 0-10.475-1.522-10.694-1.602-.466-.164-.83-.531-.997-.995C1.544 43.095-.699 36.67.291 28.41c.058-.475.32-.904.72-1.168.4-.267.894-.345 1.357-.213 3.873 1.096 7.478 3.233 10.424 6.179 2.948 2.946 5.085 6.55 6.182 10.424.13.461.053.957-.214 1.357-.266.4-.693.662-1.168.718-1.489.178-2.918.252-4.261.252zM4.497 41.5c1.623.455 5.685 1.402 10.674 1.127-1.038-2.614-2.641-5.04-4.7-7.098-2.06-2.06-4.486-3.662-7.098-4.7-.279 5.012.667 9.053 1.124 10.67zM44.08 19.034c-.15 0-.299-.02-.447-.061-3.873-1.097-7.477-3.234-10.423-6.18-2.947-2.948-5.083-6.553-6.18-10.426-.131-.461-.053-.957.213-1.357.267-.4.693-.662 1.17-.718 8.25-.99 14.684 1.253 14.952 1.35.465.164.83.53.996.994.097.268 2.34 6.694 1.35 14.953-.058.476-.32.904-.72 1.169-.273.182-.588.276-.91.276zM30.831 3.372c1.038 2.614 2.641 5.04 4.7 7.098 2.06 2.06 4.486 3.662 7.098 4.699.276-5.013-.667-9.054-1.124-10.672-1.622-.453-5.688-1.403-10.674-1.126zM15.044 32.6c-.42 0-.839-.16-1.16-.48-.64-.64-.64-1.68 0-2.32l15.914-15.916c.64-.64 1.679-.64 2.319 0 .64.64.64 1.68 0 2.319L16.202 32.119c-.32.32-.74.48-1.158.48z"/>
		<path fill={fill} d="M33.152 23.963c-.42 0-.838-.16-1.16-.48l-9.475-9.474c-.64-.64-.64-1.68 0-2.32.64-.639 1.68-.639 2.319 0l9.475 9.476c.64.64.64 1.679 0 2.318-.32.32-.74.48-1.159.48zM27.737 29.378c-.42 0-.839-.16-1.16-.48l-9.473-9.474c-.64-.64-.64-1.68 0-2.32.64-.64 1.68-.64 2.319 0l9.473 9.475c.64.64.64 1.68 0 2.32-.318.317-.739.479-1.159.479zM22.324 34.791c-.42 0-.838-.16-1.16-.48l-9.475-9.474c-.64-.64-.64-1.68 0-2.32.64-.64 1.68-.64 2.319 0l9.475 9.475c.64.64.64 1.68 0 2.32-.318.32-.738.48-1.159.48z"/>
	</svg>
);

export default Football;
