import React from 'react';
import classNames from 'classnames';
import PlayerTeamPosInfo from '../Common/Players/PlayerTeamPosInfo';
import Button from '../Common/Button';
import Header from '../Common/Header';
import getPlayerLongName from '../../helpers/getPlayerLongName';
import './TransferConfirm.scss';

const _renderPlayerContext = (headerText, player, clsName = 'player-context') => (
	<div className={clsName}>
		<div className='player-context__header-text'>{headerText}</div>
		<div className='player-context__player-name'>{getPlayerLongName(player)}</div>
		<PlayerTeamPosInfo position={player.pos} centerContent={true} team={player.nflTeam} />
	</div>
);

const TransferConfirm = ({confirmText, onConfirm, headerText, playerOut, playerIn, type}) => {
	const clsName = classNames('transfer-confirm', {
		'transfer-confirm--trade': type === 'trade',
		'transfer-confirm--waiver': type === 'waiver'
	});

	return (
		<div className={clsName}>
			<div className='transfer-confirm__header'>
				<Header headerLevel={'h1'} text={headerText} />
			</div>
			<div className='transfer-confirm__player-info'>
				{_renderPlayerContext('Outgoing Player', playerOut)}
				<div className='transfer-confirm__player-separator' />
				{_renderPlayerContext('Incoming Player', playerIn, 'player-context player-context--final')}
			</div>
			<div className='transfer-confirm__cta'>
				<Button type='tertiary' onClick={() => onConfirm(playerIn, playerOut)} text={confirmText} />
			</div>
		</div>
	)
};

export default TransferConfirm;
